import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";



import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";

import CandidatQG from './CandidatQG';
import CommuneQG from './CommuneQG';

const calculTotals = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const DistrictStatQG = ({ setVisibleDistrict, region, province, ecole, libre }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listDistrict, setListDistrict] = useState([]);
    const [visibleCommune, setVisibleCommune] = useState(false);
    const [district_d, setDistrict_d] = useState('');
    const [region_r, setRegion_r] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);
    const [lists, setLists] = useState([]);
    const [visibiliteCandidat, setVisibiliteCandidat] = useState(false)
    const detailList = (value) => {
        setDistrict_d(value)
        setVisibiliteCandidat(true)
    }

    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra,setIsMpotra]=useState(false);
    const [listss, setListss] = useState([]);
    const getListDistrict = () => {
        fetch(url.urlHttp + "candidat-midina/candidat-libre-district?region=" + region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setTotal_additionel(calculTotals(data.data.map(row => row.total_1)))
                setCandidat_ecole_1(calculTotals(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotals(data.data.map(row => row.candidat_libre_1)))
                console.log("DATA RETOUR : ", data.data);
                setListDistrict(data.data)
                setLists(data.data);
                setListss(data.data);
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListDistrict()
    }, [])
    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "25%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 2,
            fontSize: 9
        }
    });
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.district.toUpperCase().includes(value.toUpperCase()));
        setListDistrict(filteredData);
        setListss(filteredData);
    }

    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'black' }}>Types de candidats au niveau  <Text style={{ color: 'green' }}>Région</Text> de <Text style={{ color: 'red' }}>{region}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province  : <Text style={{ color: 'red' }}>{province}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de  Candidats  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Candidats d'École : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>  Candidats Libres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    {/* <View><Text style={{ fontWeight: 'bold' }}>Liste District </Text></View> */}
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>District</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats d'École</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats Libres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
                            </View>
                        </View>
                        {
                            listDistrict.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.district}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_ecole)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_libre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.total)}</Text>
                                    </View>

                                    {/* <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                    </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const stat = {
        sumCandidatLibre: listDistrict.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: listDistrict.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: listDistrict.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const detailDistrict = (value) => {
        setDistrict_d(value)
        setRegion_r(region)
        setVisibleCommune(true)
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listDistrict]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListDistrict(sortedWords);
        } else {
            setMilahatra(true);
            setListDistrict(listss);
        }
        console.log(milahatra);
    };
    if (visibleCommune == true && district_d != null) {
        return (
            <CommuneQG setVisibleCommune={setVisibleCommune} district={district_d} region={region_r} province={province} libre={libre} ecole={ecole} />
        );
    } else if (visibiliteCandidat == true && district_d != null) {
        return (
            <>
                <CandidatQG setVisibleCandidat={setVisibiliteCandidat} etablissement={''} types={'District'} commune={''} district={district_d} province={province} region={region} />
            </>
        )
    } else {
        return (
            <div className="content">

                <Row>
                    {
                        isShow == false && (
                            <>
                                <Col md="12">
                                    {/* Affichez un message d'erreur s'il y a une erreur */}
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <Row className="justify-content-center">
                                        <Col md="4">
                                            <Card style={{ height: "100%" }}>
                                                <CardHeader>
                                                    <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.1rem' }}> Au niveau Région </span>
                                                        <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red" }}>{province}</a></span>
                                                        <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red" }}>{region}</a></span>
                                                    </CardTitle>
                                                </CardHeader>
                                            </Card>
                                        </Col>
                                        <Col md="4">
                                            <Card style={{ height: "100%" }}>
                                                <CardHeader>
                                                    <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        {ecole === true ? <span style={{ fontSize: '1rem', color: "black" }}>  
                                                        Candidats d'École: <a style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}  
                                                        <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(candidat_ecole_1)}</span>
                                                        </a></span> : <br />}
                                                        {libre === true ? <span style={{ fontSize: '1rem', color: "black" }}>  
                                                        Candidats Libres : <a style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)} 
                                                          <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(candidat_libre_1)}</span>
                                                          </a></span> : <br />}
                                                        <span style={{ fontSize: '1rem', color: "black" }}> Total de Candidats : <a style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumTotal)} 
                                                         <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(total_additionel)}</span>
                                                         </a></span>
                                                    </CardTitle>
                                                </CardHeader>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )
                    }
                    {
                        isShow==false && (
                            <Col className="pr-1" md="4">

                                <FormGroup>
                                    <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>District :</a></label>
                                    <input
                                        type={'text'}
                                        style={{ fontSize: '0.9rem' }}
                                        className={'form-control'}
                                        placeholder={'Rechercher District'}
                                        onChange={e => filtre(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        )
                    }

                </Row>
                <Row>

                    <Col md="12">

                        <Card style={{ marginTop: '40px' }}>
                            <CardBody>
                                {
                                    isShow==false && (
                                        <CardHeader>
                                            <CardTitle style={{ position: 'fi', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>
                                                    Au niveau Région </span>
                                                    <span style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>
                                                    <a style={{ fontSize: '1rem', color: "black", }}>Province :    <a style={{ fontSize: '1rem', color: "red" }}>{province}</a></a>&nbsp;<br/>
                                                    <a style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red" }}>{region}</a></a><br/>
                                                    {ecole === true ? <span style={{ fontSize: '1rem', color: "black", fontWeight: 'bold' }}>  Candidats d'École: <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</a><br/></span> : <br />}
                                                    {libre === true ? <span style={{ fontSize: '1rem', color: "black" , fontWeight: 'bold'}}>  Candidats Libres : <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</a><br/></span> : <br />}
                                                    <span style={{ fontSize: '1rem', color: "black" , fontWeight: 'bold'}}> Total de Candidats : <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</a></span></span>
                                                    
                                            </CardTitle>
                                        </CardHeader>
                                    )
                                }
                               
                                <Button className="btn btn-outline-primary " onClick={e => { setVisibleDistrict(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                </Button>
                                <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"National"}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                    }
                                </PDFDownloadLink>
                                {
                                    isShow==false && (
                                        <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                            <Table striped>
                                                <thead className="text-primary sticky-header">
                                                    <tr>
                                                        <th style={{ fontSize: '1rem' }} onClick={() => handleSort('asc', 'district')}>District &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                        {ecole === true ? <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Nombre de candidats d'école &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                                        {libre === true ? <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'candidat_libre')}>Nombre de Candidats Libres &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                                        <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'total')}>Total de Candidats &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        listDistrict.map((row, index) => (
                                                            <>
                                                                <tr>
                                                                    <td style={{  textAlign: 'left' }}>
                                                                        {row.district}
                                                                    </td>
                                                                    {ecole === true ?
                                                                        <td style={{ fontSize: '1.5rem' }}>
                                                                            {ANRDataUtils.formatNombre(row.candidat_ecole)}
                                                                            <br />{isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                                +{ANRDataUtils.formatNombre(row.candidat_ecole_1)}
                                                                            </span>)}
                                                                        </td>
                                                                        :
                                                                        ''}
                                                                    {libre === true ?
                                                                        <td style={{ fontSize: '1.5rem' }}>

                                                                            {ANRDataUtils.formatNombre(row.candidat_libre)}
                                                                            <br />{isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                                +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                                            </span>)}
                                                                        </td> :
                                                                        ''}
                                                                    <td style={{ fontSize: '1.5rem' }}>

                                                                        {ANRDataUtils.formatNombre(row.total)}
                                                                        <br />{isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                            +{ANRDataUtils.formatNombre(row.total_1)}
                                                                        </span>)}
                                                                    </td>
                                                                    <td >
                                                                        {
                                                                            row.total > 0 ?
                                                                                <button className={'btn btn-danger'} onClick={(e) => { detailDistrict(row.district) }}>Afficher Centre</button>
                                                                                :
                                                                                <button className={'btn btn-danger disabled'} >Afficher Centre</button>
                                                                        }
                                                                    </td>
                                                                    {/* <td style={{ fontSize: '1.5rem' }}>
                                                                {
                                                                    row.total > 0 ?
                                                                        <button className={'btn btn-danger'} onClick={(e) => { detailList(row.district) }}>Afficher listes</button>
                                                                        :
                                                                        <button className={'btn btn-danger disabled'} >Afficher listes</button>
                                                                }
                                                            </td> */}

                                                                </tr>
                                                            </>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DistrictStatQG;
