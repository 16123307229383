import React, { useState } from "react";
import { Button, FormGroup, Input, Row, Col, CardHeader, Card, Table,CardBody,Label } from "reactstrap";
import url from "../../urlConfig";
import { useEffect } from "react";
import authHeader from "../../services/AuthHeader";

const ResponsableQG = ({ region }) => {
    const auth_data = authHeader()
    console.log("Mitantsoa ",region);
    const [article, setAricle] = useState([]);
    const getQuartierGeneral = () => {
        fetch(url.urlHttp + "responsable-qg/list?id_infra=" + region.id_infra, {
            method: "GET",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("DATA RETOUR : ", data.data);
                setAricle(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getQuartierGeneral();
      }, []);
    
    return (
        <Row>
                    <Table striped className=" ml-5">
                        <thead className="text-primary ml-5">
                            <tr>
                                <th >Nom et prenom</th>
                                <th>Contact </th>
                                <th>Fonction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                 article.map((row, index) => (
                                    <>
                                        <tr>
                                           <td>{row.nom}</td>
                                           <td>{row.contact}</td>
                                           <td>{row.fonction}</td>
                                        </tr>
                                    </>
                                ))}
                        </tbody>
                    </Table>
        </Row>
    );
};

export default ResponsableQG;
