import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Table, Label, Input, Button, FormGroup } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import url from 'urlConfig';
import LoaderUtils from "../../components/Shared/Loading";
import { Link } from 'react-router-dom';
import ANRDataUtils from 'utils/Utils';
import authHeader from 'services/AuthHeader';
import Legende from './Legende';

const SalleLG = ({ setVisibleSalle, provinces, region, district, centre, etablissement, materiel }) => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState([]);
    const [centre_sec, setCentre_sec] = useState(null);
    const [data_provinces_elect, setData_provinces_elect] = useState([]);
    const [data_provinces_feuille, setData_provinces_feuille] = useState([]);
    const [data_provinces_sujet, setData_provinces_sujet] = useState([]);
    const [general, setGeneral] = useState(true);
    const [pro, setPro] = useState(true);
    const [tech, setTech] = useState(true);
    const [list, setList] = useState([]);
    const [materiels, setMateriels] = useState('');
    const [materielFeuille, setMaterielFeuille] = useState([]);
    const [materielSujet, setMaterielSujet] = useState([]);
    const [materielElect, setMaterielElect] = useState([]);
    const [responsable, setResponsable] = useState('');
    const [receptionniste, setReceptionniste] = useState('');
    const [date_envoye, setDate_envoye] = useState('');
    const [bases, setBases] = useState([]);
    const [base_etab, setBaseEtab] = useState([]);
    const [nombre_salle, setNombre_salle] = useState([]);
    const [tanana, setTanana] = useState([]);
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const [listMisyApres, setListMisyApres] = useState([])
    const [listTsymisyApres, setListTsyMisyApres] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [img, setImg] = useState(null);
    const [nombres, setNombres] = useState([]);
    const [nombre_sal, setNombre_sal] = useState([]);

    
    const [nombre_total, setNombre_total] = useState([]);
    const [nombre_total_prefecture, setNombre_total_prefecture] = useState([]);
    const [nombre_total_district, setNombre_total_district] = useState([]);
    const [nombre_total_centre, setNombre_total_centre] = useState([]);

    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].date_envoye != null) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }

    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }



    const handleGetListEtab = async () => {
        await fetch(url.urlHttp + "materiel/data-salle?materiel=" + materiel + "&&etab=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv etab ato : ", data.data);
                setBaseEtab(data.data);
                getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const filtre = (value) => {
        const filteredData = base_etab.filter(item => {
            if (item.salles && typeof item.salles === 'string') {
                return item.salles.toUpperCase().includes(value.toUpperCase());
            }
            return false;
        });
        getsListTsisy(filteredData);
    }

    const handleGetListBaseAvant = async () => {
        await fetch(url.urlHttp + "materiel/base-avant-salle?etab=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA base avant etab", data.data);
                setBases(data.data);
                // getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleGetListNombreMatiere = async () => {
        await fetch(url.urlHttp + "materiel/nombre_serie_matiere_etab?centre=" + centre, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA matiere apres", data.data);
                setNombres(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreMateriel = async (materiels) => {
        await fetch(url.urlHttp + "materiel/count-salle?materiel=" + materiels + "&&type=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA materiel", data.data);
                if (materiels == "Electricites") {
                    setMaterielElect(data.data);
                } else if (materiels == "Sujets") {
                    setMaterielSujet(data.data)
                } else {
                    setMaterielFeuille(data.data)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreSalle = async () => {
        fetch(url.urlHttp + "materiel/nombre-salle?etab=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                setNombre_sal(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotal = async () => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-province-total", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                setNombre_total(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalPref = async () => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-prefecture-total", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                setNombre_total_prefecture(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalDist = async () => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-district-total", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                setNombre_total_district(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalCentre = async () => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-centre-total", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                setNombre_total_centre(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }


    // const detailsCentre = (value,mat) => {
    //     setCentre_sec(value);
    //     setMateriels(mat);
    //     setVisisbleEtab(true);
    //     //setVisibleCentre(true);
    // }

    const handleFile = (e, id, type) => {
        const file = e.target.files[0];
        const table = "materiel_etab_salle";
        let urls = "";
        var data = null;
        e.preventDefault();
        if (!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result;
            if (type == "env") {
                data = {
                    id: id,
                    table: table,
                    column: "photo_envoi",
                    value: base64String
                }
                urls = url.urlHttp + "materiel/update";
                fetch(urls, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': auth_data.content_type,
                        'Authorization': auth_data.authorization
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        handleGetListEtab();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                data = {
                    id: id,
                    table: table,
                    column: "photo_recu",
                    value: base64String
                }
                urls = url.urlHttp + "materiel/update";
                fetch(urls, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': auth_data.content_type,
                        'Authorization': auth_data.authorization
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        handleGetListEtab();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
    }

    const handleSort = (order, key) => {
        const sortedWords = [...list]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setList(sortedWords);
    };

    var totalEnvoyeElectricite = 0;
    var totalRecuElectricite = 0;
    var totalResteElectricte = 0;
    var totalEnvoyeFeuille = 0;
    var totalRecuFeuille = 0;
    var totalResteFeuille = 0;
    var totalEnvoyeSujet = 0;
    var totalRecuSujet = 0;
    var totalResteSujet = 0;
    var nombre_salles = 0;
    var nombre_province = 0;
    var nombreMisyFeuilles = 0;
    var nombreMisyElect = 0;
    var nombreMisySujet = 0;
    var totalEtab = 0;
    var totalSalle = 0;
    var totalCandidat = 0;
    var totalFeuille = 0;
    var totalSujet = 0;
    var nombre_sl = 0;

    let recu_totalement = 0;
    let recu_partiellement = 0;
    let non_envoye = 0;
    let recu_totalement_pref = 0;
    let recu_partiellement_pref = 0;
    let non_envoye_pref = 0;
    let recu_totalement_dist = 0;
    let recu_partiellement_dist = 0;
    let non_envoye_centre = 0;
    let recu_totalement_centre = 0;
    let recu_partiellement_centre = 0;
    let non_envoye_dist = 0;

    const accumulated = {};

    bases.forEach(item => {
        const etablissement = item.etablissement;
        if (!accumulated[etablissement]) {
            console.log(item.nombre_salle, 'ee')
            accumulated[etablissement] = true;
            totalEtab += item.nombre_centre;
            totalSalle += item.nombre_salle;
            totalCandidat += item.nombre_candidat
        }
    });

    for (let i = 0; i < bases.length; i++) {
        if (materiel == "Electricites") {
            totalEnvoyeElectricite += bases[i].electricites;
        }
        if (materiel == "Feuilles") {
            totalEnvoyeFeuille += bases[i].feuilles;
        }
        if (materiel == "Sujets") {
            totalEnvoyeSujet += bases[i].sujets;
        }
        // totalRecuElectricite += data_provinces_elect[i].q_r;
        // totalResteElectricte += data_provinces_elect[i].reste;
    }

    for (let i = 0; i < nombres.length; i++) {
        totalFeuille += nombres[i].nombre_candidat * nombres[i].nombre_matiere;
        totalSujet += nombres[i].nombre_candidat * nombres[i].nombre_matiere;
    }

    // for (let i = 0; i < bases.length; i++) {
    //     totalEnvoyeFeuille += bases[i].q_e;
    //     totalRecuFeuille += data_provinces_feuille[i].q_r;
    //     totalResteFeuille += data_provinces_feuille[i].reste;
    // }

    for (let i = 0; i < data_provinces_sujet.length; i++) {
        totalEnvoyeSujet += data_provinces_sujet[i].q_e;
        totalRecuSujet += data_provinces_sujet[i].q_r;
        totalResteSujet += data_provinces_sujet[i].reste;
    }

    for (let i = 0; i < nombre_salle.length; i++) {
        nombre_salles += nombre_salle[i].count;
    }

    for (let i = 0; i < tanana.length; i++) {
        nombre_province += tanana[i].count;
    }

    for (let i = 0; i < materielFeuille.length; i++) {
        nombreMisyFeuilles += materielFeuille[i].count;
    }

    for (let i = 0; i < materielSujet.length; i++) {
        nombreMisySujet += materielSujet[i].count;
    }

    for (let i = 0; i < materielElect.length; i++) {
        nombreMisyElect += materielElect[i].count;
    }

    for (let i = 0; i < nombre_sal.length; i++) {
        nombre_sl += nombre_sal[i].count;
    }

    for (let index = 0; index < nombre_total.length; index++) {
        recu_partiellement += nombre_total[index].recu_partiellement;
        recu_totalement += nombre_total[index].recu_totalement;
        non_envoye += nombre_total[index].non_envoye;
    }

    for (let index = 0; index < nombre_total_prefecture.length; index++) {
        recu_partiellement_pref += nombre_total_prefecture[index].recu_partiellement;
        recu_totalement_pref += nombre_total_prefecture[index].recu_totalement;
        non_envoye_pref += nombre_total_prefecture[index].non_envoye;
    }


    for (let index = 0; index < nombre_total_district.length; index++) {
        recu_partiellement_dist += nombre_total_district[index].recu_partiellement;
        recu_totalement_dist += nombre_total_district[index].recu_totalement;
        non_envoye_dist += nombre_total_district[index].non_envoye;
    }

    for (let index = 0; index < nombre_total_centre.length; index++) {
        recu_partiellement_centre += nombre_total_centre[index].recu_partiellement;
        recu_totalement_centre += nombre_total_centre[index].recu_totalement;
        non_envoye_centre += nombre_total_centre[index].non_envoye;
    }


    useEffect(() => {
        handleGetNombreMateriel(materiel);
        // handleGetNombreMateriel("Feuilles");
        // handleGetNombreMateriel("Sujets");
        handleGetListBaseAvant();
        handleGetListEtab();
        handleGetListNombreMatiere();
        handleGetNombreTotal();
        handleGetNombreTotalPref();
        handleGetNombreTotalDist();
        handleGetNombreTotalCentre();
        // handleGetListBaseAvant();
        // handleGetListBaseApres();
        // handleGetNombreSalle();
        // handleGetNombreTanana();
        handleGetNombreSalle();
    }, [])

    if (null) {
        return (
            <>
                {/* <EtablissementLG setVisisbleEtab={setVisisbleEtab} provinces={provinces} region = {region} district = {district} centre = {centre_sec} materiel = {materiels}/> */}
            </>
        );
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> PROVINCE </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> PREFECTURE </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> DISTRICT </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row><br /><br />
                <Row>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> CENTRE </span><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> ETABLISSEMENT </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="4"><br />
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Salle :</a></label>
                            <input
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechecher Salle'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup><br />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle>
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "20px" }}> Province : <a style={{ color: "red" }}>{provinces}</a>, Region : <a style={{ color: "red" }}>{region}</a>, District : <a style={{ color: "red" }}>{district}</a> , Centre : <a style={{ color: "red" }}>{centre}</a>, Etablissement d'examen : <a style={{ color: "red" }}>{etablissement}</a> </span>
                                    </CardTitle>
                                </CardHeader>
                                <Button className="btn btn-outline-primary" onClick={voirPlus}>
                                    {isVoi ? "Fermer" : "Voir Plus"}
                                </Button>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <Button className="btn btn-outline-primary" onClick={e => { setVisibleSalle(false) }}>retour</Button>
                                    <div>
                                        <Legende />
                                    </div>
                                    <Table style={{ marginTop: "40px" }} striped>
                                        <thead className="text-primary">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}>Salle&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Article&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Quantite envoyée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                {isVoi == true && (
                                                    <>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Camion transportée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Photo à l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Responsable de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Fonction du responsable de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Contact du responsable d'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Etat à l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    </>
                                                )}
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Quantite recue&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                {isVoi == true && (
                                                    <>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Photo à la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Responsable de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Fonction du responsable de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Contact de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Etat recu&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Observation&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listMisy.map((row, index) => {
                                                    let couleur = ''; // declare couleur here

                                                    if (row.q_e > row.q_r) {
                                                        couleur = 'red';
                                                    } else if (row.q_r > row.q_e) {
                                                        couleur = 'yellow';
                                                    } else if(row.q_r == row.q_e){
                                                        couleur = '#adff2f';
                                                    } else if(row.q_r == 0 && row.q_e == 0){
                                                        couleur = 'rgb(94, 199, 56)';
                                                    }
                                                    else if(row.q_r > 0 && row.q_e == 0){
                                                        couleur = 'rgb(213, 78, 11)';
                                                    }

                                                    return (
                                                        <tr key={index} style={{ backgroundColor: couleur }}>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.salles}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.materiel}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {ANRDataUtils.formatNombre(row.quantite_envoyer)}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.date_envoye}
                                                            </td>
                                                            {isVoi && (
                                                                <>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.vehicule_transport}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.photo_envoi != null ?
                                                                            <img src={row.photo_envoi} alt="Photo Envoi" /> :
                                                                            <input type="file" onChange={(e) => handleFile(e, row.id, 'env')} />
                                                                        }
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.responsable_envoye}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.fonction_envoi}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.contact_envoye}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.etat_envoye}
                                                                    </td>
                                                                </>
                                                            )}
                                                            <td style={{ fontSize: '12px' }}>
                                                                {ANRDataUtils.formatNombre(row.quantite_recu)}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.date_reception}
                                                            </td>
                                                            {isVoi && (
                                                                <>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.photo_recu != null ?
                                                                            <img src={row.photo_recu} alt="Photo Reception" /> :
                                                                            <input type="file" onChange={(e) => handleFile(e, row.id, 'rec')} />
                                                                        }
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.responsable_reception}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.fonction_responsable}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.contact_reception}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.etat_recu}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.observation}
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    )
                                                })}

                                            {
                                                isVoi == true && (
                                                    listTsymisy.map((row, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.salles}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.materiel}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {ANRDataUtils.formatNombre(row.quantite_envoyer)}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.date_envoye}
                                                                </td >
                                                                {isVoi == true && (
                                                                    <>
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.vehicule_transport}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {/* {row.photo_envoi != null ?
                                                                <img src={row.photo_envoi} alt="Photo Envoi" /> :
                                                                <input type="file" onChange={(e) => handleFile(e, row.responsable_envoye)} />
                                                            } */}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.responsable_envoye}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.fonction_envoi}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.contact_envoye}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.etat_envoye}
                                                                        </td >
                                                                    </>
                                                                )}
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {ANRDataUtils.formatNombre(row.quantite_recu)}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.date_reception}
                                                                </td >
                                                                {isVoi == true && (
                                                                    <>
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {/* {row.photo_recu != null ?
                                                                <img src={row.photo_recu} alt="Photo Envoi" /> :
                                                                <input type="file" />
                                                            }
                                                            <img src={img} alt="Photo Envoi" /> */}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.responsable_reception}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.fonction_responsable}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.contact_reception}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.etat_recu}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.observation}
                                                                        </td >
                                                                    </>
                                                                )}
                                                                {/* <td style={{ fontSize: '12px' }}>
                                                    {
                                                         row.q_e > 0 ?
                                                            <button className={'btn btn-danger'} onClick={() => { detailsCentre(row.region) }}>Afficher Etablissement</button>
                                                            :
                                                            <button className={'btn btn-danger disabled'} >Afficher Etablissement</button>
                                                    }
                                                </td> */}

                                                            </tr>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SalleLG;