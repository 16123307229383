import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import url from "../../urlConfig";
import authHeader from "services/AuthHeader";

const FormulaireInfra = ({ isOpen, toggle }) => {

    const auth_data = authHeader()
    const [commune, setCommune] = useState('');
    const [district, setdistrict] = useState('');
    const [region, setRegion] = useState('');
    const [fokontany, setFokontany] = useState('');
    const [nom_demandeur, setNom_demandeur] = useState('');
    const [fonction_dem, setFonction_dem] = useState('');
    const [tel_dem, setTel_dem] = useState('');
    const [nom_prop_qg, setNom_prop_qg] = useState('');
    const [adresse_prop_qg, setAdresse_prop_qg] = useState('');
    const [tel_prop_qg, setTel_prop_qg] = useState('');
    const [meuble_equipe, setMeuble_equipe] = useState(false); // Vous aviez une faute de frappe ici
    const [eau, setEau] = useState(false); // Vous aviez une faute de frappe ici
    const [electricite, setElectricite] = useState(false); // Vous aviez une faute de frappe ici
    const [nombre_de_pieces,setNombre_de_piece    ] = useState(0);
    const [surface_m2, setSurface_m2] = useState(0);
    const [prix_alocation, setPrix_alocation] = useState(0);
    const [date_disponibilite, setDate_disponibilite] = useState('');
    const [peinture, setPeinture] = useState('');
    const [besoin, setBesoin] = useState('');
    const [photose, SetPhostose] = useState(''); // Vous aviez une faute de frappe ici
    const [wc, setWc] = useState('');
    const [parking, setParking] = useState('');
    const [mode_acquisition, setMode_acquisition] = useState('');
    const [akaiky, setAkaiky] = useState('');
    const [sampanan_dalana, setSampanan_dalana] = useState('');
    const [quartier, setQuartier] = useState('');
    const [alavirana, setAlavirana] = useState(0);
    const [fanatsarana, setFanatsarana] = useState('');
    const [fanadiovana, setFanadiovana] = useState('');
    const [lalandehibe, setLalandehibe] = useState(''); // Vous aviez une faute de frappe ici
    const [selectedImage, setSelectedImage] = useState(null);
    // const [selectedValue, setSelectedValue] = useState('');


    const handleMeubleChange = () => {
        setMeuble_equipe(!meuble_equipe); // Inverse la valeur actuelle de meuble_equipe
    };

    const handleEauChange = () => {
        setEau(!eau); // Inverse la valeur actuelle de eau
    };

    const handleElectriciteChange = () => {
        setElectricite(!electricite); // Inverse la valeur actuelle de electricite
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Utilisez la fonction ajouterAuTableau ici pour soumettre les données au serveur
        Ajouter();


    };

    const [selectedValue, setSelectedValue] = useState('');

    const convertImageToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);
                const dataURL = canvas.toDataURL('image/jpeg'); // Vous pouvez spécifier le format ici (jpeg, png, etc.)
                resolve(dataURL);
            };
            img.onerror = (error) => {
                reject(error);
            };
            img.src = url;
        });
    };
    const [photos, setphotos] = useState([])
    //Upload image
    const handleImageUpload = (e) => {
        const selectedImages = Array.from(e.target.files);
        if (selectedImages) {
            // User has selected an image
            setSelectedImage(selectedImages);
        } else {
            // User has not selected an image (cleared the input)
            setSelectedImage(null);
        }
        // Create an array of image previews using the FileReader API
        const imagePreviews = selectedImages.map((image) => URL.createObjectURL(image));

        // SetPhostose(imagePreviews);
        convertImageToBase64(imagePreviews)
            .then((base64Image) => {
                // selectedImages(base64Image)
                // let i=0;
                alert(base64Image)
                SetPhostose(base64Image)
            })
            .catch((error) => {

                console.error('Erreur lors de la conversion de l\'image en Base64 :', error);
            });

    };


    // Function to handle dropdown value change
    const handleSelectChange = (e) => {
        const value = e.target.value;
        setSelectedValue(value);
    };
    const Ajouter = async () => {

        // Effectuez ici les actions nécessaires avec les données formData
        const data = {
            region: region,
            district: district,
            commune: commune,
            fokotany: fokontany,
            nom_demandeur: nom_demandeur,
            fonction_dem: fonction_dem,
            tel_dem: tel_dem,
            nom_prop_qg: nom_prop_qg,
            adresse_prop_qg: adresse_prop_qg,
            tel_prop_qg: tel_prop_qg,
            meuble_equipe: meuble_equipe,
            eau: eau,
            electricite: electricite,
            nombre_de_pieces: nombre_de_pieces,
            surface_m2: surface_m2,
            prix_alocation: prix_alocation,
            date_disponibilite: date_disponibilite,
            peinture: peinture,
            besoin: besoin,
            photose: photose,
            parking:parking,
            mode_acquisition:mode_acquisition,
            akaiky:akaiky,
            sampanan_dalana:sampanan_dalana,
            quartier:quartier,
            alavirana:alavirana,
            fanatsarana:fanatsarana,
            fanadiovana:fanadiovana,
            lalandehibe:lalandehibe
        }

        await fetch(url.urlHttp + "quartier-general/nouveauf", {
            method: 'POST',
            headers: { 
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },

            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                alert(JSON.stringify(data.data));
            })
            .catch(error => {
                console.log(error);

            });
    };
    if (!isOpen) { return null };
    return (
        <Form >
            <Row>
                <Col>
                    <FormGroup>
                        <h2 className="text-primary" style={{ margin: '10px', fontSize: '17px' }}>PHOTOS</h2>
                        <Input
                            multiple
                            accept="image/*"
                            type="file"
                            id="photo"
                            value={photos}
                            onChange={handleImageUpload}
                        />
                    </FormGroup>

                </Col>
                <Col>
                    <div>
                        <Row>
                            {selectedImage ? (<img src={photose} alt={`Image `} width='150px' height='auto' style={{ margin: '6px' }} />) : ('')}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row style={{ borderBottom: '1px solid #f0f0f0', paddingBottom: '16px', marginBottom: '10px' }}>
                <Col style={{ border: '1px solid #f0f0f0', borderRadius: '5px', padding: '10px', margin: '10px' }}>
                    <FormGroup>
                        <Label for="nomDem">NOM DU DEMANDEUR:</Label>
                        <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                            placeholder="Nom du demandeur"
                            value={nom_demandeur}
                            type="text"
                            onChange={e => setNom_demandeur(e.target.value)}
                        >
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="fonctionDem">FONCTION:</Label>
                        <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                            placeholder="Fonction"
                            value={fonction_dem}
                            type="text"
                            onChange={e => setFonction_dem(e.target.value)}
                        >
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="telDem">TELEPHONE: </Label>
                        <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                            placeholder="Téléphone"
                            type="text"
                            value={tel_dem}
                            onChange={e => setTel_dem(e.target.value)}
                        >
                        </Input>
                    </FormGroup>
                </Col>
                <Col style={{ border: '1px solid #f0f0f0', borderRadius: '5px', padding: '10px', margin: '10px' }}>
                    <FormGroup>
                        <Label for="nomPropQG">NOM DU PROPRIETAIRE QG: </Label>
                        <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                            placeholder="Nom du propriétaire QG"
                            type="text"
                            value={nom_prop_qg}
                            onChange={e => setNom_prop_qg(e.target.value)}
                        >
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label form="adressePropQG">ADRESSE: </Label>
                        <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                            placeholder="Adresse"
                            type="text"
                            value={adresse_prop_qg}
                            onChange={e => setAdresse_prop_qg(e.target.value)}
                        >
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="telPropQG">TELEPHONE:</Label>
                        <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                            placeholder="Téléphone"
                            type="text"
                            value={tel_prop_qg}
                            onChange={e => setTel_prop_qg(e.target.value)}
                        >
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>

                    <FormGroup>
                        <Label for="fokotany">Fokotany</Label>
                        <Input
                        placeholder="Fokotany"
                            type="text"
                            name="fokotany"
                            value={fokontany}
                            onChange={e => setFokontany(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="commune">Commune</Label>
                        <Input
                        placeholder="Commune"
                            type="text"
                            value={commune}
                            onChange={e => setCommune(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="district">District</Label>
                        <Input
                        placeholder="District"
                            type="text"
                            value={district}
                            onChange={e => setdistrict(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="region">Region</Label>
                        <Input
                            placeholder="Region"
                            value={region}
                            type="text"
                            onChange={e => setRegion(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="region">Quartier</Label>
                        <Input
                            placeholder="quartier"
                            value={quartier}
                            type="text"
                            onChange={e => setQuartier(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="region">Distance par rapport au BV</Label>
                        <Input
                            placeholder="distance"
                            value={alavirana}
                            type="number"
                            onChange={e => setAlavirana(e.target.value)}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="nombreDePieces">Nombre de Pièces</Label>
                        <Input
                            type="number"
                            name="nombreDePieces"
                            value={nombre_de_pieces}
                            onChange={e => setNombre_de_piece(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>Eau</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="eau"
                                    value="Eny"
                                    checked={eau === 'Eny'}
                                    onChange={() => setEau('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="eau"
                                    value="Tsia"
                                    checked={eau === 'Tsia'}
                                    onChange={() => setEau('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>WC</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="wc"
                                    value="Eny"
                                    checked={wc === 'Eny'}
                                    onChange={() => setWc('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="wc"
                                    value="Tsia"
                                    checked={wc === 'Tsia'}
                                    onChange={() => setWc('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>Parking</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="parking"
                                    value="Eny"
                                    checked={parking === 'Eny'}
                                    onChange={() => setParking('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="parking"
                                    value="Tsia"
                                    checked={parking === 'Tsia'}
                                    onChange={() => setParking('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup tag="fieldset">
                        <label> Électricité</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="electricite"
                                    value="Oui"
                                    checked={electricite === 'Eny'}
                                    onChange={() => setElectricite('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="electricite"
                                    value="Tsia"
                                    checked={electricite === 'Tsia'}
                                    onChange={() => setElectricite('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="surfaceM2">Superficie (m²)</Label>
                        <Input
                            type="number"
                            name="surfaceM2"
                            value={surface_m2}
                            onChange={e => setSurface_m2(e.target.value)}
                        />
                    </FormGroup>

                </Col>
                <Col>
                    <h2 className="text-primary" style={{ margin: '10px', fontSize: '17px' }}>MODE D'ACQUISITION</h2>
                    <FormGroup tag="fieldset">
                        <label>Pres</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="akaiky"
                                    value="Eny"
                                    checked={akaiky === 'Eny'}
                                    onChange={() => setAkaiky('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="akaiky"
                                    value="Tsia"
                                    checked={akaiky === 'Tsia'}
                                    onChange={() => setAkaiky('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>Route</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="lalandehibe"
                                    value="Eny"
                                    checked={lalandehibe === 'Eny'}
                                    onChange={() => setLalandehibe('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="lalandehibe"
                                    value="Tsia"
                                    checked={lalandehibe === 'Tsia'}
                                    onChange={() => setLalandehibe('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>Mode d'acquisition</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="mode_acquisition"
                                    value="Eny"
                                    checked={mode_acquisition === 'Eny'}
                                    onChange={() => setMode_acquisition('Eny')}
                                />
                                A louer 
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="mode_acquisition"
                                    value="Tsia"
                                    checked={mode_acquisition === 'Tsia'}
                                    onChange={() => setMode_acquisition('Tsia')}
                                />
                                Non alloue
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="prixAllocation">Prix d'allocation / Mois</Label>
                        <Input
                            type="number"
                            value={prix_alocation}
                            onChange={(e) => setPrix_alocation(e.target.value)}
                            disabled={mode_acquisition === 'Tsia'} // Désactiver si Mise à Disposition est sélectionné
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="besoin">Besoin</Label>
                        <Input
                        placeholder="region"
                            type="text"
                            value={besoin}
                            onChange={e => setBesoin(e.target.value)}
                        />
                    </FormGroup> 

                    <FormGroup>
                        <Label for="dateDisponibilite">Date de Disponibilité</Label>
                        <Input
                            type="date"
                            value={date_disponibilite}
                            onChange={e => setDate_disponibilite(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>Peinture</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="peinture"
                                    value="Eny"
                                    checked={peinture === 'Eny'}
                                    onChange={() => setPeinture('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="peinture"
                                    value="Tsia"
                                    checked={peinture === 'Tsia'}
                                    onChange={() => setPeinture('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup tag="fieldset">
                        <label>Meublé</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="meuble"
                                    value="Eny"
                                    checked={meuble_equipe === 'Eny'}
                                    onChange={() => setMeuble_equipe('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="meuble"
                                    value="Tsia"
                                    checked={meuble_equipe === 'Tsia'}
                                    onChange={() => setMeuble_equipe('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup tag="fieldset">
                        <label>Rehabillitation</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="fanatsarana"
                                    value="Eny"
                                    checked={fanatsarana === 'Eny'}
                                    onChange={() => setFanatsarana('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="fanatsarana"
                                    value="Tsia"
                                    checked={fanatsarana === 'Tsia'}
                                    onChange={() => setFanatsarana('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <label>Assainissement</label>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="fanadiovana"
                                    value="Eny"
                                    checked={fanadiovana === 'Eny'}
                                    onChange={() => setFanadiovana('Eny')}
                                />
                                Oui
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="fanadiovana"
                                    value="Tsia"
                                    checked={fanadiovana === 'Tsia'}
                                    onChange={() => setFanadiovana('Tsia')}
                                />
                                Non
                            </Label>
                        </FormGroup>
                    </FormGroup>
                </Col>
            </Row>
            <button className="btn btn-outline-primary" onClick={(e) => {e.preventDefault(); Ajouter(); toggle() }}>
                <i className="fa fa-plus"></i> Enregistrer
            </button>
            {/* <button className="btn btn-outline-primary" type="submit">
                <i className="fa fa-plus"></i> Enregistrer
            </button> */}
        </Form>
    );
};

export default FormulaireInfra;
