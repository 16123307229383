import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Row, Table } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';


import Candidat from 'views/Serie/Candidat';

const Etablissement = ({ setVisibleEtablissement, district, Serie, commune, object }) => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [visibleCandidat, setVisibleCandidat] = useState(false);
    const [etablissement_sec, setEtablissement_sec] = useState(null);
    const [data_etablissement, setData_etablissement] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);

    var nombre_total = 0;
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);
    // const data_etablissement = [
    //     {
    //         nom : "Antananarivo",
    //         serie : "A1",
    //         secteur : "Literraire",
    //         total : 123,
    //     },
    //     {
    //         nom : "Antsiranana",
    //         serie : "D",
    //         secteur : "Scientifique",
    //         total : 123,
    //     },{
    //         nom : "Toliary",
    //         serie : "A2",
    //         secteur : "OSE",
    //         total : 123,
    //     }
    // ]

    for (let i = 0; i < data_etablissement.length; i++) {
        nombre_total += data_etablissement[i].nombre;
    }

    const getListEtablissement = () => {
        fetch(url.urlHttp + "serie-bobon/candidat-liste-serie-etablissement?serie=" + Serie + "&&district=" + district + "&&commune=" + commune, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR Region: ", data.data);
                setData_etablissement(data.data)
                setLists(data.data);
                setListss(data.data);
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListEtablissement()
    }, [])

    const detailProvince = (etablissement) => {
        setEtablissement_sec(etablissement);
        setVisibleCandidat(true);
    }
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.etablissement.toUpperCase().includes(value.toUpperCase()));
        setData_etablissement(filteredData)
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...data_etablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setData_etablissement(sortedWords);
        } else {
            setMilahatra(true);
            setData_etablissement(listss);
        }
        console.log(milahatra);
    };

    if (visibleCandidat == true && etablissement_sec != null) {
        return (
            <>
                <Candidat setVisibleCandidat={setVisibleCandidat} types={'Etablissement'} region={etablissement_sec.region} commune={etablissement_sec.commune} province={etablissement_sec.province} object={etablissement_sec} district={etablissement_sec.district} serie={Serie} etablissement={etablissement_sec.etablissement} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row className="justify-content-center">
                    <Col md="6">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Centre :
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.commune}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>District :
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.district}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Région :
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.region}</a></span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Province :&nbsp;
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.province}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Spécialité : &nbsp;
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.specialite}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Secteur : &nbsp;
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.secteur}</a></span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Série : &nbsp;
                                        <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.serie_option}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}> Total de Candidats : &nbsp;
                                        <a style={{ color: "green", fontSize: '2rem', fontWeight: 'bold' }} className="justify-content-center">{ANRDataUtils.formatNombre(object.nombre_candidat)}</a>&nbsp;&nbsp;&nbsp;
                                        {isMpotra==true&&(<a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }} className="justify-content-center">+{ANRDataUtils.formatNombre(object.nombre_candidat_1)}</a>)}
                                    </span>

                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="4"><br />
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Établissement :</a></label>
                            <input
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechercher Etablissement'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>


                <Row>
                    <Col md="12">
                        <Card style={{ marginTop: "40px" }}>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.5rem' }}>
                                            Au niveau Centre
                                        </span>
                                        <span>
                                            <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Centre :&nbsp;
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.commune},</a>&nbsp;
                                            </span><br />
                                            <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>District :&nbsp;
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.district},</a>&nbsp;
                                            </span><br />

                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Région :
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.region}, &nbsp;</a></a><br />

                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Province :&nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.province}  &nbsp;&nbsp;</a>
                                            </a><br />
                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Spécialité : &nbsp;
                                                <a style={{ color: "red", fontSize: '1.4rem', fontWeight: 'bold' }}>{object.specialite}</a>
                                            </a>&nbsp;&nbsp;<br />
                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Secteur : &nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.4rem', fontWeight: 'bold' }}>{object.secteur}</a></a>&nbsp;&nbsp;<br />
                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Série : &nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.serie_option}</a><br />
                                            </a><br />
                                        </span>

                                    </CardTitle>
                                </CardHeader>
                                <Button className="btn btn-outline-primary" onClick={e => { setVisibleEtablissement(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                </Button>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                    <Table striped>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'etablissement')}>Établissement &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                {/* <th>Secteur</th> */}
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Nombre de Candidats &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {/* <td>{ANRDataUtils.formatNombre(row.etablissement)}</td>
                                                
                                               */}
                                                {
                                                    data_etablissement.map(row => (
                                                        <tr>
                                                            <td  >{ANRDataUtils.formatNombre(row.etablissement)}</td>
                                                            {/* <td>{ANRDataUtils.formatNombre(row.secteur)}</td> */}
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                               {isMpotra==true&&(  <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(row.nombre_candidat_1)}</span>)}
                                                            </td>
                                                            <td >
                                                                {
                                                                    row.nombre_candidat == 0 ?
                                                                        <button className={'btn btn-danger'} disabled={true} onClick={(e) => { detailProvince(row) }}>Afficher Candidats</button> :
                                                                        <button className={'btn btn-danger'} disabled={false} onClick={(e) => { detailProvince(row) }}>Afficher Candidats</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </div>
        );
    }
}


export default Etablissement;