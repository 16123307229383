import React from "react";
import { Link } from "react-router-dom";


import {
    Card,
    Button,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Footer,
    CardImg,
    Container

} from "reactstrap";
import ANR from '../../assets/img/mprss.png';
import MSPR from '../../assets/img/logo_mesupres.png';



function Accueil() {
    return (
        <div className="container-fluid vh-100" style={{ position: 'absolute', overflow: 'hidden' }}>
            <Container className="d-flex align-items-center justify-content-center h-100 w-100">
                <Row className="justify-content-center d-flex">

                    <Card
                        style={{
                            color: '#fd3f92',
                            opacity: '1',
                            border: '10px solid #109139',
                            borderRadius: '10px',
                            marginTop: '16rem',
                            filter: 'blur(0px)',
                            bottom: '-4s0px' // Ajout de l'effet de flou


                        }}
                    >

                        <Link to="/login" style={{ textDecoration: 'none' }}>
                            <Button color="primary" block className="mx-auto mt-3 mb-1" style={{ width: '15rem', opacity: '1'

                                
                             }}>
                                <p style={{ fontSize: '1.5rem', margin: '0 auto' }}>Entrer</p>
                            </Button>

                        </Link>
                        <CardBody>
                            <CardTitle
                                className="text-center"
                                style={{
                                    fontSize: '2rem',
                                    marginBottom: '0.5rem',
                                    color: '#8A8C8D',
                                    fontFamily: 'Verdana',
                                    opacity: '1',
                                    fontWeight: 'bold'

                                }}
                            >
                                <span>BACC_DATA 2024</span> <br />
                                <CardImg
                                    src={MSPR}
                                    alt="MSPR"
                                    fluid
                                    style={{ top: 0, left: 0, width: '70%', height: '70%' }}
                                />

                                {/* <div style={{ fontSize: '3rem', fontFamily: 'Tahoma', fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> */}
                            </CardTitle>

                        </CardBody>
                    </Card>
                </Row>
            </Container>

            <CardImg
                src={ANR}
                alt="ANR"
                fluid={true ? "true" : "false"}
                style={{ position: 'absolute', objectFit: 'cover', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}
            />

            <footer style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'white', position: 'absolute', bottom: 0, width: '100%' }} className="text-center p-2">
                <h1 style={{ fontSize: '1.2rem', fontFamily: 'cursive', fontWeight: 'bold', margin: '0' }}>
                    Plateforme BACC_DATA 2024
                </h1>
            </footer>

        </div>

    );
}

export default Accueil;
