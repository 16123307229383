import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Row, Table } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';


import Region from 'views/Serie/Region';
import Candidat from './Candidat';

const Province = ({ setVisibleProvince, Serie, Specialite, object }) => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [visibleRegion, setVisibleRegion] = useState(false);
    const [province_sec, setProvince_sec] = useState(null);
    const [data_province, setData_province] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);

    var nombre_total = 0;
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);
    // const data_province = [
    //     {
    //         nom : "Antananarivo",
    //         serie : "A1",
    //         secteur : "Literraire",
    //         total : 123,
    //     },
    //     {
    //         nom : "Antsiranana",
    //         serie : "D",
    //         secteur : "Scientifique",
    //         total : 123,
    //     },{
    //         nom : "Toliary",
    //         serie : "A2",
    //         secteur : "OSE",
    //         total : 123,
    //     }
    // ]
    const getListProvince = () => {
        fetch(url.urlHttp + "serie-bobon/candidat-liste-serie-province?serie=" + Serie, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setData_province(data.data)
                getsListTsisy(data.data)
                setLists(data.data);

            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListProvince()
    }, [])
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.province.toUpperCase().includes(value.toUpperCase()));
        getsListTsisy(filteredData)
    }
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listMisy]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListMisy(sortedWords);
        } else {
            setMilahatra(true);
            setListMisy(listss);
        }
        console.log(milahatra);
    };


    for (let i = 0; i < data_province.length; i++) {
        nombre_total += data_province[i].nombre;
    }
    const [objet2, setrObject2] = useState(null)

    const detailProvince = (province) => {
        setProvince_sec(province);
        setVisibleRegion(true);
        setrObject2(province)
    }
    const detailListe = (province) => {
        setProvince_sec(province);
        setVisibleCandidat(true);
        setrObject2(province)
    }
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].nombre_candidat > 0) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListss(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }
    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }
    const [visibleCandidat, setVisibleCandidat] = useState(false)
    if (visibleRegion == true && province_sec != null) {
        return (
            <>
                <Region object={objet2} setVisibleRegion={setVisibleRegion} provinces={province_sec.province} Serie={Serie} />
            </>
        )
    } else if (visibleCandidat == true && province_sec !== null) {
        return (
            <>
                <Candidat setVisibleCandidat={setVisibleCandidat} types={'Province'} region={''} commune={''} province={province_sec.province} object={objet2} district={''} serie={Serie} etablissement={''} />
            </>
        )
    } else {
        return (
            <div className="content">
                <div>
                    <Row className="justify-content-center">
                        <Col md="6">


                            <Card style={{ height: '100%' }}>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                        <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Spécialité : &nbsp;
                                            <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.specialite}</a>
                                        </span>
                                        <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Secteur : &nbsp;
                                            <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.secteur}</a></span>
                                        <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Série : &nbsp;
                                            <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.serie_option}</a>
                                        </span>
                                        <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}> Total de Candidats : &nbsp;
                                            <a style={{ color: "green", fontSize: '2rem', fontWeight: 'bold' }} className="justify-content-center">{ANRDataUtils.formatNombre(object.nombre_candidat)}</a>&nbsp;&nbsp;&nbsp;
                                            {isMpotra==true&&(<a style={{ color: "red", fontSize: '1.0rem', fontWeight: 'bold' }} className="justify-content-center">+{ANRDataUtils.formatNombre(object.nombre_candidat_1)}</a>)}
                                        </span>
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-1" md="4"><br />
                            <FormGroup>
                                <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Province :</a></label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    placeholder={'rechercher province'}
                                    onChange={e => filtre(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <Row >

                    <Col md="12">

                        <Card style={{ marginTop: '40px' }}>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ position: 'fi', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.5rem' }}>
                                            Au niveau National
                                        </span>
                                        <span>
                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Spécialité : &nbsp;
                                                <a style={{ color: "red", fontSize: '1.4rem', fontWeight: 'bold' }}>{object.specialite}</a>
                                            </a>&nbsp;&nbsp;<br />
                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Secteur : &nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.4rem', fontWeight: 'bold' }}>{object.secteur}</a></a>&nbsp;&nbsp;<br />
                                            <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Série : &nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.serie_option}</a><br />
                                            </a><br />
                                        </span>


                                        {/* <a style={{ color: "black", fontSize: '1.1rem' }}> Total de Candidats : &nbsp;
                                            
                                        </a> */}


                                    </CardTitle>
                                </CardHeader>

                                <Button className="btn btn-outline-primary" onClick={e => { setVisibleProvince(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                </Button>
                                <Button className="btn btn-outline-primary" onClick={voirPlus}>
                                    {isVoi ? "Fermer" : "Voir Plus"}
                                </Button>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                    <Table striped>
                                        <thead className="text-primary sticky-header">
                                            <tr >
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}>Province &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                {/* <th>Secteur</th> */}
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Nombre de candidats &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {/* <td>{ANRDataUtils.formatNombre(row.province)}</td>
                                                
                                               */}{
                                                    listMisy.map(row => (
                                                        <tr>
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.province)}</td>
                                                            {/* <td>{ANRDataUtils.formatNombre(row.secteur)}</td> */}
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                {isMpotra==true&&(<span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(row.nombre_candidat_1)}</span>)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {
                                                                    row.nombre_candidat == 0 ?
                                                                        <button className={'btn btn-danger'} disabled={true} onClick={(e) => { detailProvince(row) }}>Afficher Région</button> :
                                                                        <button className={'btn btn-danger'} disabled={false} onClick={(e) => { detailProvince(row) }}>Afficher Région</button>
                                                                }
                                                            </td>
                                                            {/* <td style={{ fontSize: '1.5rem' }}>
                                                {
                                                    row.nombre_candidat == 0 ?
                                                        <button className={'btn btn-danger'} disabled={true} onClick={(e) => { detailListe(row) }}>Afficher liste</button> :
                                                        <button className={'btn btn-danger'} disabled={false} onClick={(e) => { detailListe(row) }}>Afficher liste</button>
                                                }
                                            </td> */}
                                                        </tr>
                                                    ))
                                                }
                                                {

                                                    isVoi === true && (
                                                        <>
                                                            {
                                                                listTsymisy.map(row => (
                                                                    <tr>
                                                                        <td style={{ fontSize: '12px' }}>{ANRDataUtils.formatNombre(row.province)}</td>
                                                                        {/* <td>{ANRDataUtils.formatNombre(row.secteur)}</td> */}
                                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                            <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(row.nombre_candidat_1)}</span>
                                                                        </td>
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {
                                                                                row.nombre_candidat == 0 ?
                                                                                    <button className={'btn btn-danger'} disabled={true} onClick={(e) => { detailProvince(row) }}>Afficher Région</button> :
                                                                                    <button className={'btn btn-danger'} disabled={false} onClick={(e) => { detailProvince(row) }}>Afficher Région</button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    )

                                                }
                                            </>
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </div>
        );
    }
}


export default Province;