import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { AuthProvider } from "views/auth_login/context/AuthContext";
import App from "App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
   <AuthProvider>
      <App/>
   </AuthProvider>
  
);