import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Row, Table } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';


import RegionStatQG from 'views/StatQG/RegionStatQG';
//import '../Serie/style.css';
import iconElecteur2 from '../../assets/img/candidat.png';
import CandidatQG from './CandidatQG';

const calculTotals = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}
const ProvinceStatQG = () => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [labes, setLabels] = useState([]);
    const [libre, setLibre] = useState(true);
    const [ecole, setEcole] = useState(true);
    const [lists, setLists] = useState([]);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    // const vueEcole = () => {
    //     setLibre(false);
    //     setEcole(true);
    //     console.log("Candidat  d'École")
    // }
    // const vueLibre = () => {
    //     setLibre(true);
    //     setEcole(false);
    //     console.log("Candidat libre")

    // }
    // const vue = () => {
    //     setLibre(true);
    //     setEcole(true);
    //     console.log("Candidat  d'École et libre")

    // }


    let datas = {
        region: ''
    }

    const [list, setList] = useState([]);
    const [listss, setListss] = useState([]);
    const getProvinces = () => {
        console.log("tafiditra province");
        setLoader(true);
        console.log()
        fetch(url.urlHttp + "candidat-midina/candidat-libre-province", {
            method: "get",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        }).then(response => response.json())
            .then((res) => {
                setTotal_additionel(calculTotals(res.data.map(row => row.total_1)))
                setCandidat_ecole_1(calculTotals(res.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotals(res.data.map(row => row.candidat_libre_1)))
                setList(res.data);
                console.log("itooooooooooooo" + res.data);
                setLists(res.data);
                setListss(res.data);

            })
            .catch(error => { console.log(error) })
    }

    const stat = {
        sumCandidatLibre: list.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: list.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: list.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };


    const [visibleRegion, setVisibleRegion] = useState(false);
    const [province_sec, setprovince_sec] = useState(null)
    const detailProvince = (value) => {
        setprovince_sec(value)
        setVisibleRegion(true)
    }

    useEffect(() => {
        getProvinces();

        // getCardDASHBOARD();

    }, [])
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.province.toUpperCase().includes(value.toUpperCase()));
        setList(filteredData);
        setListss(filteredData);
    }
    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "25%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 2,
            fontSize: 9
        }
    });

    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'black' }}>Types de candidats au niveau  <Text style={{ color: 'green' }}>National</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> de  Candidats  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Candidats  d'École  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>  Candidats Libres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    {/* <View><Text style={{ fontWeight: 'bold' }}>Liste District </Text></View> */}
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Province</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats  d'École</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats Libres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
                            </View>
                        </View>
                        {
                            list.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.province}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_ecole)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_libre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.total)}</Text>
                                    </View>

                                    {/* <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                    </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...list]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setList(sortedWords);
        } else {
            setMilahatra(true);
            setList(listss);
        }
        console.log(milahatra);
    };

    console.log("azertyuioppapiezpouazopeua" + (list));
    const handleSelect = (key) => {

        detailProvince(key)
    }
    const [visibiliteCandidat, setVisibiliteCandidat] = useState(false)
    const detailList = (value) => {
        setprovince_sec(value)
        setVisibiliteCandidat(true)
    }
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);
    if (visibleRegion == true && province_sec != null) {
        return (
            <>
                <RegionStatQG setVisibleRegion={setVisibleRegion} province={province_sec} libre={libre} ecole={ecole} />
            </>
        )
    } else if (visibiliteCandidat == true && province_sec != null) {
        return (
            <>
                <CandidatQG setVisibleCandidat={setVisibiliteCandidat} etablissement={''} types={'Province'} commune={''} district={''} province={province_sec} region={''} />
            </>
        )
    } else {
        return (
            <div className="content">
                {
                    isShow == false && (
                        <>
                            <Row>
                                <Row className="justify-content-center">
                                    <Card style={{ padding: '10px' }} lg="4" md="5" sm="5" >
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'S', fontSize: '2.5rem' }}><b>{ANRDataUtils.formatNombre(stat.sumTotal)}</b></CardTitle>
                                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                                        </Col>
                                    </Card>
                                    <Col lg="4" md="5" sm="5">
                                        <Card
                                        //className="animated-card"

                                        // style={{ height: "100%", padding: '10px', textAlign: 'center', maxHeight: '150px', transition: 'transform 0.3s, box-shadow 0.3s' }} onClick={vueEcole}
                                        >

                                            <CardBody style={{ width: "300px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <CardTitle tag="p" className="text-center font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre  de Candidats  d'École :</CardTitle>
                                                <p className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'green' }}>
                                                    {ANRDataUtils.formatNombre(stat.sumCandidatEcole)}
                                                </p>
                                                {isMpotra == true && (
                                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>+
                                                        {ANRDataUtils.formatNombre(candidat_ecole_1)}
                                                    </p>)}

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="5" sm="5" >
                                        <Card
                                        // className="animated-card"
                                        // style={{ height: "100%", padding: '10px', textAlign: 'center', maxHeight: '150px', transition: 'transform 0.3s, box-shadow 0.3s' }} onClick={vueLibre}
                                        >
                                            <CardBody style={{ width: "300px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <CardTitle className="text-center font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre  de Candidats Libres : </CardTitle>
                                                <p className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'green' }}>
                                                    {ANRDataUtils.formatNombre(stat.sumCandidatLibre)}
                                                </p>
                                                {isMpotra == true && (<p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>+
                                                    {ANRDataUtils.formatNombre(candidat_libre_1)}
                                                </p>)}
                                            </CardBody>
                                        </Card>
                                    </Col>


                                </Row>
                            </Row>

                        </>
                    )
                }
                {
                    isShow == false && (
                        <Row>
                            <Col className="pr-1" md="4"><br />
                                <FormGroup>
                                    <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Province :</a></label>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        placeholder={'rechercher Province'}
                                        onChange={e => filtre(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )
                }

                {
                    isShow == false && (
                        <>
                            <Row>
                                <Col md="12">

                                    <Card style={{ marginTop: '40px' }}>
                                        <CardBody>
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{ position: 'fi', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.2rem' }}>
                                                        Au niveau National
                                                    </span>

                                                    <span style={{ fontWeight: 'bold' }}>
                                                        <a className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Nombre  de Candidats : &nbsp;
                                                            <a className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'red' }}>
                                                                {ANRDataUtils.formatNombre(stat.sumTotal)}
                                                            </a> </a>&nbsp;<br />
                                                        <a tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Nombre  de Candidats  d'École :&nbsp;</a>
                                                        <a className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)} </a><br />
                                                        <a className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Nombre  de Candidats Libres : &nbsp;<a className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'red' }}>
                                                            {ANRDataUtils.formatNombre(stat.sumCandidatLibre)}&nbsp;
                                                        </a></a><br />


                                                    </span>

                                                    {/* <a style={{ color: "black", fontSize: '1.1rem' }}>  de Candidats : &nbsp;
                                            
                                        </a> */}


                                                </CardTitle>
                                            </CardHeader>
                                            <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"National"}>
                                                {({ blob, url, loading, error }) =>
                                                    loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                                }
                                            </PDFDownloadLink>
                                            <Button className="btn btn-outline-primary"  style={{ fontSize: '10px' }} onClick={show}>
                                                {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                            </Button>
                                            <Card>

                                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                                    <Table style={{ marginTop: "40px" }} striped>

                                                        <thead className="text-primary sticky-header">
                                                            <tr>
                                                                <th style={{ fontSize: '1rem' }} onClick={() => handleSort('asc', 'province')}>Province &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                                {ecole === true ? <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Nombre de Candidats  d'École &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                                                {libre === true ? <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'candidat_libre')}>Nombre de Candidats Libres &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                                                <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'total')}>Total de Candidats  &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                list.map((row) => (
                                                                    <>
                                                                        <tr>
                                                                            <td style={{ textAlign: 'left' }}>
                                                                                {row.province}
                                                                            </td>
                                                                            {ecole === true ?
                                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                                    {ANRDataUtils.formatNombre(row.candidat_ecole)}
                                                                                    <br />{isMpotra == true && (<span style={{ color: 'red' }}>
                                                                                        +{ANRDataUtils.formatNombre(row.candidat_ecole_1)}
                                                                                    </span>)}
                                                                                </td>
                                                                                :
                                                                                ''}
                                                                            {libre === true ?
                                                                                <td style={{ fontSize: '1.5rem' }}>

                                                                                    {ANRDataUtils.formatNombre(row.candidat_libre)}
                                                                                    <br /> {isMpotra == true && (<span style={{ color: 'red' }}>
                                                                                        +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                                                    </span>)}
                                                                                </td> :
                                                                                ''}
                                                                            <td style={{ fontSize: '1.5rem' }}>

                                                                                {ANRDataUtils.formatNombre(row.total)}
                                                                                <br />{isMpotra == true && (<span style={{ color: 'red' }}>
                                                                                    +{ANRDataUtils.formatNombre(row.total_1)}
                                                                                </span>)}
                                                                            </td>
                                                                            <td >
                                                                                {
                                                                                    row.total > 0 ?
                                                                                        <button className={'btn btn-danger'} onClick={(e) => { detailProvince(row.province) }}>Afficher Région</button>
                                                                                        :
                                                                                        <button className={'btn btn-danger disabled'} >Afficher Région</button>
                                                                                }
                                                                            </td>
                                                                            {/* <td style={{ fontSize: '1.5rem' }}>
                                            {
                                                row. > 0 ?
                                                <button className={'btn btn-danger'} onClick={(e) => { detailList(row.province) }}>Afficher listes</button>
                                                :
                                                <button className={'btn btn-danger disabled'} >Afficher listes</button>
                                            }
                                        </td> */}

                                                                        </tr>
                                                                    </>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Card>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    )
                }

            </div>
        );
    }
}

export default ProvinceStatQG;