import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Modal, ModalBody,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import url from "../../urlConfig";
import ANRDataUtils from "../../utils/Utils";
import HistographeCommune from "./HistographeCommune";

import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';

import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import authHeader from "../../services/AuthHeader";

import ComponentsChart from '../statistique/ComponentsChart';


const HistographeDistrict = ({ setIndices, setVisibleDistrict, region }) => {
    const [totalEta, setTotalEta] = useState(0)
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listDistrict, setListDistrict] = useState([]);
    const [visibleCommune, setVisibleCommune] = useState(false);
    const [district_d, setDistrict_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([])
    // const [loader, setLoader] = useState(false);
    const [region_d, setRegion] = useState('');
    const [R_nombre_membre, setR_nombre_membre] = useState(0);
    const [nombre_ele, setNombre_elec] = useState(0);
    const [nomPdf, setNomPdf] = useState("")
    const [nombre13, setNombre13] = useState([])
    const [total13, setTotal13] = useState(0)

    const [totalCentre, setTotalCentre] = useState(0)
    const [totalCandidat, setTotalCandidat] = useState(0)
    // const[totallibre,setTotalLibre]=useState(0)

    const [nombre_libre, setNombre_libre] = useState([])
    const [indice, setIndice] = useState(0)
    const [totalLibre, setTotalLibre] = useState(0)
    const [totalEcole, setTotalEcole] = useState(0)
    // const[R_nombre_]
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };

    const getListDistrict = () => {
        fetch(url.urlHttp + "dashboard/region?province=" + region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListDistrict(data.data)
                setTotalCentre(calculTotal(data.data.map(row => row.nbr_place_max)))
                setTotalCandidat(calculTotal(data.data.map(row => row.nombre_candidat)))
                setTotalEcole(calculTotal(data.data.map(row => row.candidat_ecole)))
                setTotalLibre(calculTotal(data.data.map(row => row.candidat_libre)))
                setTotalNombre_centre(calculTotal(data.data.map(row => row.nombre_centre)))
                setNombre_salle(calculTotal(data.data.map(row => row.nombre_salle)))
                setTotalEta(calculTotal(data.data.map(row => row.nombre_eta)))
                setTotal_additionel(calculTotal(data.data.map(row => row.nombre_candidat_1)))
                setCandidat_ecole_1(calculTotal(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotal(data.data.map(row => row.candidat_libre_1)))
                setLabels(data.data.map(row => row.region))
                setCentre(data.data.map(row => row.nbr_place_max))
                setCandidat(data.data.map(row => row.candidat_ecole))
                setNombre_libre(data.data.map(row => row.candidat_libre))
                setLoader(false)
                setIndice(indice + 1)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [centre, setCentre] = useState([])
    const [candidat, setCandidat] = useState([])
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const calculPourcentage = (donnee, pourcentage) => {
        let a = 0;
        a = (donnee * pourcentage) / 100
        return Math.round(a);
    }
    const detailDistrict = (value) => {
        console.log("Miata ", value)
        setRegion(region)
        setR_nombre_membre(nombre_membre)
        setNombre_elec(electeurs)
        setDistrict_d(value)
        setVisibleCommune(true)
    }
    const [modalPhotose, setModalPhotose] = useState(false);
    const togglePhotose = () => setModalPhotose(!modalPhotose);
    const [search, setSearch] = useState(false);
    const [regionSelect, setRegionselect] = useState('');
    const handleSearch = () => {
        setSearch(!search);
    };
    const openPhotoseModal = () => {
        togglePhotose();
    };
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Centre',
                backgroundColor: 'green',
                // borderColor: 'rgba(75, 192, 192, 1)',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                // hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBackgroundColor: '#000',
                // hoverBorderColor: 'rgba(75, 192, 192, 1)',
                hoverBorderColor: '#9A9A9A',
                data: centre,
            }
            ,
            {
                label: 'Candidat',
                backgroundColor: 'red',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                hoverBackgroundColor: '#000',
                hoverBorderColor: '#9A9A9A',
                data: candidat,
            }
        ]
    };

    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "12.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 2,
            fontSize: 9
        }
    });
    const [pourcentage, setPourcentage] = useState(0);
    const [nombre_centre, setTotalNombre_centre] = useState(0)
    const [nombre_salle, setNombre_salle] = useState(0)
    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'rgb(151, 49, 22)' }}>Au niveau  Province: <Text style={{ color: 'red' }}>{region}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'rgb(151, 49, 22)' }}><Text style={{ color: 'red' }}></Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'black' }}>Au niveau : <Text style={{ color: 'red' }}>National</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre de centres : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalEta)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre d'Établissement d'Examen : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(nombre_centre)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombres de  salles  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(nombre_salle)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Capacité de Centres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalCentre)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de  Candidats  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalCandidat)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Candidats d'École : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalEcole)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>  Candidats Libres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalLibre)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    {/* <View><Text style={{ fontWeight: 'bold' }}>Liste Province <Text style={{ color: 'red' }}>{region}</Text> </Text></View> */}
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Région</Text>
                            </View>
                            {/* <View style={pdfStyles.tableColHeader}>
                            <Text style={pdfStyles.tableCell}>Nombre membre</Text>
                        </View> */}
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nombre de Centres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nombre d'Établissement d'Examen</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nombre de Salles</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Capacité de Centres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats d'école</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats Libres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
                            </View>
                        </View>
                        {
                            listDistrict.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.region}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_eta)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_centre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_salle)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nbr_place_max)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_ecole)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_libre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_candidat)}</Text>
                                    </View>
                                    {/* <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);

    useEffect(() => {
        getListDistrict()
    }, [])

    if (visibleCommune == true && district_d != null) {
        return (
            <>
                <HistographeCommune setVisibleCommune={setVisibleCommune} region={region_d} membres={nombre_membre} electeur={nbr_electeur} district={district_d} />
            </>
        )
    } else
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        {error && <div className="alert alert-danger">{error}</div>}
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle style={{ fontSize: '30px' }}>
                                        <span style={{ color: "green", fontWeight: 'bold' }}>Tableau de bord de la Province:
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {region} </span></span><br />
                                    </CardTitle>
                                </CardHeader>


                            </Card>
                        </Col>
                    </Col>
                </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Centres </p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
                        </Col>
                    </Card>
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Établissements d' Examen </p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_centre)}</b></CardTitle>
                        </Col>
                    </Card>
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de  Salles  </p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
                        </Col>
                    </Card>
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Capacité de Centres </p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                        </Col>
                    </Card>
                    {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCandidat)}</b></CardTitle>
                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                        </Col>
                    </Card>
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats Libres</p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalLibre)}</b></CardTitle>
                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_libre_1)}</b></CardTitle>)}
                        </Col>
                    </Card>
                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <div className="icon-big text-center icon-warning">
                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                </div>
                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats d'École</p>
                            </Row>
                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEcole)}</b></CardTitle>
                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_ecole_1)}</b></CardTitle>)}
                        </Col>
                    </Card>
                </Row>
                <Row>
                    <Card>
                        <CardBody className="font-weight-bold" style={{ fontSize: '17px' }}>
                            Choisir Région
                            <div className="mb-2 mt-2">
                                <select
                                    className="form-control"
                                    style={{ width: '200px' }}
                                    onChange={(e) => { detailDistrict(e.target.value) }}
                                >
                                    <option value=""></option>
                                    {labels.map((label, index) => (
                                        <option key={index} value={label}>{label}</option>
                                    ))}
                                </select>
                            </div>
                        </CardBody>
                        <CardFooter className="d-flex justify-content-between">
                            <Button className="btn btn-outline-primary btn-sm" onClick={e => {
                                setIndices(100);
                                setVisibleDistrict(false);
                            }}>
                                retour
                            </Button>
                            <Button className="btn btn-outline-primary" onClick={show}>
                                {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                            </Button>
                            <Button className="btn btn-outline-primary btn-sm" onClick={() => openPhotoseModal()}>
                                Afficher les données
                            </Button>
                        </CardFooter>
                    </Card>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <ComponentsChart id={'region'} liste={listDistrict} indice={indice} labels={labels} centre={centre} candidat={candidat} candidat_libre={nombre_libre} />
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={modalPhotose} toggle={togglePhotose} size="xl">
                    <ModalHeader toggle={togglePhotose} style={{ color: "black", fontSize: '13px' }}>
                        <CardTitle style={{ fontSize: '30px' }}>
                            <span style={{ color: "green", fontWeight: 'bold' }}>Tableau de bord de la Province:
                                <span style={{ color: "red", fontWeight: 'bold' }}> {region} </span></span><br />
                        </CardTitle>

                    </ModalHeader>
                    <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Centres </p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Établissements d' Examen </p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_centre)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de  Salles  </p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Capacité de Centres </p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                            </Col>
                        </Card>
                        {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCandidat)}</b></CardTitle>
                                {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats Libres</p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalLibre)}</b></CardTitle>
                                {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_libre_1)}</b></CardTitle>)}
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats d'École</p>
                                </Row>
                                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEcole)}</b></CardTitle>
                                {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_ecole_1)}</b></CardTitle>)}
                            </Col>
                        </Card>
                    </Row>
                    <ModalBody>
                        <PDFDownloadLink className="btn btn-primary fa fa-file " document={<DistrictPDF />} fileName={"National"}>
                            {({ blob, url, loading, error }) =>
                                loading ? ' Chargement du PDF...' : ' Exporter PDF'
                            }
                        </PDFDownloadLink>
                        <Button className="btn btn-outline-primary" onClick={show}>
                            {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                        </Button>
                        <Table striped>
                            <thead className="text-primary" >
                                <tr >
                                    <th>Région </th>
                                    {/* <th>nombre membres</th> */}
                                    <th>Nombre de centres</th>
                                    <th>Nombre d'Établissement d'Examen</th>
                                    <th>Nombre de salles</th>
                                    <th>Capacité de Centres</th>
                                    {/* <th>Resultat 2018</th> */}
                                    <th >
                                        Candidats Libres
                                    </th>
                                    <th >
                                        Candidats d'école
                                    </th>
                                    <th >
                                        Total de candidats
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listDistrict.map(row => (
                                        <tr>
                                            <td>{row.region}</td>
                                            {/* <td>{ANRDataUtils.formatNombre(row.nombre_membre)}</td> */}
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_eta)}</td>
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_centre)}</td>
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_salle)}</td>
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nbr_place_max)}</td>
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_libre)}<br />
                                                {isMpotra == true && (<span style={{ color: 'red' }}>
                                                    +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                </span>)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_ecole)}<br />
                                                {isMpotra == true && (<span style={{ color: 'red' }}>
                                                    +{ANRDataUtils.formatNombre(row.candidat_ecole_1)}
                                                </span>)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                {isMpotra == true && (<span style={{ color: 'red' }}>
                                                    +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                </span>)}
                                            </td>
                                            {/* <td >{ANRDataUtils.formatNombre(row.nombre_13)}</td> */}
                                            {/* <td><button className={'btn btn-danger'} onClick={e=>{details(row)}}> afficher plus</button></td> */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal>
            </div>
        );
}
export default HistographeDistrict;
