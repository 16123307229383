import { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';
import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";
import "../candidats/tablefixe.css";
import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";


const Candidat_non_inscrit = () => {
    const auth_data = authHeader();
    const [modalLogin, setModalLogin] = useState(false);
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const toggleModalLogin = () => setModalLogin(!modalLogin);
    const [page, setPage] = useState();
    const [search, setSearch] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [communes, setCommunes] = useState([]);
    const [typeCandidat, setTypeCandidat] = useState('');
    const [typeCandidats, setTypeCandidats] = useState([]);
    const [commune, setCommune] = useState('');
    const [disiableEcole, setDisableEcole] = useState(false);
    const [ecole, setEcole] = useState('');
    const [specialites, setSpecialites] = useState([]);
    const [specialite, setSpecialite] = useState('');
    const [secteurs, setSecteurs] = useState([]);
    const [secteur, setSecteur] = useState('');
    const [series, setSeries] = useState([]);
    const [serie, setSerie] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [genre, setGenre] = useState('');
    const [date_naissance, setDate_naissance] = useState('');
    const [addresse, setAddresse] = useState('');
    const [numero, setNumero] = useState('');
    const [titre, setTitre] = useState('');
    const [lieu_naissance, setLieu_naissance] = useState('');
    const [code_commune, setCode_commune] = useState('');
    const [offset, setOffset] = useState(0);
    const [nombre, setNombre] = useState(0);
    const [numPage, setNumPage] = useState(0);
    const [non_inscrits, setNon_inscrits] = useState([]);
    const [listss, setListss] = useState([]);
    const [seo, setSeo] = useState(false);
    const [mention, setMention] = useState('');

    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "100%",
            borderStyle: "solid",
            borderColor: "#dddddd",
            borderWidth: 1,
            borderBottomWidth: 0,
        },
        tableRow: {
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#dddddd",
        },
        tableHeader: {
            backgroundColor: "#7e7676",
            color: "#ffffff",
            fontWeight: 'bold',
        },
        tableCell: {
            padding: 5,
            fontSize: 10,
            textAlign: "center",
            borderLeftWidth: 1,
            borderLeftColor: "#dddddd",
            alignItems: 'center', // Centering content vertically
            justifyContent: 'center', // Centering content horizontally
            height: 25, // Set a fixed height for consistent vertical alignment
        },
        tableHeaderCell: {
            borderTopWidth: 1,
            borderTopColor: "#dddddd",
            backgroundColor: "#7e7676",
            color: "#ffffff",
        },
        tableDataCell: {
            backgroundColor: "#ffffff",
            color: "#000000",
        },
        title: {
            marginBottom: 10,
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: 'center', // Center title
        },
        separator: {
            marginVertical: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#cccccc',
        }
    });

    const DistrictPDF = () => (
        <Document>
            <Page size="A4" style={{ padding: 20 }}>
                <View style={pdfStyles.separator}>
                    <Text>-----------------------------------------------------------------------------------------------</Text>
                </View>
                <View>
                    <Text style={pdfStyles.title}>Liste des numeros des candidats non inscrits</Text>
                </View>
                <View style={pdfStyles.table}>
                    <View style={[pdfStyles.tableRow, pdfStyles.tableHeader]}>
                        <View style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>
                            <Text style={{textAlign:'center'}}>Numéro</Text>
                        </View>
                    </View>
                    {non_inscrits.map((dist, index) => (
                        <View style={pdfStyles.tableRow} key={index}>
                            <View style={[pdfStyles.tableCell, pdfStyles.tableDataCell]}>
                                <Text>{dist.num}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
    const [candidats, setCandidats] = useState([]);

    const handleGetListCandidats = async () => {
        await fetch(url.urlHttp + "candidat/csv?offset=" + offset, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv", data.data);
                setCandidats(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        console.log(url.urlHttp + "tanana/district?region=" + province + "&&district=" + region);
        fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommune = () => {
        console.log(url.urlHttp + "tanana/commune?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "tanana/commune?region=" + region + "&&district=" + district + "&&commune=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getSpecialite = () => {
        fetch(url.urlHttp + "candidat/specialite", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA spec", data.data);
                setSpecialites(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSecteur = () => {
        fetch(url.urlHttp + "candidat/secteur?specialite=" + specialite, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA secteur", data.data);
                setSecteurs(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSerie = () => {
        fetch(url.urlHttp + "candidat/serie?secteur=" + secteur, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA serie", data.data);
                setSeries(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleEcole = () => {
        if (typeCandidat == "Candidat libre") {
            setDisableEcole(true);
        } else {
            setDisableEcole(false);
        }
    }

    const handleTitre = () => {
        if (genre == "M") {
            setTitre('Monsieur')
        } else {
            setTitre("Mademoiselle");
        }
    }

    const handleCentre = (commune) => {
        for (let i = 0; i < communes.length; i++) {
            if (communes[i].commune == commune) {
                setCode_commune(communes[i].code_commune);
            }
        }
    }



    const
        handleSearchs = async (off) => {
            setSeo(true);
            getPagination();
            let name = "";

            if (nom != '') {
                name += nom;
            }
            if (prenom != '') {
                if (nom != '') {
                    name += " " + prenom;
                } else {
                    name += prenom;
                }
            }
            const data = {
                province: province,
                region: region,
                district: district,
                centre: commune,
                nom: nom,
                prenom: prenom,
                nomPrenom: name,
                code_commune: code_commune,
                specialite: specialite,
                specialitee: specialite,
                secteur: secteur,
                option: serie,
                type_candidat: typeCandidat,
                offset: off,
                mention: mention
            }
            console.log(data);
            // alert(numPage);
            await fetch(url.urlHttp + 'candidat/search_admis', {
                method: 'POST',
                headers: {
                    'Content-Type': auth_data.content_type,
                    'Authorization': auth_data.authorization
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((data) => {
                    console.log("DGTCc" + data.data);
                    setNon_inscrits(data.data);
                })
                .catch((error) => console.log('error', error))
        }

    const setPages = (index) => {
        setNumPage(index);
        setOffset(index * 20);
        // handleSearchs(index * 20);
        getPagination();
    }

    const getPagination = async () => {
        let name = "";

        if (nom != '') {
            name += nom;
        }
        if (prenom != '') {
            if (nom != '') {
                name += " " + prenom;
            } else {
                name += prenom;
            }
        }
        const data = {
            province: province,
            region: region,
            district: district,
            centre: commune,
            nom: nom,
            prenom: prenom,
            nomPrenom: name,
            code_commune: code_commune,
            specialite: specialite,
            specialitee: specialite,
            secteur: secteur,
            option: serie,
            type_candidat: typeCandidat,
            offset: offset,
            mention: mention
        }
        console.log(data);
        await fetch(url.urlHttp + "candidat/pagination_ni", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                console.log("nombre", res.data);
                setPage(Math.ceil(res.data / 20));
                setNombre(res.data);
                console.log("reto le page" + Math.ceil(res.data / 20))
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [isRecherche, setIsRecherche] = useState(false);
    const show = () => {
        if (isRecherche == true) {
            setIsRecherche(false);
        } else {
            setIsRecherche(true);
        }

    }
    const [isVoirPlus, setIsVoi] = useState(false)
    const plus = () => {
        if (isVoirPlus == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }
    }

    const handleNonInscrit = () => {
        fetch(url.urlHttp + "candidat/ni", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA candtrs", data.data);
                setNon_inscrits(data.data);
                setListss(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...non_inscrits]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setNon_inscrits(sortedWords);
        } else {
            setMilahatra(true);
            setNon_inscrits(listss);
        }
        console.log(milahatra);
    };


    useEffect(() => {
        setPages(0)
        //handleSearchs();
        getProvinces();
        getSpecialite();
        handleNonInscrit();
        //getPagination();
    }, [])


    return (
        <div className="content">
            <CardBody>
                {
                    isRecherche == true &&
                    (<>
                        <Card style={{ marginLeft: "-20px", marginRight: "-20px" }} >
                            <CardBody>
                                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                    RECHERCHE DE CANDIDATS ADMIS: <a style={{ color: "red" }}></a>
                                </CardTitle><br />
                                <Row >
                                    <Col className="pr-1" md="4">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > PROVINCE : </label><br />
                                        <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                            <option value=''></option>
                                            {provinces?.map(province => (
                                                <option value={province.province} >{province.province}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col className="pr-1" md="4">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > RÉGION : </label><br />
                                        <select className={'form-control'} value={region} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                                            <option value=''></option>
                                            {regions?.map(region => (
                                                <option value={region.region} >{region.region}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col className="pr-1" md="4">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > DISTRICT : </label><br />
                                        <select className={'form-control'} value={district} onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                            <option value=''></option>
                                            {districts?.map(district => (
                                                <option value={district.district} >{district.district}</option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md="3">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > CENTRE : </label><br />
                                        <select className={'form-control'} value={commune} onChange={e => { setCommune(e.target.value) }}>
                                            <option value=''></option>
                                            {communes?.map(commune => (
                                                <option value={commune.commune} >{commune.commune}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md="3">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > SPÉCIALITÉ : </label><br />
                                        <select className={'form-control'} value={specialite} onChange={e => { setSpecialite(e.target.value) }} onClick={handleSecteur}>
                                            <option value="" ></option>
                                            {specialites?.map(specialite => (
                                                <option value={specialite.specialite} >{specialite.specialite}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md="3">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > SECTEUR : </label><br />
                                        <select className={'form-control'} value={secteur} onChange={e => { setSecteur(e.target.value) }} onClick={handleSerie}>
                                            <option value=""></option>
                                            {secteurs?.map(secteur => (
                                                <option value={secteur.secteur} >{secteur.secteur}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md="3">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > SÉRIE : </label><br />
                                        <select className={'form-control'} value={serie} onChange={e => { setSerie(e.target.value) }} >
                                            <option value="" ></option>
                                            {series?.map(serie => (
                                                <option value={serie.serie_option} >{serie.serie_option}</option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md="3">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > MENTION : </label><br />
                                        <select className={'form-control'} value={mention} onChange={e => { setMention(e.target.value) }} >
                                            <option value="" ></option>
                                            <option value="tres bien">tres bien</option>
                                            <option value="bien">bien</option>
                                            <option value="assez bien">assez bien</option>
                                            <option value="passable">passable</option>
                                            <option value="deliberable">deliberable</option>
                                        </select>
                                    </Col>
                                    <Col md="3">
                                        <Label for="nomDem" style={{ border: 'type-round', fontWeight: 'bold', color: "black" }}>NOM :</Label>
                                        <Input
                                            placeholder="Nom"
                                            type="text"
                                            value={nom} onChange={e => { setNom(e.target.value) }}
                                        >
                                        </Input>
                                    </Col>
                                    <Col md="3">
                                        <Label for="fonctionDem" style={{ border: 'type-round', fontWeight: 'bold', color: "black" }}>PRÉNOM :</Label>
                                        <Input
                                            placeholder="Prénom"
                                            type="text"
                                            value={prenom} onChange={e => { setPrenom(e.target.value) }}
                                        >
                                        </Input>
                                    </Col>
                                    <Col md="3">
                                        <Button className="btn btn-outline-info" onClick={(e) => { handleSearchs(0) }} style={{ marginTop: "20px" }}>
                                            Rechercher
                                        </Button>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </>)
                    // (<></>)

                }


                {/* <Button className="btn btn-primary" onClick={toggleModalLogin}>
                    Ajouter
                </Button> */}
                <Row>
                    <Col md="12">
                        {/* <Button className="btn btn-outline-primary" onClick={show}>
                            {isRecherche ? "Fermer" : "Recherche"}
                        </Button> */}

                        <Card style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                            <CardBody>
                                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                    Liste Numero des Candidats non inscrits : <a style={{ color: "red" }}>&nbsp;&nbsp;&nbsp;{ANRDataUtils.formatNombre(nombre)}</a>
                                </CardTitle>
                                <CardTitle tag="h4" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                    Nombre de ligne : <a style={{ color: "red" }}>&nbsp;&nbsp;&nbsp;{ANRDataUtils.formatNombre(non_inscrits.length)}</a>
                                </CardTitle>
                                {
                                    seo === true && (
                                        <>
                                            <CardTitle tag="h6" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                                Province : <a style={{ color: "red" }}>{province}</a>, Region : <a style={{ color: "red" }}>{region}</a>,  District : <a style={{ color: "red" }}>{district}</a>, Centre : <a style={{ color: "red" }}>{commune}</a>
                                            </CardTitle>
                                        </>
                                    )
                                }
                                <Row className="justify-content-center">
                                    <Button className="btn btn-outline-info mr-2" disabled={numPage === 0} onClick={() => setPages(numPage - 1)}>Précédent</Button>
                                    <Button className="btn btn-outline-warning" disabled={numPage === page - 1} onClick={() => setPages(numPage + 1)}>Suivant</Button>
                                </Row>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"Candidat_non_inscrit"}>
                                        {({ blob, url, loading, error }) =>
                                            loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                        }
                                    </PDFDownloadLink>
                                    <Table striped>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'num')}>Numero &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {non_inscrits.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: '12px', textAlign: "center" }} >
                                                        {row.num}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>


                    </Col>
                </Row>
            </CardBody>
        </div>

    );
}

export default Candidat_non_inscrit;
