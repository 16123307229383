import React, { useState } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { Nav, CardImg, Modal, ModalBody, Collapse, Button } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import myImage from '../../assets/img/logo_mesupres.png';
import { useAuth } from "views/auth_login/context/AuthContext";
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './customScrollbar.css';

import logo from "logo-mini.png";
import { useEffect } from "react";

let ps = null;


function Sidebar(props) {
  const [modal, setModal] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const [isOpenAutoriteLocale, setIsOpenAutoriteLocale] = useState(false)
  const [isOpenTransport, setIsOpenTransport] = useState(false)
  const [isOpenQgLocale, setIsOpenQgLocale] = useState(false)
  const [isOpenGoodiesLocale, setIsOpenGoodiesLocale] = useState(false)
  const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
  const [isOpenQQGLocale, setOpenQQGLocale] = useState(false);
  const { authority, choseAuthority } = useAuth();
  var Interval = null;
  const navigate = useNavigate();
  const [isOpenCollapseCarte, setIsOpenCollapseCarte] = useState(false);
  const handleCarte = () => {
    setIsOpenCollapseCarte(!isOpenCollapseCarte)
  }
  const { token, logout } = useAuth();
  const toggleAutoriteCollapse = () => {
    setIsOpenAutoriteLocale(!isOpenAutoriteLocale)
  }
  const toggleQQGCollapse = () => {
    setOpenQQGLocale(!isOpenQQGLocale)
  }
  const toggleTransportCollapse = () => {
    setIsOpenTransport(!isOpenTransport)
  }
  const toggleQgCollapse = () => {
    setIsOpenQgLocale(!isOpenQgLocale)
  }
  const toggleGoodiesCollapse = () => {
    setIsOpenGoodiesLocale(!isOpenGoodiesLocale)
  }
  useEffect(() => {
    if (token) {
      Interval = setInterval(() => {
        localStorage.removeItem('token');
        navigate('/login')
      }, 3000000)
    }
  }, [])

  const toggle = () => setModal(!modal);

  const location = useLocation();
  const handleLogout = () => {
    logout();
    localStorage.removeItem('app_data');
    clearInterval(Interval);
    navigate('/logout');
    window.location.reload();
  }
  const sidebarRef = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: false,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    };
  }, []);

  const toggleCollapse1 = (itemId) => {
    setIsOpenCollapse1(!isOpenCollapse1)
  }
  const toggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse)
  }
  // const handleOnClick=(index)=>{
  //   if(index===0){
  //     setShow(!show)
  //   }

  // }
  return (
    <div
      ref={sidebarRef}
      className="sidebar mr-5"
      data-color="white" // Can be replaced with {props.bgColor}
      data-active-color={props.activeColor}
      style={{ scrollBehavior: 'smooth', maxHeight: '100vh', overflow: 'auto' }}
    >
      <div className="logo text-center">
        <CardImg
          src={myImage}
          alt="Ma Image"
          className="rounded img-fluid w-75 mx-auto rounded"
          onClick={toggle}
        />
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
          <ModalBody className="text-center">
            <img src={myImage} alt="pdp" className="img-fluid shadow" />
          </ModalBody>
        </Modal>

        <a href="#" className="simple-text logo-normal">
          <div><span style={{ fontWeight: 'bold', fontSize: '1.7rem' }}> BACC_DATA<br/>
          2024</span></div>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        <Nav style={{ paddingBottom: '50px' }}>

          {props.routes.slice(0, 1).map((prop, key) => {
            if (prop.clickable === false) {
              return (
                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    backgroundColor: '#F7f4ef',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                    paddingTop: '5px',
                    paddingBottom: '5px',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <div className="nav-NavLink">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </div>
                </li>
              );
            } else {

              const isLongText = prop.name.length > 20; // Changer 15 en fonction de la longueur souhaitée

              return (

                <li

                  style={{
                    // Ajoutez les styles personnalisés ici
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    whiteSpace: isLongText ? 'nowrap' : 'normal',
                    textAlign: isLongText ? 'center' : 'left',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={prop.id}
                >

                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" onClick={handleCarte}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                    <Collapse isOpen={isOpenCollapseCarte} >
                      {prop.submenu?.length > 0 && (
                        <ul style={{ listStyle: 'none' }}>
                          {prop.submenu.map((item, index) => (
                            <li style={{ listStyle: 'none' }}>
                              {item}
                            </li >
                          ))}
                        </ul>
                      )}

                    </Collapse>
                  </NavLink>
                </li>
              );
            }
          })}

          {props.routes.slice(1, 2).map((prop, key) => {
            if (prop.clickable === false) {
              return (
                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    backgroundColor: '#F7f4ef',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                    paddingTop: '5px',
                    paddingBottom: '5px',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <div className="nav-NavLink">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </div>
                </li>
              );
            } else {

              const isLongText = prop.name.length > 20; // Changer 15 en fonction de la longueur souhaitée

              return (

                <li

                  style={{
                    // Ajoutez les styles personnalisés ici
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    whiteSpace: isLongText ? 'nowrap' : 'normal',
                    textAlign: isLongText ? 'center' : 'left',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={prop.id}
                >

                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" onClick={handleCarte}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                    <Collapse isOpen={isOpenCollapseCarte} >
                      {prop.submenu?.length > 0 && (
                        <ul style={{ listStyle: 'none' }}>
                          {prop.submenu.map((item, index) => (
                            <li style={{ listStyle: 'none' }}>
                              {item}
                            </li >
                          ))}
                        </ul>
                      )}

                    </Collapse>
                  </NavLink>
                </li>
              );
            }
          })}

          {props.routes.slice(2, 3).map((prop, key) => {

            if (prop.clickable === false) {
              return (
                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    backgroundColor: '#F7f4ef',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                    paddingTop: '5px',
                    paddingBottom: '5px',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <div className="nav-NavLink">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </div>
                </li>
              );
            } else {

              const isLongText = prop.name.length > 20; // Changer 15 en fonction de la longueur souhaitée

              return (

                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    whiteSpace: isLongText ? 'nowrap' : 'normal',
                    textAlign: isLongText ? 'center' : 'left',
                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={prop.id}
                >
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" onClick={toggleAutoriteCollapse}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                  <Collapse isOpen={isOpenAutoriteLocale} >
                    {prop.submenu?.length > 0 && (
                      <ul style={{ listStyle: 'none' }} className="nav-NavLink">
                        {prop.submenu.map((item, index) => (
                          <li>
                            <NavLink style={{ listStyle: 'none', margin: '0px' }} onClick={() => choseAuthority(item)}>
                              {item}
                            </NavLink >
                          </li>
                        ))}
                      </ul>
                    )}
                  </Collapse>
                </li>
              );
            }
          })}

          {props.routes.slice(3, 4).map((prop, key) => {

            if (prop.clickable === false) {
              return (
                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    backgroundColor: '#F7f4ef',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                    paddingTop: '5px',
                    paddingBottom: '5px',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <div className="nav-NavLink">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </div>
                </li>
              );
            } else {

              const isLongText = prop.name.length > 20; // Changer 15 en fonction de la longueur souhaitée

              return (

                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    whiteSpace: isLongText ? 'nowrap' : 'normal',
                    textAlign: isLongText ? 'center' : 'left',
                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={prop.id}
                >
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" onClick={toggleAutoriteCollapse}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                  <Collapse isOpen={isOpenAutoriteLocale} >
                    {prop.submenu?.length > 0 && (
                      <ul style={{ listStyle: 'none' }} className="nav-NavLink">
                        {prop.submenu.map((item, index) => (
                          <li>
                            <NavLink style={{ listStyle: 'none', margin: '0px' }} onClick={() => choseAuthority(item)}>
                              {item}
                            </NavLink >
                          </li>
                        ))}
                      </ul>
                    )}
                  </Collapse>
                </li>
              );
            }
          })}

          {props.routes.slice(4, 5).map((prop, key) => {

            if (prop.clickable === false) {
              return (
                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    backgroundColor: '#F7f4ef',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                    paddingTop: '5px',
                    paddingBottom: '5px',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <div className="nav-NavLink">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </div>
                </li>
              );
            } else {

              const isLongText = prop.name.length > 20; // Changer 15 en fonction de la longueur souhaitée

              return (

                <li
                  style={{
                    // Ajoutez les styles personnalisés ici
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    whiteSpace: isLongText ? 'nowrap' : 'normal',
                    textAlign: isLongText ? 'center' : 'left',

                  }}
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={prop.id}
                >
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink" onClick={toggleCollapse1}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                  <Collapse isOpen={isOpenCollapse1} >
                    {prop.submenu?.length > 0 && (
                      <ul style={{ listStyle: 'none' }} className="nav-NavLink">
                        {prop.submenu.map((item, index) => (
                          <li key={index} >
                            <NavLink style={{ listStyle: 'none', margin: '0px' }}>
                              {item}

                            </NavLink >
                          </li>
                        ))}
                      </ul>
                    )}

                  </Collapse>
                </li>
              );
            }
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;