import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";
import ANRDataUtils from "../../utils/Utils";

const Candidat = ({ setVisibleCandidat, region, commune, object, district, province, serie, types, etablissement }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [etablissement_d, setEtablissement_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);


    const toDateFormat = (date) => {
        const dateString = date

        // Parse the date string into a Date object
        const moment = require('moment');

        // Parse the date string and format it into "DD-MM-YYYY" format
        const formattedDate = moment(dateString, "ddd MMM DD HH:mm:ss Z YYYY").format("DD-MM-YYYY");

        return formattedDate;

    }
    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const getListEtablissement = () => {
        console.log(url.urlHttp + "serie-bobon/candidat-liste-total?serie=" + serie + "&&etablissement=" + etablissement + "&&commune=" + commune + "&&region=" + object.region + "&&district=" + district + "&&province=" + province);
        fetch(url.urlHttp + "serie-bobon/candidat-liste-total?serie=" + serie + "&&etablissement=" + etablissement + "&&commune=" + commune + "&&region=" + object.region + "&&district=" + district + "&&province=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR MOOOOOO : ", data.data);
                setListEtablissement(data.data)
                setLists(data.data);
                setListss(data.data);
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const filtreNom = (value) => {
        const filteredData = lists.filter(item => item.nom.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
    };
    const filtrePrenom = (value) => {
        const filteredData = lists.filter(item => item.prenom.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
    };
    const filtreNumero = (value) => {
        const filteredData = lists.filter(item => item.numero_incription.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
    }
    const [colonne, setColonne] = useState("")
    const nombre = () => {
        var value = '';
        if (district == '' && commune == '') {
            value = "12.5%"
        } else if (commune == '') {
            value = "14.8%"
        } else {
            value = "16.66%"
        }
        return value;
    }
    useEffect(() => {
        getListEtablissement()
    }, [])

    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "12.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 6
        }
    });
    const [name, setName] = useState('')
    const DistrictPDF = () => {
        if (types == 'Région') {
            setName(region)
        } else if (types == 'District') {
            setName(district)
        } else if (types == 'Commune') {
            setName(commune)
        } else {
            setName(etablissement)
        }

        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Au niveau   <Text style={{ color: 'red' }}>{types} </Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Spécialité :
                        <Text style={{ color: 'red' }}>{object.specialite}</Text></Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Sécteur :
                        <Text style={{ color: 'red' }}>{object.secteur}</Text></Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Série :
                        <Text style={{ color: 'red' }}>{object.serie_option}</Text></Text></View>
                    {province !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province : <Text style={{ color: 'red' }}>{province}</Text> </Text></View>
                        </>
                    )}
                    {region !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Région : <Text style={{ color: 'red' }}>{object.region}</Text> </Text></View>
                        </>
                    )}
                    {district !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>District : <Text style={{ color: 'red' }}>{district}</Text> </Text></View>
                        </>
                    )}
                    {commune !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Centre : <Text style={{ color: 'red' }}>{commune}</Text> </Text></View>
                        </>
                    )}

                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de Candidats serie  <Text style={{ color: '#000000' }}>{serie}:</Text> <Text style={{ color: '#000000' }}>{ANRDataUtils.formatNombre(listEtablissement.length)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    <View><Text style={{ fontWeight: 'bold' }}>Liste {types} <Text style={{ color: 'red' }}>{name}</Text> </Text></View>
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            {/* {district === '' && ( */}
                            <>
                                <View style={pdfStyles.tableColHeader}>
                                    <Text style={pdfStyles.tableCell}>District  </Text>
                                </View>
                            </>
                            {/* )} */}
                            {/* {commune === '' && ( */}
                            <>
                                <View style={pdfStyles.tableColHeader}>
                                    <Text style={pdfStyles.tableCell}>Commune  </Text>
                                </View>
                            </>
                            {/* )} */}

                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Numero Inscription</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nom</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Prénom</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Sexe</Text>
                            </View>

                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Série</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Date Naissance</Text>
                            </View>
                        </View>
                        {
                            listEtablissement.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    {/* {district === '' && ( */}
                                    <>
                                        <View style={pdfStyles.tableColHeader}>
                                            <Text style={pdfStyles.tableCell}>{dist.district}  </Text>
                                        </View>
                                    </>
                                    {/* )} */}
                                    {/* {commune === '' && ( */}
                                    <>
                                        <View style={pdfStyles.tableColHeader}>
                                            <Text style={pdfStyles.tableCell}>{dist.commune}  </Text>
                                        </View>
                                    </>
                                    {/* )} */}
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.numero_incription}</Text>
                                    </View>

                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.nom}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.prenom}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.sexe}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.serie_option}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{toDateFormat(dist.date_naissance)}</Text>
                                    </View>
                                    {/* <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
            setListEtablissement(listss);
        }
        console.log(milahatra);
    };
    const stat = {
        // sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).blackuce((acc, val) => acc + val, 0),
        // sumCandidatEcole: listEtablissement.map(entry => entry.candidat_ecole).blackuce((acc, val) => acc + val, 0),
        sumTotal: listEtablissement.length
    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    {/* Affichez un message d'erreur s'il y a une erreur */}
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card style={{ height: "100%" }}>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ color: "green", fontWeight: 'bold', fontSize: '2rem' }}> Au niveau {types} </span>
                                        {province !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Province : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{province}</a></span>
                                            </>
                                        )}
                                        {region !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Région : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.region}</a></span>
                                            </>
                                        )}
                                        {district !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>District : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{district}</a></span>
                                            </>
                                        )}
                                        {commune !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Centre : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{commune}</a></span>
                                            </>
                                        )}
                                        {etablissement !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Établissement : </span>
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{etablissement}</a>
                                            </>
                                        )}
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card style={{ height: "100%" }}>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                        {/* <span style={{ color: "orange", fontWeight: 'bold' }}> Etablissement :  {etablissement}</span><br /> */}
                                        <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Spécialité : &nbsp;
                                            <a style={{ color: "red", fontSize: '1.4rem', fontWeight: 'bold' }}>{object.specialite}</a>
                                        </a>&nbsp;&nbsp;<br />
                                        <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Secteur : &nbsp;&nbsp;
                                            <a style={{ color: "red", fontSize: '1.4rem', fontWeight: 'bold' }}>{object.secteur}</a></a>&nbsp;&nbsp;<br />
                                        <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Série : &nbsp;&nbsp;
                                            <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.serie_option}</a><br />
                                        </a><br />
                                        <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}> Total de Candidats : &nbsp;
                                            <a style={{ color: "green", fontSize: '2rem', fontWeight: 'bold' }} className="justify-content-center">{ANRDataUtils.formatNombre(object.nombre_candidat)}</a>&nbsp;&nbsp;&nbsp;
                                            {isMpotra==true&&(<a style={{ color: "red", fontSize: '1.0rem', fontWeight: 'bold' }} className="justify-content-center">+{ANRDataUtils.formatNombre(object.nombre_candidat_1)}</a>)}
                                        </span>
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row><br />
            <Row>
                <Col className="pr-1" md="4">
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Nom :</a></label>
                        <input type={'text'} style={{ fontSize: '0.9rem' }} className={'form-control'} placeholder={'Rechercher Nom'} s onChange={e => filtreNom(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pr-1" md="4">
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Prénoms :</a></label>
                        <input type={'text'} style={{ fontSize: '0.9rem' }} className={'form-control'} placeholder={'Rechercher Prénoms'} s onChange={e => filtrePrenom(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pr-1" md="4">
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Numero_incription :</a></label>
                        <input type={'text'} style={{ fontSize: '0.9rem' }} className={'form-control'} placeholder={'Rechercher Numero_incription'} s onChange={e => filtreNumero(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card style={{ marginTop: '40px' }}>
                        <CardBody>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: '2rem' }}>Au niveau {types}</span>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.2rem' }}>
                                        <a style={{ color: "green", fontWeight: 'bold', fontSize: '2rem' }}>{stat.sumTotal},</a>&nbsp;
                                        {province !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Province : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{province},&nbsp;</a></span>
                                            </>
                                        )}
                                        {region !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Région : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.region},&nbsp;</a></span>
                                            </>
                                        )}
                                        {district !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>District : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{district},&nbsp;</a></span>
                                            </>
                                        )}
                                        {commune !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Centre : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{commune}&nbsp;</a></span>
                                            </>
                                        )}
                                        {etablissement !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Établissement : </span>
                                                <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{etablissement},</a>
                                            </>
                                        )} &nbsp;
                                        <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Spécialité : </a>&nbsp;
                                        <span style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.specialite},</span>&nbsp;
                                        <a style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}>Secteur :&nbsp;</a>
                                        <span style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{object.secteur},</span>
                                        <span style={{ color: "black", fontSize: '1.2rem', fontWeight: 'bold' }}> Série : <a style={{ color: "red", fontSize: '1.5rem', fontWeight: 'bold' }}>{serie}</a></span><br />
                                        </span>


                                </CardTitle>
                            </CardHeader>
                            <Button className="btn btn-outline-primary " onClick={e => { setVisibleCandidat(false) }}>retour</Button>
                            <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                </Button>
                            <PDFDownloadLink className="btn btn-primary fa fa-file" document={<DistrictPDF />} fileName={"National"}>
                                {({ blob, url, loading, error }) =>
                                    loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                }
                            </PDFDownloadLink>

                            <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                <Table striped>
                                    <thead className="text-primary sticky-header">
                                        <tr>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'region')}>Région &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'district')}>District &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'commune')}>Commune &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'numero_incription')}>Numéro &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'nom')}>Nom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'prenom')}>Prénom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'sexe')}>Sexe &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'type_candidat')}>Type du Candidats &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'date_naissance')}>Date de naissance &nbsp;<i class="fa fa-arrow-down"></i></th>
                                            {/* <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'additionnel')}>additionnel &nbsp;<i class="fa fa-arrow-down"></i></th> */}
                                            {/* <th>
                                            <button className={'btn btn-success'} >Valider</button>
                                                            <button className={'btn btn-danger'} >Refuser</button>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listEtablissement.map((row, index) => (
                                                <>
                                                    <tr>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {row.region}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.district)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.commune)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {row.numero_incription.split(".")[0]}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.nom)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.prenom)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.sexe)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.type_candidat)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {toDateFormat(row.date_naissance)}
                                                        </td>
                                                        {/* <td style={{ fontSize: '12px' }} >
                                                            {
                                                                row.additionnel==0&&(<>
                                                                <b style={{color:'green'}}>Non Additionnel</b>
                                                                </>)
                                                            }
                                                            {
                                                                row.additionnel==1&&(<>
                                                                <b style={{color:'red'}}>Additionnel</b>
                                                                </>)
                                                            }
                                                        </td> */}
                                                        {/* <td>
                                                            <button className={'btn btn-success'} >Valider</button>
                                                            <button className={'btn btn-danger'} >Refuser</button>
                                                        </td> */}

                                                    </tr>
                                                </>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
// }

export default Candidat;
