import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";

import { CardBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from "utils/Utils";



import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';
import CommuneC from './CommuneC';
import DistrictC from './DistrictC';
import EtablissementC from './EtablissementC';
import SalleC from './SalleC';

const calculTotals = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const RegionC = ({ setVisibleRegion, province }) => {
    const auth_data = authHeader()
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listRegion, setListRegion] = useState([]);
    const [visibleDistrict, setVisibleDistrict] = useState(false);
    const [region_d, setRegion_d] = useState('');
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);

    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const getListRegion = () => {
        fetch(url.urlHttp + "centre-milala/region?province=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", url.urlHttp + "centre-milala/region?province=" + province);
                setListRegion(data.data);
                setLists(data.data);
                setListss(data.data);
                setTotalEta(calculTotals(data.data.map(row => row.nombre_eta)))
                setTotal_additionel(calculTotals(data.data.map(row => row.nombre_candidat_1)))
                setCandidat_ecole_1(calculTotals(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotals(data.data.map(row => row.candidat_libre_1)))
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListRegion()
    }, [])

    const stat = {
        sumCandidatLibre: listRegion.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: listRegion.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: listRegion.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const detailRegion = (value) => {
        setRegion_d(value)
        setVisibleDistrict(true)
    }

    var totalCentre = 0;
    var totalSalle = 0;
    var totalCapacite = 0;
    var totalEffectif = 0;
    var totalElectricite = 0;
    const centres = [
        {
            province: "Antananarivo",
            region: "Analamanga",
            nombre_centre: 140,
            nombre_salle: 50,
            capacite: 100,
            effectif: 80,
            electricite: 12
        },
        {
            province: "Antananarivo",
            region: "Bongolava",
            nombre_centre: 140,
            nombre_salle: 50,
            capacite: 100,
            effectif: 80,
            electricite: 5
        },
        {
            province: "Antananarivo",
            region: "Itasy",
            nombre_centre: 140,
            nombre_salle: 50,
            capacite: 100,
            effectif: 80,
            electricite: 9
        },
        {
            province: "Antananarivo",
            region: "Vakinankaratra",
            nombre_centre: 140,
            nombre_salle: 50,
            capacite: 100,
            effectif: 80,
            electricite: 7
        }
    ]

    for (let index = 0; index < listRegion.length; index++) {
        totalCentre += listRegion[index].nombre_centre;
        totalSalle += listRegion[index].nombre_salle;
        totalCapacite += listRegion[index].nbr_place_max;
        totalEffectif += listRegion[index].nombre_candidat;
        totalElectricite += listRegion[index].electricite;
    }
    const [modalPhotose, setModalPhotose] = useState(false);
    const togglePhotose = () => setModalPhotose(!modalPhotose);
    const [modalDistrict, setModalDistrict] = useState(false)
    const toggleDistrict = () => setModalDistrict(!modalDistrict);
    const [modalCommune, setModalCommune] = useState(false)
    const toggleCommune = () => setModalCommune(!modalCommune);
    const [modalEtablissement, setModalEtablissement] = useState(false)
    const toggleEtablissement = () => setModalEtablissement(!modalEtablissement);
    const [modalSalle, setModalSalle] = useState(false)
    const toggleSalle = () => setModalSalle(!modalSalle);
    const vider = () => {
        setCommune_c('')
        setDistrict_c('')
        setRegion_c('')
        setEtablissement_c('')
        setSalle_c('')
    }
    const [isShow, setisShow] = useState(false);
    const [isMpotra,setIsMpotra]=useState(false);
    const openPhotoseModal = () => {
        // vider();S
        togglePhotose();
    }
    const openDistrictModal = () => {
        toggleDistrict()
    }
    const openCommuneModal = () => {
        toggleCommune()
    }
    const openEtablissementModal = () => {
        toggleEtablissement()
    }
    const openSalleModal = () => {
        toggleSalle()
    }
    // const open
    const [etablissements, setEtablissements] = useState([])

    const [salles, setSalles] = useState([])

    const [communes, setCommunes] = useState([]);

    const [districts, setDistricts] = useState([]);

    const [regions, setRegions] = useState([]);
    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        // console.log(url.urlHttp + "tanana/district?region=" + province_c + "&&district=" + region);
        fetch(url.urlHttp + "tanana/district?region=" + region_d + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const list_midina = [
        { types: 'province', niveau: 'Province', valeur: 'Région' },
        { types: 'region', niveau: 'Province', valeur: 'District' },
        { types: 'district', niveau: 'Province', valeur: 'Commune' },
        { types: 'commune', niveau: 'Province', valeur: 'Etablissement' },
        { types: 'etablissement', niveau: 'Province', valeur: 'Salle' },
        { types: 'salle', niveau: 'Province', valeur: 'Candidat' },
    ]
    const handleCommune = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/commune?district=" + distrcit_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleEtablissment = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune_c + "&&district=" + distrcit_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setEtablissements(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleSalle = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-salle?commune=" + commune_c + "&&district=" + distrcit_c + "&&centre_xamen=" + etablissement_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setEtablissements(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [visibleCommune, setVisibleCommune] = useState(false)
    const [visibleEtablissement, setVisibleEtablissement] = useState(false)
    const [visibleSalle, setVisibleSalle] = useState(false)
    const detailsDistrict = () => {
        setVisibleDistrict(true)
        toggleDistrict()
    }
    const detailsCommune = () => {
        setVisibleCommune(true)
        toggleCommune()
    }
    const detailsEtablissement = () => {
        setVisibleEtablissement(true)
        toggleEtablissement()
    }
    const detailsSalle = () => {

        setVisibleSalle(true)
        toggleSalle()
    }
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [province_c, setProvince_c] = useState('');
    const [region_c, setRegion_c] = useState('');
    const [commune_c, setCommune_c] = useState('')
    const [etablissement_c, setEtablissement_c] = useState('')
    const [salle_c, setSalle_c] = useState('')
    const [distrcit_c, setDistrict_c] = useState('');
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listRegion]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListRegion(sortedWords);
        } else {
            setMilahatra(true);
            setListRegion(listss);
        }
        console.log(milahatra);
    };

    const sortByDifferenceAndSet = () => {
        if (milahatra == true) {
            const sortedData = [...listRegion].sort((a, b) => {
                const differenceA = a.nbr_place_max - a.nombre_candidat;
                const differenceB = b.nbr_place_max - b.nombre_candidat;
                return differenceA - differenceB; // Sorting in ascending order, adjust as needed
            });

            setListRegion(sortedData);
            setMilahatra(false);
        } else {
            setListRegion(listss)
            setMilahatra(true);
        }
    }
    const [totalEta, setTotalEta] = useState(0)
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.region.toUpperCase().includes(value.toUpperCase()));
        setListRegion(filteredData);
        setListss(filteredData);
    }
    if (visibleDistrict == true && region_d != null) {
        return (
            <DistrictC setVisibleDistrict={setVisibleDistrict} region={region_d} province={province} />
        );
    } else if (visibleCommune == true && region_c != '' && distrcit_c != '') {
        return (
            <>
                <CommuneC setVisibleCommune={setVisibleCommune} district={distrcit_c} region={region_c} province={province_c} />
            </>
        )
    } else if (visibleEtablissement == true && region_c != '' && distrcit_c != '' && commune_c != '') {
        return (
            <>
                <EtablissementC setVisibiliteEtablissement={setVisibleEtablissement} commune={commune_c} district={distrcit_c} province={province_c} region={region_c} />
            </>
        )
    } else if (visibleSalle == true && region_c != '' && distrcit_c != '' && commune_c != '' && etablissement_c != '') {
        return (
            <SalleC setVisibleCandidat={setVisibleSalle} etablissement={etablissement_c} commune={commune_c} district={distrcit_c} province={province_c} region={region_c} />
        )
    } else {
        return (
            <div className="content">


                <Row>
                    {isShow == false && (
                        <Col md="12">
                            <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Nombre de Centres </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Établissements d'Examen </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Nombre de  Salles  </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalSalle)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Capacité de Centres </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Candidats Inscrits </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalEffectif)}</b></CardTitle>
                                        {isMpotra==true&&(<CardTitle tag="p"  style={{ fontSize: '1rem' ,color:'red'}}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            > Places disponibles</p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite - totalEffectif)}</b></CardTitle>
                                    </Col>
                                </Card>
                                {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                            </Row>


                        </Col>)}
                    {isShow == false && (<Col className="pr-1" md="4">
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.rem' }}>Région :</a></label>
                            <input
                                style={{ fontSize: '0.9rem' }}
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechercher province'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup> <br />
                    </Col>)}
                </Row>
                <Row>

                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle style={{ fontSize: '20px' }}>
                                    <span style={{ color: "black    ", fontWeight: 'bold' }}>Capacité du Province :
                                        <span style={{ color: "red", fontWeight: 'bold' }}> {province} </span></span><br />
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Button className="btn btn-outline-primary" onClick={e => { setVisibleRegion(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir Candidats additionnels"}
                                </Button>
                                <Button className="btn btn-outline-primary" onClick={openDistrictModal}>
                                    Afficher District
                                </Button>
                                <Button className="btn btn-outline-primary" onClick={openCommuneModal}>
                                    Afficher Centre
                                </Button>
                                <Button className="btn btn-outline-primary" onClick={openEtablissementModal}>
                                    Afficher Établissement d'examen
                                </Button>
                                <Button className="btn btn-outline-primary" onClick={openSalleModal}>
                                    Afficher Salle
                                </Button><br />
                                {isShow == false && (<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                    <Table striped>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th onClick={() => handleSort('asc', 'region')} style={{ fontSize: '12px' }}>Région &nbsp;<i class="fa fa-arrow-up"></i></th>
                                                <th onClick={() => handleSort('desc', 'nombre_eta')} style={{ fontSize: '12px' }}>Nombre de Centres &nbsp;<i className="fa fa-arrow-down-9-1"></i></th>
                                                <th onClick={() => handleSort('desc', 'nombre_centre')} style={{ fontSize: '12px' }}>Nombre d' établissements d'examen &nbsp;<i className="fa fa-arrow-down-9-1"></i></th>

                                                <th onClick={() => handleSort('desc', 'nombre_salle')} style={{ fontSize: '12px' }}>Nombre Total de salles &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th onClick={() => handleSort('desc', 'nbr_place_max')} style={{ fontSize: '12px',width:'100px' }}>Capacité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th onClick={() => handleSort('desc', 'nombre_candidat')} style={{ fontSize: '12px' }}>Candidats inscrits &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th onClick={() => sortByDifferenceAndSet()} style={{ fontSize: '12px' }}>Reste &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                                {/* <th onClick={() => handleSort('desc', 'electricite')}>Salle sans électricité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> */}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listRegion.map((row) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {row.region}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nombre_eta)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nombre_centre)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nombre_salle)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' ,weight:'200px'}}>
                                                                {ANRDataUtils.formatNombre(row.nbr_place_max)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                {isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                    +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                                </span>)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nbr_place_max - row.nombre_candidat)}
                                                            </td>
                                                            <td>
                                                                {
                                                                    row.nombre_centre > 0 ?
                                                                        <button className={'btn btn-danger'} onClick={(e) => { detailRegion(row.region) }}>Afficher District</button>
                                                                        :
                                                                        <button className={'btn btn-danger disabled'} >Afficher District</button>
                                                                }
                                                            </td>

                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </Table></div>)}
                                <Modal isOpen={modalDistrict} toggle={toggleDistrict} size="m">
                                    <ModalBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Région<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setRegion_d(e.target.value)
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {listRegion.map((label, index) => (
                                                        <option key={index} value={label.region}>{label.region}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='btn btn-prmary' onClick={detailsDistrict}>
                                            Voir District
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal isOpen={modalCommune} toggle={toggleCommune} size="m">
                                    <ModalBody>

                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Région<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setRegion_c(e.target.value)
                                                    }}
                                                    onClick={handleDistrict}
                                                >
                                                    <option value=""></option>
                                                    {listRegion.map((label, index) => (
                                                        <option key={index} value={label.region}>{label.region}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  District<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setDistrict_c(e.target.value)
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {districts.map((label, index) => (
                                                        <option key={index} value={label.district}>{label.district}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='btn btn-prmary' onClick={detailsCommune}>
                                            Voir Centre
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal isOpen={modalEtablissement} toggle={toggleEtablissement} size="m">
                                    <ModalBody>

                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Région<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setRegion_c(e.target.value)
                                                    }}
                                                    onClick={handleDistrict}
                                                >
                                                    <option value=""></option>
                                                    {listRegion.map((label, index) => (
                                                        <option key={index} value={label.region}>{label.region}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  District<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setDistrict_c(e.target.value)
                                                    }}
                                                    onClick={handleCommune}
                                                >
                                                    <option value=""></option>
                                                    {districts.map((label, index) => (
                                                        <option key={index} value={label.district}>{label.district}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Centre<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setCommune_c(e.target.value)
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {communes.map((label, index) => (
                                                        <option key={index} value={label.commune}>{label.commune}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='btn btn-prmary' onClick={detailsEtablissement}>
                                            Voir établissement
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal isOpen={modalSalle} toggle={toggleSalle} size="m">
                                    <ModalBody>

                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Région<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setRegion_c(e.target.value)
                                                    }}
                                                    onClick={handleDistrict}
                                                >
                                                    <option value=""></option>
                                                    {listRegion.map((label, index) => (
                                                        <option key={index} value={label.region}>{label.region}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  District<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setDistrict_c(e.target.value)
                                                    }}
                                                    onClick={handleCommune}
                                                >
                                                    <option value=""></option>
                                                    {districts.map((label, index) => (
                                                        <option key={index} value={label.district}>{label.district}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Centre<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setCommune_c(e.target.value)
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {communes.map((label, index) => (
                                                        <option key={index} value={label.commune}>{label.commune}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Etablissement<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setEtablissement_c(e.target.value)
                                                    }}
                                                    onClick={handleEtablissment}
                                                >
                                                    <option value=""></option>
                                                    {etablissements.map((label, index) => (
                                                        <option key={index} value={label.centre_examen}>{label.centre_examen}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='btn btn-prmary' onClick={detailsSalle}>
                                            Voir Salle
                                        </Button>
                                    </ModalFooter>
                                </Modal>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default RegionC;
