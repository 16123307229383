import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";
import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";
import CommuneV from './CommuneV';
import EtablissementV from './EtablissementV';

const DistrictV = ({ setVisibleDistrict, region, province, ecole, libre }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listDistrict, setListDistrict] = useState([]);
    const [visibleCommune, setVisibleCommune] = useState(false);
    const [district_d, setDistrict_d] = useState('');
    const [region_r, setRegion_r] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);

    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const getListDistrict = () => {
        fetch(url.urlHttp + "validation/district?region=" + region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListDistrict(data.data)
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListDistrict()
    }, [])

    const stat = {
        // sumCandidatLibre: listDistrict.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: listDistrict.map(entry => entry.refuser).reduce((acc, val) => acc + val, 0),
        // sumTotal: listDistrict.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }

    const [visibiliteCandidat, setVisibleCandidat] = useState(false)
    const detailsList = (value) => {
        setDistrict_d(value)
        setRegion_r(region)
        setVisibleCandidat(true)
    }

    const detailDistrict = (value) => {
        setDistrict_d(value)
        setRegion_r(region)
        setVisibleCommune(true)
    }
    const handleSort = (order, key) => {
        const sortedWords = [...listDistrict]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setListDistrict(sortedWords);
    };
    if (visibleCommune == true && district_d != null) {
        return (
            <CommuneV setVisibleCommune={setVisibleCommune} district={district_d} region={region_r} province={province} />
        );
    }
    else if (visibiliteCandidat == true && district_d != null) {
        return (
            <>
                <EtablissementV types='District' setVisibiliteEtablissement={setVisibleCandidat} commune={''} district={district_d} province={province} region={region} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Affichez un message d'erreur s'il y a une erreur */}
                        {error && <div className="alert alert-danger">{error}</div>}
                        <Row className="justify-content-center">
                            <Col md="6">
                                <Card>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ color: "green", fontSize: '1rem', fontWeight: 'bold' }}> Au niveau Régional </span>
                                        <span style={{ color: "black", fontSize: '1rem' }}>Province :
                                            <a style={{ color: "red", fontSize: '1rem' }}> {province}</a> </span>
                                        <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red", fontSize: '1rem' }}>{region}</a></span>
                                        {ecole === true ? <span style={{ color: "black", fontSize: '1rem' }}> Total de  candidats libres  avec anomalie : </span> : <br />}
                                        {ecole === true ? <h2 style={{ color: "red", fontWeight: 'bold', fontSize: '1.3rem' }}> {ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</h2> : <br />}
                                    </CardTitle>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>

                    <Col>

                        <Button className="btn btn-outline-primary " onClick={e => { setVisibleDistrict(false) }}>retour</Button>
                        <Table striped>
                            <thead className="text-primary">
                                <tr>
                                    <th style={{ fontSize: "13px" }} onClick={() => handleSort('asc', 'district')}>District &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    {ecole === true ? <th style={{ fontSize: "13px" }} onClick={() => handleSort('desc', 'refuser')}>Nombre de Candidats avec anomalie  &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                    {/* <th onClick={() => handleSort('desc', 'total')}>Nombre Total &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> */}
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listDistrict.map((row, index) => (
                                        <>
                                            <tr>
                                                <td style={{ fontSize: "13px" }}>
                                                    {row.district}
                                                </td>
                                                {ecole === true ?
                                                    <td>
                                                        {ANRDataUtils.formatNombre(row.refuser)}
                                                    </td>
                                                    :
                                                    ''}

                                                <td style={{ fontSize: "13px" }}>
                                                    {
                                                        row.refuser > 0 ?
                                                            <button className={'btn btn-danger'} onClick={(e) => { detailDistrict(row.district) }}>Afficher Centre</button>
                                                            :
                                                            <button className={'btn btn-danger disabled'} >Afficher Centre</button>
                                                    }
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    {
                                                        row.refuser > 0 ?
                                                            <button className={'btn btn-danger'} onClick={(e) => { detailsList(row.district) }}>Afficher Listes</button>
                                                            :
                                                            <button className={'btn btn-danger disabled'} >Afficher Listes</button>
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                            </tbody>
                        </Table>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default DistrictV;
