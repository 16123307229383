import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Modal, ModalBody, ModalFooter,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";



import url from "../../urlConfig";
// import './../../';

import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';
// import CommuneQG from './CommuneQG';
import authHeader from '../../services/AuthHeader';
import SalleC from './SalleC';

const EtablissementC = ({ setVisibiliteEtablissement, region, commune, district, province }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [visiblecandidat, setVisibleCandidat] = useState(false);
    const [etablissement_d, setEtablissement_d] = useState('');
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra,setIsMpotra]=useState(false);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);

    console.log("ito commune :" + commune + "ito le district" + district);

    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const getListEtablissement = () => {
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissement(data.data)
                setLists(data.data);
                setListss(data.data);
                setTotal_additionel(calculTotal(data.data.map(row => row.nombre_candidat_1)))
                setCandidat_ecole_1(calculTotal(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotal(data.data.map(row => row.candidat_libre_1)))
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListEtablissement()
    }, [])

    const stat = {
        sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: listEtablissement.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: listEtablissement.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const detailEtablissement = (value) => {
        console.log('isaiaiaiaiaiiaiai');
        setEtablissement_d(value)
        setVisibleCandidat(true)
        setVisibleSalle(true)
        console.log('isaiaiaiaiaiiaiai', [{
            etabli: etablissement_d,
            visibleCommune: visiblecandidat
        }]);
    }

    var totalCentre = 0;
    var totalSalle = 0;
    var totalCapacite = 0;
    var totalEffectif = 0;
    var totalElectricite = 0;
    for (let index = 0; index < listEtablissement.length; index++) {
        totalCentre = listEtablissement.length;
        totalSalle += listEtablissement[index].nombre_salle;
        totalCapacite += listEtablissement[index].nbr_place_max;
        totalEffectif += listEtablissement[index].nombre_candidat;
        totalElectricite += listEtablissement[index].electricite;
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
            setListEtablissement(listss);
        }
        console.log(milahatra);
    };

    const sortByDifferenceAndSet = () => {
        if (milahatra == true) {
            const sortedData = [...listEtablissement].sort((a, b) => {
                const differenceA = a.nbr_place_max - a.nombre_candidat;
                const differenceB = b.nbr_place_max - b.nombre_candidat;
                return differenceA - differenceB; // Sorting in ascending order, adjust as needed
            });

            setListEtablissement(sortedData);
            setMilahatra(false);
        } else {
            setListEtablissement(listss)
            setMilahatra(true);
        }
    }

    const filtre = (value) => {
        const filteredData = lists.filter(item => item.centre_examen.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
        setListss(filteredData);
    }
    const [province_c, setProvince_c] = useState('');
    const [region_c, setRegion_c] = useState('');
    const [commune_c, setCommune_c] = useState('')
    const [etablissement_c, setEtablissement_c] = useState('')
    const [salle_c, setSalle_c] = useState('')
    const [distrcit_c, setDistrict_c] = useState('');
    const [modalPhotose, setModalPhotose] = useState(false);
    const togglePhotose = () => setModalPhotose(!modalPhotose);
    const [modalDistrict, setModalDistrict] = useState(false)
    const toggleDistrict = () => setModalDistrict(!modalDistrict);
    const [modalCommune, setModalCommune] = useState(false)
    const toggleCommune = () => setModalCommune(!modalCommune);
    const [modalEtablissement, setModalEtablissement] = useState(false)
    const toggleEtablissement = () => setModalEtablissement(!modalEtablissement);
    const [modalSalle, setModalSalle] = useState(false)
    const toggleSalle = () => setModalSalle(!modalSalle);
    const vider = () => {
        setCommune_c('')
        setDistrict_c('')
        setRegion_c('')
        setEtablissement_c('')
        setSalle_c('')
    }
    const openPhotoseModal = () => {
        // vider();
        togglePhotose();
    }
    const openDistrictModal = () => {
        toggleDistrict()
    }
    const openCommuneModal = () => {
        toggleCommune()
    }
    const openEtablissementModal = () => {
        toggleEtablissement()
    }
    const openSalleModal = () => {
        toggleSalle()
    }
    // const open
    const [etablissements, setEtablissements] = useState([])

    const [salles, setSalles] = useState([])

    const [communes, setCommunes] = useState([]);

    const [districts, setDistricts] = useState([]);

    const [regions, setRegions] = useState([]);
    const list_midina = [
        { types: 'province', niveau: 'Province', valeur: 'Région' },
        { types: 'region', niveau: 'Province', valeur: 'District' },
        { types: 'district', niveau: 'Province', valeur: 'Commune' },
        { types: 'commune', niveau: 'Province', valeur: 'Etablissement' },
        { types: 'etablissement', niveau: 'Province', valeur: 'Salle' },
        { types: 'salle', niveau: 'Province', valeur: 'Candidat' },
    ]
    const handleSalle = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-salle?commune=" + commune_c + "&&district=" + distrcit_c + "&&centre_xamen=" + etablissement_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setEtablissements(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [visibleEtablissement, setVisibleEtablissement] = useState(false)
    const [visibleSalle, setVisibleSalle] = useState(false)
    const detailsEtablissement = () => {
        setVisibleEtablissement(true)
        setVisibiliteEtablissement(true)
        toggleEtablissement()
    }
    const detailsSalle = () => {
        setVisibleCandidat(true)
        setVisibleSalle(true)
        toggleSalle()
    }
    if (visiblecandidat == true && visibleSalle == true && etablissement_d != null) {
        return (
            <SalleC setVisibleCandidat={setVisibleCandidat} etablissement={etablissement_d} commune={commune} district={district} province={province} region={region} />
        );
    } else {
        return (
            <div className="content">
                <Row>
                    {isShow==false&&(<Row md="12" style={{ gap: '5px', padding: '0px', margin: '0px' }} className="justify-content-center" >

                        <Card style={{ padding: '10px' }} lg="2.4" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                    >Établissements d'examen </p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2.4" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                    >Nombre de  Salles  </p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalSalle)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2.4" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                    >Capacité de centres </p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2.4" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                    >Candidats inscrits </p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalEffectif)}</b></CardTitle>
                                {isMpotra==true&&(<CardTitle tag="p" style={{ fontSize: '1.5rem',color:'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px' }} lg="2.4" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                    > Places disponibles</p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite - totalEffectif)}</b></CardTitle>
                            </Col>
                        </Card>
                        {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}


                    </Row>)}

                    {isShow==false && (<Col className="pr-1" md="4">
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Établissement :</a></label>
                            <input
                                type={'text'}
                                style={{ fontSize: '0.9rem' }}
                                className={'form-control'}
                                placeholder={'rechercher etablissement'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup>
                    </Col>)}
                </Row>
                <Row>

                    <Col>
                        <Card>
                            <CardBody>
                                {isShow==false && (<CardHeader>
                                    <CardTitle style={{ fontSize: '20px' }}>
                                        <span style={{ color: "black", fontWeight: 'bold' }}>Capacité du  Centre :
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {commune}</span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> District :
                                                <a style={{ color: "red", fontWeight: 'bold' }}> {district} </a></span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> Région :
                                                <a style={{ color: "red", fontWeight: 'bold' }}> {region} </a></span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> Province :
                                                <a style={{ color: "red", fontWeight: 'bold' }}> {province} </a> </span>
                                        </span><br />
                                    </CardTitle><br />
                                </CardHeader>)}
                                <Button className="btn btn-outline-primary" onClick={e => { setVisibiliteEtablissement(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "voir candidats additionnels"}
                                </Button>
                                <Button className="btn btn-outline-primary" onClick={openSalleModal}>
                                    Afficher Salle
                                </Button><br />
                                {isShow==false && (<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <Table striped>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'centre_examen')}>Établissement &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nombre_salle')}>Nombre Total de salle &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nbr_place_max')}>Capacité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Candidats inscrits &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => sortByDifferenceAndSet()}>Reste &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'electricite')}>Salle sans electricité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listEtablissement.map((row) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {row.centre_examen}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nombre_salle)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nbr_place_max)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                {isMpotra==true&&( <span style={{ color: 'red' }}>
                                                                    +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                                </span>  )}
                                                                </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nbr_place_max - row.nombre_candidat)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.electricite)}
                                                            </td>
                                                            <td>
                                                                {
                                                                    row.nombre_salle > 0 ?
                                                                        <button className={'btn btn-danger'} onClick={(e) => { detailEtablissement(row.centre_examen) }}>Afficher Salle</button>
                                                                        :
                                                                        <button className={'btn btn-danger disabled'} >Afficher Salle</button>
                                                                }
                                                            </td>

                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </Table></div>)}
                                <Modal isOpen={modalSalle} toggle={toggleSalle} size="m">
                                    <ModalBody>
                                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                            Choisir  Etablissement<div className="mb-2">
                                                <select
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    onChange={(e) => {
                                                        setEtablissement_d(e.target.value)
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {listEtablissement.map((label, index) => (
                                                        <option key={index} value={label.centre_examen}>{label.centre_examen}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CardBody>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='btn btn-prmary' onClick={detailsSalle}>
                                            Voir Salle
                                        </Button>
                                    </ModalFooter>
                                </Modal>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default EtablissementC;
