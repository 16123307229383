import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";
import url from "../../urlConfig";
// import CommuneQG from './CommuneQG';
// import CandidatV from './';
import authHeader from '../../services/AuthHeader';

const EtablissementV = ({ setVisibiliteEtablissement, region, commune, district, province, types }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [visibleCommune, setVisibleCandidat] = useState(false);
    const [etablissement_d, setEtablissement_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);
    console.log("ito commune :" + commune + "ito le district" + district);

    const [listeId, setListeId] = useState([]);

    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const getListEtablissement = () => {
        fetch(url.urlHttp + "validation/liste?commune=" + commune + "&&district=" + district + "&&province=" + province + "&&region=" + region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissement(data.data)
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListEtablissement()
    }, [])

    const
        setValidations = (etat) => {
            console.log("miditra", url.urlHttp + "candidat-midina/validation?etat=" + etat + "&&etablissement=" + etablissement_d + "&&centre=" + commune);
            fetch(url.urlHttp + "candidat-midina/validation?etat=" + etat + "&&etablissement=" + etablissement_d + "&&centre=" + commune, {
                method: 'POST',
                headers: {
                    'Content-Type': auth_data.content_type,
                    'Authorization': auth_data.authorization
                },
                body: JSON.stringify(listeId),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("Valider daholo");
                    setListEtablissement(data.data)
                })
                .catch(error => {
                    console.log(error);
                });
        }

    const maka_listId = () => {
        // const timer = setTimeout(() => {
        const filteredIds = listEtablissement
            .filter(entry => entry.validation === 0)
            .map(entry => entry.id);
        setListeId(filteredIds);
        // }, 1000);

        // Clear the timer
        // return () => clearTimeout(timer);
    }

    // useEffect(() => {
    //     getListEtablissement();
    //     maka_listId();
    // }, [])
    console.log(listeId);
    const valide = (value1, value2) => {
        setListeId([]);
        listeId.push(value1)

        console.log(listeId);
        setValidations(value2);

        // clearTimeout(timer);
        // set(value)
        // setVisibleCandidat(true)
    }

    const valides = (value) => {
        maka_listId();

        console.log(listeId);
        setValidations(value);

        // set(value)
        // setVisibleCandidat(true)
    }
    const toDateFormat = (date) => {
        const dateString = date

        // Parse the date string into a Date object
        const moment = require('moment');

        // Parse the date string and format it into "DD-MM-YYYY" format
        const formattedDate = moment(dateString, "ddd MMM DD HH:mm:ss Z YYYY").format("DD-MM-YYYY");

        return formattedDate;

    }
    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "14.28%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "14.28%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "14.28%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "14.28%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 6
        }
    });
    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>

                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Au niveau   <Text style={{ color: '#000000' }}>{types} </Text> </Text></View>
                    {province !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province : {province} </Text></View>
                        </>
                    )}
                    {region !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Région : {region} </Text></View>
                        </>
                    )}
                    {district !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>District : {district} </Text></View>
                        </>
                    )}
                    {commune !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Centre : {commune} </Text></View>
                        </>
                    )}
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de  Candidats Libres avec anomalie  : <Text style={{ color: '#000000' }}>{ANRDataUtils.formatNombre(listEtablissement.length)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    <View><Text style={{ fontWeight: 'bold' }}>Liste Région <Text style={{ color: 'red' }}>{district}</Text> </Text></View>
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Numero Inscription</Text>
                            </View>
                            {/* <View style={pdfStyles.tableColHeader}>
                            <Text style={pdfStyles.tableCell}>Nombre membre</Text>
                        </View> */}
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Commune Inscription </Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nom</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Prénom</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Sexe</Text>
                            </View>

                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Série</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Date Naissance</Text>
                            </View>
                        </View>
                        {
                            listEtablissement.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.numero_incription}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.commune}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.nom}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.prenom}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.sexe}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.serie_option}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{toDateFormat(dist.date_naissance)}</Text>
                                    </View>
                                    {/* <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const stat = {
        // sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: listEtablissement.map(entry => entry.refuser).reduce((acc, val) => acc + val, 0),
        // sumTotal: listEtablissement.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const detailEtablissement = (value) => {
        setEtablissement_d(value)
        setVisibleCandidat(true)
    }
    if (visibleCommune == true && etablissement_d != null) {
        // return (
        //     <CommuneV setVisibleCandidat={setVisibleCandidat} etablissement={etablissement_d} commune={commune} district={district} province={province} region={region} />
        // );
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Affichez un message d'erreur s'il y a une erreur */}
                        {error && <div className="alert alert-danger">{error}</div>}
                        <Row className="justify-content-center">
                            <Col md="6">
                                <Card>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ color: "green", fontWeight: 'bold', fontSize: "1rem" }}> Au niveau {types} </span>
                                        {/* <span style={{ color: "red", fontWeight: 'bold' }}>Province : {province}</span> */}
                                        {province !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: "1rem" }}>Province : <a style={{ color: "red", fontSize: "1rem" }}>{province}</a></span>
                                            </>
                                        )}
                                        {region !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: "1rem" }}>Région : <a style={{ color: "red", fontSize: "1rem" }}>{region}</a></span>
                                            </>
                                        )}
                                        {district !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: "1rem" }}>District : <a style={{ color: "red", fontSize: "1rem" }}>{district}</a></span>
                                            </>
                                        )}
                                        {commune !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: "1rem" }}>centre : <a style={{ color: "red", fontSize: "1rem" }}>{commune}</a></span>
                                            </>
                                        )}
                                        <span style={{ color: "black", fontSize: "1rem" }}> Total de  Candidats Libres  avec anomalie </span>  
                                        <h2 style={{ color: "red", fontWeight: 'bold', fontSize: "1.3rem"}}> {ANRDataUtils.formatNombre(listEtablissement.length)}</h2>
                                    </CardTitle>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {/* <Row> */}
                    <Col md="12">
                        <Button className="btn btn-outline-primary " onClick={e => { setVisibiliteEtablissement(false) }}>retour</Button>
                        <PDFDownloadLink className="btn btn-outline-primary fa fa-file" document={<DistrictPDF />} fileName={"National"}>
                            {({ blob, url, loading, error }) =>
                                loading ? ' Chargement du PDF...' : ' Exporter PDF'
                            }
                        </PDFDownloadLink>

                        <Table striped>
                            <thead className="text-primary">
                                <tr>
                                    <th style={{ fontSize: '13px' }}>Numéro</th>
                                    <th style={{ fontSize: '13px' }}>Centre d'inscription</th>
                                    <th style={{ fontSize: '13px' }}>Nom</th>
                                    <th style={{ fontSize: '13px' }}>Prénom</th>
                                    <th style={{ fontSize: '13px' }}>sexe</th>
                                    <th style={{ fontSize: '13px' }}>Série</th>
                                    <th style={{ fontSize: '13px' }}>Date de naissance</th>
                                    <th style={{ fontSize: '13px' }}>
                                        <button className={'btn btn-success'} disabled onClick={(e) => { valides(1) }}>Valider</button>
                                        <button className={'btn btn-danger'} disabled onClick={(e) => { valides(2) }}>Refuser</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listEtablissement.map((row, index) => (
                                        <>
                                            <tr>
                                                <td style={{ fontSize: '13px' }}>
                                                    {row.numero_incription.split(".")[0]}
                                                </td>
                                                <td style={{ fontSize: '13px', textAlign: "left" }}>
                                                    {row.commune}
                                                </td>
                                                <td style={{ fontSize: '13px', textAlign: "left" }}>
                                                    {(row.nom)}
                                                </td>
                                                <td style={{ fontSize: '13px', textAlign: "left" }}>
                                                    {(row.prenom)}
                                                </td>
                                                <td style={{ fontSize: '13px' }}>
                                                    {(row.sexe)}
                                                </td>
                                                <td style={{ fontSize: '13px' }}>
                                                    {(row.serie_option)}
                                                </td>
                                                <td style={{ fontSize: '13px' }}>
                                                    {toDateFormat(row.date_naissance)}
                                                </td>
                                                <td style={{ fontSize: '13px' }}>
                                                    {row.validation === 2 && (
                                                        <>
                                                            <button className={'btn btn-success'} onClick={(e) => { valide(row.id, 0) }}>Valider</button>
                                                            <button className={'btn btn-danger'} onClick={(e) => { valide(row.id, 2) }}>Refuser</button>
                                                        </>
                                                    )}
                                                </td>


                                            </tr>
                                        </>
                                    ))}
                            </tbody>
                        </Table>
                    </Col>
                    {/* </Row> */}
                </Row>
            </div>
        );
    }
}

export default EtablissementV;
