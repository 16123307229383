import { ThreeCircles } from 'react-loader-spinner';


const LoaderUtils = () => {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex:10 };
    return (
        <div style={style}>
            <ThreeCircles
                height="100"
                width="100"
                color="#ef8157"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="anr-data-loading"
                outerCircleColor="#630908"
                innerCircleColor="#F75E22"
                middleCircleColor="#B351A6"
            />
        </div>
        
    );
}
export default LoaderUtils;
