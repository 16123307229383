import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";



import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';
import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";

import './styles.css';

const CandidatC = ({ nombre_salle, setVisibleCandidat, salle, etablissement, commune, district, province, region }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [etablissement_d, setEtablissement_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);
    const [listss, setListss] = useState([]);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);

    const toDateFormat = (date) => {
        const dateString = date

        // Parse the date string into a Date object
        const moment = require('moment');

        // Parse the date string and format it into "DD-MM-YYYY" format
        const formattedDate = moment(dateString, "ddd MMM DD HH:mm:ss Z YYYY").format("DD-MM-YYYY");

        return formattedDate;

    }
    // const [loader, setLoader] = useState(false);
    // const [nom]
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [serie, setSerie] = useState([])
    const getPersonne = (candidat) => {
        const statusCount = candidat.reduce((acc, person) => {
            const status = person.serie_option;
            if (!acc[status]) {
                acc[status] = 0;
            }
            acc[status]++;
            return acc;
        }, {});

        setSerie(Object.keys(statusCount).map(status => ({
            status: status,
            nombre: statusCount[status]
        })));
    }
    const getListEtablissement = () => {
        fetch(url.urlHttp + "centre-milala/liste-candidat?salle=" + salle + "&&centre_xamen=" + etablissement + "&&commune=" + commune, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissement(data.data)
                setListss(data.data);
                getPersonne(data.data)
                setTotal_additionel(calculTotal(data.data))
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListEtablissement()
    }, [])
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            if (donnee[i].additionnel == 1) {
                t = t + 1;
            }

        }
        return t;
    }
    const stat = {
        // sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        // sumCandidatEcole: listEtablissement.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: listEtablissement.length
    };
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
            setListEtablissement(listss);
        }
        console.log(milahatra);
    };
    const [isVoirPlus, setIsVoi] = useState(false)
    const plus = () => {
        if (isVoirPlus == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }
    }
    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Col md="12">
                        <Card>
                        </Card>
                    </Col>
                    <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                        <Card style={{ padding: '10px', height: "150px" }} lg="3" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black' }}
                                    >Capacité du salle d'examen </p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '1.5rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px', height: "150px" }} lg="3" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black' }}
                                    >Candidats inscrits </p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '1.5rem' }}><b>{ANRDataUtils.formatNombre(listEtablissement.length)}</b></CardTitle>
                                {isMpotra==true&&(<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                            </Col>
                        </Card>
                        <Card style={{ padding: '10px', height: "150px" }} lg="3" md="5" sm="5">
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                    <div className="icon-big text-center icon-warning">
                                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                    </div>
                                    <p className="font-weight-bold" style={{ color: 'black' }}
                                    > Places disponibles</p>
                                </Row>
                                <CardTitle tag="p" style={{ fontSize: '1.5rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle - (listEtablissement.length))}</b></CardTitle>

                            </Col>
                        </Card>
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Card style={{ padding: '5px 5px 0px 5px', width: '100%', height: '90%' }} lg="3" md="5" sm="5">
                                <CardTitle>
                                    <Table>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th style={{ fontSize: '12px' }}>Serie</th>
                                                <th style={{ fontSize: '12px' }}>Nombre</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                serie.map((row, index) => (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: '12px' }}>{row.status}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.nombre}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </CardTitle>
                            </Card>
                        </Col>

                    </Row>
                    <Row md="4" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center">

                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <Card>

                        <CardBody>


                            <CardHeader>
                                <CardTitle style={{ fontSize: '20px' }}>
                                    <span style={{ color: "black", fontWeight: 'bold', }}>Capacité de  Salle d'examen :
                                        <span style={{ color: "red", fontWeight: 'bold' }}> {salle}</span>,&nbsp;
                                        <span style={{ color: "black", fontWeight: 'bold' }}>Capacité d'etablissement d'éxamen:
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {etablissement}, </span></span><br />
                                        <span style={{ color: "black", fontWeight: 'bold' }}> Centre :
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {commune}, </span></span>
                                        <span style={{ color: "black", fontWeight: 'bold' }}> District :
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {district}, </span></span>
                                        <span style={{ color: "black", fontWeight: 'bold' }}> Région :
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {region}, </span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> Province :
                                                <span style={{ color: "red", fontWeight: 'bold' }}> {province} </span> </span>
                                        </span></span><br />
                                </CardTitle>
                            </CardHeader>

                            <Button className="btn btn-outline-primary" onClick={e => { setVisibleCandidat(false) }}>retour</Button>
                            <Button className="btn btn-outline-primary" onClick={show}>
                                {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                            </Button>
                            <Button className="btn btn-outline-primary" onClick={plus}>
                                {isVoirPlus ? "Fermer" : "Voir Plus"}
                            </Button>
                            <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                <Table striped>
                                    <thead className="text-primary sticky-header">
                                        <tr>
                                            {
                                                isVoirPlus == true && (
                                                    <>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'region')}>Région &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'district')}>District &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'commune')}>Centre &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>

                                                    </>
                                                )
                                            }

                                            <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'numero_incription')}>Numéro &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                            <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'nom')}>Nom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'prenom')}>Prénom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'sexe')}>Sexe &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'type_candidat')}>Type Candidats &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'serie_option')}>Série &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            {/* <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'date_naissance')}>Date de naissance &nbsp;<i class="fa fa-arrow-down"></i></th> */}
                                            {/* <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'salle')}>Salle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th> */}
                                            {
                                                isVoirPlus == true && (
                                                    <>
                                                        <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'date_naissance')}>Date de naissance &nbsp;<i class="fa fa-arrow-down"></i></th>
                                                        <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'etablissement')}>établissement &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                                        <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'salle')}>Salle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                        <th style={{ fontSize: '12px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'centre_examen')}>Centre d'éxamen &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>

                                                    </>
                                                )
                                            }
                                            {/* <th>
                                            <button className={'btn btn-success'} >Valider</button>
                                                            <button className={'btn btn-danger'} >Refuser</button>
                                            </th> */}
                                            {/* <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'additionnel')}>additionnel &nbsp;<i class="fa fa-arrow-down"></i></th> */}
                                            {/* <td style={{ fontSize: '12px' }} >
                                                            {
                                                                row.additionnel==0&&(<>
                                                                <b style={{color:'green'}}>Non Additionnel</b>
                                                                </>)
                                                            }
                                                            {
                                                                row.additionnel==1&&(<>
                                                                <b style={{color:'red'}}>Additionnel</b>
                                                                </>)
                                                            }
                                                        </td> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listEtablissement.map((row, index) => (
                                                <>
                                                    <tr>
                                                        {
                                                            isVoirPlus == true && (
                                                                <>
                                                                    <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                                        {row.region}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                                        {(row.district)}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                                        {(row.commune)}
                                                                    </td>
                                                                </>
                                                            )
                                                        }
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {row.numero_incription.split(".")[0]}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.nom)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.prenom)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.sexe)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.type_candidat)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.serie_option)}
                                                        </td>
                                                        {
                                                            isVoirPlus == true && (
                                                                <>
                                                                    <td style={{ fontSize: '12px' }} >
                                                                        {toDateFormat(row.date_naissance)}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                                        {row.etablissement}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }} >
                                                                        {(row.salle)}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }} >
                                                                        {(row.centre_examen)}
                                                                    </td>
                                                                </>
                                                            )
                                                        }

                                                    </tr>
                                                </>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
// }

export default CandidatC;
