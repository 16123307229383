import React, { useEffect, useState, useRef } from "react";

// reactstrap components
import url from "../../urlConfig";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button

} from "reactstrap";
import TableauInfra from "./TableauInfra";
import ANRDataUtils from "utils/Utils";
import ChefLieuRegion from "./ChefLieuRegion";
import ChefLieuDistrict from "./ChefLieuDistrict";
import ChefLieuCommune from "./ChefLieuCommune";
import authHeader from "../../services/AuthHeader";
import FokotanyQG from "./FokotanyQG";

const calculTotal = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}
const Infrastructures = () => {

    const auth_data = authHeader()
    const [dashboard_qg, setDashboard_qg] = useState([]);
    const [id, setId] = useState('');
    const [isRegion, setIsRegion] = useState(true);
    const [isDistrict, setIsDitrict] = useState(false);
    const [isCommune, setisCommune] = useState(false);
    const [totalqg, setTotalQG] = useState(0)
    const [region_qg, setRegion_qg] = useState(0);
    const [district_qg, setdistrict] = useState(0);
    const [commune_qg, setcommune] = useState(0);
    const[isAutre,setIsautre]=useState(false);
    const handleIsDistrict = (e) => {
        setIsDitrict(true);
        setIsRegion(false);
        setisCommune(false);
        setIsautre(false);
    }
    const handleIsRegion = (e) => {
        setIsRegion(true);
        setIsDitrict(false);
        setisCommune(false);
        setIsautre(false);
    }
    const handleIsCommune = (e) => {
        setisCommune(true);
        setIsRegion(false);
        setIsDitrict(false);
        setIsautre(false);
    }
    const handleIsAutre = (e) => {
        setIsautre(true);
        setisCommune(false);
        setIsRegion(false);
        setIsDitrict(false);
    }
    const getDashBoard_qg = () => {
        fetch(url.urlHttp + "quartier-general/dashboard-qg", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setTotalQG(data.data.total_qg);
                setRegion_qg(data.data.region_qg);
                setdistrict(data.data.district_qg);
                setcommune(data.data.commune_qg);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [isFokotany, setisFokotany] = useState(false);
    const handleIsFokotany = (e) => {
        setisFokotany(true);
        setIsautre(false);
        setisCommune(false);
        setIsRegion(false);
        setIsDitrict(false);
    }
    const [nbr_fokotany,setNbr_fokotany]=useState(0);
    const getFokotany=()=>{
        fetch(url.urlHttp + "quartier-nouveau/nombre", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR 1 : ", data.data);
                setNbr_fokotany(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getFokotany()
        getDashBoard_qg()
    }, [])
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <Row>
                                <Col md="12" >
                                    
                                        <CardHeader>
                                        <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold">
                                            Liste des QG (Quartiers Généraux)
                                            </CardTitle>
                                        </CardHeader>
                                        <Row className="m-3">
                                            <Col lg="3" md="5" sm="5" >
                                                <Card style={{ width: '250px',cursor:'pointer' }} onClick={handleIsAutre} >
                                                    <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <CardTitle className="text-center" tag="p" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>Nombre total QG</CardTitle>
                                                        <p className="text-center" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'red' }}>
                                                            {/* {ANRDataUtils.formatNombre(dashboard_qg.region_qg)} */}
                                                            {ANRDataUtils.formatNombre(totalqg)}
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="3" md="5" sm="5">
                                                <Card onClick={handleIsRegion} style={{cursor:'pointer'}} >
                                                    <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <CardTitle className="text-center" tag="p" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>Chef lieu de Région</CardTitle>
                                                        <p className="text-center" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'red' }}>
                                                            {/* {ANRDataUtils.formatNombre(dashboard_qg.district_qg)} */}
                                                            {ANRDataUtils.formatNombre(region_qg)}/23
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="3" md="5" sm="5">
                                                <Card onClick={handleIsDistrict} style={{cursor:'pointer'}}>
                                                    <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <CardTitle className="text-center" tag="p" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>Chef lieu de District</CardTitle>
                                                        <p className="text-center" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'red' }}>
                                                            {/* {ANRDataUtils.formatNombre(dashboard_qg.commune_qg)}/ 1 695 */}
                                                            {ANRDataUtils.formatNombre(district_qg)} / 119
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="3" md="5" sm="5">
                                                <Card onClick={handleIsCommune} style={{cursor:'pointer'}}>
                                                    <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <CardTitle tag="p" className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>Chef lieu de Commune</CardTitle>
                                                        <p className="text-center" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'red' }}>
                                                            {/* {ANRDataUtils.formatNombre(dashboard_qg.fokotany_qg)}  */}
                                                            {ANRDataUtils.formatNombre(commune_qg)} / 1 695
                                                        </p>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="3" md="5" sm="5">
                                                <Card onClick={handleIsFokotany} style={{cursor:'pointer'}}>
                                                    <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <CardTitle tag="p" className="text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>QG Fokotany</CardTitle>
                                                        <p className="text-center" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'red' }}>
                                                            {/* {ANRDataUtils.formatNombre(dashboard_qg.fokotany_qg)}  */}
                                                            {ANRDataUtils.formatNombre(nbr_fokotany)} 
                                                        </p>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    
                                    {
                                        isRegion? (
                                            <>
                                                <CardBody>
                                                    <ChefLieuRegion />
                                                </CardBody>
                                            </>
                                        ):(<></>)
                                        
                                    }
                                     {
                                        isCommune?(
                                            <>
                                                <CardBody>
                                                    <ChefLieuCommune />
                                                </CardBody>
                                            </>
                                        ):(<></>)
                                    }
                                    {
                                        isDistrict?(
                                            <>
                                                <CardBody>
                                                    <ChefLieuDistrict />
                                                </CardBody>
                                            </>
                                        ):(<></>)
                                    }
                                    {
                                        isAutre?(
                                            <>
                                                <CardBody>
                                                    <TableauInfra />
                                                </CardBody>
                                            </>
                                        ):(<></>)
                                    }
                                    {
                                        isFokotany?(
                                            <>
                                                <CardBody>
                                                    <FokotanyQG />
                                                </CardBody>
                                            </>
                                        ):(<></>)
                                    }


                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Infrastructures;
