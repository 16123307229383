import React, { useState, useEffect } from "react";

// reactstrap components
import {
    Table, Card, CardBody, CardHeader
} from "reactstrap";




const NewMembers = ({ membre, dateDebut, dateFin }) => {

    return (
        <div className="content">
            <Card>
                <CardHeader>
                    Membre(s) ajouté(s)
                </CardHeader>
                <CardBody>
                    <Table striped>
                        <thead className="text-primary">
                            <tr>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Téléphone</th>
                                <th>Adresse</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Nom</td>
                                <td>Prenom</td>
                                <td>Téléphone</td>
                                <td>Address</td>
                            </tr>

                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}
export default NewMembers;
{/* <td>Région</td>
<td>District</td>
<td>Commune</td>
<td>Fokotany</td> */}