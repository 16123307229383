import React, { createContext, useContext, useState, useEffect } from 'react';
import ANRDataUtils from '../../../utils/Utils'


const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isConnected, setIsConnected]=useState(false);
  const [authority, setAuthority] = useState('Gouverneur');
  const token = localStorage.getItem('app_data');
  const choseAuthority =(params)=>{
    setAuthority(params);
  }
  const connect =()=>{
    setIsConnected(true);
  }
  const logout=()=>{
    setIsConnected(false);
  }

  useEffect(() => {
    setAuthority(authority)
  }, [authority]);

    return (
      <AuthContext.Provider value={{isConnected,token,authority,choseAuthority,  connect, logout,  }}>
        {children}
      </AuthContext.Provider>
    );
  };