import { useEffect, useState } from 'react';
import { FormSelect } from 'react-bootstrap';
import 'react-html5-camera-photo/build/css/index.css';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import authHeader from 'services/AuthHeader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';
import url from "../../urlConfig";
import "../candidats/tablefixe.css";

const AlertSwal = withReactContent(Swal);
const Nouveau_centre = ({ setVisible }) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [page, setPage] = useState();

    const auth_data = authHeader()
    const [total, setTotal] = useState('')
    const [province, setProvince] = useState('')
    const [region, setRegion] = useState('');
    const [district, setDistrict] = useState('');
    const [commune, setCommune] = useState('');
    const [fokotany, setFokontany] = useState('');
    const [nom, setNom] = useState('');
    const [code, setCode] = useState('');

    const [provinces, setProvinces] = useState([]);
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [communes, setCommunes] = useState([]);

    const [provincess, setProvincess] = useState([]);
    const [regionss, setRegionss] = useState([]);
    const [districtss, setDistrictss] = useState([]);
    const [communess, setCommuness] = useState([]);

    const [provincerecherche, setProvincerecherche] = useState('');
    const [regionrecherche, setRegionrecherche] = useState('');
    const [districtrecherche, setDistrictrecherche] = useState('');
    const [communerecherche, setCommunerecherche] = useState('');
    const [etablissementrecherche, setEtablissementrecherche] = useState('');
    const [centres, setCentres] = useState([]);


    const [salles, setsalles] = useState([]);
    const [nom_salle, setNom_salle] = useState('');
    const [code_salle, setCode_salle] = useState('');
    const [capacite, setCapacite] = useState('');
    const [electricite, setElectricite] = useState('');

    const [offset, setOffset] = useState(0);
    const [numPage, setNumPage] = useState(0);

    const errorAlert = (args) => {
        Swal.fire(
            'Erreur !',
            args,
            'error'
        )
    }

    const handleInputChange = (e) => {
        if (e.target.checked) {
            setElectricite(0);
        } else {
            setElectricite(1);
        }
    };

    const ajout = () => {
        const data = {
            province: province,
            region: region,
            district: district,
            code_commune: commune,
            fokotany: fokotany,
            nom_etablissement: nom,
            code_centre_examen: code
        }
        const datas = {
            centre_Examen: data,
            Salle_centre: salles
        }
        // console.log(datas);
        fetch(url.urlHttp + "examen/insert-centre-salle", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: datas
        }).then((res) => {
            setsalles([]);
            setNom('');
            setCode('');
            setOffset(0);
            getPagination();
            getAllCentre();
        })
            .catch(error => {
                console.log(error);
            });
    }

    const getAllCentre = () => {
        console.log(url.urlHttp + "examen/liste-centre-examen?province=" + provincerecherche + "&&region=" + regionrecherche + "&&district=" + districtrecherche + "&&commune=" + communerecherche + "&&etablissement=" + etablissementrecherche + "&&offset=" + offset)
        fetch(url.urlHttp + "examen/liste-centre-examen?province=" + provincerecherche + "&&region=" + regionrecherche + "&&district=" + districtrecherche + "&&commune=" + communerecherche + "&&etablissement=" + etablissementrecherche + "&&offset=" + offset, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        }).then(response => response.json())
            .then((res) => {
                setCentres(res.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    //
    // pagination
    //
    const getPagination = () => {
        console.log(url.urlHttp + "examen/pagination?province=" + provincerecherche + "&&region=" + regionrecherche + "&&district=" + districtrecherche + "&&commune=" + communerecherche + "&&etablissement=" + etablissementrecherche);
        fetch(url.urlHttp + "examen/pagination?province=" + provincerecherche + "&&region=" + regionrecherche + "&&district=" + districtrecherche + "&&commune=" + communerecherche + "&&etablissement=" + etablissementrecherche, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        }).then(response => response.json())
            .then((res) => {
                setPage(Math.ceil(res.data / 20))
                // console.log("reto le page" + Math.ceil(res.data / 20))
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getAllProvince = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            // body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                setProvinces(res.data);
                setProvincess(res.data);
                console.log(JSON.stringify(res.data));
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getRegions = (province) => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            // body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                setRegions(res.data);
                console.log(JSON.stringify(res.data));
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getDistricts = (region) => {
        fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getCommunes = (district) => {
        console.log(url.urlHttp + "tanana/commune?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "tanana/commune?region=" + region + "&&district=" + district + "&&commune=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getRegionss = (province) => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            // body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                setRegionss(res.data);
                console.log(JSON.stringify(res.data));
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getDistrictss = (region) => {
        fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=" + '', {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            // body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                setDistrictss(res.data);
                console.log(JSON.stringify(res.data));
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getCommuness = (district) => {
        // console.log(url.urlHttp + "tanana/commune?commune=" + provincerecherche + "&&region=" + regionrecherche + "&&district=" + district)
        fetch(url.urlHttp + "tanana/commune?commune=" + '' + "&&region=" + regionrecherche + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            // body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                setCommuness(res.data);
                console.log(JSON.stringify(res.data));
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getAllRegion = (value) => {
        if (value === "") {
            setRegions([]);
        }
        setProvince(value);
        getRegions(value);
        setDistricts([]);
        setCommunes([]);
    };
    const getAllDistrict = (value) => {
        if (value === "") {
            setDistricts([]);
        }
        setRegion(value);
        getDistricts(value);
        setCommunes([]);
    };
    const getAllCommune = (value) => {
        if (value === "") {
            setCommunes([]);
        }
        setDistrict(value);
        getCommunes(value);
    };

    //recherche
    const getAllRegions = (value) => {
        if (value === "") {
            setRegionss([]);
        }
        setProvincerecherche(value);
        getRegionss(value);
        setDistrictss([]);
        setCommuness([]);
    };
    const getAllDistricts = (value) => {
        if (value === "") {
            setDistrictss([]);
        }
        setRegionrecherche(value);
        getDistrictss(value);
        setCommuness([]);
    };
    const getAllCommunes = (value) => {
        if (value === "") {
            setCommuness([]);
        }
        setDistrictrecherche(value);
        getCommuness(value);
    };

    const Ajouter = () => {
        let salle_ap = {
            nom_salle: nom_salle,
            code_salle: code_salle,
            nbr_place_max: capacite,
            code_centre: code,
            electricite: electricite
        };
        salles.push(salle_ap);
        console.log(salles);
        setsalles(salles);
        toggle();
    }
    useEffect(() => {
        getAllProvince();
        setOffset(0);
        getPagination();
        getAllCentre();
    }, []);


    const setPages = (index) => {
        setNumPage(index);
        setOffset(index * 20);
        getAllCentre();
        getPagination();
    }

    const filter = () => {
        setOffset(0);
        getPagination();
        getAllCentre();
        // console.log("nombre de page actuel"+page)
    }
    const [isRecherche, setIsRecherche] = useState(false);
    const show = () => {
        if (isRecherche == true) {
            setIsRecherche(false);
        } else {
            setIsRecherche(true);
        }

    }
    const [isAjout, setIsAjout] = useState(false)
    const showAjout = () => {
        if (isAjout == true) {
            setIsAjout(false);
        } else {
            setIsAjout(true);
        }
    }
    return (
        <>
            <div className="content">

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}><a style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'green' }}>Ajout Salle par centre</a></ModalHeader>
                    <ModalBody>
                        <div>
                            {/* Section 1: Image, Immatriculation, Marque, Type Vehicule */}
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Nom salle : </label>
                                        <input
                                            className={'form-control'}
                                            type="text"
                                            value={nom_salle}
                                            onChange={e => setNom_salle(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Code salle : </label>
                                        <input
                                            className={'form-control'}
                                            type="text"
                                            value={code_salle}
                                            onChange={e => setCode_salle(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Capacité : </label>
                                        <input
                                            className={'form-control'}
                                            type="number"
                                            value={capacite}
                                            onChange={e => setCapacite(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Électricité :     </label>
                                        <input
                                            style={{ marginLeft: '20px' }}
                                            type="checkbox"
                                            checked={electricite === 0}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button className="btn btn-outline-primary" onClick={() => { Ajouter() }}>
                            Enregistrer
                        </Button>
                        <Button className="btn btn-outline-secondary" onClick={toggle}>
                            Quitter
                        </Button>
                    </ModalFooter>
                </Modal>


                <Row>
                    <Col md="12">
                        <Button className="btn btn-outline-primary" onClick={showAjout}>
                            {isAjout ? "Fermer  Ajout" : "Ajout"}
                        </Button>
                        <Button className="btn btn-outline-primary" onClick={show}>
                            {isRecherche ? "Fermer Recherche" : "Recherche"}
                        </Button>
                        <Card  style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                            <CardHeader></CardHeader>
                            <CardBody>
                                {
                                    isAjout == true &&
                                    (<>
                                        <Row>
                                            <Col lg="12" >
                                                <Card className="card-user" style={{ marginLeft: "-12px", marginRight: "-12px" }}>
                                                    <CardTitle className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green", fontSize: "1.3rem" }}>
                                                        AJOUT SALLE
                                                    </CardTitle>
                                                    <FormGroup>
                                                        <Button className="btn btn-outline-primary" onClick={toggle} style={{ marginTop: "40px", marginLeft: "20px" }}>
                                                            <i class="fa fa-plus"> </i> Ajout salle</Button>
                                                    </FormGroup>
                                                    <CardBody>
                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Province : </label>
                                                                <FormSelect onChange={(e) => { getAllRegion(e.target.value) }}
                                                                    className={'form-control'}>
                                                                    <option value="" style={{ fontSize: '1rem' }}>Choisir Province</option>
                                                                    {provinces.map((province) => (
                                                                        <option value={province.province}>{province.province}</option>
                                                                    ))}
                                                                </FormSelect>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Région : </label>
                                                                <FormSelect onChange={(e) => { getAllDistrict(e.target.value) }}
                                                                    className={'form-control'}>
                                                                    <option value="" style={{ fontSize: '1rem' }}>Choisir Région</option>

                                                                    {regions.map((region) => (
                                                                        <option value={region.region}>{region.region}</option>
                                                                    ))}
                                                                </FormSelect>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>District : </label>
                                                                <FormSelect onChange={(e) => { getAllCommune(e.target.value) }}
                                                                    className={'form-control'}>
                                                                    <option value="" style={{ fontSize: '1rem' }}>Choisir District</option>

                                                                    {districts.map((district) => (
                                                                        <option value={district.district}>{district.district}</option>
                                                                    ))}
                                                                </FormSelect>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Centre : </label>
                                                                <FormSelect onChange={(e) => { setCommune(e.target.value) }}
                                                                    className={'form-control'}>
                                                                    <option value="" style={{ fontSize: '1rem' }}>Choisir Centre</option>

                                                                    {communes.map((commune) => (
                                                                        <option value={commune.code_commune}>{commune.commune}</option>
                                                                    ))}
                                                                </FormSelect>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <FormGroup>
                                                                    <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Code Etablissement :</label>
                                                                    <input
                                                                        type={'text'}
                                                                        style={{ fontSize: '1rem' }}
                                                                        value={code}
                                                                        className={'form-control'}
                                                                        onChange={e => setCode(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <FormGroup>
                                                                    <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Nom Etablissement d'examen : </label>
                                                                    <input
                                                                        type={'text'}
                                                                        style={{ fontSize: '1rem' }}
                                                                        value={nom}
                                                                        className={'form-control'}
                                                                        onChange={e => setNom(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row> <br />
                                                        <Row>
                                                            <Table striped>
                                                                <thead className="text-primary sticky-header">
                                                                    <tr>
                                                                        <th style={{ fontSize: '13px' }}>Code salle</th>
                                                                        <th style={{ fontSize: '13px' }}>Nom salle</th>
                                                                        <th style={{ fontSize: '13px' }}>Centre</th>
                                                                        <th style={{ fontSize: '13px' }}>capacité maximale</th>
                                                                        <th style={{ fontSize: '13px' }}>Électricité</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        salles.map((row) => (
                                                                            <>
                                                                                <tr>
                                                                                    <td style={{ fontSize: '13px' }}>
                                                                                        {row.code_salle}
                                                                                    </td>
                                                                                    <td style={{ fontSize: '13px' }}>
                                                                                        {(row.nom_salle)}
                                                                                    </td>
                                                                                    <td style={{ fontSize: '13px' }}>
                                                                                        {(nom)}
                                                                                    </td>
                                                                                    <td style={{ fontSize: '13px' }}>
                                                                                        {(row.nbr_place_max)}
                                                                                    </td>
                                                                                    <td style={{ fontSize: '13px' }}>
                                                                                        {(row.electricite === 0 ? "oui" : "non")}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        </Row>
                                                        <Col className="pl-1" md="8">
                                                            <button className={'btn btn-outline-primary'} onClick={e => { ajout() }}>
                                                                <i className="fa fa-save"></i> Ajouter
                                                            </button>
                                                        </Col>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                    </>)

                                }
                                {
                                    isRecherche == true &&
                                    (<>
                                        <CardBody>
                                            <Row>
                                                <Col className="pr-1" md="4">
                                                    <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Province : </label>
                                                    <FormSelect onChange={(e) => { getAllRegions(e.target.value) }}
                                                        className={'form-control'}>
                                                        <option value="">Choisir province</option>
                                                        {provincess.map((province) => (
                                                            <option value={province.province}>{province.province}</option>
                                                        ))}
                                                    </FormSelect>
                                                </Col>
                                                <Col className="pr-1" md="4">
                                                    <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Région : </label>
                                                    <FormSelect onChange={(e) => { getAllDistricts(e.target.value) }}
                                                        className={'form-control'}>
                                                        <option value="">Choisir région</option>

                                                        {regionss.map((region) => (
                                                            <option value={region.region}>{region.region}</option>
                                                        ))}
                                                    </FormSelect>
                                                </Col>
                                                <Col className="pr-1" md="4">
                                                    <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>District : </label>
                                                    <FormSelect onChange={(e) => { getAllCommunes(e.target.value) }}
                                                        className={'form-control'}>
                                                        <option value="">Choisir district</option>

                                                        {districtss.map((district) => (
                                                            <option value={district.district}>{district.district}</option>
                                                        ))}
                                                    </FormSelect>
                                                </Col>
                                                <Col className="pr-1" md="4">
                                                    <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Centre : </label>
                                                    <FormSelect onChange={(e) => { setCommunerecherche(e.target.value) }}
                                                        className={'form-control'}>
                                                        <option value="">Choisir centre</option>

                                                        {communess.map((commune) => (
                                                            <option value={commune.commune}>{commune.commune}</option>
                                                        ))}
                                                    </FormSelect>
                                                </Col>
                                                <Col className="pr-1" md="4">
                                                    <FormGroup>
                                                        <label style={{ color: 'grey', fontSize: '1rem', fontWeight: 'bold' }}>Établissement :</label>
                                                        <input
                                                            type={'text'}
                                                            className={'form-control'}
                                                            onChange={e => setEtablissementrecherche(e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pl-1" md="4">
                                                    <button className={'btn btn-outline-primary'} onClick={e => { filter() }} style={{ marginTop: '30px', marginLeft: "13px" }}>
                                                        <i className="fa fa-save"></i> Filtrer
                                                    </button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </>)
                                }

                                {
                                    isAjout == false &&
                                    (<>
                                        <CardTitle className="d-flex mt-3 justify-content-center font-weight-bold"
                                            style={{ color: "green", fontSize: "1.2rem" }}>
                                            LISTE DE CENTRES
                                        </CardTitle>
                                        <CardTitle tag="h4" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green", fontSize: "1.2rem" }}>
                                            Nombre de ligne : <a style={{ color: "red" }}>  &nbsp;&nbsp;&nbsp; {ANRDataUtils.formatNombre(centres.length)}</a>
                                        </CardTitle>
                                        <Row>
                                            <div style={{ "align-items": "center", "justify-content": "center", "marginLeft": "40px" }}>
                                                {/* {Array.from({ length: page }, (_, index) => (
                                        <button key={index} onClick={() => setPages(index)} style={{ width: '40px' }}>
                                            {index + 1}
                                        </button>
                                    ))} */}
                                                {
                                                    numPage >= 1 && (
                                                        <Button className="btn btn-outline-info" onClick={() => setPages(numPage - 1)}>Précédent</Button>
                                                    )
                                                }&nbsp;&nbsp;&nbsp;
                                                {
                                                    numPage + 1 < page && (
                                                        <Button className="btn btn-outline-warning" onClick={() => setPages(numPage + 1)}>Suivant</Button>
                                                    )
                                                }
                                            </div>
                                        </Row>
                                        <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                        <Table striped>

                                            <thead className="text-primary">
                                                <tr>
                                                    <th style={{ fontSize: '13px' }}>Établissements d'Examen</th>
                                                    <th style={{ fontSize: '13px' }}>Capacités</th>
                                                    <th style={{ fontSize: '13px' }}>Salle</th>
                                                    <th style={{ fontSize: '13px' }}>Centre</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {
                                                        centres.map(row => (
                                                            <tr>
                                                                <td style={{ fontSize: '1.3rem' }}>{row.centre_examen}</td>
                                                                <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nbr_place_max)}</td>
                                                                <td style={{ fontSize: '1.5rem' }}>{row.salle}</td>
                                                                <td style={{ fontSize: '1.5rem' }}>{row.commune}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            </tbody>
                                        </Table>
                                        </div>
                                    </>)
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default Nouveau_centre;