import { useState } from "react";
import { HiQrcode } from "react-icons/hi";
import { Card, Row } from "reactstrap";
import QrCode from "./QrCode";
const Page = ({ }) => {
    //     const auth_data = authHeader();

    //     const getProvinces = () => {
    //         fetch(url.urlHttp + "tanana/province", {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': auth_data.content_type,
    //                 'Authorization': auth_data.authorization
    //             },
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 // console.log("DATA", data.data);
    //                 setProvinces(data.data);
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             });
    //     }
    const [visible_page, setVisible_page] = useState(false);
    const [types, setTypes] = useState(0);
    const [title, setTitle] = useState('')
    const buttons = (value) => {
        setVisible_page(true)
        setTypes(value)
        if (value == 1) {
            setTitle(' Générer un code QR pour une Paquet ')
        } else {
            setTitle(' Générer un code QR pour une Enveloppe ')
        }
    } 
    if (title != '' && visible_page == true) {
        return (<>
            <QrCode types={types} title={title} setVisible_page={setVisible_page} />
        </>)
    }
    return (
        <div className="content">
             <Row  md='12' style={{ gap: '12px', padding: '10px', margin: '0px'}} className="justify-content-center">
             <Card lg="6" md="5" sm="5" style={{ padding: '20px' ,cursor:true}}  onClick={(e) => { buttons(1) }}>
                <span style={{ color: 'red',fontSize:'2rem'}}>Générer Code QR PAQUET</span>
                <HiQrcode style={{width:'200px',height:'200px'}} />
            </Card>
            <Card lg="6" md="5" sm="5" style={{ padding: '20px',cursor:true }} onClick={(e) => { buttons(0) }}>
                <span style={{ color: 'red',fontSize:'2rem'}}>Générer Code QR ENVELLOPE</span>
                <HiQrcode style={{width:'200px',height:'200px'}} />
            </Card>
             </Row>
            {/* <Button style={{ height: "50%" }} className="btn btn-outline-primary "  > Générer Code QR URNE </Button>
            <Button style={{ height: "50%" }} className="btn btn-outline-primary" onClick={(e) => { buttons(0) }}>  Générer Code QR ENVELLOPE </Button> */}
        </div>
    );
}


export default Page;