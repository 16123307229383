import React from "react";
import './Legende.css'; 

const Legende = () => {
    return (
        <>
            <div className="legend">
                <div className="legend-item">
                    <div className="legend-color legend-color1"></div>
                    <span>Recu partiellement</span>
                </div>
                <div className="legend-item">
                    <div className="legend-color legend-color4"></div>
                    <span>Recu totalement</span>
                </div>
                <div className="legend-item">
                    <div className="legend-color legend-color2"></div>
                    <span>Non envoyé</span>
                </div>
            </div>
        </>
    )
}

export default Legende;