import React, { useEffect, useState } from "react";
import url from "../../urlConfig";
import {
  Card,
  CardBody,
  Button,
  Table,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup, Input, Label, CardTitle
} from "reactstrap";
import FormulaireInfra from "./FormulaireInfra";
import ANRDataUtils from "utils/Utils";
import ImportPhoto from "./ImportPhoto";
import LoaderUtils from "components/Shared/Loading";
import QGgoodies from "views/goodies/QGgoodies";
import authHeader from "../../services/AuthHeader";
import DetailsQG from "./detailsQG";
import ResponsableQG from "./ResponsableQG";
import {PDFDownloadLink, Page, Document, View, Text, StyleSheet} from "@react-pdf/renderer";

function ChefLieuRegion() {
  const auth_data = authHeader()
  const [showMore, setShowMore] = useState(false);

  const [SearchFilter, setSearchFilter] = useState([]);
  const [quartier_general, setQuartierGeneral] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const toggleModal = () => setModal(!modal);
  const toggleImage = () => setModalImage(!modalImage);
  const [modalPhotose, setModalPhotose] = useState(false);
  const togglePhotose = () => setModalPhotose(!modalPhotose);
  const [selectedLine, setSelectedLine] = useState(null);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const validation = async (row) => {
    const data = {
      numero_de_dossier: row,
    }
    console.log('donneee', data);
    await fetch(url.urlHttp + "quartier-nouveau/validate?id=" + row, {
      method: 'PUT',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        alert(JSON.stringify(data.data));
      })
      .catch(error => {
        console.log(error);
      });
    // }
    window.location.reload()
  }
  const supprimer = async (numero) => {
    const data = {
      numero_de_dossier: numero,
    }
    console.log('donneee', data);
    await fetch(url.urlHttp + "quartier-general/delete?id=" + numero, {
      method: 'PUT',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        alert(JSON.stringify(data.data));
      })
      .catch(error => {
        console.log(error);
      });
    // }
    window.location.reload()
  }
  const [search, setSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const [nom, setNom] = useState('');
  const [region, setRegion] = useState('');
  const [district, setdistrict] = useState('');
  const [commune, setcommune] = useState('');
  const [fokotany, setfokontany] = useState('');
  const [loader, setLoader] = useState([]);
  const handleSearch = () => {
    setSearch(!search);
  };
  const [filterNomRegion, setFilterNomRegion] = useState("");
  const [filterNomCommune, setFilterNomCommune] = useState("");
  const [filterNomDistrict, setFilterNomDistrict] = useState("");
  const [filterNomFokontany, setFilterNomFokontany] = useState("");
  const [filterNom, setFilterNom] = useState("");
  const [filterMode, setFilterMode] = useState("");
  const [mode_acquisition,setMode_Acquisition]=useState('')
  const getQuartierGeneral = (nom, region, district, commune, fokotany,mode_acquisition) => {
    setLoader(true);
    const apiUrl = url.urlHttp + "quartier-nouveau/liste-qg-regional?region=" + region + "&&district=" + district + "&&Commune=" + commune + "&&fokotany=" + fokotany + "&&nom=" + nom+"&&contrat="+mode_acquisition;
    const queryParams = {};
    if (filterNomRegion) {
      queryParams.region = filterNomRegion;
    }
    if (filterNomDistrict) {
      queryParams.district = filterNomDistrict;
    }
    if (filterNomCommune) {
      queryParams.commune = filterNomCommune;
    }
    if (filterNomFokontany) {
      queryParams.fokotany = filterNomFokontany;
    }
    if (filterNom) {
      queryParams.nom_prop_qg = filterNom;
    }
    if (filterMode) {
      queryParams.code_bv = filterMode;
    }
    if (Object.keys(queryParams).length > 0) {
      const filteredUrl = new URL(apiUrl);
      for (const key in queryParams) {
        filteredUrl.searchParams.append(key, queryParams[key]);
      }
      fetch(filteredUrl, {
        method: "GET",
        headers: {
          'Content-Type': auth_data.content_type,
          'Authorization': auth_data.authorization
        },
      })
          .then((response) => response.json())
          .then((data) => {
            setLoader(false);
            console.log("DATA RETOUR : ", data.data);
            setQuartierGeneral(data.data);
          })
          .catch((error) => {
            console.log(error);
          });
    } else {
      fetch(apiUrl, {
        method: "GET",
        headers: {
          'Content-Type': auth_data.content_type,
          'Authorization': auth_data.authorization
        },
      })
          .then((response) => response.json())
          .then((data) => {
            setLoader(false);
            console.log("DATA RETOUR : ", data.data);
            setQuartierGeneral(data.data);

          })
          .catch((error) => {
            console.log(error);
          });
    }
  };
  const handleACQUISITION=(value)=>{
    setMode_Acquisition(value)
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }
  const [modalgoogie, setmodalgoogie] = useState(false);

  const [modalDetails, setModalDetails] = useState(false);
  const toggleDetails = () => setModalDetails(!modalDetails);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const openDetailsModal = (index) => {
    setSelectedDetails(index);
    toggleDetails();
  };
  const togglegoogie = () => setmodalgoogie(!modalgoogie);
  const [selectedSuppri, setSelectedSuppri] = useState(null);
  const [nomResp, setNomResp] = useState('');
  const [fonctionResp, setFonctionResp] = useState('');
  const [contactResp, setContactResp] = useState('');
  const ajoutResp = async (id_infra) => {
    if (nomResp && fonctionResp && contactResp) {
      const imageData = {
        id_infra: id_infra, // Utilisez directement l'image Base64 depuis l'état
        nom: nomResp,
        fonction: fonctionResp,
        contact: contactResp
      };

      await fetch(url.urlHttp + "responsable-qg/nouveau-resp", {
        method: 'POST',
        headers: {
          'Content-Type': auth_data.content_type,
          'Authorization': auth_data.authorization
        },
        body: JSON.stringify(imageData),
      })
          .then(response => response.json())
          .then(data => {
            alert(JSON.stringify(data.data));
          })
          .catch(error => {
            console.log(error);
          });
      window.location.reload()
    } else {
      alert("Veuiller completer tous les champs.");
    }
    //
  };
  const [modalResp, setModalResp] = useState(false);
  const toggleResp = () => setModalResp(!modalResp);
  const [selectedResp, setSelectedResp] = useState(null);
  const openRespModal = (index) => {
    console.log("Mitakelley", index);
    setSelectedResp(index);
    toggleResp();
  };
  const openGoogie = (index) => {
    console.log("Ieny hoa",index);
    setSelectedSuppri(index);
    togglegoogie();
  };
  const handleNom = (value) => {
    setNom(value)
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }
  const handleRegion = (value) => {
    setRegion(value)
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }
  const handleDistrict = (value) => {
    setdistrict(value)
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }
  const handleCommune = (value) => {
    setcommune(value)
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }
  const handleFokotany = (value) => {
    setfokontany(value)
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }
  useEffect(() => {
    getQuartierGeneral(nom, region, district, commune, fokotany,mode_acquisition);
  }, []);

  const openImageModal = (index) => {
    setSelectedRow(index);
    toggleImage();
  };

  const openPhotoseModal = (index) => {
    setSelectedLine(index);
    togglePhotose();
  };

  /*
  *   EXPORT PDF QG - chef lieu region
  * */
  const pdfStylesQg = StyleSheet.create({
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableColHeader: {
      width: "14%",
      borderStyle: "solid",
      backgroundColor:"#f5f5f5",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColNonCheker: {
      width: "14%",
      borderStyle: "solid",
      backgroundColor:"#7e7676",
      borderWidth: 1,
      color:"#ffffff",
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColCheker: {
      width: "14%",
      borderStyle: "solid",
      backgroundColor:"#e35f5f",
      borderWidth: 1,
      borderLeftWidth: 0,
      color:"#ffffff",
      borderTopWidth: 0
    },
    tableCol: {
      width: "14%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 6
    }
  });

  const QgPdf = () => {
    return (
        <Document>
          <Page size={'A4'} style={{padding:'20px'}}>
            <View><Text style={{fontWeight: 'bold'}}>QG par Chef Lieu de Région</Text></View>
            <View><Text>-----------------------------------------------------------------------------------------------</Text></View>
            <View style={pdfStylesQg.table}>
              <View style={pdfStylesQg.tableRow}>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>Region</Text>
                </View>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>District</Text>
                </View>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>Commune</Text>
                </View>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>Fokotany</Text>
                </View>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>Proprietaire</Text>
                </View>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>Telephone</Text>
                </View>
                <View style={pdfStylesQg.tableColHeader}>
                  <Text style={pdfStylesQg.tableCell}>Quartier</Text>
                </View>
              </View>
              {
                quartier_general.map(qg => (
                    <View style={pdfStylesQg.tableRow}>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.region}</Text>
                      </View>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.district}</Text>
                      </View>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.commune}</Text>
                      </View>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.fokotany}</Text>
                      </View>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.nom_prop_qg}</Text>
                      </View>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.tel_prop_qg}</Text>
                      </View>
                      <View style={pdfStylesQg.tableCol}>
                        <Text style={pdfStylesQg.tableCell}>{qg.quartier}</Text>
                      </View>
                    </View>
                ))
              }
            </View>
          </Page>
        </Document>
    )
  }


  return (
    <div>
      <CardTitle>
        {/* <Label > */}
        <h2 className="title" >QG par Chef Lieu de Région  </h2>
        {/* </Label> */}
      </CardTitle>
      <Button className="btn btn-primary" onClick={handleShowMore}>
        {showMore ? "Voir moins" : "Voir plus"}
      </Button>
      <Button className="btn btn-primary" onClick={handleSearch}>
        {search ? "Fermer" : "Rechecrche"}
      </Button>
      <Button className="btn btn-primary" onClick={toggleModal}>
        Ajouter
      </Button>
      <Col className={"pr-1"} md={"3"}>
        <FormGroup>
          <PDFDownloadLink className="btn btn-primary fa fa-file" document={<QgPdf />} fileName={"QG_CHEF_LIEU_REGION"}>
            {({ blob, url, loading, error }) =>
                loading ? ' Chargement du PDF...' : ' Exporter PDF'
            }
          </PDFDownloadLink>
        </FormGroup>
      </Col>
      <Modal isOpen={modal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Ajouter un élément</ModalHeader>
        <ModalBody>
          <FormulaireInfra isOpen={modal} toggle={toggleModal} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              {
                search == true && (
                  <>
                    <Row>
                      <Col lg="3" md="5" sm="5" >
                        <Card style={{ width: '250px', cursor: 'pointer' }} >
                          <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CardTitle className="text-center" tag="p" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>Résultats trouvés</CardTitle>
                            <p className="text-center" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'red' }}>
                              {ANRDataUtils.formatNombre(
                                  quartier_general
                                      .filter((row,index) => {
                                        // Appliquez ici les critères de filtrage
                                        return (
                                            (!filterNomRegion || row.region.includes(filterNomRegion)) &&
                                            (!filterNomDistrict || row.district.includes(filterNomDistrict)) &&
                                            (!filterNomCommune || row.commune.includes(filterNomCommune)) &&
                                            (!filterNomFokontany || row.fokotany.includes(filterNomFokontany)) &&
                                            (!filterNom || row.nom_prop_qg.includes(filterNom))
                                        );
                                      })
                                      .length
                              )}
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="pr-1" md="3"
                      // style={{ border: '1px solid #f0f0f0', borderRadius: '5px', padding: '10px', margin: '10px' }}
                      >
                        <FormGroup>
                          <label style={{ border: 'type-' }} >Région</label>
                          <Input
                            type="text"
                            // style={{ borderRadius: "2" }}
                            value={filterNomRegion}
                            onChange={(e) => setFilterNomRegion(e.target.value)}
                            placeholder="champ recherche"
                            // onChange={e => handleNom(e.target.value)}
                            className={'form-control'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label style={{ border: 'type-round' }} >District</label>
                          <Input
                            type="text"
                            // style={{ borderRadius: "2" }}
                            value={filterNomDistrict}
                            onChange={(e) => setFilterNomDistrict(e.target.value)}
                            placeholder="champ recherche"
                            // onChange={e => handleNom(e.target.value)}
                            className={'form-control'}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-3" md="3"
                      // style={{ border: '1px solid #f0f0f0', borderRadius: '5px', padding: '10px', margin: '10px' }}
                      >
                        <FormGroup>
                          <label style={{ border: 'type-' }} >Commune</label>
                          <Input
                            type="text"
                            // style={{ borderRadius: "2" }}
                            value={filterNomCommune}
                            onChange={(e) => setFilterNomCommune(e.target.value)}
                            placeholder="champ recherche"
                            // onChange={e => handleNom(e.target.value)}
                            className={'form-control'}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label style={{ border: 'type-round' }} >Fokotany</label>
                          <Input
                            type="text"
                            // style={{ borderRadius: "2" }}
                            value={filterNomFokontany}
                            onChange={(e) => setFilterNomFokontany(e.target.value)}
                            placeholder="champ recherche"
                            // onChange={e => handleNom(e.target.value)}
                            className={'form-control'}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-3" md="6"
                      // style={{ border: '1px solid #f0f0f0', borderRadius: '5px', padding: '10px', margin: '10px' }}
                      >
                        <FormGroup>
                          <label style={{ border: 'type-round' }} >Nom</label>
                          <Input
                            type="text"
                            // style={{ borderRadius: "2" }}
                            value={filterNom}
                            onChange={(e) => setFilterNom(e.target.value)}
                            placeholder="champ recherche"
                            // onChange={e => handleNom(e.target.value)}
                            className={'form-control'}
                          />
                        </FormGroup>
                        {/*<FormGroup>
                          <label style={{ border: 'type-round' }} >AMPANOFAINA(ENY sa TSIA)</label>
                          <Input
                              type="text"
                              // style={{ borderRadius: "2" }}
                              placeholder="champ recherche"
                              value={mode_acquisition}
                              onChange={e => handleACQUISITION(e.target.value)}
                              className={'form-control'}
                          />
                        </FormGroup>*/}
                      </Col>
                    </Row>

                  </>
                )
              }
            </Card>
            <Card>
              <CardBody>

                  <Table striped>
                    <thead className="text-primary">
                      <tr>
                        <th></th>
                        <th>photo</th>
                        <th>Région</th>
                        <th>District</th>
                        <th>Commune</th>
                        <th>Fokontany</th>
                        <th>Quartier</th>
                        <th>Proprietaire</th>
                        <th>Telephone</th>
                        <th></th>
                        <th></th>

                        {showMore && (
                          <>
                            <th>Adresse du Proprietaire</th>
                            <th>Nom demandeur</th>
                            <th>Fonction demandeur</th>
                            <th>Telephone demandeur</th>
                            <th>Contre Partis</th>
                            <th>Meuble</th>
                            <th>Eau</th>
                            <th>Parking</th>
                            <th>WC</th>
                            <th>Electricite</th>
                            <th>Nombre de pieces</th>
                            <th>Superficie</th>
                            <th>Prix allocation</th>
                            <th>Distance au BV</th>
                            <th>Date de disponibilite</th>
                            <th>Assainissiment</th>
                            <th>Rehabillitation</th>
                            <th>Peinture</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                    {loader ? (
                        <LoaderUtils />
                    ) : quartier_general.length === 0 ? (
                        <tr>
                          <td colSpan="8">AUCUNE DONNÉE</td>
                        </tr>
                    ) : (
                        quartier_general
                            .filter((row, index) => {
                              if (filterNomRegion && !row.region.includes(filterNomRegion)) {
                                return false;
                              }
                              if (filterNomDistrict && !row.district.includes(filterNomDistrict)) {
                                return false;
                              }
                              if (filterNomCommune && !row.commune.includes(filterNomCommune)) {
                                return false;
                              }
                              if (filterNomFokontany && !row.fokotany.includes(filterNomFokontany)) {
                                return false;
                              }
                              if (filterNom && !row.nom_prop_qg.includes(filterNom)) {
                                return false;
                              }
                              return true;
                            })
                            .map((row, index) => (
                                <tr key={index}>
                                  <td>
                                    <button className="btn btn-outline-primary" onClick={() => openPhotoseModal(index)}>
                                      <i className="fas fa-camera" style={{ fontSize: '30px' }}></i>Ajout photo
                                    </button>
                                  </td>
                                  <td>
                                    <div>
                                      <img
                                          className="rounded w-90"
                                          src={row.photose}
                                          onClick={() => openImageModal(index)}
                                          alt={`Image ${index}`}
                                      />
                                    </div>
                                  </td>
                                  <td>{row.region}</td>
                                  <td>{row.district}</td>
                                  <td>{row.commune}</td>
                                  <td>{row.fokotany}</td>
                                  <td>{row.quartier}</td>
                                  <td>{row.nom_prop_qg}</td>
                                  <td>{row.tel_prop_qg}</td>

                                  {showMore && (
                                      <>
                                        <td>{row.adresse_prop_qg}</td>
                                        <td>{row.nom_dem}</td>
                                        <td>{row.fonction_dem}</td>
                                        <td>{row.tel_dem}</td>
                                        <td>{row.besoin}</td>
                                        <td>{row.meuble_equipe}</td>
                                        <td>{row.eau}</td>
                                        <td>{row.parking}</td>
                                        <td>{row.wc}</td>
                                        <td>{row.electricite}</td>
                                        <td>{row.nombre_de_pieces}</td>
                                        <td>{row.surface_m2}</td>
                                        <td>{ANRDataUtils.formatNombre(row.prix_alocation)}</td>
                                        <td>{row.alavirana}</td>
                                        <td>{row.date_disponibilite}</td>
                                        <td>{row.fanadiovana}</td>
                                        <td>{row.fanatsarana}</td>
                                        <td>{row.peinture}</td>
                                      </>
                                  )}

                                  <td>
                                    {row.validations === 1 ? (
                                        <>
                                          <b style={{ color: 'green' }}>
                                            <i className="fas fa-check">VALIDÉ</i>
                                          </b>
                                        </>
                                    ) : (
                                        <>
                                          <button className="btn btn-outline-primary" onClick={() => validation(row.id_infra)}>
                                            VALIDER
                                          </button>
                                        </>
                                    )}
                                  </td>
                                  <td>
                                    <button className="btn btn-outline-primary" onClick={() => supprimer(row.id_infra)}>
                                      <i className="fa fa-trash"> </i> Supprimer
                                    </button>
                                  </td>
                                </tr>
                            ))
                    )}
                    </tbody>

                  </Table>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modalImage} toggle={toggleImage} centered>
        <ModalBody>
          {selectedRow !== null && (
              <img
                  src={quartier_general[selectedRow].photose}
                  className="img-fluid"
                  alt={`Image ${selectedRow}`}
              />
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDetails} toggle={toggleDetails} size="lg" style={{maxWidth: '90%', width: '100%'}} >
        <ModalBody>
          {selectedDetails !== null && (
              <DetailsQG
                  region={selectedDetails !== null ? quartier_general[selectedDetails] : null}
              />
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleDetails}>Quitter</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalResp} toggle={toggleResp} size='l'>
        <ModalBody>
          {selectedResp !== null && (
              <Row>
                <Row>
                  <h4 style={{ marginLeft: '27%' }}>ajout nouveau responsable  </h4>
                  <div className=" justify-content-center align-items-center" style={{ marginLeft: '10%' }}>

                    <FormGroup >
                      <Label for="nomDem">NOM DU RESPONSABLE:</Label>
                      <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                             placeholder="Nom du responsable"
                             value={nomResp}
                             type="text"
                             onChange={e => setNomResp(e.target.value)}
                      >
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="nomDem">FONCTION:</Label>
                      <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                             placeholder="fonction"
                             value={fonctionResp}
                             type="text"
                             onChange={e => setFonctionResp(e.target.value)}
                      >
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="nomDem">CONTACT:</Label>
                      <Input style={{ borderTop: "none", borderLeft: 'none', borderRight: 'none', borderRadius: "0" }}
                             placeholder="contact"
                             value={contactResp}
                             type="text"
                             onChange={e => setContactResp(e.target.value)}
                      >
                      </Input>
                    </FormGroup>
                    {/* {} */}
                    <button className="btn btn-outline-primary"
                            onClick={() => ajoutResp(quartier_general[selectedResp].id_infra)}
                    >
                      ajouter
                    </button>
                  </div>

                </Row>
              </Row>

          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={modalPhotose} toggle={togglePhotose} size="xl">
        <ModalHeader toggle={togglePhotose}>Importation photo</ModalHeader>
        <ModalBody>
          <ImportPhoto
              id_infra={selectedLine !== null ? quartier_general[selectedLine].id_infra : null}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePhotose}>
            Annuler
          </Button>
        </ModalFooter>


      </Modal>
      <Modal isOpen={modalgoogie} toggle={togglegoogie} size="xl">
        <ModalHeader toggle={togglegoogie} className="fw-bold"><b></b></ModalHeader>
        <ModalBody >
          <ResponsableQG
              region={selectedSuppri !== null ? quartier_general[selectedSuppri] : null}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglegoogie}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ChefLieuRegion;
