import React, { useState } from "react";
import { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
    Table,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input
} from "reactstrap";
import url from "urlConfig";
import authHeader from 'services/AuthHeader';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';

const FormulaireCandidats = () => {

    const auth_data = authHeader();
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [communes, setCommunes] = useState([]);
    const [typeCandidat, setTypeCandidat] = useState('');
    const [typeCandidats, setTypeCandidats] = useState([]);
    const [commune, setCommune] = useState('');
    const [disiableEcole, setDisableEcole] = useState(false);
    const [ecole, setEcole] = useState('');
    const [specialites, setSpecialites] = useState([]);
    const [specialite, setSpecialite] = useState('');
    const [secteurs, setSecteurs] = useState([]);
    const [secteur, setSecteur] = useState('');
    const [series, setSeries] = useState([]);
    const [serie, setSerie] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [genre, setGenre] = useState('');
    const [date_naissance, setDate_naissance] = useState('');
    const [addresse, setAddresse] = useState('');
    const [numero, setNumero] = useState('');
    const [titre, setTitre] = useState('');
    const [lieu_naissance, setLieu_naissance] = useState('');
    const [code_commune, setCode_commune] = useState('');

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        fetch(url.urlHttp + "tanana/district?region=" + province + "&&district=" + region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommune = () => {
        console.log(url.urlHttp + "tanana/commune?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "tanana/commune?region=" + province + "&&district=" + region + "&&commune=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getSpecialite = () => {
        fetch(url.urlHttp + "candidat/specialite", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA spec", data.data);
                setSpecialites(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSecteur = () => {
        fetch(url.urlHttp + "candidat/secteur?specialite=" + specialite, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA secteur", data.data);
                setSecteurs(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSerie = () => {
        fetch(url.urlHttp + "candidat/serie?secteur=" + secteur, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA serie", data.data);
                setSeries(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleEcole = () => {
        if (typeCandidat == "Candidat libre") {
            setDisableEcole(true);
        } else {
            setDisableEcole(false);
        }
    }

    const handleTitre = (e) => {
        setGenre(e);
        if (e == "M") {
            setTitre('Monsieur');
        } else {
            setTitre("Mademoiselle");
        }
    }

    const handleCentre = (commune) => {
        for (let i = 0; i < communes.length; i++) {
            if (communes[i].commune == commune) {
                setCode_commune(communes[i].code_commune);
            }
        }
    }

    const save = async () => {
        const data_send = {
            numero_incription: numero,
            adresse: addresse,
            titre: titre,
            nom: nom,
            prenom: prenom,
            sexe: genre,
            date_naissance: date_naissance,
            lieu_naissance: lieu_naissance,
            type_candidat: typeCandidat,
            centre: commune,
            etablissement: ecole,
            specialite: specialite,
            secteur: secteur,
            serie_option: serie,
            validation: 0,
            code_commune: code_commune
        }
        await fetch(url.urlHttp + 'candidat/csv', {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data_send)
        }).then((response) => response.json())
            .then((data) => {
                console.log(data.data);
                ANRDataUtils.successAlert(data.message);
            })
            .catch((error) => console.log('error', error))
    }


    useEffect(() => {
        getProvinces();
        getSpecialite();
    }, [])

    return (
        <>
            <Card style={{ marginTop: "100px", marginLeft: "20px", marginRight: "20px" }} >
                <CardHeader></CardHeader>
                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                    AJOUT DE CANDIDATS
                </CardTitle>
                <CardBody>
                    <Form >
                        <Row>
                            <Col className="pr-3" md="6">
                                <FormGroup className="mr-3">
                                    <Label for="nomDem"> <a style={{ color: "black", fontWeight: 'bold' }} >NUMÉRO D'INSCRIPTION : </a> </Label>
                                    <Input
                                        placeholder="Numéro"
                                        type="text"
                                        value={numero} onChange={e => { setNumero(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pr-3" md="6">
                                <FormGroup className="mr-3">
                                    <Label for="nomDem"><a style={{ color: "black", fontWeight: 'bold' }}>NOM :</a></Label>
                                    <Input
                                        placeholder="Nom"
                                        type="text"
                                        value={nom} onChange={e => { setNom(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>PRÉNOMS :</a></Label>
                                    <Input
                                        placeholder="Prénoms"
                                        type="text"
                                        value={prenom} onChange={e => { setPrenom(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup tag="fieldset">
                                    <label><a style={{ color: "black", fontWeight: 'bold' }}>GENRE :</a></label>
                                    <select className={'form-control'} value={genre} onChange={e => { setGenre(e.target.value) }} onClick={handleTitre}>
                                        <option value="" ></option>
                                        <option value="M" >Homme</option>
                                        <option value="F" >Femme</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>DATE DE NAISSANCE</a> :</Label>
                                    <Input
                                        placeholder="Adresse"
                                        type="date"
                                        value={date_naissance} onChange={e => { setDate_naissance(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup >
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>LIEU DE NAISSANCE</a></label><br />
                                    <input type="text" className={'form-control'} value={lieu_naissance} onChange={e => { setLieu_naissance(e.target.value) }} />
                                </FormGroup>
                            </Col>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PROVINCE :</a></label><br />
                                    <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                        <option value={'0'}></option>
                                        {provinces?.map(province => (
                                            <option value={province.province} >{province.province}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>RÉGION : </a> </label><br />
                                    <select className={'form-control'} value={region} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                                        <option value={'0'}></option>
                                        {regions?.map(region => (
                                            <option value={region.region} >{region.region}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                    <select className={'form-control'} value={district} onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                        <option value={'0'}></option>
                                        {districts?.map(district => (
                                            <option value={district.district} >{district.district}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                    <select className={'form-control'} value={commune} onChange={e => { { setCommune(e.target.value); handleCentre(e.target.value) } }}>
                                        <option value={'0'}></option>
                                        {communes?.map(commune => (
                                            <option value={commune.commune} >{commune.commune}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="4">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>ADRESSE :</a></Label>
                                    <Input
                                        placeholder="Adresse"
                                        type="text"
                                        value={addresse} onChange={e => { setAddresse(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}> TYPE DE CANDIDAT :</a> </label><br />
                                    <select className={'form-control'} value={typeCandidat} onChange={e => { setTypeCandidat(e.target.value) }} onClick={handleEcole}>
                                        <option value="" ></option>
                                        <option value="Candidat libre" >Candidat libre</option>
                                        <option value="Candidat d ecole" >Candidat d'école</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup >
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>ÉCOLE : </a> </label><br />
                                    <input type="text" className={'form-control'} value={ecole} onChange={e => { setEcole(e.target.value) }} disabled={disiableEcole} />
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} > <a style={{ color: "black", fontWeight: 'bold' }}>SPÉCIALITÉ :</a></label><br />
                                    <select className={'form-control'} value={specialite} onChange={e => { setSpecialite(e.target.value) }} onClick={handleSecteur}>
                                        <option value="" ></option>
                                        {specialites?.map(specialite => (
                                            <option value={specialite.specialite} >{specialite.specialite}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} > <a style={{ color: "black", fontWeight: 'bold' }}>SÉCTEUR :</a> </label><br />
                                    <select className={'form-control'} value={secteur} onChange={e => { setSecteur(e.target.value) }} onClick={handleSerie}>
                                        <option value="" ></option>
                                        {secteurs?.map(secteur => (
                                            <option value={secteur.secteur} >{secteur.secteur}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} > <a style={{ color: "black", fontWeight: 'bold' }}>SÉRIE</a> </label><br />
                                    <select className={'form-control'} value={serie} onChange={e => { setSerie(e.target.value) }} >
                                        <option value="" ></option>
                                        {series?.map(serie => (
                                            <option value={serie.serie_option} >{serie.serie_option}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className="btn btn-primary" onClick={save}>
                            Enregistrer
                        </Button>
                    </Form>
                    {/* </div> */}
                </CardBody>
            </Card>
        </>
    );
}

export default FormulaireCandidats;