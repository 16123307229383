import Infrastructures from "views/infrastructures/Infrastructure";
import NewMembers from "views/membre/NewMembers/NewMembers";
import NouveauMembre from "views/membre/NouveauMembre";
import LoginMen from "views/auth_login/loginMen";
const clientRoutes=[
    {
        id:0,
        path: "/membre-tgv",
        name: "Ajout membre",
        icon: "nc-icon nc-simple-add",
        component: <NouveauMembre />,
        layout: "/men",
        submenu: []
      },
    {
      id:1,
      path: "/membres",
      name: "Membres",
      icon: "nc-icon nc-zoom-split",
      component: <NewMembers ressource={0} categorieP={''} titre={'Recherche Membres'} />,
      layout: "/men",
      submenu: []
    },
    {
        id:2,
        path: "/quartier-generale",
        name: "QG (Quartier Général)",
        icon: "nc-icon nc-bank",
        component: <Infrastructures />,
        layout: "/men",
        submenu: []
      },
      {
        id:2,
        name: "Se déconnecter",
        icon: "nc-icon nc-button-power",
        submenu: []
      },
      
  ]
  export default clientRoutes;