import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import url from "urlConfig";
import ANRDataUtils from "utils/Utils";



import authHeader from "../../services/AuthHeader";

// import Etablissement from "./fokotany";

import CandidatQG from './CandidatQG';
import EtablissementStatQG from "./EtablissementQG";
// import EtablissementVotes from "./EtablissementVotes";

const calculTotals = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const CommuneQG = ({ region, district, province, setVisibleCommune, libre, ecole }) => {
    const auth_data = authHeader()
    const [lists, setLists] = useState([]);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [communelist, setCommunelist] = useState([])
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [commune_d, setcommune_d] = useState(null)
    const [visibiliteEtablissement, setVisibiliteEtablissement] = useState(false)
    const [visibiliteCandidat, setVisibiliteCandidat] = useState(false)
    const detailList = (value) => {
        setcommune_d(value)
        setVisibiliteCandidat(true)
    }
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].total > 0) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListss(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }
    const [isVoi, setIsVoi] = useState(false)
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra,setIsMpotra]=useState(false);

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }
    const [listss, setListss] = useState([]);
    const getListCommune = () => {
        fetch(url.urlHttp + "candidat-midina/candidat-libre-commune?region=" + region + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setCommunelist(data.data);
                setLists(data.data);
                getsListTsisy(data.data);
                setTotal_additionel(calculTotals(data.data.map(row => row.total_1)))
                setCandidat_ecole_1(calculTotals(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotals(data.data.map(row => row.candidat_libre_1)))
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListCommune()
    }, [])
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.commune.toUpperCase().includes(value.toUpperCase()));
        getsListTsisy(filteredData);
        // setListMisy(filteredData);
    }

    const stat = {
        sumCandidatLibre: communelist.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: communelist.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: communelist.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const details = (value) => {
        setcommune_d(value)
        setVisibiliteEtablissement(true)
    }
    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "25%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "25%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 2,
            fontSize: 9
        }
    });

    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'black' }}>Types de candidats au niveau  <Text style={{ color: 'green' }}>District</Text> de <Text style={{ color: 'red' }}>{district}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province : <Text style={{ color: 'red' }}>{province}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Région : <Text style={{ color: 'red' }}>{region}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de Candidats  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Candidats d'École : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Candidats Libres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    {/* <View><Text style={{ fontWeight: 'bold' }}>Liste District </Text></View> */}
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Centre</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats d'École</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats Libres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
                            </View>
                        </View>
                        {
                            communelist.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.commune}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_ecole)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_libre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.total)}</Text>
                                    </View>

                                    {/* <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                    </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listMisy]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListMisy(sortedWords);
        } else {
            setMilahatra(true);
            setListMisy(listss);
        }
        console.log(milahatra);
    };
    if (commune_d != null && visibiliteEtablissement == true) {
        return (
            <>
                <EtablissementStatQG setVisibiliteEtablissement={setVisibiliteEtablissement} commune={commune_d} district={district} province={province} region={region} ecole={ecole} libre={libre} />
            </>
        )
    } else if (visibiliteCandidat == true && commune_d != null) {
        return (
            <>
                <CandidatQG setVisibleCandidat={setVisibiliteCandidat} etablissement={''} types={'Centre'} commune={commune_d} district={district} province={province} region={region} />
            </>
        )
    } else {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            {/* Affichez un message d'erreur s'il y a une erreur */}
                            {error && <div className="alert alert-danger">{error}</div>}
                            {
                                isShow == false && (
                                    <Row className="justify-content-center">
                                        <Col md="4">
                                            <Card style={{ height: " 100%" }}>
                                                <CardHeader>
                                                    <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        <span style={{ fontWeight: 'bold', color: "green", fontSize: '1rem' }}> Au niveau District </span>
                                                        <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red" }}>{province}</a></span>
                                                        <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red" }}>{region}</a></span>
                                                        <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red" }}>{district}</a></span>
                                                    </CardTitle>
                                                </CardHeader>
                                            </Card>
                                        </Col>
                                        <Col md="4">
                                            <Card style={{ height: " 100%" }}>
                                                <CardHeader>
                                                    <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        {ecole === true ? <span style={{ fontSize: '1rem', color: "black" }}>  Candidats d'École: <a style={{ fontSize: '1.5rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}
                                                            <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(candidat_ecole_1)}</span>
                                                        </a></span> : <br />}
                                                        {libre === true ? <span style={{ fontSize: '1rem', color: "black" }}>  Candidats Libres : <a style={{ fontSize: '1.5rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}
                                                            <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(candidat_libre_1)}</span>
                                                        </a></span> : <br />}
                                                        <span style={{ fontSize: '1rem', color: "black" }}> Total de Candidats : <a style={{ fontSize: '1.5rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}
                                                            <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(total_additionel)}</span>
                                                        </a></span>
                                                    </CardTitle>
                                                </CardHeader>
                                            </Card >
                                        </Col>
                                    </Row>
                                )
                            }

                        </Col>
                        {isShow==false && (<Col className="pr-1" md="4">
                            <FormGroup>
                                <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Centre :</a></label>
                                <input
                                    type={'text'}
                                    style={{ fontSize: '0.9rem' }}
                                    className={'form-control'}
                                    placeholder={'Rechercher centre'}
                                    onChange={e => filtre(e.target.value)}
                                />
                            </FormGroup>
                        </Col>)}
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card style={{ marginTop: '40px' }}>
                                <CardBody>
                                    {isShow==false && (<CardHeader>
                                        <CardTitle tag="h4" style={{ position: 'fi', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>
                                                Au niveau District </span>
                                                <span style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>
                                                <a style={{ fontSize: '1rem', color: "black", }}>Province :    <a style={{ fontSize: '1rem', color: "red" }}>{province}</a></a>&nbsp;<br />
                                                <a style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{fontSize: '1rem', color: "red" }}>{region}</a></a>&nbsp;<br />
                                                <a style={{ color: "black", fontSize: '1rem' }}>District : <a style={{fontSize: '1rem', color: "red" }}>{district}</a></a><br />
                                                {ecole === true ? <span style={{ fontSize: '1rem', color: "black" }}>  Candidats d'École: <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</a><br /></span> : <br />}
                                                {libre === true ? <span style={{ fontSize: '1rem', color: "black" }}>  Candidats de Libres : <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</a><br /></span> : <br />}
                                                <span style={{ fontSize: '1rem', color: "black" }}> Total de Candidats : <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</a><br /></span>
                                                </span>



                                        </CardTitle>
                                    </CardHeader>)}
                                    
                                    <Button className="btn btn-outline-primary " onClick={e => { setVisibleCommune(false) }}>retour</Button>
                                    <Button className="btn btn-outline-primary" onClick={show}>
                                        {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                    </Button>
                                    <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"National"}>
                                        {({ blob, url, loading, error }) =>
                                            loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                        }
                                    </PDFDownloadLink>
                                    <Button className="btn btn-outline-primary" onClick={voirPlus}>
                                        {isVoi ? "Fermer" : "Voir Plus"}
                                    </Button>
                                    {isShow==false && (<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                        <Table striped>

                                            <thead className="text-primary sticky-header">
                                                <tr>
                                                    <th style={{ fontSize: '1rem' }} onClick={() => handleSort('asc', 'commune')}>centre &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                    {ecole === true ? <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Nombre de Candidats d'École&nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                                    {libre === true ? <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'candidat_libre')}>Nombre de Candidat Libre &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                                    <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'total')}>Total de Candidats &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listMisy.map((row, index) => (
                                                        <>
                                                            <tr>
                                                                <td style={{  textAlign: 'left' }}>
                                                                    {row.commune}
                                                                </td>
                                                                {ecole === true ?
                                                                    <td style={{ fontSize: '1.5rem' }}>
                                                                        {ANRDataUtils.formatNombre(row.candidat_ecole)}
                                                                        <br />{isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                            +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                                        </span>)}
                                                                    </td>
                                                                    :
                                                                    ''}
                                                                {libre === true ?
                                                                    <td style={{ fontSize: '1.5rem' }}>

                                                                        {ANRDataUtils.formatNombre(row.candidat_libre)}
                                                                        <br />{isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                            +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                                        </span>)}
                                                                    </td> :
                                                                    ''}
                                                                <td style={{ fontSize: '1.5rem' }}>

                                                                    {ANRDataUtils.formatNombre(row.total)}
                                                                    <br />{isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                        +{ANRDataUtils.formatNombre(row.total_1)}
                                                                    </span>)}
                                                                </td>
                                                                <td >
                                                                    {
                                                                        row.total > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher Établissement</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Afficher Établissement</button>
                                                                    }
                                                                </td>
                                                                <td >
                                                                    {
                                                                        row.total > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { detailList(row.commune) }}>Afficher listes</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Afficher listes</button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    ))}
                                                {

                                                    isVoi === true && (
                                                        <>
                                                            {
                                                                listTsymisy.map(row => (
                                                                    <tr>
                                                                        <td style={{  textAlign: 'left' }}>
                                                                            {row.commune}
                                                                        </td>
                                                                        {ecole === true ?
                                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                                {ANRDataUtils.formatNombre(row.candidat_ecole)}
                                                                                 {/* <br /><span style={{ color: 'red' }}>
                                                                                    +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                                                </span> */}
                                                                            </td>
                                                                            :
                                                                            ''}
                                                                        {libre === true ?
                                                                            <td style={{ fontSize: '1.5rem' }}>

                                                                                {ANRDataUtils.formatNombre(row.candidat_libre)}
                                                                                 {/* <br /><span style={{ color: 'red' }}>
                                                                                    +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                                                </span> */}
                                                                            </td> :
                                                                            ''}
                                                                        <td style={{ fontSize: '1.5rem' }}>

                                                                            {ANRDataUtils.formatNombre(row.total)}
                                                                             {/* <br /><span style={{ color: 'red' }}>
                                                                                +{ANRDataUtils.formatNombre(row.total_1)}
                                                                            </span> */}
                                                                        </td>
                                                                        <td >
                                                                            {
                                                                                row.total > 0 ?
                                                                                    <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher Établissement</button>
                                                                                    :
                                                                                    <button className={'btn btn-danger disabled'} >Afficher Établissement</button>
                                                                            }
                                                                        </td>
                                                                        <td >
                                                                            {
                                                                                row.total > 0 ?
                                                                                    <button className={'btn btn-danger'} onClick={(e) => { detailList(row.commune) }}>Afficher listes</button>
                                                                                    :
                                                                                    <button className={'btn btn-danger disabled'} >Afficher listes</button>
                                                                            }
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    )

                                                }
                                            </tbody>
                                        </Table>
                                    </div>)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

}
export default CommuneQG;