import { useEffect, useState } from "react";
// react plugin used to create charts
// reactstrap components
// core components
import {
  Button,
  Card, CardBody,
  CardTitle,
  Col,
  Modal, ModalBody,
  ModalHeader,
  Row, Table
} from 'reactstrap';
import ComponentsChart from '../statistique/ComponentsChart';

import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

// import authHeader from 'services/AuthHeader';
// import ANRDataUtils from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import authHeader from "services/AuthHeader";
import ANRDataUtils from "utils/Utils";



import myImage from '../../assets/img/ANR_BANNER.jpg';
import myImage2 from '../../assets/img/ANR_PDP.jpg';
import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';
import url from '../../urlConfig';
import '../../views/Chartfonction';
import HistographeDistrict from '../histographe/HistographeDistrict';


const calculTotals = (donnee) => {
  let t = 0;
  let i = 0;
  for (i = 0; i < donnee.length; i++) {
    t = t + donnee[i];
  }
  return t;
}

function Dashboard() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loader, setLoader] = useState(false);
  const [totalCentre, setTotalCentre] = useState(0)
  const [totalCandidat, setTotalCandidat] = useState(0)
  const [totalLibre, setTotalLibre] = useState(0)
  const [totalEcole, setTotalEcole] = useState(0)
  const [total_additionel, setTotal_additionel] = useState(0);
  const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
  const [candidat_libre_1, setCandidat_libre_1] = useState(0);
  const data = authHeader();
  const [totalVoix, setTotalVoix] = useState(0);
  const [province, setProvince] = useState([])
  const [centre_nombre, setNombre_centre] = useState([])
  const [candidat_nombre, setNombre_candidat] = useState([])
  const [nombre_libre, setNombre_libre] = useState([])
  const [indice, setIndice] = useState(0)
  const [centre, setCentre] = useState([])

  // const[cand]
  const [nombre_centre, setTotalNombre_centre] = useState(0)

  const [nombre_salle, setNombre_salle] = useState(0)
  const [totalEta, setTotalEta] = useState(0)
  const getDashboard = () => {
    fetch(
      url.urlHttp + "dashboard/province", {
      method: "GET",
      headers: {
        'Content-Type': data.content_type,
        'Authorization': data.authorization
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCentre(data.data)
        setTotalCentre(calculTotals(data.data.map(row => row.nbr_place_max)))
        setTotalCandidat(calculTotals(data.data.map(row => row.nombre_candidat)))
        setTotalEcole(calculTotals(data.data.map(row => row.candidat_ecole)))
        setTotalNombre_centre(calculTotals(data.data.map(row => row.nombre_centre)))
        setTotalEta(calculTotals(data.data.map(row => row.nombre_eta)))
        setNombre_salle(calculTotals(data.data.map(row => row.nombre_salle)))
        setTotal_additionel(calculTotals(data.data.map(row => row.nombre_candidat_1)))
        setCandidat_ecole_1(calculTotals(data.data.map(row => row.candidat_ecole_1)))
        setCandidat_libre_1(calculTotals(data.data.map(row => row.candidat_libre_1)))
        setTotalLibre(calculTotals(data.data.map(row => row.candidat_libre)))
        setProvince(data.data.map(row => row.province))
        setNombre_centre(data.data.map(row => row.nbr_place_max))
        setNombre_candidat(data.data.map(row => row.candidat_ecole))
        setNombre_libre(data.data.map(row => row.candidat_libre))
        setIndice(indice + 1)
        console.log('Centre', centre);

      })
      .catch((error) => {
        console.log(error);
      });
  };
  const navigate = useNavigate()


  const [indicesdash, setIndiceDash] = useState(0);
  useEffect(() => {
    // alert(indicesdash)
    getDashboard();

  }, [indicesdash]);
  const [candidat, setCandidat] = useState({});

  useEffect(() => {
    // getCandidat();
  }, []);


  const [totalBn, setTotalBn] = useState(0);
  useEffect(() => {
    // totalBN();
  }, []);
  const [totalVotant, setTotalVotant] = useState(0);
  const [visibleDistrict, setVisibleDistrict] = useState(false);
  const [region_sec, setregion_sec] = useState(null)
  const detailRegion = (value) => {
    setLoader(true);
    setregion_sec(value)
    setVisibleDistrict(true)
    setLoader(false);
  }
  useEffect(() => {

  }, [indice])
  const [modalPhotose, setModalPhotose] = useState(false);

  const togglePhotose = () => setModalPhotose(!modalPhotose);

  const openPhotoseModal = () => {
    togglePhotose();
  };

  const pdfStyles = StyleSheet.create({
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableColHeader: {
      width: "12.5%",
      borderStyle: "solid",
      backgroundColor: "#f5f5f5",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColNonCheker: {
      width: "12.5%",
      borderStyle: "solid",
      backgroundColor: "#7e7676",
      borderWidth: 1,
      color: "#ffffff",
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColCheker: {
      width: "12.5%",
      borderStyle: "solid",
      backgroundColor: "#e35f5f",
      borderWidth: 1,
      borderLeftWidth: 0,
      color: "#ffffff",
      borderTopWidth: 0
    },
    tableCol: {
      width: "12.5%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      margin: "auto",
      marginTop: 2,
      fontSize: 9
    }
  });

  const DistrictPDF = () => {
    return (
      <Document>
        <Page size={'A4'} style={{ padding: '19px' }}>
          <View><Text style={{ fontWeight: 'bold', color: 'black', fontSize: '1.5rem' }}>Au niveau : <Text style={{ color: 'red' }}>National</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre de centres : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalEta)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre d'Établissement d'Examen : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(nombre_centre)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombres de salles : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(nombre_salle)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Capacité de Centres : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalCentre)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de Candidats : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalCandidat)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Candidats d'École : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalEcole)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Candidats Libres : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalLibre)}</Text> </Text></View>
          \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
          {/* <View><Text style={{ fontWeight: 'bold' }}>Liste District </Text></View> */}
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableRow}>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Province</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Nombre de Centres</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Nombre d'Établissements d'Examen</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Nombre de Salles</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Capacité de Centres</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}> Candidats d'École</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}> Candidats Libres</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
              </View>
            </View>
            {
              centre.map(dist => (
                <View style={pdfStyles.tableRow}>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{dist.province}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_eta)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_centre)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_salle)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nbr_place_max)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_ecole)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_libre)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_candidat)}</Text>
                  </View>

                  {/* <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                    </View> */}
                </View>
              ))
            }
          </View>
        </Page>
      </Document>
    )
  }
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    barPercentage: 0.5,
    categoryPercentage: 0.7,
  };
  const [regionSelect, setRegionselect] = useState('');
  const handleSelect = (key) => {

    detailRegion(key)
  }
  const [indices, setIndices] = useState(0)
  const show = () => {
    setIsMpotra(!isMpotra);
  }
  const [isMpotra, setIsMpotra] = useState(false);
  useEffect(() => {
    // setLoader(true);
    // getNombreElecteur();
    // getNombreMembre();
    // totalV()
    // setLoader(false);
  }, [])
  if (visibleDistrict == true && region_sec != null) {
    return (
      <>
        <HistographeDistrict setIndices={setIndice} setVisibleDistrict={setVisibleDistrict} region={region_sec} />
      </>
    )
  }
  else {

    return (
      <>
        <div className="content">
          <Modal isOpen={modal} toggle={toggle} centered>
            <ModalBody>
              <img src={myImage} alt="photoPrez" className="img-fluid" />
            </ModalBody>
            <ModalBody>
              <img src={myImage2} alt="photoPrez2 " className="img-fluid" />
            </ModalBody>
          </Modal>
          <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Centres </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
              </Col>
            </Card>
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Établissements d' Examen </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_centre)}</b></CardTitle>
              </Col>
            </Card>
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de  Salles  </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
              </Col>
            </Card>
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" onClick={() => navigate("/admin/ListeCentre")}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Capacité de Centres </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
              </Col>
            </Card>
            {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" onClick={(e) => navigate("/admin/Candidat")}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCandidat)}</b></CardTitle>
                {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
              </Col>
            </Card>
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" onClick={(e) => navigate("/admin/candidats/type-candidat")}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats Libres</p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalLibre)}</b></CardTitle>
                {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_libre_1)}</b></CardTitle>)}
              </Col>
            </Card>
            <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" onClick={(e) => navigate("/admin/candidats/type-candidat")}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats d'École</p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEcole)}</b></CardTitle>
                {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_ecole_1)}</b></CardTitle>)}
              </Col>
            </Card>
          </Row>
          <Card>
            <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
              Choisir Province
            </CardBody>
            <CardBody>
              <div className="mb-2">
                <select
                  // value={precision_emplacement}
                  // onChange={(e) => setPrecison_emplacement(e.target.value)}
                  style={{
                    padding: '8px',
                    fontSize: '14px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    color: '#333',
                    width: '200px'
                  }}
                  // value={regionSelect}
                  onChange={(e) => { detailRegion(e.target.value) }}
                >
                  <option value="">Choix province </option>
                  {province.map((label, index) => (
                    <option key={index} value={label} >{label}</option>
                  ))}

                </select>
              </div>
              <Button className="btn btn-outline-primary btn-sm" onClick={() => openPhotoseModal()}>
                Afficher les données
              </Button>
              <Button className="btn btn-outline-primary" onClick={show}>
                {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
              </Button>
            </CardBody>

          </Card>
          <Card>

            <ComponentsChart id={'province'} liste={centre} indice={indice} labels={province} centre={centre_nombre} candidat={candidat_nombre} candidat_libre={nombre_libre} />
          </Card>
          <Row>
            <Modal isOpen={modalPhotose} toggle={togglePhotose} size="xl">
              <ModalHeader toggle={togglePhotose} style={{ color: "black", fontSize: '12px' }}>
                <span style={{ color: "green", fontWeight: 'bold' }}> Au niveau National </span><br />
              </ModalHeader>
              <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Centres </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
                  </Col>
                </Card>
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Établissements d' Examen </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_centre)}</b></CardTitle>
                  </Col>
                </Card>
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de  Salles  </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
                  </Col>
                </Card>
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Capacité de Centres </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                  </Col>
                </Card>
                {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCandidat)}</b></CardTitle>
                    {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                  </Col>
                </Card>
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats Libres</p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalLibre)}</b></CardTitle>
                    {isMpotra==true&&(<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_libre_1)}</b></CardTitle>)}
                  </Col>
                </Card>
                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats d'École</p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEcole)}</b></CardTitle>
                    {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_ecole_1)}</b></CardTitle>)}
                  </Col>
                </Card>
              </Row>
              <ModalBody>
                <Button className="btn btn-outline-primary" onClick={show}>
                  {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                </Button>
                <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"National"}>
                  {({ blob, url, loading, error }) =>
                    loading ? ' Chargement du PDF...' : ' Exporter PDF'
                  }
                </PDFDownloadLink>
                <Table striped>
                  <thead className="text-primary" >
                    <tr >
                      <th>Province </th>
                      {/* <th>nombre membres</th> */}
                      <th>Nombre de centres </th>
                      <th>Nombre d'Établissements d'Examen</th>
                      <th>Nombre de salles</th>
                      <th>Capacité de Centres</th>
                      {/* <th>Resultat 2018</th> */}
                      <th >
                        Candidats Libres
                      </th>
                      <th >
                        Candidats d'École
                      </th>
                      <th >
                        Total de candidats
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      centre.map(row => (
                        <tr>
                          <td>{row.province}</td>
                          {/* <td>{ANRDataUtils.formatNombre(row.nombre_membre)}</td> */}
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_eta)}</td>
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_centre)}</td>
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_salle)}</td>
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nbr_place_max)}</td>
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_libre)}<br />
                            {isMpotra==true&&(<span style={{ color: 'red' }}>
                              +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                            </span>)}
                          </td>
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_ecole)}<br />
                            {isMpotra==true&&(<span style={{ color: 'red' }}>
                              +{ANRDataUtils.formatNombre(row.candidat_ecole_1)}
                            </span>)}
                          </td>
                          <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                           {isMpotra==true&&( <span style={{ color: 'red' }}>
                              +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                            </span>)}
                          </td>
                          {/* <td >{ANRDataUtils.formatNombre(row.nombre_13)}</td> */}
                          {/* <td><button className={'btn btn-danger'} onClick={e=>{details(row)}}> afficher plus</button></td> */}
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
            {/* </div > */}
          </Row>
        </div>
      </>
    );

  }

}

export default Dashboard;