import React, { useState } from "react";
import { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
    Table,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input
} from "reactstrap";
import url from "urlConfig";
import authHeader from 'services/AuthHeader';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';

const FormulairePersonnels = () => {

    const auth_data = authHeader();
    const [provincer, setProvincer] = useState('');
    const [regionr, setRegionr] = useState('');
    const [districtr, setDistrictr] = useState('');
    const [communer, setCommuner] = useState('');
    const [centrer, setCentrer] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [communes, setCommunes] = useState([]);
    const [commune, setCommune] = useState('');
    const [ecole, setEcole] = useState('');
    const [series, setSeries] = useState([]);
    const [salle, setSalle] = useState('');
    const [salles, setSalles] = useState('');
    const [nom, setNom] = useState('');
    const [nomr, setNomr] = useState('');
    const [prenom, setPrenom] = useState('');
    const [prenomr, setPrenomr] = useState('');
    const [genre, setGenre] = useState('');
    const [mail, setMail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [addresse, setAddresse] = useState('');
    const [numero, setNumero] = useState('');
    const [titre, setTitre] = useState('');
    const [contact, setcontact] = useState('');
    const [code_commune, setCode_commune] = useState('');
    const [listEtablissement, setListEtablissement] = useState([]);
    const [etablissement, setEtablissement] = useState('')
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [roler, setRoler] = useState('');
    const [candidats, setCandidats] = useState('');
    const [rolesr, setRolesr] = useState(["Chef d etablissement",
        "Autre agents",
        "Surveillants de cour",
        "Surveillants de salle",
        "Secretaires"]);
    const [listss, setListss] = useState([]);

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    let data = {
        offset: 0,
        province: "",
        region: "",
        district: "",
        commune: "",
        centre_examen: "",
        roles: "",
        nom: "",
        prenom: "",
        code_commune: "",
        limit: 0
    }
    const getListEtablissement = () => {
        fetch(url.urlHttp + "personnel/liste", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setCandidats(data.data);
                setListss(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        // console.log("tanana/district?region=" + region + "&&district=" + province);
        fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommune = () => {
        fetch(url.urlHttp + "tanana/commune?region=" + region + "&&district=" + district + "&&commune=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCentre_examen = () => {
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setListEtablissement(data.data)
                console.log(listEtablissement);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const role_centre = () => {
        fetch(url.urlHttp + "role_centre/role", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setRoles(data.data)

            })
            .catch(error => {
                console.log(error);
            });
    }

    const [isRecherche, setIsRecherche] = useState(false);
    const show = () => {
        if (isRecherche == true) {
            setIsRecherche(false);
        } else {
            setIsRecherche(true);
        }

    }
    const handleSearchs = async (off) => {
        const data = {
            province: provincer,
            region: regionr,
            district: districtr,
            nom: nomr,
            prenom: prenomr,
            commune: communer,
            offset: off,
            code_commune: "",
            centre_examen: centrer,
            roles: roler,
            limit: 0,
        }
        console.log(data);
        // alert(numPage);
        await fetch(url.urlHttp + 'personnel/liste', {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((data) => {
                setCandidats(data.data);
                setListss(data.data);
            })
            .catch((error) => console.log('error', error))

    }
    const handleSalle = () => {
        fetch(url.urlHttp + "centre-milala/liste-salle?district=" + district + "&&commune=" + commune + "&&centre_xamen=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log("itooooooo eeeeeeeeeeeeeeeeeeeee"+data.data);
                setSalles(data.data)
                // console.log(salles);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleTitre = () => {
        if (genre == "M") {
            setTitre('Monsieur')
        } else {
            setTitre("Mademoiselle");
        }
    }

    const handleCentre = (commune) => {
        for (let i = 0; i < communes.length; i++) {
            if (communes[i].commune == commune) {
                setCode_commune(communes[i].code_commune);
            }
        }
    }

    const [milahatra, setMilahatra] = useState(true);

    const handleSort = (order, key) => {
        const sortedWords = [...candidats]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setCandidats(sortedWords);
        } else {
            setMilahatra(true);
            setCandidats(listss);
        }
        console.log(milahatra);
    };
    // const handleCentre = (commune) => {
    //     for (let i = 0; i < communes.length; i++) {
    //         if (listEtablissement[i].commune == commune) {
    //             setCode_commune(communes[i].code_commune);
    //         }
    //     }
    // }

    const save = async () => {
        if (!role.includes('chef') || !role.includes('cretaires')) {
            setSalle('Non concerné')
        }
        const data_send = {
            adresse: addresse,
            nom: nom,
            prenom: prenom,
            sexe: genre,
            contact: contact,
            adresse: addresse,
            mail: mail,
            whatsapp: whatsapp,
            centre: commune,
            roles: role,
            centre_examen: etablissement,
            code_commune: code_commune,
            salle: salle
        }
        await fetch(url.urlHttp + 'personnel/nouveau', {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data_send)
        }).then((response) => response.json())
            .then((data) => {
                console.log(data.data);
                ANRDataUtils.successAlert(data.message);
                setCandidats(prevCandidats => [...prevCandidats, data_send]);
            })
            .catch((error) => console.log('error', error))
        // console.log(data_send);
    }


    useEffect(() => {
        getProvinces();
        role_centre();
        getListEtablissement();
    }, [])

    return (
        <>
            <Card style={{ marginTop: "100px", marginLeft: "20px", marginRight: "20px" }} >
                <CardHeader></CardHeader>
                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                    AJOUT DE PERSONNEL
                </CardTitle>
                <CardBody>
                    <Form >
                        <Row>
                            <Col className="pr-3" md="6">
                                <FormGroup className="mr-3">
                                    <Label for="nomDem"><a style={{ color: "gray", fontWeight: 'bold' }}>NOM :</a></Label>
                                    <Input
                                        placeholder="Nom"
                                        type="text"
                                        value={nom} onChange={e => { setNom(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "gray", fontWeight: 'bold' }}>PRÉNOMS :</a></Label>
                                    <Input
                                        placeholder="Prénoms"
                                        type="text"
                                        value={prenom} onChange={e => { setPrenom(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup tag="fieldset">
                                    <label><a style={{ color: "gray", fontWeight: 'bold' }}>GENRE :</a></label>
                                    <select className={'form-control'} value={genre} onChange={e => { setGenre(e.target.value) }} onClick={handleTitre}>
                                        <option value="" ></option>
                                        <option value="H" >Homme</option>
                                        <option value="F" >Femme</option>
                                        <option value="N" >Non définis</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup >
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>CONTACT</a></label><br />
                                    <input type="text" className={'form-control'} value={contact} onChange={e => { setcontact(e.target.value) }} />
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup >
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>WHATSAPP</a></label><br />
                                    <input type="text" className={'form-control'} value={whatsapp} onChange={e => { setWhatsapp(e.target.value) }} />
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup >
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>ADRESSE MAIL</a></label><br />
                                    <input type="text" className={'form-control'} value={mail} onChange={e => { setMail(e.target.value) }} />
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup >
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>ADRESSE</a></label><br />
                                    <input type="text" className={'form-control'} value={addresse} onChange={e => { setAddresse(e.target.value) }} />
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>PROVINCE :</a></label><br />
                                    <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                        <option value={'0'}></option>
                                        {provinces?.map(province => (
                                            <option value={province.province} >{province.province}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>RÉGION : </a> </label><br />
                                    <select className={'form-control'} value={region} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                                        <option value={'0'}></option>
                                        {regions?.map(region => (
                                            <option value={region.region} >{region.region}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                    <select className={'form-control'} value={district} onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                        <option value={'0'}></option>
                                        {districts?.map(district => (
                                            <option value={district.district} >{district.district}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                    <select className={'form-control'} value={commune} onChange={e => { { setCommune(e.target.value); handleCentre(e.target.value) } }} onClick={handleCentre_examen}>
                                        <option value={'0'}></option>
                                        {communes?.map(commune => (
                                            <option value={commune.commune} >{commune.commune}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}> ETABLISSEMENT D'EXAMEN :</a> </label><br />
                                    <select className={'form-control'} value={etablissement} onChange={e => { { setEtablissement(e.target.value) } }} onClick={handleSalle}>
                                        <option value={'0'}></option>
                                        {listEtablissement?.map(commune => (
                                            <option value={commune.centre_examen} >{commune.centre_examen}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>

                            <Col className="pr-3" md="3">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}> RÔLE :</a> </label><br />
                                    <select className={'form-control'} value={role} onChange={e => { { setRole(e.target.value) } }}>
                                        <option value={'0'}></option>
                                        {/* <option value={'Chef d etablissement'}>Chef d'Établissement</option> */}
                                        {roles?.map(roles => (
                                            <option value={roles.roles} >{roles.roles}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            {salles && role.includes('salle') || role.includes('cretaires') ? (
                                <Col className="pr-3" md="3">
                                    <FormGroup className="mr-3">
                                        <label style={{ border: 'type-round' }} > <a style={{ color: "gray", fontWeight: 'bold' }}>SALLE</a> </label><br />
                                        <select className={'form-control'} value={salle} onChange={e => { setSalle(e.target.value) }} >
                                            <option value="" ></option>
                                            {salles?.map(salle => (
                                                <option value={salle.salle} >{salle.salle}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                            )
                                : ('')
                            }
                        </Row>
                        <Button className="btn btn-primary" onClick={save}>
                            Enregistrer
                        </Button>
                    </Form>
                    {/* </div> */}
                </CardBody>
            </Card>
            <Card>
                {
                    isRecherche == true &&
                    (<>
                        <Card style={{ marginLeft: "20px", marginRight: "20px" }} >
                            <CardBody>
                                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                    RECHERCHE DE PERSONNELS: <a style={{ color: "red" }}></a>
                                </CardTitle><br />
                                <Row >
                                    {province ? '' :
                                        <Col className="pr-1" md="4">
                                            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > PROVINCE : </label><br />
                                            <select className={'form-control'} value={provincer} onChange={e => { setProvincer(e.target.value) }} onClick={handleRegion}>
                                                <option value=''></option>
                                                {provinces?.map(province => (
                                                    <option value={province.province} >{province.province}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    }
                                    {region ? '' :
                                        <Col className="pr-1" md="4">
                                            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > RÉGION : </label><br />
                                            <select className={'form-control'} value={regionr} onChange={e => { setRegionr(e.target.value) }} onClick={handleDistrict}>
                                                <option value=''></option>
                                                {regions?.map(region => (
                                                    <option value={region.region} >{region.region}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    }
                                    {district ? '' :
                                        <Col className="pr-1" md="4">
                                            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > DISTRICT : </label><br />
                                            <select className={'form-control'} value={districtr} onChange={e => { setDistrictr(e.target.value) }} onClick={handleCommune}>
                                                <option value=''></option>
                                                {districts?.map(district => (
                                                    <option value={district.district} >{district.district}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    }
                                    {commune ? '' :
                                        <Col className="pr-1" md="4">
                                            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > CENTRE : </label><br />
                                            <select className={'form-control'} value={communer} onChange={e => { setCommuner(e.target.value) }} onClick={handleCentre}>
                                                <option value=''></option>
                                                {communes?.map(commune => (
                                                    <option value={commune.commune} >{commune.commune}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    }
                                    {etablissement ? '' :
                                        <Col className="pr-1" md="4">
                                            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > CENTRE D'EXAMEN: </label><br />
                                            <select className={'form-control'} value={centrer} onChange={e => { setCentrer(e.target.value) }}>
                                                <option value=''></option>
                                                {listEtablissement?.map(commune => (
                                                    <option value={commune.centre_examen} >{commune.centre_examen}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    }
                                </Row>
                                <Row >
                                    <Col md="4">
                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > RÔLE : </label><br />
                                        <select className={'form-control'} value={roler} onChange={e => { setRoler(e.target.value) }}>
                                            <option value=''></option>
                                            {rolesr?.map(commune => (
                                                <option value={commune} >{commune}</option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md="4">
                                        <Label for="nomDem" style={{ border: 'type-round', fontWeight: 'bold', color: "black" }}>NOM :</Label>
                                        <Input
                                            placeholder="Nom"
                                            type="text"
                                            value={nomr} onChange={e => { setNomr(e.target.value) }}
                                        >
                                        </Input>
                                    </Col>
                                    <Col md="4">
                                        <Label for="fonctionDem" style={{ border: 'type-round', fontWeight: 'bold', color: "black" }}>PRÉNOM :</Label>
                                        <Input
                                            placeholder="Prénom"
                                            type="text"
                                            value={prenomr} onChange={e => { setPrenomr(e.target.value) }}
                                        >
                                        </Input>
                                    </Col>
                                    <Col md="4">
                                        <Button className="btn btn-outline-info" onClick={(e) => { handleSearchs(0) }} style={{ marginTop: "20px" }}>
                                            Rechercher
                                        </Button>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </>)
                    // (<></>)

                }
                
                <CardBody>
                <Button className="btn btn-outline-primary" onClick={show}>
                    {isRecherche ? "Fermer" : "Recherche"}
                </Button>
                    <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                        <Table striped>
                            <thead className="text-primary sticky-header">
                                <tr>
                                    <th onClick={() => handleSort('asc', 'province')}>Province &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'commmune')}>Centre &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'centre_examen')}>Établissement &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'nom')}>Nom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'prenom')}>Prénom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'salle')}>Salle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'sexe')}>Sexe &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'contact')}>Contact &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'contact')}>Adresse mail &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'adresse')}>Adresse &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    <th onClick={() => handleSort('asc', 'roles')}>Rôle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    candidats && candidats.map((row, index) => (
                                        <>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.province)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.centre)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.centre_examen)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.nom)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.prenom)}
                                                </td>
                                                <td>
                                                    {(row.salle)}
                                                </td>
                                                <td>
                                                    {(row.sexe)}
                                                </td>
                                                <td>
                                                    {(row.contact)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.mail)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.adresse)}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {(row.roles)}
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default FormulairePersonnels;