import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Table, Label, Input, Button, FormGroup } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import url from 'urlConfig';
import LoaderUtils from "../../components/Shared/Loading";
import { Link } from 'react-router-dom';
import ANRDataUtils from 'utils/Utils';
import authHeader from 'services/AuthHeader';
import EtablissementLG from './EtablissementLG';
import Legende from 'views/logistiques/Legende';

const CentreLG = ({ setVisibleCentre, provinces, region, district, materiel }) => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState([]);
    const [centre_sec, setCentre_sec] = useState(null);
    const [data_provinces_elect, setData_provinces_elect] = useState([]);
    const [data_provinces_feuille, setData_provinces_feuille] = useState([]);
    const [data_provinces_sujet, setData_provinces_sujet] = useState([]);
    const [general, setGeneral] = useState(true);
    const [pro, setPro] = useState(true);
    const [tech, setTech] = useState(true);
    const [list, setList] = useState([]);
    const [materiels, setMateriels] = useState('');
    const [materielFeuille, setMaterielFeuille] = useState([]);
    const [materielSujet, setMaterielSujet] = useState([]);
    const [materielElect, setMaterielElect] = useState([]);
    const [responsable, setResponsable] = useState('');
    const [receptionniste, setReceptionniste] = useState('');
    const [date_envoye, setDate_envoye] = useState('');
    const [bases, setBases] = useState([]);
    const [base_centre, setBaseCentre] = useState([]);
    const [nombre_salle, setNombre_salle] = useState([]);
    const [tanana, setTanana] = useState([]);
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const [listMisyApres, setListMisyApres] = useState([])
    const [listTsymisyApres, setListTsyMisyApres] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [img, setImg] = useState(null);
    const [nombres, setNombres] = useState([]);
    const [visibleEtab, setVisisbleEtab] = useState(false);
    const [nombre_centre, setNombre_centre] = useState([]);
    const [centre_total, setCentre_total] = useState(0);
    const [centre_partiel, setCentre_partiel] = useState(0);
    const [centre_non, setCentre_non] = useState(0);
    const [etab_total, setEtab_total] = useState(0);
    const [etab_partiel, setEtab_partiel] = useState(0);
    const [etab_non, setEtab_non] = useState(0);

    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].date_envoye != null) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }

    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }



    const handleGetListCentre = async () => {
        await fetch(url.urlHttp + "materiel/urne?view=v_district_commune&&destinataire=&&expediteur=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv centre ato : ", data.data);
                setBaseCentre(data.data);
                // getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const filtre = (value) => {
        const filteredData = base_centre.filter(item => {
            if (item.centre && typeof item.centre === 'string') {
                return item.centre.toUpperCase().includes(value.toUpperCase());
            }
            return false;
        });
        getsListTsisy(filteredData);
    }

    const handleGetListBaseAvant = async () => {
        await fetch(url.urlHttp + "materiel/base-avant-centre?district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA base avant district", data.data);
                setBases(data.data);
                // getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleGetListNombreMatiere = async () => {
        await fetch(url.urlHttp + "materiel/nombre_serie_matiere_centre?district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA matiere apres", data.data);
                setNombres(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreCentre = async () => {
        fetch(url.urlHttp + "materiel/nombre-centre?district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                setNombre_centre(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreMateriel = async (materiels) => {
        await fetch(url.urlHttp + "materiel/count-centre?materiel=" + materiels + "&&type=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA materiel", data.data);
                if (materiels == "Urne") {
                    setMaterielElect(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const detailsCentre = (value, mat) => {
        setCentre_sec(value);
        setMateriels(mat);
        setVisisbleEtab(true);
        //setVisibleCentre(true);
    }

    const handleFile = (e, id, type) => {
        const file = e.target.files[0];
        const table = "materiel_district_centre";
        let urls = "";
        var data = null;
        e.preventDefault();
        if (!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result;
            if (type == "env") {
                data = {
                    id: id,
                    table: table,
                    column: "photo_envoi",
                    value: base64String
                }
                urls = url.urlHttp + "materiel/update";
                fetch(urls, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': auth_data.content_type,
                        'Authorization': auth_data.authorization
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        handleGetListCentre();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                data = {
                    id: id,
                    table: table,
                    column: "photo_recu",
                    value: base64String
                }
                urls = url.urlHttp + "materiel/update";
                fetch(urls, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': auth_data.content_type,
                        'Authorization': auth_data.authorization
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        handleGetListCentre();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
    }

    const handleSort = (order, key) => {
        const sortedWords = [...list]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setList(sortedWords);
    };

    var totalEnvoyeElectricite = 0;
    var totalRecuElectricite = 0;
    var totalResteElectricte = 0;
    var totalEnvoyeFeuille = 0;
    var totalRecuFeuille = 0;
    var totalResteFeuille = 0;
    var totalEnvoyeSujet = 0;
    var totalRecuSujet = 0;
    var totalResteSujet = 0;
    var nombre_salles = 0;
    var nombre_province = 0;
    var nombreMisyFeuilles = 0;
    var nombreMisyElect = 0;
    var nombreMisySujet = 0;
    var totalEtab = 0;
    var totalSalle = 0;
    var totalCandidat = 0;
    var totalFeuille = 0;
    var totalSujet = 0;
    var nombre_ctr = 0;
    const accumulated = {};

    bases.forEach(item => {
        const communes = item.communes;
        if (!accumulated[communes]) {
            console.log(item.nombre_salle, 'ee')
            accumulated[communes] = true;
            totalEtab += item.nombre_centre;
            totalSalle += item.nombre_salle;
            totalCandidat += item.nombre_candidat
        }
    });

    for (let i = 0; i < bases.length; i++) {
        if (materiel == "Electricites") {
            totalEnvoyeElectricite += bases[i].electricites;
        }
        if (materiel == "Feuilles") {
            totalEnvoyeFeuille += bases[i].feuilles;
        }
        if (materiel == "Sujets") {
            totalEnvoyeSujet += bases[i].sujets;
        }
        // totalRecuElectricite += data_provinces_elect[i].q_r;
        // totalResteElectricte += data_provinces_elect[i].reste;
    }

    for (let i = 0; i < nombres.length; i++) {
        totalFeuille += nombres[i].nombre_candidat * nombres[i].nombre_matiere;
        totalSujet += nombres[i].nombre_candidat * nombres[i].nombre_matiere;
    }

    // for (let i = 0; i < bases.length; i++) {
    //     totalEnvoyeFeuille += bases[i].q_e;
    //     totalRecuFeuille += data_provinces_feuille[i].q_r;
    //     totalResteFeuille += data_provinces_feuille[i].reste;
    // }

    for (let i = 0; i < data_provinces_sujet.length; i++) {
        totalEnvoyeSujet += data_provinces_sujet[i].q_e;
        totalRecuSujet += data_provinces_sujet[i].q_r;
        totalResteSujet += data_provinces_sujet[i].reste;
    }

    for (let i = 0; i < nombre_salle.length; i++) {
        nombre_salles += nombre_salle[i].count;
    }

    for (let i = 0; i < tanana.length; i++) {
        nombre_province += tanana[i].count;
    }

    for (let i = 0; i < materielFeuille.length; i++) {
        nombreMisyFeuilles += materielFeuille[i].count;
    }

    for (let i = 0; i < materielSujet.length; i++) {
        nombreMisySujet += materielSujet[i].count;
    }

    for (let i = 0; i < materielElect.length; i++) {
        nombreMisyElect += materielElect[i].count;
    }

    for (let i = 0; i < nombre_centre.length; i++) {
        nombre_ctr += nombre_centre[i].count;
    }

    const handleGetNombreTotalCentres = async (type) => {
        await fetch(url.urlHttp + "materiel/data-centre-trac-count?type="+type+"&&district=&&prefecture="+region+"&&expediteur="+district+"&&province="+provinces, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
          
                if(type == "recu_totalement"){
                    setCentre_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setCentre_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setCentre_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalEtabT = async (type) => {
        await fetch(url.urlHttp + "materiel/data-etab-trac-count?type="+type+"&&district="+district+"&&prefecture="+region+"&&expediteur=&&province="+provinces, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
          
                if(type == "recu_totalement"){
                    setEtab_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setEtab_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setEtab_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    useEffect(() => {
        handleGetNombreMateriel(materiel);
        // handleGetNombreMateriel("Feuilles");
        // handleGetNombreMateriel("Sujets");
        handleGetListBaseAvant();
        handleGetListCentre();
        handleGetListNombreMatiere();
        // handleGetListBaseAvant();

        handleGetNombreTotalCentres('recu_partiellement');
        handleGetNombreTotalCentres('recu_totalement');
        handleGetNombreTotalCentres('non_envoye');

        handleGetNombreTotalEtabT('recu_partiellement');
        handleGetNombreTotalEtabT('recu_totalement');
        handleGetNombreTotalEtabT('non_envoye');
        // handleGetListBaseApres();
        // handleGetNombreSalle();
        // handleGetNombreTanana();
        handleGetNombreCentre();
    }, [])

    if (visibleEtab == true && centre_sec != null) {
        return (
            <>
                <EtablissementLG setVisisbleEtab={setVisisbleEtab} provinces={provinces} region={region} district={district} centre={centre_sec} materiel={materiels} />
            </>
        );
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> PROVINCE </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> PREFECTURE </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> DISTRICT </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(0)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row><br /><br />
                <Row>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> CENTRE </span><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(centre_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(centre_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(centre_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> ETABLISSEMENT </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(etab_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(etab_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(etab_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="4"><br />
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Centre :</a></label>
                            <input
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechecher centre'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup><br />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle>
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "20px" }}> DOB/DEN : <a style={{ color: "red" }}>{provinces}</a>, PREFECTURE : <a style={{ color: "red" }}>{region}</a>, DISTRICT : <a style={{ color: "red" }}>{district}</a> </span>
                                    </CardTitle>
                                </CardHeader>
                                <Button className="btn btn-outline-primary" onClick={e => { setVisibleCentre(false) }}>retour</Button>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <div>
                                        <Legende />
                                    </div>
                                    <Table style={{ marginTop: "40px" }} striped>
                                        <thead className="text-primary">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}>DISTRICT/CENTRE&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Quantite envoyée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Transport envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Quantite recue&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Transport reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {base_centre.map((row, index) => {
                                                let couleur = ''; // déclaration de couleur ici

                                                if (row.quantite_envoyer > row.quantite_recu) {
                                                    couleur = '#adff2f';
                                                }
                                                if (row.quantite_recu == row.quantite_envoyer) {
                                                    couleur = 'rgb(94, 199, 56)';
                                                }
                                                if(row.quantite_envoyer == 0){
                                                    couleur = 'red';
                                                }

                                                return (
                                                    <tr key={index} style={{ backgroundColor: couleur }}>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.expediteur}/{row.destinataire}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {ANRDataUtils.formatNombre(row.quantite_envoyer)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {new Date(row.date_envoie).toLocaleString()}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.transport_envoie}
                                                        </td>
                                                        <td style={{ fontSize: '12px', backgroundColor: couleur }}>
                                                            {ANRDataUtils.formatNombre(row.quantite_recu)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {new Date(row.date_reception).toLocaleString()}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.transport_recu}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.quantite_envoyer > 0 ? (
                                                                <button className={'btn btn-danger'} onClick={() => detailsCentre(row.destinataire, "Urne")}>
                                                                    Afficher Centre
                                                                </button>
                                                            ) : (
                                                                <button className={'btn btn-danger disabled'}>
                                                                    Afficher Centre
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>

                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CentreLG;