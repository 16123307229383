import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardTitle, Col, Row, Table, CardBody, FormGroup } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';

const Salle = ({ setVisibleCandidat, province, region, district, commune, specialite, serie, matiere, object, etablissement }) => {
    console.log("'"+etablissement+"'");
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [etablissement_sec, setEtablissement_sec] = useState(null);
    const [data_etablissement, setData_etablissement] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);
    const [specialites, setSpecialites] = useState([]);
    const [specialitee, setSpecialite] = useState(specialite);
    const [series, setSeries] = useState([]);
    const [seriee, setSerie] = useState(serie);
    const [matieres, setMatieres] = useState([]);
    const [matieree, setMatiere] = useState(matiere);

    var nombre_total = 0;

    for (let i = 0; i < data_etablissement.length; i++) {
        nombre_total += data_etablissement[i].nombre;
    }

    const getListEtablissement = () => {
        console.log("'"+url.urlHttp + "denombrement/salle?specialite=" + specialitee + "&&secteur=&&serie=" + seriee + "&&matiere=" + matieree + "&&province=" + province + "&&region=" + region + "&&district=" + district + "&&commune=" + commune + "&&etablissement=" + etablissement +"'");
        fetch(url.urlHttp + "denombrement/salle?specialite=" + specialitee + "&&secteur=&&serie=" + seriee + "&&matiere=" + matieree + "&&province=" + province + "&&region=" + region + "&&district=" + district + "&&commune=" + commune + "&&etablissement=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR Region: ", data.data);
                setData_etablissement(data.data)
                setLists(data.data);
                setListss(data.data);
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getSpecialites = () => {
        setLoader(true);
        fetch(url.urlHttp + 'serie-bobon/liste-specialite', {
            method: "GET",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSpecialites(data.data);
            })
            .catch(error => { console.log(error) })
    }
    const getSeries = () => {
        fetch(url.urlHttp + "serie-bobon/candidat-liste-serie-liste?specialite=" + specialitee, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setSeries(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getMatiers = () => {
        fetch(url.urlHttp + "edt/matiere?id_session=" + 1 + "&serie="+seriee+"&specialite="+specialitee, {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setMatieres(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListEtablissement();
        getSpecialites();
        getMatiers();
    }, [])

    const handleSpecialite = () => {
        setSerie("");
        setSeries([]);
        setMatiere("");
        getSeries();
        getMatiers();
    }
    const handleSerie = () => {
        setMatiere("");
        getMatiers();
    }
    const handleSearchs = async (off) => {
        getListEtablissement();
    }

    const detailProvince = (etablissement) => {
        setEtablissement_sec(etablissement);
        setVisibleCandidat(true);
    }
    const filtre = (value) => {
        const filteredData = data_etablissement.filter(item => item.etablissement.toUpperCase().includes(value.toUpperCase()));
        setData_etablissement(filteredData)
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...data_etablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setData_etablissement(sortedWords);
        } else {
            setMilahatra(true);
            setData_etablissement(listss);
        }
        console.log(milahatra);
    };
    const stat = {
        denombrement: data_etablissement.map(entry => entry.total_effectif).reduce((acc, val) => acc + val, 0),
        sujet: data_etablissement.map(entry => entry.nombre_candidat).reduce((acc, val) => acc + val, 0),
    };
    // if (visibleCandidat == true && etablissement_sec != null) {
    //     return (
    //         <>
    //             <Candidat setVisibleCandidat={setVisibleCandidat} types={'Etablissement'} region={etablissement_sec.region} commune={etablissement_sec.commune} province={etablissement_sec.province} object={etablissement_sec} district={etablissement_sec.district} serie={Serie} etablissement={etablissement_sec.etablissement} />
    //         </>
    //     )
    // } else {
    return (
        <div className="content">
            <Row className="justify-content-center">
                <Col md="6">
                    <Card style={{ height: "100%" }}>
                        <CardHeader>
                            <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red", fontSize: '1rem' }}>{object.province}</a>

                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red", fontSize: '1rem' }}>{object.region}</a>

                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red", fontSize: '1rem' }}>{object.district}</a>

                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}>Commune : <a style={{ color: "red", fontSize: '1rem' }}>{object.commune}</a>
                                </span>

                                <span style={{ color: "black", fontSize: '1rem' }}>Établissement : <a style={{ color: "red", fontSize: '1rem' }}>{etablissement}</a>
                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}>Spécialité : <a style={{ color: "red", fontSize: '1rem' }}>{specialitee}</a>

                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}>Série :&nbsp;&nbsp;
                                    <a style={{ color: "red", fontSize: '1rem' }}>{seriee}</a>
                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}>Matière : &nbsp;&nbsp;
                                    <a style={{ color: "red", fontSize: '1.2rem' }}>{matieree}</a>
                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}> Total de sujets estimés  : &nbsp;&nbsp;
                                    <a style={{ color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sujet)}</a>
                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}> Total de sujets envoyés  : &nbsp;&nbsp;
                                    <a style={{ color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.denombrement)}</a>
                                </span>
                                <span style={{ color: "black", fontSize: '1rem' }}> Différences : &nbsp;&nbsp;
                                    <a style={{
                                        fontSize: '1.5rem', color: (stat.denombrement - stat.sujet) > 0 ? "red"
                                            : (stat.denombrement - stat.sujet) === 0 ? "black"
                                                : "green", fontWeight: 'bold'
                                    }}>{(stat.denombrement - stat.sujet) > 0 ? '+' : ''}{ANRDataUtils.formatNombre(stat.denombrement - stat.sujet)}</a>
                                </span>

                            </CardTitle>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="pr-1" md="4"><br />
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Salle :</a></label>
                        <input
                            type={'text'}
                            className={'form-control'}
                            placeholder={'rechercher salle'}
                            onChange={e => filtre(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>


            <Row>
                <Col md="12">
                    <Card style={{ marginTop: "40px" }}>
                        <CardBody>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.2rem' }}>Au niveau Centre <br />
                                        <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red", fontSize: '1rem' }}>{object.province},</a>&nbsp;
                                        </span><br />
                                        <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red", fontSize: '1rem' }}>{object.region},</a>&nbsp;
                                        </span><br />
                                        <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red", fontSize: '1rem' }}>{object.district},</a>&nbsp;
                                        </span><br />
                                        <span style={{ color: "black", fontSize: '1rem' }}>Commune : <a style={{ color: "red", fontSize: '1rem' }}>{object.commune},</a>&nbsp;
                                        </span><br />
                                        <span style={{ color: "black", fontSize: '1rem' }}>Établissement : <a style={{ color: "red", fontSize: '1rem' }}>{etablissement},</a>&nbsp;
                                        </span><br />
                                        <a style={{ color: "black", fontSize: '1rem' }}>Spécialité : </a>
                                        <a style={{ color: "red", fontSize: '1rem' }}>{specialitee}, &nbsp;</a><br />
                                        <a style={{ color: "black", fontSize: '1rem' }}>Série :</a>
                                        <a style={{ color: "red", fontSize: '1rem' }}>{seriee}, &nbsp;</a><br />
                                        <a style={{ color: "black", fontSize: '1rem' }}>Matière :</a> &nbsp;
                                        <a style={{ color: "red", fontSize: '1rem' }}>{matieree} &nbsp;</a><br />
                                    </span>

                                </CardTitle>
                            </CardHeader>
                            <Row>
                                <Col className="pr-1" md="3">
                                    <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > Specialité : </label><br />
                                    <select className={'form-control'} value={specialitee} onChange={e => { setSpecialite(e.target.value) }} onClick={handleSpecialite}>
                                        <option value=''></option>
                                        {specialites?.map(commune => (
                                            <option value={commune.specialite} >{commune.specialite}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > Série : </label><br />
                                    <select className={'form-control'} value={seriee} onChange={e => { setSerie(e.target.value) }} onClick={handleSerie}>
                                        <option value=''></option>
                                        {series?.map(commune => (
                                            <option value={commune.serie_option} >{commune.serie_option}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > Matière : </label><br />
                                    <select className={'form-control'} value={matieree} onChange={e => { setMatiere(e.target.value) }}>
                                        <option value=''></option>
                                        {matieres?.map(commune => (
                                            <option value={commune.matiere} >{commune.matiere}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col md="3">
                                    <Button className="btn btn-outline-info" onClick={(e) => { handleSearchs(0) }} style={{ marginTop: "20px" }}>
                                        Filtrer
                                    </Button>
                                </Col>
                            </Row>
                            <Button className="btn btn-outline-primary" onClick={e => { setVisibleCandidat(false) }}>retour</Button>

                            <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                <Table striped>
                                    <thead className="text-primary sticky-header">
                                        <tr>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'etablissement')}>Salle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            {/* <th>Secteur</th> */}
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Nombre de Candidats &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Nombre de sujets estimés &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'total_effectif')}>Nombre de sujets envoyés &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'total_effectif')}>Différences &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {/* <td>{ANRDataUtils.formatNombre(row.province)}</td>
                                                
                                               */}{
                                                data_etablissement.map(row => (
                                                    <tr>
                                                        <td style={{ fontSize: '1.2rem' }}>{ANRDataUtils.formatNombre(row.salle)}</td>
                                                        {/* <td>{ANRDataUtils.formatNombre(row.secteur)}</td> */}
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_inscrit == 0 ? row.nombre_candidat : row.candidat_inscrit)}</td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}</td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.total_effectif)}</td>
                                                        <td style={{
                                                            fontSize: '1.5rem', color: (row.total_effectif - row.nombre_candidat) > 0 ? "red"
                                                                : (row.total_effectif - row.nombre_candidat) === 0 ? "black"
                                                                    : "green"
                                                        }}>{(row.total_effectif - row.nombre_candidat) > 0 ? '+' : ''}{ANRDataUtils.formatNombre(row.total_effectif - row.nombre_candidat)}</td>
                                                        {/* <td style={{ fontSize: '12px' }}>
                                                                {
                                                                    row.nombre_candidat == 0 ?
                                                                        <button className={'btn btn-danger'} disabled={true} onClick={(e) => { detailProvince(row) }}>Afficher Candidats</button> :
                                                                        <button className={'btn btn-danger'} disabled={false} onClick={(e) => { detailProvince(row) }}>Afficher Candidats</button>
                                                                }
                                                            </td> */}
                                                    </tr>
                                                ))
                                            }
                                        </>
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>

        </div>
    );
    // }
}


export default Salle;