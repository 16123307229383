import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    FormGroup, Button
} from "reactstrap";
import DataTable from 'react-data-table-component';
import url from "urlConfig";
import ANRDataUtils from "utils/Utils";
import authHeader from "../../services/AuthHeader";
// import Etablissement from "./fokotany";
import EtablissementV from "./EtablissementV";
// import EtablissementVotes from "./EtablissementVotes";

const calculTotal = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const CommuneV = ({ region, district, province, setVisibleCommune }) => {
    const auth_data = authHeader()
    const [communelist, setCommunelist] = useState([])
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [t_nbr_parrain, sett_nbr_parrain] = useState(0)
    const [t_nbr_parraine, sett_nbr_parraine] = useState(0)
    const [t_nbr_membre, sett_nbr_membre] = useState(0)
    const [commune_d, setcommune_d] = useState(null)
    const [visibiliteEtablissement, setVisibiliteEtablissement] = useState(false)
    const getListCommune = () => {
        fetch(url.urlHttp + "validation/commune?region=" + region + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setCommunelist(data.data)
                // sett_nbr_parrain(calculTotal(data.data.map(row => row.nbr_parrain)))
                // sett_nbr_parraine(calculTotal(data.data.map(row => row.nbr_parraine)))
                // sett_nbr_membre(calculTotal(data.data.map(row => row.nbr_membre)))
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSort = (order, key) => {
        const sortedWords = [...communelist]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setCommunelist(sortedWords);
    };
    useEffect(() => {
        getListCommune()
    }, [])

    const stat = {
        // sumCandidatLibre: communelist.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: communelist.map(entry => entry.refuser).reduce((acc, val) => acc + val, 0),
        // sumTotal: communelist.map(entry => entry.total).reduce((acc, val) => acc + val, 0)

    };
    const details = (value) => {
        setcommune_d(value)
        setVisibiliteEtablissement(true)
    }
    if (commune_d != null && visibiliteEtablissement == true) {
        return (
            <>
                <EtablissementV types={'Commune'} setVisibiliteEtablissement={setVisibiliteEtablissement} commune={commune_d} district={district} province={province} region={region} />
            </>
        )
    } else {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            {/* Affichez un message d'erreur s'il y a une erreur */}
                            {error && <div className="alert alert-danger">{error}</div>}
                            <Row className="justify-content-center">
                                <Col md="6">
                                    <Card>
                                        <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{ color: "green", fontSize: '1rem', fontWeight: 'bold' }}> Au niveau District </span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>Province :<a style={{ color: "red", fontSize: "1rm"}}>{province}</a></span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>Region : <a style={{ color: "red", fontSize: "1rm"}}>{region}</a></span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red", fontSize: "1rm"}}>{district}</a></span>
                                            <span style={{ color: "black", fontSize: '1rem' }}> Total de  candidats libres  avec anomalie </span> 
                                            <h2 style={{ color: "red", fontSize: '1.3rem', fontWeight: 'bold' }}> {ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</h2>
                                        </CardTitle>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Button className="btn btn-outline-primary" onClick={e => { setVisibleCommune(false) }}>retour</Button>
                            <Table striped>
                                <thead className="text-primary">
                                    <tr>
                                        <th style={{ fontSize: "13px" }} onClick={() => handleSort('desc', 'commune')}>Centre &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: "13px" }} onClick={() => handleSort('desc', 'refuser')}>Nombre de Candidats avec anomalie  &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        communelist.map((row, index) => (
                                            <>
                                                <tr>
                                                    <td style={{ fontSize: "13px" }}>
                                                        {row.commune}
                                                    </td>
                                                    <td style={{ fontSize: "13px" }}>
                                                        {ANRDataUtils.formatNombre(row.refuser)}
                                                    </td>
                                                    <td style={{ fontSize: "13px" }}>
                                                        {
                                                            row.refuser > 0 ?
                                                                <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher Liste</button>
                                                                :
                                                                <button className={'btn btn-danger disabled'} >Afficher Liste</button>
                                                        }
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

}
export default CommuneV;