import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import url from 'urlConfig';
import authHeader from 'services/AuthHeader';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Table, Label, Input, Button, FormGroup } from 'reactstrap';

const Caa = () => {
    const auth_data = authHeader();
    const [data, setData] = useState([]);
    const prefectures =
        [
            {
                prefecture: "AMBATONDRAZAKA",
                district: "Amparafaravola",
                chef_district: "TSIRY Nohary Andrianina Harimanana Lalaina",
                contact: "034 07 522 57"
            },
            {
                prefecture: "AMBOSITRA",
                district: "Fandriana",
                chef_district: "RAZAZAROANABAVY  Felanirina  Hortensia",
                contact: "034 16 880 51 / 034 16 880 51"
            }
        ]
    const [data_contact, setData_contact] = useState([]);
    const [datas, setDatas] = useState();

    const handleGetListContact = async () => {
        await fetch(url.urlHttp + "materiel/contact_caa", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv caa ato : ", data.data);
                setData_contact(data.data);
                setDatas(data.data)
                //getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const filtre = (value, type) => {
        var filteredData = datas;
        if(type === "region"){
            filteredData = datas.filter(item => {
                return item.region.toUpperCase().includes(value.toUpperCase());
            });
        }
        if(type == "district"){
            filteredData = datas.filter(item => {
                return item.district.toUpperCase().includes(value.toUpperCase());
            });
        }
        if(type == "commune"){
            filteredData = datas.filter(item => {
                if (item.commune && typeof item.commune === 'string') {
                    return item.commune.toUpperCase().includes(value.toUpperCase());
                }
                return false;
            });
        }
        if(type == "nom"){
            filteredData = datas.filter(item => {
                if (item.nom_prenom && typeof item.nom_prenom === 'string') {
                    return item.nom_prenom.toUpperCase().includes(value.toUpperCase());
                }
                return false;
            });
        }
        setData_contact(filteredData);
    }

    useEffect(() => {
        // setData(prefectures);
        handleGetListContact();
    }, [])
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle>
                                    </CardTitle>
                                </CardHeader>
                                <Row>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Region :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher Region'}
                                                onChange={e => filtre(e.target.value, "region")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>District :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher District'}
                                                onChange={e => filtre(e.target.value, "district")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Commune :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher Commune'}
                                                onChange={e => filtre(e.target.value, "commune")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Noms et prenoms :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher Noms et Prenoms'}
                                                onChange={e => filtre(e.target.value, "nom")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                </Row>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <Table style={{ marginTop: "40px" }} striped>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>REGION&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th>DISTRICT&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th>COMMUNE&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th>FONCTION&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th>NOMS ET PRENOMS&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th>CONTACT&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data_contact.map((row, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.region}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.district}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.commune}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.fonction}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.nom_prenom}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.contact}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Caa;