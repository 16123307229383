import ANRDataUtils from '../utils/Utils';
import url from '../urlConfig';
import { useNavigate } from "react-router-dom";

export default function authHeader() {

  //const navigate=useNavigate()

    const checkToken = (current_token) => {
      //const navigate=useNavigate()
      let isExpired = false;
      const payload = JSON.parse(atob(current_token.split(".")[1]));
      const expirationTimestamp = payload.exp;

      const dateNow = new Date().getTime();

      if(expirationTimestamp * 1000 < dateNow){
        isExpired = true;
        console.log("TAPITRA")
        return isExpired;
      } else {
        isExpired = false;
        console.log("MITOHY")
        return isExpired;
      } 
    } 
      
    if(localStorage.getItem('app_data')){
      const dataStorage = localStorage.getItem('app_data');
      const objectString = ANRDataUtils.decryptLocalStorage(dataStorage)
      const parsedObject = JSON.parse(objectString);
    
      if (parsedObject.token) {
            const status_token = checkToken(parsedObject.token);
            if (status_token === false){
              return {
                'content_type':'application/json',
                'authorization':"Bearer "+ parsedObject.token
              }
            } else {
                ANRDataUtils.errorAlert("SESSION EXPIRER, VEUILLEZ RECONNECTER")
                fetch(url.urlHttp + 'auth/logout', { 
                  method: 'POST',
                  headers: {
                    'Authorization': `${parsedObject.type_token} ${parsedObject.token}`
                  },
                })
                .then(response => response.json())
                .then(data => {
                  //console.log("DATA BE  MIVOKA : ", data)
                  //navigate("/login")
                  localStorage.removeItem("app_data");
                  //ANRDataUtils.successAlert(data.message)
                  window.location.reload();
                  window.location.href = "/#/login";
                  
                }).catch(err => {
                  console.log("ERROR : ", err)
                })
            }
      } else {
        return {};
      }
    }    
}