import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from "react-router-dom";

import Accueil from "components/Accueil/Accueil";
import MenLayout from "layouts/Men";
import Login from "views/auth_login/loginMen";
import ANRDataUtils from "utils/Utils";
import Dashboard from "layouts/Admin";
import { AuthProvider } from "views/auth_login/context/AuthContext";
import { useAuth } from "views/auth_login/context/AuthContext";

const App = () => {
  const [role, setRole] = useState(null);
  const {isConnected, token}=useAuth();

 
  if(token){
    return (
      <HashRouter>
        <Routes>
        <Route path="/" element={<Accueil />} exact />
        <Route path="/admin/*" element={<Dashboard />} />
        <Route path="/men/*" element={<MenLayout />} />
        <Route path="/login" element={<Login />} exact />
        <Route path="*" element={<Navigate to='/admin/dashboard' />} />
        </Routes>
      </HashRouter>
  );
  }else{
    return (
      <HashRouter>
        <Routes>
        <Route path="/" element={<Accueil />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Navigate to='/login'/>} />
        </Routes>
      </HashRouter>
  );
  }
  
};

export default App;