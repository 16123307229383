import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    CardFooter, Button, Label, Input
} from "reactstrap";
import ANRDataUtils from 'utils/Utils';
import './../scrollbar.css';
import url from "../../urlConfig";
import authHeader from '../../services/AuthHeader';

const CandidatQG = ({ setVisibleCandidat, region, commune, district, province, etablissement }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [listEtablissements, setListEtablissements] = useState([]);
    const [listeId, setListeId] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [provincer, setProvince] = useState(province);
    const [regions, setRegions] = useState([]);
    const [regionr, setRegion] = useState(region);
    const [districts, setDistricts] = useState([]);
    const [districtr, setDistrict] = useState(district);
    const [communes, setCommunes] = useState([]);
    const [communer, setCommune] = useState(commune);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [genre, setGenre] = useState('');
    const [date_naissance, setDate_naissance] = useState('');
    const [code_commune, setCode_commune] = useState('');
    const [addresse, setAddresse] = useState('');
    const [numero, setNumero] = useState('');
    const [offset, setOffset] = useState(0);
    const [nombre, setNombre] = useState(0);
    const [numPage, setNumPage] = useState(0);
    const [page, setPage] = useState();
    const [role, setRole] = useState('');
    const [roles, setRoles] = useState(["Chef d etablissement",
        "Autre agents",
        "Surveillants de cour",
        "Surveillants de salle",
        "Secretaires"]);
    const [centres, setCentres] = useState([]);
    const [centrer, setCentre] = useState(etablissement);
    const [listss, setListss] = useState([]);


    const toDateFormat = (date) => {
        const dateString = date
        const moment = require('moment');
        const formattedDate = moment(dateString, "ddd MMM DD HH:mm:ss Z YYYY").format("DD-MM-YYYY");
        return formattedDate;
    }
    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    let data = {
        offset: 0,
        province: provincer,
        region: regionr,
        district: districtr,
        commune: communer,
        centre_examen: centrer,
        roles: "",
        nom: "",
        prenom: "",
        code_commune: "",
        limit: 10
    }
    const getListEtablissement = () => {
        fetch(url.urlHttp + "personnel/liste", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissement(data.data)
                setListss(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    let datas = {
        offset: 0,
        province: provincer,
        region: regionr,
        district: districtr,
        commune: communer,
        centre_examen: centrer,
        roles: "",
        nom: "",
        prenom: "",
        code_commune: "",
        limit: 0
    }
    const getListEtablissements = () => {
        fetch(url.urlHttp + "personnel/liste", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(datas)
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissements(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + provincer, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        console.log(url.urlHttp + "tanana/district?region=" + provincer + "&&district=" + regionr);
        fetch(url.urlHttp + "tanana/district?region=" + regionr + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommune = () => {
        fetch(url.urlHttp + "tanana/commune?region=" + regionr + "&&district=" + districtr + "&&commune=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCentre = () => {
        fetch(url.urlHttp + "personnel/centre?commune=" + communer + "&&district=" + districtr + "&&region=" + regionr, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA centre : ", JSON.stringify(data.data));
                setCentres(data.data);
            })
            .then(() => {
                console.log("Filtered List: ", listEtablissement); // Log the updated filteredList here
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getPagination = async () => {
        // alert(numPage)
        const data = {
            province: provincer,
            region: regionr,
            district: districtr,
            nom: nom,
            prenom: prenom,
            commune: communer,
            offset: offset,
            code_commune: "",
            centre_examen: centrer,
            roles: role,
            limit: 0
        }
        console.log(data);
        await fetch(url.urlHttp + "personnel/pagination", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((res) => {
                console.log("nombre", res.data);
                setPage(Math.ceil(res.data / 10));
                setNombre(res.data);
                console.log("reto le page" + Math.ceil(res.data / 10))
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSearchs = async (off) => {
        getPagination();
        const data = {
            province: provincer,
            region: regionr,
            district: districtr,
            nom: nom,
            prenom: prenom,
            commune: communer,
            offset: off,
            code_commune: "",
            centre_examen: centrer,
            roles: role,
            limit: 10,
        }
        console.log(data);
        // alert(numPage);
        await fetch(url.urlHttp + 'personnel/liste', {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((data) => {
                setListEtablissement(data.data);
                setListss(data.data)
            })
            .catch((error) => console.log('error', error))

    }

    const setPages = (index) => {
        setNumPage(index);
        setOffset(index * 10);
        handleSearchs(index * 10);
        getPagination();
    }

    const [isRecherche, setIsRecherche] = useState(false);
    const show = () => {
        if (isRecherche == true) {
            setIsRecherche(false);
        } else {
            setIsRecherche(true);
        }

    }
    useEffect(() => {
        getListEtablissements();
        setPages(0);
        getListEtablissement();
        if (!province) {
            getProvinces();
        } else if (!region) {
            handleRegion();
        } else if (!district) {
            handleDistrict();
        } else if (!commune) {
            handleCommune();
        } else if (!etablissement) {
            handleCentre();
        }
    }, [])
    console.log(listeId);

    const stat = {
        sumsds: listEtablissements.filter(entry => entry.roles.includes('Surveillants de salle')).length,
        sumsdc: listEtablissements.filter(entry => entry.roles.includes('Surveillants de cour')).length,
        sumaa: listEtablissements.filter(entry => entry.roles.includes('Autre agents')).length,
        sumcdc: listEtablissements.filter(entry => entry.roles.includes('Chef d etablissement')).length,
        sums: listEtablissements.filter(entry => entry.roles.includes('Secretaires')).length,
        sumsalle: listEtablissements.length > 0 ? listEtablissements[0].nombre_salle : 0,
        sumTotal: listEtablissements.length,
        sumcentre: 1
    };

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
            setListEtablissement(listss);
        }
        console.log(milahatra);
    };


    return (
        <div className="content">
            <Row>
                <Col md="12">
                    {/* Affichez un message d'erreur s'il y a une erreur */}
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Row>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }}>
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Total de salles</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumsalle)}
                                    </p>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }}>
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Chef de centre</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumcdc)}
                                    </p>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4" >
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Secrétaires</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sums)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Surveillants de cours</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumsdc)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Surveillants de salle</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumsds)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Autres agents</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumaa)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Nombre de Personnels : </CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumTotal)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md="12" lg="12" sm="12">

                </Col>
            </Row>
            <Row>

                <Col>
                    <Card>
                        <CardBody>
                            <CardHeader>
                                <CardTitle>
                                    <span style={{ fontWeight: 'bold', fontSize: '20px', padding: '20px' }}>
                                        <a style={{ color: "black" }}>Province : </a> <a style={{ color: "red" }}>{provincer}</a>
                                        <a style={{ color: "black" }}>, Région : </a> <a style={{ color: "red" }}>{regionr}</a>
                                        <a style={{ color: "black" }}>, District : </a> <a style={{ color: "red" }}>{districtr}</a>
                                        <a style={{ color: "black" }}>, Centre : </a> <a style={{ color: "red" }}>{communer}</a>
                                        <a style={{ color: "black" }}>, Etablissement : </a> <a style={{ color: "red" }}>{centrer}</a>
                                    </span>
                                </CardTitle>
                            </CardHeader>
                            {
                                isRecherche == true &&
                                (<>
                                    <Card style={{ marginLeft: "-20px", marginRight: "-20px" }} >
                                        <CardBody>
                                            <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                                RECHERCHE DE PERSONNELS: <a style={{ color: "red" }}></a>
                                            </CardTitle><br />
                                            <Row >
                                                {province ? '' :
                                                    <Col className="pr-1" md="4">
                                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > PROVINCE : </label><br />
                                                        <select className={'form-control'} value={provincer} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                            <option value=''></option>
                                                            {provinces?.map(province => (
                                                                <option value={province.province} >{province.province}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                }
                                                {region ? '' :
                                                    <Col className="pr-1" md="4">
                                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > RÉGION : </label><br />
                                                        <select className={'form-control'} value={regionr} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                                                            <option value=''></option>
                                                            {regions?.map(region => (
                                                                <option value={region.region} >{region.region}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                }
                                                {district ? '' :
                                                    <Col className="pr-1" md="4">
                                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > DISTRICT : </label><br />
                                                        <select className={'form-control'} value={districtr} onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                                            <option value=''></option>
                                                            {districts?.map(district => (
                                                                <option value={district.district} >{district.district}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                }
                                                {commune ? '' :
                                                    <Col className="pr-1" md="4">
                                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > CENTRE : </label><br />
                                                        <select className={'form-control'} value={communer} onChange={e => { setCommune(e.target.value) }} onClick={handleCentre}>
                                                            <option value=''></option>
                                                            {communes?.map(commune => (
                                                                <option value={commune.commune} >{commune.commune}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                }
                                                {etablissement ? '' :
                                                    <Col className="pr-1" md="4">
                                                        <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > CENTRE D'EXAMEN: </label><br />
                                                        <select className={'form-control'} value={centrer} onChange={e => { setCentre(e.target.value) }}>
                                                            <option value=''></option>
                                                            {centres?.map(commune => (
                                                                <option value={commune.centre_examen} >{commune.centre_examen}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row >
                                                <Col md="4">
                                                    <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > RÔLE : </label><br />
                                                    <select className={'form-control'} value={role} onChange={e => { setRole(e.target.value) }}>
                                                        <option value=''></option>
                                                        {roles?.map(commune => (
                                                            <option value={commune} >{commune}</option>
                                                        ))}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md="4">
                                                    <Label for="nomDem" style={{ border: 'type-round', fontWeight: 'bold', color: "black" }}>NOM :</Label>
                                                    <Input
                                                        placeholder="Nom"
                                                        type="text"
                                                        value={nom} onChange={e => { setNom(e.target.value) }}
                                                    >
                                                    </Input>
                                                </Col>
                                                <Col md="4">
                                                    <Label for="fonctionDem" style={{ border: 'type-round', fontWeight: 'bold', color: "black" }}>PRÉNOM :</Label>
                                                    <Input
                                                        placeholder="Prénom"
                                                        type="text"
                                                        value={prenom} onChange={e => { setPrenom(e.target.value) }}
                                                    >
                                                    </Input>
                                                </Col>
                                                <Col md="4">
                                                    <Button className="btn btn-outline-info" onClick={(e) => { handleSearchs(0) }} style={{ marginTop: "20px" }}>
                                                        Rechercher
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </>)
                                // (<></>)

                            }
                            <Button className="btn btn-outline-primary " onClick={e => { setVisibleCandidat(false) }}>retour</Button>
                            <Button className="btn btn-outline-primary" onClick={show}>
                                {isRecherche ? "Fermer" : "Recherche"}
                            </Button>
                            <CardTitle tag="h4" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                Total de Personnels : <a style={{ color: "red" }}>&nbsp;&nbsp;&nbsp;{ANRDataUtils.formatNombre(nombre)}</a>
                            </CardTitle>
                            <CardTitle tag="h4" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                                Nombre de ligne : <a style={{ color: "red" }}>&nbsp;&nbsp;&nbsp;{ANRDataUtils.formatNombre(listEtablissement.length)}</a>
                            </CardTitle>
                            <Row className="justify-content-center">
                                <Button className="btn btn-outline-info mr-2" disabled={numPage === 0} onClick={() => setPages(numPage - 1)}>Précédent</Button>
                                <Button className="btn btn-outline-warning" disabled={numPage === page - 1} onClick={() => setPages(numPage + 1)}>Suivant</Button>
                            </Row>
                            <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                <Table striped>
                                    <thead className="text-primary sticky-header">
                                        <tr>
                                            <th onClick={() => handleSort('asc', 'nom')}>Nom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'prenom')}>Prénom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'prenom')}>Salle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'sexe')}>Sexe &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'contact')}>Contact &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'contact')}>Adresse mail &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'adresse')}>Adresse &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th onClick={() => handleSort('asc', 'roles')}>Rôle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listEtablissement.map((row, index) => (
                                                <>
                                                    <tr>
                                                        <td style={{textAlign: "left"}}>
                                                            {(row.nom)}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {(row.prenom)}
                                                        </td>
                                                        <td>
                                                            {(row.salle)}
                                                        </td>
                                                        <td>
                                                            {(row.sexe)}
                                                        </td>
                                                        <td>
                                                            {(row.contact)}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {(row.mail)}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {(row.adresse)}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {(row.roles)}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
// }

export default CandidatQG;
