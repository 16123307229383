import React from "react";
import cryptKey from "../Key";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
class ANRDataUtils {
 
    static formatNombre(number) {
        if(number){
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
            return number = 0;
        }
        
    }

    static hideString(info_hide) {
      return '*'.repeat(info_hide.length);
    }

    static encryptLocalStorage(storageInfo){
        let key = cryptKey.aesKey;
        const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(storageInfo), key).toString();
        return encryptedText;
    }

    static decryptLocalStorage(encryptStorageInfo){
        let key = cryptKey.aesKey;
        const decryptedText = CryptoJS.AES.decrypt(encryptStorageInfo, key).toString(CryptoJS.enc.Utf8);
        return decryptedText;
    }

    static successAlert(message){
        Swal.fire(
            'Success !',
            message,
            'success'
        )
    }

    static errorAlert(args){
        Swal.fire(
            'Erreur !',
            args,
            'error'
        )
    }

}

export default ANRDataUtils;