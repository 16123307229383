import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const StackedBarChartWithGroups = ({ labels, data1, data2, data3 , data4, data5, loader }) => {
    const datasets = [
        {
            label: 'Candidat inscrit',
            data: data1,
            backgroundColor: 'rgba(7, 205, 250, 0.6)',
            borderColor: 'rgba(7, 205, 250, 1)',
            borderWidth: 1,
        }
    ];
    
    if (loader == false) {
        datasets.push({
            label: 'Admis',
            data: data2,
            backgroundColor: 'rgba(10, 101, 247, 0.6)',
            borderColor: 'rgba(10, 101, 247, 1)',
            borderWidth: 1,
            stack: 'Stack 1',
        });
    } else {
        datasets.push({
            label: 'General',
            data: data3,
            backgroundColor: 'rgba(250, 242, 2, 0.6)',
            borderColor: 'rgba(250, 242, 2, 1)',
            borderWidth: 1,
            stack: 'Stack 1',
        });
        datasets.push({
            label: 'Professionnel et Technique',
            data: data4,
            backgroundColor: 'rgba(14, 250, 2, 0.6)',
            borderColor: 'rgba(14, 250, 2, 1)',
            borderWidth: 1,
            stack: 'Stack 1',
        });
        datasets.push({
            label: 'Technologique',
            data: data5,
            backgroundColor: 'rgba(17, 138, 178, 0.6)',
            borderColor: 'rgba(17, 138, 178, 1)',
            borderWidth: 1,
            stack: 'Stack 1',
        });
    }
    
    const data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default StackedBarChartWithGroups;
