import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardTitle, Col, Row, Table, CardBody, FormGroup } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';
import Serie from './Serie';

const Specialite = ({ setVisibleSpecialite }) => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [visibleCandidat, setVisibleSerie] = useState(false);
    const [etablissement_sec, setEtablissement_sec] = useState(null);
    const [data_etablissement, setData_etablissement] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);
    const [specialitee, setSpecialite] = useState('');
    const [seriee, setSerie] = useState('');
    const [matieree, setMatiere] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [communes, setCommunes] = useState([]);
    const [commune, setCommune] = useState('');
    const [code_commune, setCode_commune] = useState('');
    const [listEtablissement, setListEtablissement] = useState([]);
    const [etablissement, setEtablissement] = useState('');
    const [salle, setSalle] = useState('');
    const [salles, setSalles] = useState('');
    var nombre_total = 0;
    for (let i = 0; i < data_etablissement.length; i++) {
        nombre_total += data_etablissement[i].nombre;
    }

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        // console.log("tanana/district?region=" + region + "&&district=" + province);
        fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommune = () => {
        fetch(url.urlHttp + "tanana/commune?region=" + region + "&&district=" + district + "&&commune=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                // console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCentre_examen = () => {
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setListEtablissement(data.data)
                console.log(listEtablissement);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSalle = () => {
        fetch(url.urlHttp + "centre-milala/liste-salle?district=" + district + "&&commune=" + commune + "&&centre_xamen=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log("itooooooo eeeeeeeeeeeeeeeeeeeee"+data.data);
                setSalles(data.data)
                // console.log(salles);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getListEtablissement = () => {
        console.log(url.urlHttp + "denombrement/specialite?specialite=" + specialitee + "&&secteur=&&serie=" + seriee + "&&matiere=" + matieree + "&&province=" + province + "&&region=" + region + "&&district=" + district + "&&commune=" + commune + "&&etablissement=" + etablissement + "&&salle=" + salle)
        fetch(url.urlHttp + "denombrement/specialite?specialite=" + specialitee + "&&secteur=&&serie=" + seriee + "&&matiere=" + matieree + "&&province=" + province + "&&region=" + region + "&&district=" + district + "&&commune=" + commune + "&&etablissement=" + etablissement + "&&salle=" + salle, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR Region: ", data.data);
                setData_etablissement(data.data)
                setLists(data.data);
                setListss(data.data);
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getListEtablissement();
        getProvinces();
    }, [])

    const detailProvince = (etablissement) => {
        setEtablissement_sec(etablissement);
        setVisibleSerie(true);
    }
    const filtre = (value) => {
        const filteredData = data_etablissement.filter(item => item.specialite.toUpperCase().includes(value.toUpperCase()));
        setData_etablissement(filteredData)
    }

    const handleCentre = (commune) => {
        for (let i = 0; i < communes.length; i++) {
            if (communes[i].commune == commune) {
                setCode_commune(communes[i].code_commune);
            }
        }
    }

    const handleSearchs = async (off) => {
        getListEtablissement();
    }
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...data_etablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setData_etablissement(sortedWords);
        } else {
            setMilahatra(true);
            setData_etablissement(listss);
        }
        console.log(milahatra);
    };
    const stat = {
        denombrement: data_etablissement.map(entry => entry.total_effectif).reduce((acc, val) => acc + val, 0),
        sujet: data_etablissement.map(entry => entry.nombre_candidat).reduce((acc, val) => acc + val, 0),
    };
    if (visibleCandidat == true && etablissement_sec != null) {
        return (
            <>
                <Serie setVisibleSerie={setVisibleSerie} specialite={etablissement_sec.specialite} matiere={matieree} serie={seriee} province={province} region={region} district={district} commune={commune} etablissement={etablissement} salle={salle} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row className="justify-content-center">
                    <Col md="6">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "black", fontSize: '1rem' }}> Total de sujets estimés  : &nbsp;&nbsp;
                                        <a style={{ color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sujet)}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1rem' }}> Total de sujets envoyés  : &nbsp;&nbsp;
                                        <a style={{ color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.denombrement)}</a>
                                    </span>
                                    <span style={{ color: "black", fontSize: '1rem' }}> Différences : &nbsp;&nbsp;
                                        <a style={{
                                            fontSize: '1.5rem', color: (stat.denombrement - stat.sujet) > 0 ? "red"
                                                : (stat.denombrement - stat.sujet) === 0 ? "black"
                                                    : "green", fontWeight: 'bold'
                                        }}>{(stat.denombrement - stat.sujet) > 0 ? '+' : ''}{ANRDataUtils.formatNombre(stat.denombrement - stat.sujet)}</a>
                                    </span>

                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="4"><br />
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Spécialité :</a></label>
                            <input
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechercher specialite'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>


                <Row>
                    <Col md="12">
                        <Card style={{ marginTop: "40px" }}>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.2rem' }}>Au niveau Spécialité <br />
                                            {province != "" ? (<>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red", fontSize: '1rem' }}>{province},</a>&nbsp;
                                                </span><br /></>) : ''
                                            }
                                            {region != "" ? (<>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red", fontSize: '1rem' }}>{region},</a>&nbsp;
                                                </span><br /></>) : ''
                                            }
                                            {district != "" ? (<>
                                                <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red", fontSize: '1rem' }}>{district},</a>&nbsp;
                                                </span><br /></>) : ''
                                            }
                                            {commune != "" ? (<>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Commune : <a style={{ color: "red", fontSize: '1rem' }}>{commune},</a>&nbsp;
                                                </span><br /></>) : ''
                                            }
                                            {etablissement != "" ? (<>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Établissement : <a style={{ color: "red", fontSize: '1rem' }}>{etablissement},</a>&nbsp;
                                                </span><br /></>) : ''
                                            }
                                            {salle != "" ? (<>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Salle : <a style={{ color: "red", fontSize: '1rem' }}>{salle},</a>&nbsp;
                                                </span><br /></>) : ''
                                            }
                                            <span style={{ color: "black", fontSize: '1rem' }}> Total de sujets estimés  : &nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sujet)}</a>
                                            </span>
                                            <span style={{ color: "black", fontSize: '1rem' }}> Total de sujets envoyés  : &nbsp;&nbsp;
                                                <a style={{ color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.denombrement)}</a>
                                            </span>

                                        </span>

                                    </CardTitle>
                                </CardHeader>
                                <Row>
                                    <Col className="pr-3" md="3">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>PROVINCE :</a></label><br />
                                            <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                <option value={''}></option>
                                                {provinces?.map(province => (
                                                    <option value={province.province} >{province.province}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="3">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>RÉGION : </a> </label><br />
                                            <select className={'form-control'} value={region} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                                                <option value={''}></option>
                                                {regions?.map(region => (
                                                    <option value={region.region} >{region.region}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="3">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                            <select className={'form-control'} value={district} onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                                <option value={''}></option>
                                                {districts?.map(district => (
                                                    <option value={district.district} >{district.district}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="3">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                            <select className={'form-control'} value={commune} onChange={e => { { setCommune(e.target.value); handleCentre(e.target.value) } }} onClick={handleCentre_examen}>
                                                <option value={''}></option>
                                                {communes?.map(commune => (
                                                    <option value={commune.commune} >{commune.commune}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="3">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }} ><a style={{ color: "gray", fontWeight: 'bold' }}> ETABLISSEMENT D'EXAMEN :</a> </label><br />
                                            <select className={'form-control'} value={etablissement} onChange={e => { { setEtablissement(e.target.value) } }} onClick={handleSalle}>
                                                <option value={''}></option>
                                                {listEtablissement?.map(commune => (
                                                    <option value={commune.centre_examen} >{commune.centre_examen}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="3">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }} > <a style={{ color: "gray", fontWeight: 'bold' }}>SALLE</a> </label><br />
                                            <select className={'form-control'} value={salle} onChange={e => { setSalle(e.target.value) }} >
                                                <option value="" ></option>
                                                {salles && salles?.map(salle => (
                                                    <option value={salle.salle} >{salle.salle}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <Button className="btn btn-outline-info" onClick={(e) => { handleSearchs(0) }} style={{ marginTop: "20px" }}>
                                            Filtrer
                                        </Button>
                                    </Col>
                                </Row>
                                <Button className="btn btn-outline-primary" onClick={e => { setVisibleSpecialite(false) }}>retour</Button>

                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                    <Table striped>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'etablissement')}>Specialite &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                {/* <th>Secteur</th> */}
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Nombre de Candidats &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Nombre de sujets estimés &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'total_effectif')}>Nombre de sujets envoyés &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'total_effectif')}>Différences &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {/* <td>{ANRDataUtils.formatNombre(row.province)}</td>
                                                
                                               */}{
                                                    data_etablissement.map(row => (
                                                        <tr>
                                                            <td >{ANRDataUtils.formatNombre(row.specialite)}</td>
                                                            {/* <td>{ANRDataUtils.formatNombre(row.secteur)}</td> */}
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_inscrit)}</td>
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}</td>
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.total_effectif)}</td>
                                                            <td style={{
                                                                fontSize: "1.5rem", color: (row.total_effectif - row.nombre_candidat) > 0 ? "red"
                                                                    : (row.total_effectif - row.nombre_candidat) === 0 ? "black"
                                                                        : "green"
                                                            }}>{(row.total_effectif - row.nombre_candidat) > 0 ? '+' : ''}{ANRDataUtils.formatNombre(row.total_effectif - row.nombre_candidat)}</td>
                                                            <td style={{ fontSize: '1.2rem' }}>
                                                                {
                                                                    row.nombre_candidat == 0 ?
                                                                        <button className={'btn btn-danger'} disabled={true} onClick={(e) => { detailProvince(row) }}>Afficher Série</button> :
                                                                        <button className={'btn btn-danger'} disabled={false} onClick={(e) => { detailProvince(row) }}>Afficher Série</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </div>
        );
    }
}


export default Specialite;