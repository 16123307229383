import { useEffect, useState } from "react";
import {
  Button,
  Card, CardBody,
  CardTitle,
  Col,
  Modal, ModalBody,
  ModalHeader,
  Row, Table, FormGroup
} from 'reactstrap';
import StackedBarChartWithGroups from "./chart";

import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import authHeader from "services/AuthHeader";
import ANRDataUtils from "utils/Utils";



import myImage from '../../assets/img/ANR_BANNER.jpg';
import myImage2 from '../../assets/img/ANR_PDP.jpg';
import iconElecteur2 from '../../assets/img/candidat.png';
import url from '../../urlConfig';
import '../Chartfonction';


const calculTotals = (donnee) => {
  let t = 0;
  let i = 0;
  for (i = 0; i < donnee.length; i++) {
    t = t + donnee[i];
  }
  return t;
}

function Stat_Note() {
  const auth_data = authHeader();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loader, setLoader] = useState(false);
  const [dataAdmis, setDataAdmis] = useState([])
  const data = authHeader();
  const [indice, setIndice] = useState(0);
  const [province, setProvince] = useState('')
  const [provinces, setProvinces] = useState([])
  const [region, setRegion] = useState('')
  const [regions, setRegions] = useState([])
  const [district, setDistrict] = useState('')
  const [districts, setDistricts] = useState([])
  const [specialite, setSpecialite] = useState('');
  const [candidat, setCandidat] = useState(0);
  const [admis, setAdmis] = useState(0);
  const [candidatG, setCandidatG] = useState(0);
  const [candidatTP, setCandidatTP] = useState(0);
  const [candidatT, setCandidatT] = useState(0);
  const [admisG, setAdmisG] = useState(0);
  const [admisTP, setAdmisTP] = useState(0);
  const [admisT, setAdmisT] = useState(0);


  const getProvinces = () => {
    fetch(url.urlHttp + "tanana/province", {
      method: 'GET',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA", data.data);
        setProvinces(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleRegion = () => {
    fetch(url.urlHttp + "tanana/region?region=" + province, {
      method: 'GET',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA REG", data.data);
        setRegions(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleDistrict = () => {
    fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=", {
      method: 'GET',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA DIST", data.data);
        setDistricts(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  const getDashboard = (admiss) => {
    fetch(
      url.urlHttp + "admis/liste", {
      method: "POST",
      headers: {
        'Content-Type': data.content_type,
        'Authorization': data.authorization
      },
      body: JSON.stringify(admiss)
    })
      .then((response) => response.json())
      .then((data) => {
        setDataAdmis(data.data);
        setCandidat(calculTotals(data.data.map(row => row.effectif)))
        setAdmis(calculTotals(data.data.map(row => row.admis)))
        setCandidatG(calculTotals(data.data.filter(row => row.specialite === 'General').map(row => row.effectif)))
        setCandidatTP(calculTotals(data.data.filter(row => row.specialite === 'Professionnel et Technique').map(row => row.effectif)))
        setCandidatT(calculTotals(data.data.filter(row => row.specialite === 'Technologique').map(row => row.effectif)))
        setAdmisG(calculTotals(data.data.filter(row => row.specialite === 'General').map(row => row.admis)))
        setAdmisTP(calculTotals(data.data.filter(row => row.specialite === 'Professionnel et Technique').map(row => row.admis)))
        setAdmisT(calculTotals(data.data.filter(row => row.specialite === 'Technologique').map(row => row.admis)))
        setIndice(indice + 1)
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDashboardPro = (admiss) => {
    fetch(
      url.urlHttp + "admis/listePro", {
      method: "POST",
      headers: {
        'Content-Type': data.content_type,
        'Authorization': data.authorization
      },
      body: JSON.stringify(admiss)
    })
      .then((response) => response.json())
      .then((data) => {
        setDataAdmis(data.data);
        setCandidat(calculTotals(data.data.map(row => row.effectif)))
        setAdmis(calculTotals(data.data.map(row => row.admis)))
        setIndice(indice + 1)
        setLoader(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const navigate = useNavigate()


  const [indicesdash, setIndiceDash] = useState(0);
  useEffect(() => {
    let admiss = {
      num: "",
      province: province,
      region: region,
      district: district,
      specialite: specialite,
      serie: "",
      statut: "",
      admis: "",
      effectif: ""
    }
    getProvinces();
    getDashboard(admiss);

  }, []);

  const [visibleDistrict, setVisibleDistrict] = useState(false);
  const [region_sec, setregion_sec] = useState(null)

  const [modalPhotose, setModalPhotose] = useState(false);

  const togglePhotose = () => setModalPhotose(!modalPhotose);

  const openPhotoseModal = () => {
    togglePhotose();
  };

  const save = (value) => {
    if (value == 1) {
      setSpecialite("")
      let admisss = {
        num: "",
        province: province,
        region: region,
        district: district,
        specialite: "",
        serie: "",
        statut: "",
        admis: "",
        effectif: ""
      }
      getDashboard(admisss);
    } else {
      let admisss = {
        num: "",
        province: province,
        region: region,
        district: district,
        specialite: specialite,
        serie: "",
        statut: "",
        admis: "",
        effectif: ""
      }
      if (loader == false) {
        getDashboard(admisss);
      }
      if (loader == true) {
        getDashboardPro(admisss);
      }
    }
  }

  const DistrictPDF = () => {
    return (
      <Document>
        <Page size={'A4'} style={{ padding: '19px' }}>
          {province ? <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province : <Text style={{ color: 'red' }}>{province}</Text> </Text></View> : ""}
          {region ? <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Region : <Text style={{ color: 'red' }}>{region}</Text> </Text></View> : ""}
          {district ? <View><Text style={{ fontWeight: 'bold', color: 'green' }}>District : <Text style={{ color: 'red' }}>{district}</Text> </Text></View> : ""}
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre de Candidats : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(candidat)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre de Candidats admis : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(admis)}</Text> </Text></View>
          <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Pourcentage de Réussite : <Text style={{ color: 'red' }}>{parseFloat(((admis / candidat) * 100).toFixed(2))} %</Text> </Text></View>

          \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
          {/* <View><Text style={{ fontWeight: 'bold' }}>Liste District </Text></View> */}
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableRow}>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Specialite</Text>
              </View>
              {specialite ? <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Série</Text>
              </View> : ""}
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Nombre de Candidats</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Nombre des Admis</Text>
              </View>
              <View style={pdfStyles.tableColHeader}>
                <Text style={pdfStyles.tableCell}>Pourcentage de Réussite</Text>
              </View>
            </View>
            {
              dataAdmis.map(dist => (
                <View style={pdfStyles.tableRow}>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{dist.specialite}</Text>
                  </View>
                  {specialite ?
                    <View style={pdfStyles.tableCol}>
                      <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.serie)}</Text>
                    </View> : ""}
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.effectif)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.admis)}</Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCell}>{parseFloat(((dist.admis / dist.effectif) * 100).toFixed(2))} %</Text>
                  </View>
                </View>
              ))
            }
          </View>
        </Page>
      </Document>
    )
  }

  const speci = (value) => {
    let admiss = {
      num: "",
      province: province,
      region: region,
      district: district,
      specialite: value,
      serie: "",
      statut: "",
      admis: "",
      effectif: ""
    }
    setSpecialite(value);
    getDashboard(admiss);
  }

  const speciPro = () => {
    let admiss = {
      num: "",
      province: province,
      region: region,
      district: district,
      specialite: "",
      serie: "",
      statut: "",
      admis: "",
      effectif: ""
    }
    getDashboardPro(admiss);
  }
  const pdfStyles = StyleSheet.create({
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      flexDirection: "row",
      width: "100%",  // Ensure row takes full width
    },
    tableColHeader: {
      flex: 1,  // Adjust flex as needed for proportion
      borderStyle: "solid",
      backgroundColor: "#f5f5f5",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColNonCheker: {
      flex: 1,
      borderStyle: "solid",
      backgroundColor: "#7e7676",
      borderWidth: 1,
      color: "#ffffff",
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColCheker: {
      flex: 1,
      borderStyle: "solid",
      backgroundColor: "#e35f5f",
      borderWidth: 1,
      borderLeftWidth: 0,
      color: "#ffffff",
      borderTopWidth: 0
    },
    tableCol: {
      flex: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      margin: "auto",
      marginTop: 2,
      fontSize: 9
    }
  });
  const labels = dataAdmis.map(row => {
    if (loader) {
      if (district) {
        return row.centre;
      } else if (region) {
        return row.district;
      } else if (province) {
        return row.region;
      }
      return row.province;
    }
    return specialite ? row.serie : row.specialite;
  });
  const data1 = dataAdmis.map(row => row.effectif);
  const data2 = dataAdmis.map(row => row.admis);
  const data3 = dataAdmis.map(row => row.ad_gen);
  const data4 = dataAdmis.map(row => row.ad_protech);
  const data5 = dataAdmis.map(row => row.ad_tech);
  const show = () => {
    setIsMpotra(!isMpotra);
  }
  const [isMpotra, setIsMpotra] = useState(false);
  return (
    <>
      <div className="content">
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalBody>
            <img src={myImage} alt="photoPrez" className="img-fluid" />
          </ModalBody>
          <ModalBody>
            <img src={myImage2} alt="photoPrez2 " className="img-fluid" />
          </ModalBody>
        </Modal>
        {specialite ? (<><span style={{ color: "green", fontWeight: 'bold', fontSize: "20px" }}>Specialite : {specialite}</span><br /> </>) : ""}
        {province ? (<><span style={{ color: "green", fontWeight: 'bold', fontSize: "20px" }}>Province : {province}</span><br /> </>) : ""}
        {region ? (<><span style={{ color: "green", fontWeight: 'bold', fontSize: "20px" }}>Région : {region}</span><br /> </>) : ""}
        {district ? (<><span style={{ color: "green", fontWeight: 'bold', fontSize: "20px" }}>District : {district}</span><br /> </>) : ""}
        <br></br>
        <Row md="12" style={{ gap: '0px', padding: '0px', margin: '0px' }} className="justify-content-center" >
          <Col lg="4" md="4" sm="4">
            <Card style={{ padding: '10px' }}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Candidats </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(candidat)}</b></CardTitle>
                <br></br>
              </Col>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="4">
            <Card style={{ padding: '10px' }}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Candidats admis </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(admis)}</b></CardTitle>
                <br></br>
              </Col>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="4">
            <Card style={{ padding: '10px' }} onClick={() => speciPro()}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                  <div className="icon-big text-center icon-warning">
                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                  </div>
                  <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Pourcentage de réussite  </p>
                </Row>
                <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{parseFloat(((admis / candidat) * 100).toFixed(2))} %</b></CardTitle>
                <p className="font-weight-bold" style={{ color: 'red', fontSize: '0.7rem' }}>vous pouvez cliquez ici</p>
              </Col>
            </Card>
          </Col>
          {specialite ? "" :
            <Col lg="4" md="4" sm="4">
              <Card style={{ padding: '10px' }} onClick={() => speci("General")}>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                    <div className="icon-big text-center icon-warning">
                      <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                    </div>
                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>General</p>
                  </Row>
                  <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{parseFloat(((admisG / candidatG) * 100).toFixed(2))} %</b></CardTitle>
                  <p className="font-weight-bold" style={{ color: 'red', fontSize: '0.7rem' }}>vous pouvez cliquez ici</p>
                </Col>
              </Card>
            </Col>
          }
          {specialite ? "" :
            <Col lg="4" md="4" sm="4">
              <Card style={{ padding: '10px' }} onClick={() => speci("Professionnel et Technique")}>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                    <div className="icon-big text-center icon-warning">
                      <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                    </div>
                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Professionnel et Technique</p>
                  </Row>
                  <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{parseFloat(((admisTP / candidatTP) * 100).toFixed(2))} %</b></CardTitle>
                  <p className="font-weight-bold" style={{ color: 'red', fontSize: '0.7rem' }}>vous pouvez cliquez ici</p>
                </Col>
              </Card>
            </Col>
          }
          {specialite ? "" :
            <Col lg="4" md="4" sm="4">
              <Card style={{ padding: '10px' }} onClick={() => speci("Technologique")}>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                    <div className="icon-big text-center icon-warning">
                      <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                    </div>
                    <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Technologique</p>
                  </Row>
                  <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{parseFloat(((admisT / candidatT) * 100).toFixed(2))} %</b></CardTitle>
                  <p className="font-weight-bold" style={{ color: 'red', fontSize: '0.7rem' }}>vous pouvez cliquez ici</p>
                </Col>
              </Card>
            </Col>
          }
        </Row>
        <Card>
          <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
            <Row>
              <Col className="pr-4" md="4">
                <FormGroup className="mr-3">
                  <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PROVINCE :</a></label><br />
                  <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                    <option value={''}></option>
                    {provinces?.map(province => (
                      <option value={province.province} >{province.province}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col className="pr-4" md="4">
                <FormGroup className="mr-3">
                  <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>RÉGION : </a> </label><br />
                  <select className={'form-control'} value={region} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                    <option value={''}></option>
                    {regions?.map(region => (
                      <option value={region.region} >{region.region}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col className="pr-4" md="4">
                <FormGroup className="mr-3">
                  <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                  <select className={'form-control'} value={district} onChange={e => { setDistrict(e.target.value) }}>
                    <option value={''}></option>
                    {districts?.map(district => (
                      <option value={district.district} >{district.district}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Button className="btn btn-primary" onClick={(e) => save(2)}>
              Filtrer
            </Button>
            <Button className="btn btn-primary" onClick={() => openPhotoseModal()}>
              Afficher les données
            </Button>
            <Button className="btn btn-primary" onClick={() => navigate("/admin/admis")}>
              Afficher les Admis
            </Button>
            {specialite ? <Button className="btn btn-primary" onClick={(e) => save(1)}>
              Retour
            </Button> : ""}

          </CardBody>
          {/* <CardBody>
            <div className="mb-2">
              <select
                // value={precision_emplacement}
                // onChange={(e) => setPrecison_emplacement(e.target.value)}
                style={{
                  padding: '8px',
                  fontSize: '14px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  backgroundColor: '#fff',
                  color: '#333',
                  width: '200px'
                }}
                // value={regionSelect}
                onChange={(e) => { detailRegion(e.target.value) }}
              >
                <option value="">Choix province </option>
                {province.map((label, index) => (
                  <option key={index} value={label} >{label}</option>
                ))}

              </select>
            </div>
            <Button className="btn btn-outline-primary btn-sm" onClick={() => openPhotoseModal()}>
              Afficher les données
            </Button>
            <Button className="btn btn-outline-primary" onClick={show}>
              {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
            </Button>
          </CardBody> */}

        </Card>
        <Card>

          <StackedBarChartWithGroups labels={labels} data1={data1} data2={data2} data3={data3} data4={data4} data5={data5} loader={loader}/>
        </Card>
        <Row>
          <Modal isOpen={modalPhotose} toggle={togglePhotose} size="xl">
            <ModalHeader toggle={togglePhotose} style={{ color: "black", fontSize: '20px' }}>
              {specialite ? (<><span style={{ color: "green", fontWeight: 'bold' }}>Spécialité : {specialite}</span><br /> </>) : ""}
              {province ? (<><span style={{ color: "green", fontWeight: 'bold' }}>Province : {province}</span><br /> </>) : ""}
              {region ? (<><span style={{ color: "green", fontWeight: 'bold' }}>Région : {region}</span><br /> </>) : ""}
              {district ? (<><span style={{ color: "green", fontWeight: 'bold' }}>District : {district}</span><br /> </>) : ""}
            </ModalHeader>
            <Row md="12" style={{ gap: '0px', padding: '0px', margin: '0px' }} className="justify-content-center" >
              <Col lg="4" md="4" sm="4">
                <Card style={{ padding: '10px' }} >
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Candidats </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(candidat)}</b></CardTitle>
                  </Col>
                </Card>
              </Col>
              <Col lg="4" md="4" sm="4">
                <Card style={{ padding: '10px' }} >
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Candidats admis </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(admis)}</b></CardTitle>
                  </Col>
                </Card>
              </Col>
              <Col lg="4" md="4" sm="4">
                <Card style={{ padding: '10px' }} >
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                      <div className="icon-big text-center icon-warning">
                        <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                      </div>
                      <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Pourcentage de réussite  </p>
                    </Row>
                    <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{parseFloat(((admis / candidat) * 100).toFixed(2))} %</b></CardTitle>
                  </Col>
                </Card>
              </Col>
            </Row>
            <ModalBody>
              <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"National"}>
                {({ blob, url, loading, error }) =>
                  loading ? ' Chargement du PDF...' : ' Exporter PDF'
                }
              </PDFDownloadLink>
              <Table striped>
                <thead className="text-primary" >
                  <tr >
                    {loader == false ?
                      <th>Specialite </th> : ""}
                    {specialite && loader == false ? <th>Série</th> : ""}
                    {loader == true ?
                      <th>Province </th> : ""}
                    {province && loader == true ?
                      <th>Region </th> : ""}
                    {region && loader == true ?
                      <th>District </th> : ""}
                    {district && loader == true ?
                      <th>Centre </th> : ""}
                    <th>Nombre de Candidats</th>
                    <th>Nombre de Candidats Admis</th>
                    <th>Pourcentage de réussite</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataAdmis.map(row => (
                      <tr>
                        {loader == false ?
                          <td style={{ fontSize: '1.5rem' }}>{row.specialite}</td> : ""}
                        {specialite && loader == false ? <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.serie)}</td> : ""}
                        {loader == true ?
                          <td style={{ fontSize: '1.5rem' }}>{row.province} </td> : ""}
                        {province && loader == true ?
                          <td style={{ fontSize: '1.5rem' }}>{row.region} </td> : ""}
                        {region && loader == true ?
                          <td style={{ fontSize: '1.5rem' }}>{row.district} </td> : ""}
                        {district && loader == true ?
                          <td style={{ fontSize: '1.5rem' }}>{row.centre} </td> : ""}
                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.effectif)}</td>
                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.admis)}</td>
                        <td style={{ fontSize: '1.5rem' }}>{parseFloat(((row.admis / row.effectif) * 100).toFixed(2))} %</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          {/* </div > */}
        </Row>
      </div>
    </>
  );

}

export default Stat_Note;