import React, { useState } from "react";
import { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
    Table,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input
} from "reactstrap";
import url from "urlConfig";
import authHeader from 'services/AuthHeader';
import Swal from 'sweetalert2'
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';
import FormulaireCandidats from "./formulaireCandidats";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const auth_data = authHeader();
    const [visibleAjout, setVisibleAjout] = useState(false);
    const [modal, setModal] = useState(true);
    const toggleModal = () => setModal(!modal);

    const errorAlert = (state, args) => {
        Swal.fire(
            {
                icon: state,
                title: args,
            }
        )
    }

    const handlePass = async () => {
        console.log(username +""+password);
        if(username == "MOSA@gmail.com" && password == "1234"){
            ANRDataUtils.successAlert('');
            setVisibleAjout(true);
        }else{
            errorAlert('error', "VERIFIER VOS INFORMATION")
        }
        // await fetch(url.urlHttp + 'auth/login', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         username: username,
        //         password: password
        //     })
        // }).then((response) => response.json())
        //     .then((data) => {
        //         console.log(data.status);
        //         if (data.status === 401) {
        //             errorAlert('error', data.message)
        //         } else {
        //             if(data.data == 'Bad credentials'){
        //                 errorAlert('error', "VERIFIER VOS INFORMATION");
        //             }else{
        //                 ANRDataUtils.successAlert('');
        //                 setVisibleAjout(true);
        //             }
        //             // if (data.data.message) {
        //             //     errorAlert('error', data.message)
        //             // }

        //             // if (data.data) {
        //             //     ANRDataUtils.successAlert(data.data.message);
        //             //     <FormulaireCandidats />
        //             // } else {
        //             //     errorAlert('error', "VERIFIER VOS INFORMATION");
        //             // }
        //         }
        //     })
        //     .catch((error) => errorAlert('error', error))
    }

    if(visibleAjout == true){
       return (
        <>
          <FormulaireCandidats />
        </>
       );
    }else{
        return (
            <>
                <div className="content" style={{ alignItems: "center", paddingLeft: "300px", paddingTop: "100px", width: "100%", height: "200px" }}>
                    <Modal isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal} style={{ color: 'green', fontWeight:"bold", textAlign: 'center'}}>LOGIN</ModalHeader>
                        <ModalBody>
                            <Row>
                                <FormGroup className="md-12" style={{ left: "70px" }}>
                                    <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="username" className="form-control" style={{ width: "320px" }}></Input>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="md-12" style={{ left: "70px" }}>
                                    <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" className="form-control" style={{ width: "320px" }}></Input>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="md-12" style={{ left: "70px" }}>
                                    <Button className="btn btn-outline-primary" onClick={handlePass} style={{ width: "320px" }}>
                                        Login
                                    </Button>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn btn-outline-secondary" onClick={toggleModal} >
                                Annuler
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
    
                {/* </Col> */}
                {/* </Row>
                </Row>
                <Form >
                    <Row>
                    <Input type = "text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="username"></Input>
                    <br/>
                    <Input type = "password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password"></Input>
                    <Button className="btn btn-primary" onClick={handlePass}>
                        Login
                    </Button>
                    </Row>
                </Form>
                {/* </div> */}
    
            </>
        );
    }
}

export default Login;