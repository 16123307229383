import React, { useState } from "react";
import { Button, FormGroup, Input, Row, Col } from "reactstrap";
import url from "../../urlConfig";
import authHeader from "services/AuthHeader";

const ImportPhoto = ({ id_infra }) => {

  const auth_data = authHeader()
  const [photose, setPhotose] = useState(""); // Utilisez une chaîne vide pour stocker l'image Base64

  const handleImageUpload = (e) => {
    e.preventDefault();
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      // L'utilisateur a sélectionné une image
      const reader = new FileReader();

      reader.onload = (event) => {
        setPhotose(event.target.result); // Stockez l'image Base64 dans l'état
      };

      reader.readAsDataURL(selectedImage);
    } else {
      // L'utilisateur n'a pas sélectionné d'image (a effacé l'entrée)
      setPhotose(""); // Réinitialisez l'état à une chaîne vide
    }
  };
  // alert(photose)
  // const [toggle,setToggle]=useState(false)
  const Importer = async () => {
    if (photose) {
      const imageData = {
        photose: photose, // Utilisez directement l'image Base64 depuis l'état
        id_infra: id_infra,
      };

      await fetch(url.urlHttp + "quartier-general/import-sary", {
        method: 'PUT',
        headers: {
          'Content-Type': auth_data.content_type,
          'Authorization': auth_data.authorization
        },
        body: JSON.stringify(imageData),
      })
        .then(response => response.json())
        .then(data => {
            alert(JSON.stringify(data.data));
        })
        .catch(error => {
            console.log(error);
        });
    } else {
      alert("Sélectionnez une image à importer.");
    }
  };

  return (
    <Row>
      <Col>
        <Button
          className="btn btn-outline-primary"
          onClick={Importer}
        >
          <i className="fa fa-plus"></i> Enregistrer
        </Button>
        <FormGroup>
          <h2 className="text-primary" style={{ margin: "10px", fontSize: "17px" }}>
            PHOTO {id_infra}
          </h2>
          <Input
            type="file"
            id="photo"
            onChange={handleImageUpload}
          />
        </FormGroup>
      </Col>
      <Col>
        <div>
          {photose && (
            <img
              src={photose}
              alt={`Image ${id_infra}`}
              width="150px"
              height="auto"
              style={{ margin: "6px" }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ImportPhoto;
