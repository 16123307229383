import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";

import { CardBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';


import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';
import EtablissementC from './EtablissementC';
import SalleC from './SalleC';

const calculTotal = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const CommuneC = ({ region, district, province, setVisibleCommune }) => {
    const auth_data = authHeader()
    const [lists, setLists] = useState([]);
    const [communelist, setCommunelist] = useState([])
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra,setIsMpotra]=useState(false);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [t_nbr_parrain, sett_nbr_parrain] = useState(0)
    const [t_nbr_parraine, sett_nbr_parraine] = useState(0)
    const [t_nbr_membre, sett_nbr_membre] = useState(0)
    const [commune_d, setcommune_d] = useState(null)
    const [visibiliteEtablissement, setVisibiliteEtablissement] = useState(false);
    const [listss, setListss] = useState([]);
    const [totalEta, setTotalEta] = useState(0)
    const getListCommune = () => {
        fetch(url.urlHttp + "centre-milala/commune?district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setCommunelist(data.data);
                setLists(data.data);
                setTotal_additionel(calculTotal(data.data.map(row => row.nombre_candidat_1)))
                setCandidat_ecole_1(calculTotal(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotal(data.data.map(row => row.candidat_libre_1)))
                getsListTsisy(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListCommune()
    }, [])

    const stat = {
        sumCandidatLibre: communelist.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: communelist.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: communelist.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const details = (value) => {
        setcommune_d(value)
        setVisibiliteEtablissement(true)
        setVisibleEtablissement(true)
    }
    var totalCentre = 0;
    var totalSalle = 0;
    var totalCapacite = 0;
    var totalEffectif = 0;
    var totalElectricite = 0;
    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listMisy]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListMisy(sortedWords);
        } else {
            setMilahatra(true);
            setListMisy(listss);
        }
        console.log(milahatra);
    };

    const sortByDifferenceAndSet = () => {
        if (milahatra == true) {
            const sortedData = [...listMisy].sort((a, b) => {
                const differenceA = a.nbr_place_max - a.nombre_candidat;
                const differenceB = b.nbr_place_max - b.nombre_candidat;
                return differenceA - differenceB; // Sorting in ascending order, adjust as needed
            });

            setListMisy(sortedData);
            setMilahatra(false);
        } else {
            setListMisy(listss)
            setMilahatra(true);
        }
    }

    const filtre = (value) => {
        const filteredData = lists.filter(item => item.commune.toUpperCase().includes(value.toUpperCase()));
        setListMisy(filteredData);
        setListss(filteredData);
    }
    for (let index = 0; index < communelist.length; index++) {
        totalCentre += communelist[index].nombre_centre;
        totalSalle += communelist[index].nombre_salle;
        totalCapacite += communelist[index].nbr_place_max;
        totalEffectif += communelist[index].nombre_candidat;
        totalElectricite += communelist[index].electricite;
    }
    const calculTotals = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].nombre_candidat > 0) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListss(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }
    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }
    const [province_c, setProvince_c] = useState('');
    const [region_c, setRegion_c] = useState('');
    const [commune_c, setCommune_c] = useState('')
    const [etablissement_c, setEtablissement_c] = useState('')
    const [salle_c, setSalle_c] = useState('')
    const [distrcit_c, setDistrict_c] = useState('');
    const [modalPhotose, setModalPhotose] = useState(false);
    const togglePhotose = () => setModalPhotose(!modalPhotose);
    const [modalDistrict, setModalDistrict] = useState(false)
    const toggleDistrict = () => setModalDistrict(!modalDistrict);
    const [modalCommune, setModalCommune] = useState(false)
    const toggleCommune = () => setModalCommune(!modalCommune);
    const [modalEtablissement, setModalEtablissement] = useState(false)
    const toggleEtablissement = () => setModalEtablissement(!modalEtablissement);
    const [modalSalle, setModalSalle] = useState(false)
    const toggleSalle = () => setModalSalle(!modalSalle);
    const vider = () => {
        setCommune_c('')
        setDistrict_c('')
        setRegion_c('')
        setEtablissement_c('')
        setSalle_c('')
    }
    const openPhotoseModal = () => {
        // vider();
        togglePhotose();
    }
    const openDistrictModal = () => {
        toggleDistrict()
    }
    const openCommuneModal = () => {
        toggleCommune()
    }
    const openEtablissementModal = () => {
        toggleEtablissement()
    }
    const openSalleModal = () => {
        toggleSalle()
    }
    // const open
    const [etablissements, setEtablissements] = useState([])

    const [salles, setSalles] = useState([])


    const handleEtablissment = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune_c + "&&district=" + distrcit_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setEtablissements(data.data)
                setTotalEta(calculTotals(data.data.map(row => row.nombre_eta)))
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleSalle = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-salle?commune=" + commune_c + "&&district=" + distrcit_c + "&&centre_xamen=" + etablissement_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setSalles(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [visibleEtablissement, setVisibleEtablissement] = useState(false)
    const [visibleSalle, setVisibleSalle] = useState(false)
    const detailsCommune = () => {
        setVisibleCommune(true)
        toggleCommune()
    }
    const detailsEtablissement = () => {
        setVisibleEtablissement(true)
        setVisibiliteEtablissement(true)
        toggleEtablissement()
    }
    const detailsSalle = () => {

        setVisibleSalle(true)
        toggleSalle()
    }
    if (commune_d != null && visibiliteEtablissement == true && visibleEtablissement == true) {
        return (
            <>
                <EtablissementC setVisibiliteEtablissement={setVisibiliteEtablissement} commune={commune_d} district={district} province={province} region={region} />
            </>
        )
    } else if (visibleSalle == true && commune_c != '' && etablissement_c != '') {
        return (
            <SalleC setVisibleCandidat={setVisibleSalle} etablissement={etablissement_c} commune={commune_c} district={distrcit_c} province={province_c} region={region_c} />
        )
    }
    else {
        return (
            <>
                <div className="content">
                    <Row>
                    {
                        isShow == false && (
                            <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >

                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Nombre de Centres </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(listMisy.length)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Établissements d'Examen </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Nombre de  Salles  </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalSalle)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Capacité de Centres </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite)}</b></CardTitle>
                                    </Col>
                                </Card>
                                {/* </Row>
<Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            >Candidats Inscrits </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalEffectif)}</b></CardTitle>
                                        {isMpotra==true&&(<CardTitle tag="p"  style={{ fontSize: '1rem' ,color:'red'}}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                            > Places disponibles</p>
                                        </Row>
                                        <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite - totalEffectif)}</b></CardTitle>
                                    </Col>
                                </Card>
                                {/* <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        <div className="icon-big text-center icon-warning">
                                            <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                        </div>
                                        <p className="font-weight-bold" style={{ color: 'black',fontSize:'1.5rem' }}
                                        > Salle sans Électricité</p>
                                    </Row>
                                    <CardTitle tag="p" style={{ fontSize: '2rem',color:'green' }}><b>{ANRDataUtils.formatNombre(totalElectricite)}/{ANRDataUtils.formatNombre(totalSalle)}</b></CardTitle>
                                </Col>
                            </Card> */}
                            </Row>
                        )
                    }
                       {isShow==false&&( <Col className="pr-1" md="4">
                            <FormGroup>
                                <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Centre :</a></label>
                                <input
                                    type={'text'}
                                    style={{ fontSize: '0.9rem' }}
                                    className={'form-control'}
                                    placeholder={'rechercher centre'}
                                    onChange={e => filtre(e.target.value)}
                                />
                            </FormGroup> <br />
                        </Col>)}
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>

                                   {isShow==false&&( <CardHeader>
                                        <CardTitle style={{ fontSize: '20px' }}>
                                            <span style={{ color: "black", fontWeight: 'bold' }}>Capacité du District :
                                                <span style={{ color: "red", fontWeight: 'bold' }}> {district}</span><br/>
                                                <span style={{ color: "black", fontWeight: 'bold' }}> Région :
                                                    <span style={{ color: "red", fontWeight: 'bold' }}> {region} </span><br/>
                                                    <span style={{ color: "black", fontWeight: 'bold' }}> Province :
                                                        <span style={{ color: "red", fontWeight: 'bold' }}> {province} </span> </span>
                                                </span></span><br />
                                        </CardTitle>
                                    </CardHeader>)}<br />

                                    <Button className="btn btn-outline-primary " onClick={e => { setVisibleCommune(false) }}>retour</Button>
                                    <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                </Button>
                                    <Button className="btn btn-outline-primary" onClick={openEtablissementModal}>
                                        Afficher Établissements d'éxamen
                                    </Button>
                                    <Button className="btn btn-outline-primary" onClick={openSalleModal}>
                                        Afficher Salle
                                    </Button>
                                    <Button className="btn btn-outline-primary" onClick={voirPlus}>
                                        {isVoi ? "Fermer" : "Voir Plus"}
                                    </Button><br />
                                    {isShow==false &&(<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>


                                        <Table striped >
                                            <thead className="text-primary sticky-header">
                                                <tr>
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'commune')}>Centre &nbsp;<i class="fa fa-arrow-up"></i></th>
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nombre_centre')}>Nombre d' établissements d'examen &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nombre_salle')}>Nombre Total de salles &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nbr_place_max')}>Capacité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nombre_candidat')}>Candidats inscrits &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => sortByDifferenceAndSet()}> Places disponibles &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                                    {/* <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'electricite')}>Salle sans électricité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> */}
                                                    <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listMisy.map((row) => (
                                                        <>
                                                            <tr>
                                                                <td >
                                                                    {row.commune}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nombre_centre)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nombre_salle)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nbr_place_max)}
                                                                </td>
                                                                <td>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                {isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                    +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                                </span>)}
                                                                </td>    
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nbr_place_max - row.nombre_candidat)}
                                                                </td>
                                                                {/* <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.electricite)}
                                                                </td> */}
                                                                <td >
                                                                    {
                                                                        row.nombre_centre > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher Établissement</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Afficher Établissement</button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    ))}
                                                {
                                                    isVoi === true && (
                                                        <>
                                                            {
                                                                listTsymisy.map(row => (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                {row.commune}
                                                                            </td>
                                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                                {ANRDataUtils.formatNombre(row.nombre_centre)}
                                                                            </td>
                                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                                {ANRDataUtils.formatNombre(row.nombre_salle)}
                                                                            </td>
                                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                                {ANRDataUtils.formatNombre(row.nbr_place_max)}
                                                                            </td> <td>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                                <span style={{ color: 'red' }}>
                                                                                    +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                                                </span></td>
                                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                                {ANRDataUtils.formatNombre(row.nbr_place_max - row.nombre_candidat)}
                                                                            </td>
                                                                            {/* <td style={{ fontSize: '1.5rem' }}>
                                                                                {ANRDataUtils.formatNombre(row.electricite)}
                                                                            </td> */}
                                                                            <td >
                                                                                {
                                                                                    row.nombre_centre > 0 ?
                                                                                        <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher Établissement</button>
                                                                                        :
                                                                                        <button className={'btn btn-danger disabled'} >Afficher Établissement</button>
                                                                                }
                                                                            </td>

                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                    )

                                                }

                                            </tbody>
                                        </Table>
                                    </div>)}
                                    <Modal isOpen={modalEtablissement} toggle={toggleEtablissement} size="m">
                                        <ModalBody>

                                            <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                                Choisir  Centre<div className="mb-2">
                                                    <select
                                                        className="form-control"
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => {
                                                            setcommune_d(e.target.value)
                                                        }}
                                                    >
                                                        <option value=""></option>
                                                        {listMisy.map((label, index) => (
                                                            <option key={index} value={label.commune}>{label.commune}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </CardBody>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className='btn btn-prmary' onClick={detailsEtablissement}>
                                                Voir établissement
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={modalSalle} toggle={toggleSalle} size="m">
                                        <ModalBody>
                                            <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                                Choisir  Centre<div className="mb-2">
                                                    <select
                                                        className="form-control"
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => {
                                                            setCommune_c(e.target.value)
                                                        }}
                                                    >
                                                        <option value=""></option>
                                                        {listMisy.map((label, index) => (
                                                            <option key={index} value={label.commune}>{label.commune}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </CardBody>
                                            <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                                Choisir  Etablissement<div className="mb-2">
                                                    <select
                                                        className="form-control"
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => {
                                                            setEtablissement_c(e.target.value)
                                                        }}
                                                        onClick={handleEtablissment}
                                                    >
                                                        <option value=""></option>
                                                        {etablissements.map((label, index) => (
                                                            <option key={index} value={label.centre_examen}>{label.centre_examen}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </CardBody>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className='btn btn-prmary' onClick={detailsSalle}>
                                                Voir Salle
                                            </Button>
                                        </ModalFooter>
                                    </Modal>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

}
export default CommuneC;