import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Modal, ModalBody,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';
import authHeader from "../../services/AuthHeader";
import url from "../../urlConfig";
import ANRDataUtils from "../../utils/Utils";
import HistoGrapheFokotany from "./HistoGrapheFokotany";

import ComponentsChart from '../statistique/ComponentsChart';

// function 

const HistographeCommune = ({ setVisibleCommune, district, membres, electeur, region }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listCommune, setListCommune] = useState([]);
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([])
    const [nombre13, setNombre13] = useState([])
    const [total13, setTotal13] = useState(0)
    const [totalEta, setTotalEta] = useState(0)
    const [totalCentre, setTotalCentre] = useState(0)
    const [totalCandidat, setTotalCandidat] = useState(0)

    const [centre, setCentre] = useState([])
    const [candidat, setCandidat] = useState([])
    const [nombre_libre, setNombre_libre] = useState([])
    const [indice, setIndice] = useState(0)
    // const [totallibre, setTotalLibre] = useState(0)
    const detailCommune = (value) => {
        setCommune(value)
        setDistrict_d(district);
        setRegion_d(region)

        // navigate("/admin/programme-campagne")
    }
    const detailFokotany = (value) => {
        setDistrict_d(district);
        setRegion_d(region)
        setVisibleFokotany(true)
        setCommune(value)
    }

    const navigate = useNavigate();
    // const [visibleCommune,setVisibleCommune] = useState(false);
    const getListCommune = () => {
        fetch(url.urlHttp + "dashboard/district?region=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListCommune(data.data)
                setTotalCentre(calculTotal(data.data.map(row => row.nbr_place_max)))
                setTotalCandidat(calculTotal(data.data.map(row => row.nombre_candidat)))
                setTotalEcole(calculTotal(data.data.map(row => row.candidat_ecole)))
                setTotalLibre(calculTotal(data.data.map(row => row.candidat_libre)))
                setLabels(data.data.map(row => row.district))
                setCentre(data.data.map(row => row.nbr_place_max))
                setCandidat(data.data.map(row => row.candidat_ecole))
                setNombre_libre(data.data.map(row => row.candidat_libre))
                setTotalNombre_centre(calculTotal(data.data.map(row => row.nombre_centre)))
                setNombre_salle(calculTotal(data.data.map(row => row.nombre_salle)))
                setTotal_additionel(calculTotal(data.data.map(row => row.nombre_candidat_1)))
                setCandidat_ecole_1(calculTotal(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotal(data.data.map(row => row.candidat_libre_1)))
                setTotalEta(calculTotal(data.data.map(row => row.nombre_eta)))
                setLoader(false)
                setIndice(indice + 1)

            })
            .catch(error => {
                console.log(error);
            });
    }
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);
    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "12.5%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "12.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 2,
            fontSize: 9
        }
    });
    const [totalLibre, setTotalLibre] = useState(0)
    const [totalEcole, setTotalEcole] = useState(0)
    const calculPourcentage = (donnee, pourcentage) => {
        let a = 0;
        a = (donnee * pourcentage) / 100
        return Math.round(a);
    }
    const [pourcentage, setPourcentage] = useState(0);
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const [nombre_centre, setTotalNombre_centre] = useState(0)
    const [nombre_salle, setNombre_salle] = useState(0)
    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Au niveau  Région <Text style={{ color: 'red' }}></Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province :  <Text style={{ color: 'red' }}>{region}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Région : <Text style={{ color: 'red' }}>{district}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'black' }}>Au niveau : <Text style={{ color: 'red' }}>National</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre de centres : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalEta)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombre d'Établissement d'Examen : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(nombre_centre)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Nombres de salles : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(nombre_salle)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Capacité de Centres : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalCentre)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de Candidats : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalCandidat)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Candidats d'École : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalEcole)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Candidats Libres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(totalLibre)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    {/* <View><Text style={{ fontWeight: 'bold' }}>Liste Région <Text style={{ color: 'red' }}>{district}</Text> </Text></View> */}
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>District</Text>
                            </View>
                            {/* <View style={pdfStyles.tableColHeader}>
                            <Text style={pdfStyles.tableCell}>Nombre membre</Text>
                        </View> */}
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nombre de Centres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nombre d'Établissements d'Examen</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nombre de Salles</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Capacité de Centres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats d'École</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Candidats Libres</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
                            </View>
                        </View>
                        {
                            listCommune.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.district}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_eta)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_centre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_salle)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nbr_place_max)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_ecole)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.candidat_libre)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.nombre_candidat)}</Text>
                                    </View>
                                    {/* <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }

    const [district_d, setDistrict_d] = useState([]);
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Centre',
                backgroundColor: 'black',
                // borderColor: 'rgba(75, 192, 192, 1)',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                // hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBackgroundColor: '#000',
                // hoverBorderColor: 'rgba(75, 192, 192, 1)',
                hoverBorderColor: '#9A9A9A',
                data: centre,
            }
            ,
            {
                label: 'Candidat',
                backgroundColor: 'red',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                hoverBackgroundColor: '#000',
                hoverBorderColor: '#9A9A9A',
                data: candidat,
            }
        ]
    };
    const [visiblePlanii, setVisiblePlani] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [modalPhotose, setModalPhotose] = useState(false);
    const togglePhotose = () => setModalPhotose(!modalPhotose);
    const [search, setSearch] = useState(false);
    const [regionSelect, setRegionselect] = useState('');

    const handleSearch = () => {
        setSearch(!search);
    };
    const openPhotoseModal = () => {
        togglePhotose();
    };
    const [region_d, setRegion_d] = useState('');

    const [visibleFokotany, setVisibleFokotany] = useState(false);
    const [commune_d, setCommune] = useState('');
    /*const detailCommune = (value) => {
        console.log("Miata ", value)
        setCommune(value.split('/')[1])
        setDistrict_d(value.split('/')[0])
        setVisibleFokotany(true)
    }*/
    useEffect(() => {
        getListCommune()
    }, []);


    // if (visiblePlanii == true) {
    //     return (
    //         <>
    //             <Planification district_d1={district} region_d={region} />
    //         </>
    //     )
    // } else 
    if (visibleFokotany == true && commune_d != null) {
        return (
            <>
                <HistoGrapheFokotany commune={commune_d} setVisibleFokotany={setVisibleFokotany} district={district} region={region} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Affichez un message d'erreur s'il y a une erreur */}
                        {error && <div className="alert alert-danger">{error}</div>}
                        <Col md="12">
                            <Row>
                                <Col md="12">
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <Col md="12">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle style={{ fontSize: '30px' }}>
                                                    <span style={{ color: "green", fontWeight: 'bold' }}>Tableau de bord  de la Région:
                                                        <span style={{ color: "red", fontWeight: 'bold' }}> {district}</span> <span style={{ color: "green", fontWeight: 'bold' }}><br /> Province:<span style={{ color: "red", fontWeight: 'bold' }}> {region} </span> </span></span><br />
                                                </CardTitle>
                                            </CardHeader>

                                        </Card>
                                    </Col>
                                </Col>
                            </Row>
                            <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Centres </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Établissements d' Examen </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_centre)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de  Salles  </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" onClick={() => navigate("/admin/ListeCentre")}>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Capacité de Centres </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                                    </Col>
                                </Card>
                                {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCandidat)}</b></CardTitle>
                                        {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats Libres</p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalLibre)}</b></CardTitle>
                                        {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_libre_1)}</b></CardTitle>)}
                                    </Col>
                                </Card>
                                <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats d'École</p>
                                        </Row>
                                        <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEcole)}</b></CardTitle>
                                        {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_ecole_1)}</b></CardTitle>)}
                                    </Col>
                                </Card>
                            </Row>
                            <Row>
                                <Card>
                                    <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                                        Choisir District<div className="mb-2">
                                            <select
                                                className="form-control"
                                                style={{ width: '200px' }}
                                                onChange={(e) => { detailFokotany(e.target.value) }}
                                            >
                                                <option value=""></option>
                                                {labels.map((label, index) => (
                                                    <option key={index} value={label}>{label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </CardBody>
                                    <CardFooter className="d-flex justify-content-between">
                                        <Button className="btn btn-outline-primary btn-sm" onClick={e => setVisibleCommune(false)}>retour</Button>
                                        <Button className="btn btn-outline-primary btn-sm" onClick={() => openPhotoseModal()}>Afficher les données</Button>
                                        <Button className="btn btn-outline-primary" onClick={show}>
                                            {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <ComponentsChart id={'commune'} liste={listCommune} indice={indice} labels={labels} centre={centre} candidat={candidat} candidat_libre={nombre_libre} />
                                    </Card>
                                </Col>
                            </Row>
                            <Modal isOpen={modalPhotose} toggle={togglePhotose} size="xl">
                                <ModalHeader toggle={togglePhotose} style={{ color: "black", fontSize: "12px" }}>
                                    <CardHeader>
                                        <CardTitle style={{ fontSize: '30px' }}>
                                            <span style={{ color: "green", fontWeight: 'bold' }}>Tableau de bord de la Région:
                                                <span style={{ color: "red", fontWeight: 'bold' }}> {district}</span> <span style={{ color: "green", fontWeight: 'bold' }}><br /> Province:<span style={{ color: "red", fontWeight: 'bold' }}> {region} </span> </span></span><br />
                                        </CardTitle>
                                    </CardHeader>

                                </ModalHeader>
                                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de Centres </p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEta)}</b></CardTitle>
                                        </Col>
                                    </Card>
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Établissements d' Examen </p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_centre)}</b></CardTitle>
                                        </Col>
                                    </Card>
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5">
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Nombre de  Salles  </p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(nombre_salle)}</b></CardTitle>
                                        </Col>
                                    </Card>
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" onClick={() => navigate("/admin/ListeCentre")}>
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Capacité de Centres </p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCentre)}</b></CardTitle>
                                        </Col>
                                    </Card>
                                    {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}>Total de  Candidats </p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalCandidat)}</b></CardTitle>
                                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                                        </Col>
                                    </Card>
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats Libres</p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalLibre)}</b></CardTitle>
                                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_libre_1)}</b></CardTitle>)}
                                        </Col>
                                    </Card>
                                    <Card style={{ padding: '10px' }} lg="2" md="5" sm="5" >
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <div className="icon-big text-center icon-warning">
                                                    <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                                </div>
                                                <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}> Candidats d'École</p>
                                            </Row>
                                            <CardTitle tag="p" style={{ color: 'green', fontSize: '2rem' }}><b>{ANRDataUtils.formatNombre(totalEcole)}</b></CardTitle>
                                            {isMpotra == true && (<CardTitle tag="p" style={{ fontSize: '1.5rem', color: 'red' }}>+<b>{ANRDataUtils.formatNombre(candidat_ecole_1)}</b></CardTitle>)}
                                        </Col>
                                    </Card>
                                </Row>
                                <ModalBody>
                                    <PDFDownloadLink className="btn btn-primary fa fa-file" document={<DistrictPDF />} fileName={"National"}>
                                        {({ blob, url, loading, error }) =>
                                            loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                        }
                                    </PDFDownloadLink>
                                    <Button className="btn btn-outline-primary" onClick={show}>
                                        {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                                    </Button>
                                    <Table striped>
                                        <thead className="text-primary" >
                                            <tr >
                                                <th>District </th>
                                                {/* <th>nombre membres</th> */}
                                                <th>Nombre de centres </th>
                                                <th>Nombre d'Établissement d'Examen </th>
                                                <th>Nombre de salles</th>
                                                <th>Capacité de Centres</th>
                                                {/* <th>Resultat 2018</th> */}
                                                <th >
                                                    Candidats Libres
                                                </th>
                                                <th >
                                                    Candidats d'école
                                                </th>
                                                <th >
                                                    Total de candidats
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listCommune.map(row => (
                                                    <tr>
                                                        <td>{row.district}</td>
                                                        {/* <td>{ANRDataUtils.formatNombre(row.nombre_membre)}</td> */}
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_eta)}</td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_centre)}</td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_salle)}</td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nbr_place_max)}</td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_libre)}<br />
                                                            {isMpotra == true && (<span style={{ color: 'red' }}>
                                                                +{ANRDataUtils.formatNombre(row.candidat_libre_1)}
                                                            </span>)}
                                                        </td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.candidat_ecole)}<br />
                                                            {isMpotra == true && (<span style={{ color: 'red' }}>
                                                                +{ANRDataUtils.formatNombre(row.candidat_ecole_1)}
                                                            </span>)}
                                                        </td>
                                                        <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                            {isMpotra == true && (<span style={{ color: 'red' }}>
                                                                +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                            </span>)}
                                                        </td>
                                                        {/* <td >{ANRDataUtils.formatNombre(row.nombre_13)}</td> */}
                                                        {/* <td><button className={'btn btn-danger'} onClick={e=>{details(row)}}> afficher plus</button></td> */}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </ModalBody>
                            </Modal>
                        </Col>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default HistographeCommune;
