import * as htmlToImage from "html-to-image";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from 'react';
import QRCode from "react-qr-code";
import { Button, Card, CardBody, CardTitle, Row, Table } from "reactstrap";
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
const QrCode = ({ setVisible_page, title, types }) => {

    const auth_data = authHeader()
    const [list, setList] = useState([]);
    const getProvinces = () => {
        fetch(url.urlHttp + "centre-milala/province", {
            method: "get",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        }).then(response => response.json())
            .then((res) => {
                setList(res.data);
            })
            .catch(error => { console.log(error) })
    }
    const [communes, setCommunes] = useState([]);
    useEffect(() => {
        getProvinces();
    }, [])
    const captureRef = useRef(null);
    const handleCaptureClick = async () => {
        const inputData=captureRef.current;
        // if (captureRef.current) {

            console.log('Element found:',inputData);
            try {
                const date=new Date().toISOString();
                console.log('isaiaiai betay bola',date);
                const canvas = await html2canvas(inputData);
                const imgData = canvas.toDataURL('image/jpeg',0.75);
                const link = document.createElement('a');
                const pdf=new jsPDF({
                    orientation:'landscape',
                    unit:'px',
                    format:'a4'
                })
                const width=pdf.internal.pageSize.getWidth()
                const height=(canvas.height*width)/canvas.width
                link.href = imgData;
                if(types==1){
                    link.download = `${etablissement_c}-urne-${date}.png`;
                }else{
                    link.download = `${etablissement_c}-salle-${salle_c}-enveloppe-${matiere_c}-${date}.png`;
                }
                
                link.click();
            } catch (error) {
                console.error('Error capturing the screenshot:', error);
            }
        // } else {
        //     console.error('Invalid element reference');
        // }
    };
    const [etablissements, setEtablissements] = useState([])
    const [districts, setDistricts] = useState([]);
    const [totalEta, setTotalEta] = useState(0)
    const [regions, setRegions] = useState([]);
    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [province_c, setProvince_c] = useState('');
    const [region_c, setRegion_c] = useState('');
    const [commune_c, setCommune_c] = useState('')
    const [etablissement_c, setEtablissement_c] = useState('')
    const [salle_c, setSalle_c] = useState('')
    const [distrcit_c, setDistrict_c] = useState('');

    const handleDistrict = () => {
        // console.log(url.urlHttp + "tanana/district?region=" + province_c + "&&district=" + region);
        fetch(url.urlHttp + "tanana/district?region=" + region_c + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleCommune = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/commune?district=" + distrcit_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleEtablissment = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune_c + "&&district=" + distrcit_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                // setcode(data.data[0].code_commune+data.data[0].centre_examen)
                setEtablissements(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [matiere_c, setMatiere_c] = useState('')
    const [matieres, setMatieres] = useState([])
    const [visibleDetail, setVisibleDetail] = useState(false)
    const valider = () => {
        // alert('isaiaiaiaiai'+province_c+region_c+distrcit_c+commune_c+etablissement_c+salle_c+matiere_c)
        if (types == 0) {
            if (province_c != '' && region_c != '' && distrcit_c != '' && commune_c != '' && etablissement_c != '' && salle_c != '' && matiere_c != '') {
                getListEtablissement()
                setVisibleDetail(true)
                for (let index = 0; index < salles.length; index++) {
                    if(salles[index].salle==salle_c){
                        setcode(salles[index].code_centre+salle_c+'{'+matiere_c)
                    }
                } 
                // setcode(setcode(`${province_c}/${region_c}/${distrcit_c}/${commune_c}/${etablissement_c}/${salle_c}/${matiere_c}`))
            }
        } else {
            if (province_c != '' && region_c != '' && distrcit_c != '' && commune_c != '' && etablissement_c != '') {
                setVisibleDetail(true)
                for (let index = 0; index < etablissements.length; index++) {
                    if(etablissements[index].centre_examen==etablissement_c){
                        setcode(etablissements[index].code_centre)
                    }
                } 
            }
        }
    }
    var listMa = ['Mathematique', 'Francais', 'Malagasy','Histoire-Géographie','Anglais','Mécanique RDM','Economie','Science','Appliquées'
    ,'Philosophie','Langues Vivantes','Téchnologie Générale','Mathématiques Financières','Technologie','Maths Financières','',
    'Sciences Physiques','Sciences Naturelles','Sciences Economiques et Sociales'];
    const [salles, setSalles] = useState([])
    const handleSalle = () => {
        // console.log(url.urlHttp + "tanana/commune-liste?region=" + province + "&&district=" + region + "&&commune=" + district);
        fetch(url.urlHttp + "centre-milala/liste-salle?commune=" + commune_c + "&&district=" + distrcit_c + "&&centre_xamen=" + etablissement_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setSalles(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [nombre_isa, setNombre_isa] = useState(0)
    const getListEtablissement = () => {
        fetch(url.urlHttp + "centre-milala/liste-candidat?salle=" + salle_c + "&&centre_xamen=" + etablissement_c + "&&commune=" + commune_c, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : poru", data.data);
                getPersonne(data.data)
                // setcode(data.data[0].code_commune+data.data[0].centre_examen+data.data[0].salle)
                setNombre_isa(data.data.length)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const [code, setcode] = useState('')
    const [serie, setSerie] = useState([])
    const getPersonne = (candidat) => {
        const statusCount = candidat.reduce((acc, person) => {
            const status = person.serie_option;
            if (!acc[status]) {
                acc[status] = 0;
            }
            acc[status]++;
            return acc;
        }, {});

        setSerie(Object.keys(statusCount).map(status => ({
            status: status,
            nombre: statusCount[status]
        })));
    }
    const qrCodeRef = useRef(null);
    const downloadQRCode = () => {
        htmlToImage
            .toPng(qrCodeRef.current)
            .then(function (dataUrl) {
                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "qr-code.png";
                link.click();
            })
            .catch(function (error) {
                console.error("Error generating QR code:", error);
            });
    }
    return (
        <div className="content">
            <Card style={{ height: "100%" }}>
                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ color: "black", fontSize: '1rem' }}>
                        <a style={{ color: "green", fontSize: '2rem', fontWeight: 'bold' }}>{title}</a>
                    </span>
                </CardTitle>
            </Card>
            <Button style={{ height: "50%" }} className="btn btn-outline-primary " onClick={(e) => { setVisible_page(false) }} >Retour </Button>
            {visibleDetail == false && (<Card>
                <Card size="m">
                    {/* <ModalBody> */}
                    <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                        Choisir  Province<div className="mb-2">
                            <select
                                className="form-control"
                                style={{ width: '50%' }}
                                onChange={(e) => {
                                    setProvince_c(e.target.value)
                                }}
                                onClick={handleRegion}
                            >
                                <option value=""></option>
                                {list.map((label, index) => (
                                    <option key={index} value={label.province}>{label.province}</option>
                                ))}
                            </select>
                        </div>
                    </CardBody>
                    <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                        Choisir  Région<div className="mb-2">
                            <select
                                className="form-control"
                                style={{ width: '50%' }}
                                onChange={(e) => {
                                    setRegion_c(e.target.value)
                                }}
                                onClick={handleDistrict}
                            >
                                <option value=""></option>
                                {regions.map((label, index) => (
                                    <option key={index} value={label.region}>{label.region}</option>
                                ))}
                            </select>
                        </div>
                    </CardBody>
                    <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                        Choisir  District<div className="mb-2">
                            <select
                                className="form-control"
                                style={{ width: '50%' }}
                                onChange={(e) => {
                                    setDistrict_c(e.target.value)
                                }}
                                onClick={handleCommune}
                            >
                                <option value=""></option>
                                {districts.map((label, index) => (
                                    <option key={index} value={label.district}>{label.district}</option>
                                ))}
                            </select>
                        </div>
                    </CardBody>
                    <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                        Choisir  Centre<div className="mb-2">
                            <select
                                className="form-control"
                                style={{ width: '50%' }}
                                onChange={(e) => {
                                    setCommune_c(e.target.value)
                                }}
                            >
                                <option value=""></option>
                                {communes.map((label, index) => (
                                    <option key={index} value={label.commune}>{label.commune}</option>
                                ))}
                            </select>
                        </div>
                    </CardBody>
                    <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                        Choisir  Etablissement<div className="mb-2">
                            <select
                                className="form-control"
                                style={{ width: '50%' }}
                                onChange={(e) => {
                                    setEtablissement_c(e.target.value)
                                }}
                                onClick={handleEtablissment}
                            >
                                <option value=""></option>
                                {etablissements.map((label, index) => (
                                    <option key={index} value={label.centre_examen}>{label.centre_examen}</option>
                                ))}
                            </select>
                        </div>
                    </CardBody>
                    {types == 0 && (<>
                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                            Choisir  Salle<div className="mb-2">
                                <select
                                    className="form-control"
                                    style={{ width: '50%' }}
                                    onChange={(e) => {
                                        setSalle_c(e.target.value)
                                        getListEtablissement();
                                    }}
                                    onClick={handleSalle}
                                >
                                    <option value=""></option>
                                    {salles.map((label, index) => (
                                        <option key={index} value={label.salle}>{label.salle}</option>
                                    ))}
                                </select>
                            </div>
                        </CardBody>
                        <CardBody style={{ fontSize: '17px', fontWeight: "bold", color: "black" }}>
                            Choisir  Matiere<div className="mb-2">
                                <select
                                    className="form-control"
                                    style={{ width: '50%' }}
                                    onChange={(e) => {
                                        setMatiere_c(e.target.value)
                                    }}
                                >
                                    <option value=""></option>
                                    {listMa.map((label, index) => (
                                        <option key={index} value={label}>{label}</option>
                                    ))}
                                </select>
                            </div>
                        </CardBody>
                    </>)}
                    <Button className='btn btn-prmary' onClick={valider} style={{ width: '30%' }} >
                        Générer la code QR
                    </Button>
                </Card>
            </Card>)}
            {visibleDetail == true && (
                <>
                    <div ref={captureRef} style={{ gap: '12px', padding: '10px', margin: '0px'}}>
                    <Row  md='12' style={{ gap: '12px', padding: '10px', margin: '0px'}} className="justify-content-center">
                        <Card lg="6" md="5" sm="5" style={{ padding: '20px' }}>
                            <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Province:&nbsp;&nbsp;
                                <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{province_c}</span>
                            </span><br />
                            <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Région:&nbsp;&nbsp;
                                <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{region_c}</span>
                            </span><br />
                            <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > District:&nbsp;&nbsp;
                                <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{distrcit_c}</span>
                            </span><br />
                            <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Centre:&nbsp;&nbsp;
                                <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{commune_c}</span>
                            </span><br />
                            <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Etablissement:&nbsp;&nbsp;
                                <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{etablissement_c}</span>
                            </span><br />
                            {types == 0 && (<>
                                <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Salle:&nbsp;&nbsp;
                                    <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{salle_c}</span>
                                </span><br />
                                <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Matiere:&nbsp;&nbsp;
                                    <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{matiere_c}</span>
                                </span>
                                <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} >Nombre Candidat:&nbsp;&nbsp;
                                    <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "red" }} >{nombre_isa}</span>
                                </span>
                            </>)}
                            <span style={{ fontSize: '1rem', fontWeight: "bold", color: "black" }} > Code QR:&nbsp;&nbsp;
                                <span style={{ fontSize: '1.5rem', fontWeight: "bold", color: "green" }} >{code}</span>
                            </span><br />
                        </Card>
                        {types == 0 && (<Card lg="4" md="5" sm="5" style={{ padding: '20px' }}>
                            {/* style={{ padding: '5px 5px 0px 5px', width: '100%', height:'90%' }} */}
                            <CardTitle>
                                <Table style={{border:1}}>
                                    <thead  className="text-primary sticky-header">
                                        <tr>
                                            <th style={{ fontSize: '2rempx' }}>Serie</th>
                                            <th style={{ fontSize: '2rempx' }}>Nombre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            serie.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: '2rempx' }}>{row.status}</td>
                                                    <td style={{ fontSize: '2rempx' }}>{row.nombre}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </CardTitle>
                        </Card>)}
                        <Card lg="2" md="5" sm="5" style={{ padding: '20px' }}>
                            <div className="qrcode__container">
                                <div className="qrcode__download">
                                    <div className="qrcode__image">
                                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                                            <QRCode
                                                size={500}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={code} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Row>
                    </div>
                    <Button onClick={handleCaptureClick} className="btn btn primary key"> Telecharger</Button>
                </>
            )}
        </div>
    );
}


export default QrCode;