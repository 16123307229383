import { React, useState, useEffect } from "react";
import url from "urlConfig";
import { Button, Card, CardHeader, CardTitle, Col, Row, Table, CardBody, FormGroup } from 'reactstrap';
import authHeader from 'services/AuthHeader';


const Prefecture = () => {
    const auth_data = authHeader();
    const [prefectures, setPrefectures] = useState([]);
    const [prefecturess, setPrefecturess] = useState([])

    const listePrefecture = () => {
        fetch(
            url.urlHttp + 'prefecture/liste', {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        }
        ).then(response => response.json())
            .then(data => {
                setPrefectures(data.data);
                setPrefecturess(data.data);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        listePrefecture();
    }, [])

    const filtre = (value, type) => {
        var filteredData = prefecturess;
        if(type == "prefecture"){
            filteredData = prefecturess.filter(item => item.prefecture.toUpperCase().includes(value.toUpperCase()));
        }else if(type == "prefet"){
            filteredData = prefecturess.filter(item => item.prefet.toUpperCase().includes(value.toUpperCase()));
        }
        setPrefectures(filteredData)
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...data_etablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setData_etablissement(sortedWords);
        } else {
            setMilahatra(true);
            setData_etablissement(listss);
        }
    };

    return (
        <div className="content">
            <Row>
                <Col className="pr-1" md="4"><br />
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>PREFECTURE :</a></label>
                        <input
                            type={'text'}
                            className={'form-control'}
                            placeholder={'rechercher prefecture'}
                            onChange={e => filtre(e.target.value, "prefecture")}
                        />
                    </FormGroup>
                </Col>
                <Col className="pr-1" md="4"><br />
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>PREFET :</a></label>
                        <input
                            type={'text'}
                            className={'form-control'}
                            placeholder={'rechercher prefet'}
                            onChange={e => filtre(e.target.value, "prefet")}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <div className="table-container" style={{ maxHeight: "700px", overflowY: "auto", direction: "" }}>
                <Table striped>
                    <thead>
                        <tr>
                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'prefecture')}>PREFECTURE&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'prefet')}>PREFET&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'contact')}>CONTACT&nbsp;<i class="fa fa-arrow-up-0-9"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {
                                prefectures && prefectures.map(row => (
                                    <tr>
                                        <td style={{ fontSize: '1.2rem' }}>{row.prefecture}</td>
                                        <td style={{ fontSize: '1.2rem' }}>{row.prefet}</td>
                                        <td style={{ fontSize: '1.2rem' }}>{row.contact}</td>
                                    </tr>
                                ))
                            }
                        </>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
export default Prefecture;