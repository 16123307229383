import React, { useState, useEffect } from 'react';
import './Calendar.css';
import { Table } from "reactstrap";

const Calendar = ({ scheduleData }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [days, setDays] = useState([]);
  
  useEffect(() => {
    const firstEventDate = getFirstEventDate(scheduleData);
    if (firstEventDate) {
      setCurrentYear(firstEventDate.getFullYear());
      setCurrentMonth(firstEventDate.getMonth());
    }
  }, [scheduleData]);

  useEffect(() => {
    generateCalendar(currentYear, currentMonth);
  }, [currentYear, currentMonth, scheduleData]);

  const getFirstEventDate = (events) => {
    if (events.length === 0) return null;
    const sortedEvents = [...events].sort((a, b) => new Date(a.date_matiere) - new Date(b.date_matiere));
    return new Date(sortedEvents[0].date_matiere);
  }

  const generateCalendar = (year, month) => {
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month + 1, 0);
    const startDay = startOfMonth.getDay();
    const endDay = endOfMonth.getDate();

    const calendarDays = [];

    // Add empty days for previous month
    for (let i = 0; i < startDay; i++) {
      calendarDays.push({ day: null, morningEvents: [], eveningEvents: [] });
    }

    // Add days of the current month
    for (let i = 1; i <= endDay; i++) {
      const eventsForDay = scheduleData.filter(event => {
        const eventDate = new Date(event.date_matiere);
        return (
          eventDate.getFullYear() === year &&
          eventDate.getMonth() === month &&
          eventDate.getDate() === i
        );
      });

      const morningEvents = [];
      const eveningEvents = [];

      eventsForDay.forEach(event => {
        const hour = parseInt(event.heure_debut.split(':')[0], 10);
        if (hour < 12) {
          morningEvents.push({
            matiere: event.matiere,
            heure: `${event.heure_debut} - ${event.heure_fin}`
          });
        } else {
          eveningEvents.push({
            matiere: event.matiere,
            heure: `${event.heure_debut} - ${event.heure_fin}`
          });
        }
      });

      calendarDays.push({
        day: i,
        morningEvents,
        eveningEvents
      });
    }

    // Add empty days for next month
    const totalDays = calendarDays.length;
    const additionalDays = totalDays % 7 !== 0 ? 7 - (totalDays % 7) : 0;
    for (let i = 0; i < additionalDays; i++) {
      calendarDays.push({ day: null, morningEvents: [], eveningEvents: [] });
    }

    setDays(calendarDays);
  };

  const formatTimeRange = (timeRange) => {
    const [start, end] = timeRange.split(' - ');
    const [startHour, startMinute] = start.split(':');
    const [endHour, endMinute] = end.split(':');

    return `${startHour}h${startMinute} à ${endHour}h${endMinute}`;
  }

  const handleYearChange = (event) => {
    setCurrentYear(parseInt(event.target.value, 10));
  };

  const handleMonthChange = (event) => {
    setCurrentMonth(parseInt(event.target.value, 10));
  };

  return (
    <div className="calendar">
      <div className="calendar-header">
        <select value={currentYear} onChange={handleYearChange}>
          {Array.from({ length: 10 }, (_, i) => currentYear - 5 + i).map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <select value={currentMonth} onChange={handleMonthChange}>
          {Array.from({ length: 12 }, (_, i) => i).map(month => (
            <option key={month} value={month}>
              {new Date(0, month).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
      </div>
      <Table striped responsive>
        <thead className="text-primary sticky-header">
          <tr>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Dim.</th>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Lun.</th>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Mar.</th>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Mer.</th>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Jeu.</th>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Ven.</th>
            <th style={{ fontSize: '13px', width: '14.28%' }}>Sam.</th>
          </tr>
        </thead>
        <tbody>
          {Array(Math.ceil(days.length / 7)).fill().map((_, rowIndex) => (
            <tr key={rowIndex}>
              {days.slice(rowIndex * 7, (rowIndex + 1) * 7).map((day, cellIndex) => (
                <td key={cellIndex} className="calendar-cell" style={{ height: '150px', verticalAlign: 'top' }}>
                  {day.day ? (
                    <>
                      <div className="day-number" style={{ borderBottom: '1px solid black', backgroundColor: '#f0b9b9', height: '30px' }}>
                        <a style={{ color: 'black', display: 'block', lineHeight: '30px' }}>{day.day}</a>
                      </div>
                      <div className="events">
                        <div className="morning-events" style={{ height: '100%', width: '100%', borderBottom: '1px solid black' }}>
                          {day.morningEvents && day.morningEvents.map((event, index) => (
                            <div key={index} className="event" style={{backgroundColor:'cyan'}}>
                              <div className="subject"><a style={{ color: 'black' }}>{event.matiere}</a></div>
                              <div className="time"><a style={{ color: 'black' }}>{formatTimeRange(event.heure)}</a></div>
                            </div>
                          ))}
                        </div>
                        <div className="evening-events" style={{ height: '100%', width: '100%' }}>
                          {day.eveningEvents && day.eveningEvents.map((event, index) => (
                            <div key={index} className="event" style={{backgroundColor:'yellow'}}>
                              <div className="subject"><a style={{ color: 'black' }}>{event.matiere}</a></div>
                              <div className="time"><a style={{ color: 'black' }}>{formatTimeRange(event.heure)}</a></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="empty-day"></div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Calendar;
