import React, { useState, useEffect, useRef, useCallback } from 'react';
import ChoixFokontany from "./ChoixFokontany";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    FormGroup,
    Form,
    Row,
    Input,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap";
import url from "../../urlConfig";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import PhotoIdt from '../../assets/img/PhotoIdt.avif'
import authHeader from 'services/AuthHeader';

const AlertSwal = withReactContent(Swal);

const NouveauMembre = () => {

    const auth_data = authHeader()
    const [regionList, setRegionList] = useState([]);
    const [commune, setCommune] = useState('')
    const [district, setdistrict] = useState('')
    const [region, setRegion] = useState('')
    const [fokotany, setFokotany] = useState('');
    const [informationBase, setInformationBase] = useState(true)
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [cin, setCin] = useState('')
    const [id_parraine, setid_parraine] = useState(0);
    const [contact, setContact] = useState('')
    const [dateNaissance, setDateNaissance] = useState('')
    const [adresse, setAdresse] = useState('')
    const [photo, setPhoto] = useState('')
    const [listMembre, setlistMembre] = useState([])
    const [cinParent, setcinParent] = useState(0);
    const [suggestionCinParent, setsuggestionCinParent] = useState([])
    const [dateLivraison, setdateLivraison] = useState('')
    const [lieu, setLieu] = useState('')
    const [categorie, setcategorie] = useState('');
    const [nombre_membre, setnombre_membre] = useState(1)

    const [numero_recepisse, setnumero_recepisse] = useState('');
    const [lieu_naissance, setlieu_naissance] = useState('')
    const [genre, setGenre]=useState('');
    const [email, setEmail] = useState('')
    const [situation_matrimoniale, setsituation_matrimoniale] = useState('')
    const [profession, setprofession] = useState('')
    const [diplome, setdiplome] = useState('')
    const [nom_pere, setnom_pere] = useState('')
    const [nom_mere, setnom_mere] = useState('')
    const [is_candidat, setis_candidat] = useState('')
    const [parti_politique_candidat_avant, setparti_politique_candidat_avant] = useState('')
    const [resultat_election_avant, setresultat_election_avant] = useState('')
    const [raison_demande_inscription, setraison_demande_inscription] = useState('')
    const [lieu_travail, setlieu_travail] = useState('')
    const [domaine_etude, setdomaine_etude] = useState('')
    const [dons_perso, setdons_perso] = useState('')
    const [ancien_parti_politique, setancien_parti_politique] = useState('')
    const [responsabilite, setresponsabilite] = useState('')
    const [attente, setattente] = useState('')
    const [contribution, setcontribution] = useState('')
    const [manager_fokotany, setmanager_fokotany] = useState('')
    const [manager_departement_tgv, setmanager_departement_tgv] = useState('')
    const [nom_complet_garant, setnom_complet_garant] = useState('')
    const [responsabilite_garant, setresponsabilite_garant] = useState('')
    const [numero_fiche_garant, setnumero_fiche_garant] = useState('')
    const [date_signature_garant, setdate_signature_garant] = useState('')
    const [numero_fiche, setnumero_fiche] = useState(0)
    const [province, setprovince] = useState('')
    const [parti_politique_candidat, setparti_politique_candidat] = useState('')
    const [plus, setPlus] = useState(false)
    const [affiche, setAffiche] = useState('plus');

    const handlePlus = () => {
        if (plus == false) {
            setPlus(true)
            setAffiche('reduire')
        } else {
            setPlus(false)
            setAffiche('plus')

        }
    }
    function handleTakePhoto(dataUri) {
        const convertImageToBase64 = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const dataURL = canvas.toDataURL('image/jpeg'); // Vous pouvez spécifier le format ici (jpeg, png, etc.)
                    resolve(dataURL);
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = url;
            });
        };

        convertImageToBase64(dataUri)
            .then((base64Image) => {
                // base64Image est maintenant une chaîne (string) représentant l'image Base64
                console.log(base64Image);
                setPhoto(base64Image)
                toggle()
            })
            .catch((error) => {
                console.error('Erreur lors de la conversion de l\'image en Base64 :', error);
            });
    }


    const getListMembre = () => {
        fetch(url.urlHttp + "membre/liste-global", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setlistMembre(data.data)
            })
            .catch(error => {
                console.log(error);
            });

    }

    const successAlert = (args) => {
        Swal.fire(
            'Success !',
            args,
            'success'
        )
    }

    const errorAlert = (args) => {
        Swal.fire(
            'Erreur !',
            args,
            'error'
        )
    }

    /******** Recherche Parrain **********/
    const [parent, setParent]=useState([]);
    useEffect(()=>{
        fetch(url.urlHttp + "search/memberBy?cin="+cinParent, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
        })
            .then(response => response.json())
            .then(data => {
                setParent(data.data)
                console.log(' PARENT '+ data.data);
            })
            .catch(error => {
                console.log(error);
            });
            
    }, [cinParent])
    const ajout = () => {
        let a = '';
        if (genre !== null) {
            a = genre
        }
        const data = {
            region: region,
            district: district,
            commune: commune,
            fokotany: fokotany,
            categorie: categorie,
            numero_recepisse: numero_recepisse,
            nom: nom,
            prenom: prenom,
            date_naissance: dateNaissance,
            lieu_naissance: lieu_naissance,
            numero_cin: cin,
            date_delivrance: dateLivraison,
            lieu_delivrance: lieu,
            genre: a,
            addresse: adresse,
            telephone: contact,
            email: email,
            nombre_membre: nombre_membre,
            situation_matrimoniale: situation_matrimoniale,
            profession: profession,
            diplome: diplome,
            nom_pere: nom_pere,
            nom_mere: nom_mere,
            is_candidat: is_candidat,
            parti_politique_candidat_avant: parti_politique_candidat_avant,
            resultat_election_avant: resultat_election_avant,
            raison_demande_inscription: raison_demande_inscription,
            lieu_travail: lieu_travail,
            domaine_etude: domaine_etude,
            dons_perso: dons_perso,
            ancien_parti_politique: ancien_parti_politique,
            responsabilite: responsabilite,
            attente: attente,
            contribution: contribution,
            manager_fokotany: manager_fokotany,
            manager_departement_tgv: manager_departement_tgv,
            nom_complet_garant: nom_complet_garant,
            responsabilite_garant: responsabilite_garant,
            numero_fiche_garant: numero_fiche_garant,
            date_signature_garant: date_signature_garant,
            numero_fiche: numero_fiche,
            province: province,
            photo: photo,
            id_parraine: id_parraine,
            parti_politique_candidat: parti_politique_candidat
        }
        
        fetch(url.urlHttp+ "membre/nouveauf", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
            
                successAlert(data.data);
                setAdresse('');
                setAffiche('');
                setCin('');
                setPlus(false);
                setEmail('');
                setGenre('');
                setPhoto('');
                setLieu('');
                setNom('');
                setPrenom('');
                setcategorie('');
                setdateLivraison('');
                setContact('');
                setAdresse('');
                setcinParent(0);
                
            })
            .catch(error => {
                console.log(error);
            });
    }
    const [loader, setLoader] = useState(false);

    const handleChangeCin = (value) => {
        setcinParent(value)
        console.log(value)
        const filteredSuggestions = listMembre?.filter(row =>
            row.nom.toLowerCase().includes(value.toLowerCase())
        );
        setsuggestionCinParent(filteredSuggestions);
    }

    const handleSelectCIN = (value) => {
        setcinParent(value.nom + value.prenom)
        setid_parraine(value.id_membre)
        setsuggestionCinParent([])
    }
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const getListRegion = () => {
        fetch(url.urlHttp + "population/region-all", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data);
                setRegionList(data); // Met à jour l'état avec les données reçues
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getListRegion();
        getListMembre();
    }, [])
    useEffect(()=>{
        setGenre(genre);
        console.log(genre);
    },[genre])

    return (
        <>
            <div className="content">
                <Modal isOpen={modal} toggle={toggle} size='xl'>
                    <ModalHeader toggle={toggle}>Prendre photo</ModalHeader>
                    <ModalBody>
                        <div>
                            <Camera
                                idealFacingMode={FACING_MODES.USER}
                                idealResolution={{ width: 640, height: 480 }}
                                imageType={IMAGE_TYPES.JPG}
                                imageCompression={0.97}
                                onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Quitter
                        </Button>
                    </ModalFooter>
                </Modal>
                <Row>

                    <Col lg="18">
                        <Card className="card-user">
                            <CardHeader>
                                <Row>
                                    
                                    <Col>
                                        <CardTitle tag="h4" className='title'>Ajouter un Membre</CardTitle>
                                    </Col>
                                    <Col>
                                    <div className='d-flex justify-content-end' >
                                        <div className='d-flex justify-content-end' style={{ width: '10rem', height: '12rem', border: '2px solid #ced4da', borderRadius: '4px', position: 'relative', marginRight:'2rem' }}>
                                            <img src={PhotoIdt} alt='iconElecteur' className="img-responsive mb-3" style={{ width: '100%', height: '100%', background: '#ced4da', borderRadius: '2px', filter: 'blur(0.5px)' }} />
                                            <button onClick={toggle} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1', background: 'rgba(206, 212, 218, 0.4)' }}>
                                                <i className="fa fa-user btn btn-outline-primary btn-round" style={{ fontSize: '1rem', color: '#343a40' }}>+  Ajouter une photo </i>
                                            </button>

                                        </div>
                                        {
                                            photo.length > 0 && (
                                                <div>
                                                    <img src={photo} alt="ImageCapturée" width={'20%'} height={'20%'} style={{ display: 'block', margin: '2rem auto' }} />
                                                </div>
                                            )
                                        }
                                        </div>

                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Col className="pl-1" md="8">


                                    <button className="btn btn-outline-primary" onClick={e => { ajout() }}>
                                        <i className="fa fa-plus"></i> Ajouter 
                                    </button>
                                    <button className="btn btn-outline-danger btn-round" onClick={e => { window.location.reload() }}>
                                        <i className="fa fa-minus"></i> supprimer
                                    </button>
                                    {
                                        photo.length > 0 && (
                                            <div>
                                                <img src={photo} alt="Image capturée" width={'20%'} height={'20%'} />
                                            </div>
                                        )
                                    }
                                </Col>

                                <Row>
                                    <Col className="pr-1" md="6">
                                        <label className="title">Sokajy (TGV , IFI , TMH , PROD ANR , Association , ...)</label>
                                        <Input
                                            type={'text'}
                                            className={'form-control'}
                                            value={categorie}
                                            onChange={e => setcategorie(e.target.value)}
                                            required
                                        />
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <label className="title">Isan'ny mpikambana</label>
                                        <Input
                                            type={'number'}
                                            className={'form-control'}
                                            value={nombre_membre}
                                            onChange={e => setnombre_membre(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <FormGroup>
                                            <label className="title">Anarana</label>
                                            <input
                                                type={'text'}
                                                value={nom}
                                                className={'form-control'}
                                                onChange={e => setNom(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <FormGroup>
                                            <label className="title">Fanampiny</label>
                                            <input
                                                type={'text'}
                                                value={prenom}
                                                className={'form-control'}
                                                onChange={e => setPrenom(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-1" md="6">
                                        <FormGroup>
                                            <label className="title">Laharan'ny CIN</label>
                                            <input
                                                type={'number'}
                                                value={cin}
                                                className={'form-control'}
                                                onChange={e => setCin(e.target.value)}
                                                max={20}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-1" md="3">
                                        <FormGroup>
                                            <label className="title">Nomena ny </label>
                                            <input
                                                type={'date'}
                                                value={dateLivraison}
                                                className={'form-control'}
                                                onChange={e => setdateLivraison(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col><Col className="pl-1" md="3">
                                        <FormGroup>
                                            <label className="title">tao</label>
                                            <input
                                                type={'text'}
                                                value={lieu}
                                                className={'form-control'}
                                                onChange={e => setLieu(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-1" md="6">
                                        <FormGroup>
                                            <label className="title">Laharan'ny finday</label>
                                            <input
                                                type={'number'}
                                                value={contact}
                                                className={'form-control'}
                                                onChange={e => setContact(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <FormGroup>
                                            <label className="title">Adiresy</label>
                                            <input
                                                type={'text'}
                                                value={adresse}
                                                className={'form-control'}
                                                onChange={e => setAdresse(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <ChoixFokontany setCommune_f={setCommune} setDistrict_f={setdistrict} setfokotany_f={setFokotany} setregion_f={setRegion} regionList={regionList}  />
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <FormGroup>
                                            <label className="title">Laharana Kara-panondron'ny mpiahy :</label>
                                            <input
                                                type={'number'}
                                                value={cinParent}
                                                className={'form-control'}
                                                onChange={(e)=>{setcinParent(e.target.value)}}
                                            />
                                            {/* {
                                                suggestionCinParent.length > 0 && (
                                                    <div className={'form-control'} onClick={e => { setsuggestionCinParent([]); setcinParent([]); setid_parraine(0) }}><span style={{ color: 'red' }}>Selectionner</span> </div>
                                                )
                                            }
                                            {
                                                suggestionCinParent.map(row => (
                                                    <div className={'form-control'} onClick={e => { handleSelectCIN(row) }}>{row.nom} {row.prenom}</div>
                                                ))
                                            } */}
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">laharan'ny Fiche</label>
                                                    <Input
                                                        type={'number'}
                                                        className={'form-control'}
                                                        value={numero_fiche}
                                                        onChange={e => setnumero_fiche(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                
                                <button className="btn btn-outline-primary" onClick={handlePlus}>
                                    {affiche}
                                </button>
                                {/*----------------------------------------------------------------------------------------------------------------------*/}
                                {
                                    plus == true && (
                                        <>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Genre</label>
                                                    <select value={genre} onChange={(e)=>{setGenre(e.target.value);}} className={'form-control'}>
                                                        <option value={'Homme'}>Homme</option>
                                                        <option value={'Femme'}>Femme</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Adiresy mailaka</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Situation matrimoniale</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={situation_matrimoniale}
                                                        onChange={e => setsituation_matrimoniale(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Asa</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={profession}
                                                        onChange={e => setprofession(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Mari-pahaizana</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={diplome}
                                                        onChange={e => setdiplome(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Anaran'ny Ray</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={nom_pere}
                                                        onChange={e => setnom_pere(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Anaran'ny Reny</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={nom_mere}
                                                        onChange={e => setnom_mere(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Efa kandida t@ fifidianana ?</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={is_candidat}
                                                        onChange={e => setis_candidat(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Solotenan'antoko politika ?</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={parti_politique_candidat_avant}
                                                        onChange={e => setparti_politique_candidat_avant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Anaran'ny antoko politika  ?</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={parti_politique_candidat_avant}
                                                        onChange={e => setparti_politique_candidat_avant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Antony hidirana ho mpikambana </label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={raison_demande_inscription}
                                                        onChange={e => setraison_demande_inscription(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Antoko politika teo aloha ?</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={parti_politique_candidat_avant}
                                                        onChange={e => setparti_politique_candidat_avant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Toeram'piasana</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={lieu_travail}
                                                        onChange={e => setlieu_travail(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Sampam'pianarana</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={domaine_etude}
                                                        onChange={e => setdomaine_etude(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Antoko politika nisy teo aloha ?</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={parti_politique_candidat_avant}
                                                        onChange={e => setparti_politique_candidat_avant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Fahaiza-manao</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={dons_perso}
                                                        onChange={e => setdons_perso(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Antoko nisy teo aloha</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={ancien_parti_politique}
                                                        onChange={e => setancien_parti_politique(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Andraikitra</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={responsabilite}
                                                        onChange={e => setresponsabilite(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Vinavina</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={attente}
                                                        onChange={e => setattente(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Fandraisana anjara</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={contribution}
                                                        onChange={e => setcontribution(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Tompon'andraikitra eo anivon'ny fokotany</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={manager_fokotany}
                                                        onChange={e => setmanager_fokotany(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Anarana feno ny mpiantoka</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={nom_complet_garant}
                                                        onChange={e => setnom_complet_garant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Andraikitrin'ny mpiantoka</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={responsabilite_garant}
                                                        onChange={e => setresponsabilite_garant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Daty nanaovan'ny mpiantoka sonia</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={date_signature_garant}
                                                        onChange={e => setdate_signature_garant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Laharan'ny fiche ny mpiantoka</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={numero_fiche_garant}
                                                        onChange={e => setnumero_fiche_garant(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                            

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Antoko politika nanaovana kandida</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={parti_politique_candidat}
                                                        onChange={e => setparti_politique_candidat(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <label className="title">Faritany</label>
                                                    <Input
                                                        type={'text'}
                                                        className={'form-control'}
                                                        value={province}
                                                        onChange={e => setprovince(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default NouveauMembre;
