import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';


import Province from 'views/Serie/Province';
import '../Serie/style.css';

const Serie = ({ setVisibleSerie, Specialite }) => {
    const specialite = "General";
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [visibleProvince, setVisibleProvince] = useState(false);
    const [serie_sec, setSerie_sec] = useState(null);
    const [data_serie, setData_serie] = useState([]);
    var nombre_total = 0;
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);
    // const data_serie = [
    //     {
    //         nom : "A1",
    //         secteur : "Literraire",
    //         total : 123,
    //     },
    //     {
    //         nom : "D",
    //         secteur : "Scientifique",
    //         total : 123,
    //     },{
    //         nom : "L",
    //         secteur : "OSE",
    //         total : 123,
    //     }
    // ]



    const getListSerie = () => {
        fetch(url.urlHttp + "serie-bobon/candidat-liste-serie-liste?specialite=" + Specialite.specialite, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setData_serie(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListSerie()
    }, [])

    for (let i = 0; i < data_serie.length; i++) {
        nombre_total += data_serie[i].nombre;
    }
    const [object, setObject] = useState(null)
    const detailSerie = (serie) => {
        setSerie_sec(serie);
        setVisibleProvince(true);
        setObject(serie)
    }

    // useEffect(() => {
    //     getProvinces();
    // }, [])

    if (visibleProvince == true && serie_sec != null) {
        return (
            <>
                <Province setVisibleProvince={setVisibleProvince} object={object} Serie={serie_sec.serie_option} Specialite={serie_sec.specialite} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row className="justify-content-center">
                    <Card style={{ width: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CardHeader>
                            <CardBody tag="h4" className="text-center">
                                <span style={{ color: "black", fontSize: '1.2rem' }}>Spécialité: </span>
                                <span style={{ color: "red", fontSize: '1.2rem' }}>{Specialite.specialite}</span><br />
                                <span style={{ color: "black", fontSize: '1.2rem' }}>Total de Candidats: </span><br />
                                <span style={{ color: "green", fontWeight: 'bold', fontSize: '1.3rem' }}>{ANRDataUtils.formatNombre(Specialite.nombre_candidat)}</span><br />
                                {isMpotra==true&&(<span style={{ color: "red", fontWeight: 'bold', fontSize: '1.1rem' }}>+{ANRDataUtils.formatNombre(Specialite.nombre_candidat_1)}</span>)}
                            </CardBody>
                        </CardHeader>
                    </Card>
                </Row>
                {/* <Row> */}

                {/* </Row> */}
                <Button className="btn btn-outline-primary my-3" onClick={() => setVisibleSerie(false)}>Retour</Button>
                <Button className="btn btn-outline-primary" onClick={show}>
                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                </Button>
                <Row className="g-4">
                    {data_serie.map((row, index) => (
                        <Col lg="3" md="4" sm="6" key={index}>
                            <Card
                                className="animated-card"
                                style={{ width: '250px', padding: '10px', textAlign: 'center', transition: 'transform 0.3s, box-shadow 0.3s', gap: '50px' }} onClick={() => detailSerie(row)}>
                                <CardBody className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="icon-big text-center icon-warning mb-3">
                                        <img width='50px' height='auto' alt='' className="img-responsive" />
                                    </div>
                                    <p className="font-weight-bold mb-2" style={{ fontSize: '1rem', color: 'green' }}>
                                        Série: <span style={{ fontSize: '1.5rem', color: "green" }}>{row.serie_option}</span>
                                    </p>
                                    <p className="mb-1" style={{ fontSize: '1rem', color: 'black' }}>
                                        Spécialité: <span style={{ fontSize: '1rem', color: " red" }}>{row.specialite}</span>
                                    </p>
                                    <p className="mb-1" style={{ fontSize: '1rem', color: 'black' }}>
                                        Secteur: <span style={{ fontSize: '1rem', color: " red" }}>{row.secteur}</span>
                                    </p>
                                    <CardTitle tag="p" className="mt-2" style={{ fontSize: '1rem', color: " red" }}>
                                        <b style={{ color: 'green' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}</b>
                                    </CardTitle>
                                    {isMpotra==true&&(<CardTitle tag="p" className="mt-2" style={{ fontSize: '0.8rem', color: " green" }}>
                                        <b style={{ color: 'red' }}>+{ANRDataUtils.formatNombre(row.nombre_candidat_1)}</b>
                                    </CardTitle>)}
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>

            </div>
        );
    };

}


export default Serie;