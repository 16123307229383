import React, { useState } from "react";
import { Button, FormGroup, Input, Row, Col, CardHeader, Card, Table, CardBody, Label } from "reactstrap";
import url from "../../urlConfig";
import { useEffect } from "react";
import ImagePrezi from '../QG/prezi.jpg';
import ANRDataUtils from "../../utils/Utils";
import authHeader from "../../services/AuthHeader";
const DetailsQG = ({ region }) => {
  const auth_data = authHeader()
  console.log("Mitantsoa ", region);
  const [article, setAricle] = useState([]);
  const getQuartierGeneral = () => {
    fetch(url.urlHttp + "article/liste-article-qg?id_infra=" + region.id_infra, {
      method: "GET",
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("DATA RETOUR : ", data.data);
        setAricle(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getQuartierGeneral();
  }, []);
  const cellStyle = { width: '5%' }

  return (
    <main className='bg-white container-fluid' id="main">
      <Row>
        <Col lg={3}>
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>

            <img
              src={region.photose}
              alt="Votre Image"
              style={{
                // Ajustez selon vos besoins
              }}
            />
          </div>
        </Col>
        <Col lg={9} style={{ borderRight: '1px solid #000', borderLeft: '1px solid #000', paddingLeft: '3rem', paddingRight: '2rem' }}>
          <div>
            <div>

              <Row>
                <Col lg={10}>
                  <strong> TARATASY TEKNIKA FENONA MOMBAMOMBA NY FOIBE :</strong><br />
                  <strong>ANARAN'NY MPANOTANY :
                    <span style={{ border: 'none' }}>
                      {region.nom_demandeur}
                    </span>
                  </strong>
                </Col>
                <Col lg={2} className="text-right">
                  <img
                    src={ImagePrezi}
                    alt="Votre Image"
                    style={{
                      maxWidth: '100px', // Ajustez selon vos besoins
                    }}
                  />
                </Col>
              </Row>




              <div style={{ display: 'flex', alignItems: 'center' }}>
                <strong>ASA:</strong>
                <span style={{ flex: 1, border: 'none', backgroundColor: 'transparent' }}>
                  {region.fonction_dem}
                </span>
                <strong>Tel:</strong>
                <span style={{ flex: 1, border: 'none', backgroundColor: 'transparent' }}>
                  {region.tel_dem}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Anaran'ny tompony :</strong>
                <span style={{ flex: 1, border: 'none', backgroundColor: 'transparent' }}>
                  {region.nom_prop_qg}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <strong>ADRESY:</strong>
                <span style={{ flex: 1, border: 'none', backgroundColor: 'transparent' }}>
                  {region.adresse_prop_qg}
                </span>
                <strong>Tel:</strong>
                <span style={{ flex: 1, border: 'none', backgroundColor: 'transparent' }}>
                  {region.tel_prop_qg}
                </span>
              </div>


            </div>


          </div>
          <Table striped bordered>
            <thead style={{ backgroundColor: '#808080' }}>
              <tr>
                <th className='text-center'>n°</th>
                <th className='text-center' colSpan='11'>Famaritana</th>
                <th className='text-center' colSpan='6'>A propos du lieu</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '0.8rem', textAlign: 'center', cursor: 'pointer' }}>
              <tr>
                <td rowSpan='5'>Q1</td>
                <td className='text-center' colSpan='4' rowSpan='5' style={{ backgroundColor: '	#C0C0C0', verticalAlign: 'middle' }}>Toerana</td>
                <td className='text-center' colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Region</td>
                <td className='text-center' colSpan='3'> {region.region}</td>
                <td className='text-center' colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Akaiky Tsena</td>
                <td className='text-center' colSpan='1' style={cellStyle}> {region.akaiky}</td>
              </tr>
              <tr>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>District</td>
                <td colSpan='3'> {region.district}</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Sampanandalana</td>
                <td>{region.sampanan_dalana}</td>
              </tr>
              <tr>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Commune</td>
                <td colSpan='3'> {region.commune}</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Lalandehibe</td>
                <td>{region.lalandehibe}</td>
              </tr>
              <tr>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Fokotany</td>
                <td colSpan='3'> {region.fokotany}</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Halavitra miala ny toerampifidianana</td>
                <td>{region.alavirana}</td>
              </tr>
              <tr>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Quartier</td>
                <td colSpan='3'> {region.quartier}</td>
              </tr>
              <tr>
                <td rowSpan='2'>Q2</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Isany Efitra</td>
                <td colSpan='4'>{region.nombre_de_pieces}</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Q22 Velarany (M2)</td>
                <td colSpan='5'>{region.surface_m2}</td>
              </tr>
              <tr>
                <td colSpan='7' style={{ backgroundColor: '#FFB266' }}></td>
                <td style={{ backgroundColor: '#FFB266' }}>Valiny</td>
                <td colSpan='9' style={{ backgroundColor: '#FFB266' }}>Fanamarinana</td>
              </tr>

              <tr>
                <td>Q3</td>
                <td colSpan='7' style={{ backgroundColor: '	#C0C0C0' }}>Misy Fanaka</td>
                <td>{region.meuble_equipe}</td>
              </tr>
              <tr>
                <td>Q4</td>
                <td colSpan='7' style={{ backgroundColor: '	#C0C0C0' }}>Misy Rano</td>
                <td>{region.eau}</td>
              </tr>
              <tr>
                <td>Q5</td>
                <td colSpan='7' style={{ backgroundColor: '	#C0C0C0' }}>Misy Jiro  Électricité</td>
                <td>{region.electricite}</td>
              </tr>
              <tr>
                <td>Q6</td>
                <td colSpan='7' style={{ backgroundColor: '	#C0C0C0' }}>Misy Hijanonan'ny Fiara</td>
                <td>{region.parking}</td>
              </tr>
              <tr>
                <td>Q7</td>
                <td colSpan='7' style={{ backgroundColor: '	#C0C0C0' }}>Misy Toerampivoahana</td>
                <td>{region.wc}</td>
              </tr>
              <tr>
                <td style={{ verticalAlign: 'middle' }} rowSpan='5'>Q7</td>
                <td colSpan='11' style={{ backgroundColor: '#FFB266' }} >Fepetra AHAZONA NY TRANO</td>
                <td colSpan='7' style={{ backgroundColor: '#FFB266' }}>Filana (Asio X)</td>
              </tr>
              <tr>
                <td colSpan='6' style={{ backgroundColor: '	#C0C0C0' }}>Raha AMPANOFAINA: HOFANY ISAMBOLANA (Ar/Mois)</td>
                <td colSpan='3'>{region.mode_acquisition}</td>
                {/* <td colSpan='5' style={{ backgroundColor: '	#C0C0C0' }}>Q9 FANATSARANA</td> */}
                <td colSpan='3'>{ANRDataUtils.formatNombre(region.prix_alocation)}</td>
              </tr>
              <tr>
                <td colSpan='6' style={{ backgroundColor: '	#C0C0C0' }}>Raha MAIMAIMPONA: INONA NO TAMBERINDIMBY</td>
                <td colSpan='4'>{region.contrat}</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Q10 FANADIOVANA</td>
                <td colSpan='3'>{region.besoin}</td>
              </tr>
              <tr>
                <td colSpan='6' style={{ backgroundColor: '	#C0C0C0' }}>DATY AHAFAHANA MANDRAY NY TRANO (DD/MM)</td>
                <td colSpan='4'>{region.date_disponibilite}</td>
                <td colSpan='4' style={{ backgroundColor: '	#C0C0C0' }}>Q11 FANDOKOANA</td>
                <td colSpan='3' style={cellStyle}>{region.peinture}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col >
          <h4 className="text-primary"> GOODIES</h4>
          <div className="d-flex justify-content-center " style={{ height: '100vh' }}>

            <Table striped>
              <thead className="text-primary">
                <tr>
                  <th>Titre article</th>
                  <th>Date de sortie </th>
                  <th>Nombre Sortie</th>
                  <th>Beneficiaire</th>
                  <th>Etat de sortie</th>
                  <th>Provenance</th>
                </tr>
              </thead>
              <tbody>
                {
                  article.map((row, index) => (
                    <>
                      <tr>
                        <td>{row.titreArticle}</td>
                        <td>{row.dateSortie}</td>
                        <td>{row.nombreSortie}</td>
                        <td>{row.beneficiaire}</td>
                        <td>{row.etatSortie}</td>
                        <td>{row.provenance}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row >
    </main>
  );
};

export default DetailsQG;
