import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, CardTitle, CardBody, Table, Label, Input, Button } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import url from 'urlConfig';
import LoaderUtils from "../../components/Shared/Loading";
import { Link } from 'react-router-dom';
import RegionLG from 'views/logistiques/RegionLG';
import ANRDataUtils from 'utils/Utils';
import authHeader from 'services/AuthHeader';
import moment from 'moment';
import Legende from './Legende';

const ListLogistiques = () => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState([]);
    const [visibleRegion, setVisibleRegion] = useState(false);
    const [province_sec, setProvince_sec] = useState('');
    const [data_provinces_elect, setData_provinces_elect] = useState([]);
    const [data_provinces_feuille, setData_provinces_feuille] = useState([]);
    const [data_provinces_sujet, setData_provinces_sujet] = useState([]);
    const [general, setGeneral] = useState(true);
    const [pro, setPro] = useState(true);
    const [tech, setTech] = useState(true);
    const [list, setList] = useState([]);
    const [materiel, setMateriel] = useState('');
    const [materielFeuille, setMaterielFeuille] = useState([]);
    const [materielSujet, setMaterielSujet] = useState([]);
    const [materielElect, setMaterielElect] = useState([]);
    const [responsable, setResponsable] = useState('');
    const [receptionniste, setReceptionniste] = useState('');
    const [date_envoye, setDate_envoye] = useState('');
    const [bases, setBases] = useState([]);
    const [base_prov, setBase_prov] = useState([]);
    const [nombre_total, setNombre_total] = useState([]);
    const [prov_total, setProv_total] = useState(0);
    const [prov_partiel, setProv_partiel] = useState(0);
    const [prov_non, setProv_non] = useState(0);
    const [pref_total, setPref_total] = useState(0);
    const [pref_partiel, setPref_partiel] = useState(0);
    const [pref_non, setPref_non] = useState(0);
    const [dist_total, setDist_total] = useState(0);
    const [dist_partiel, setDist_partiel] = useState(0);
    const [dist_non, setDist_non] = useState(0);
    const [centre_total, setCentre_total] = useState(0);
    const [centre_partiel, setCentre_partiel] = useState(0);
    const [centre_non, setCentre_non] = useState(0);
    const [etab_total, setEtab_total] = useState(0);
    const [etab_partiel, setEtab_partiel] = useState(0);
    const [etab_non, setEtab_non] = useState(0);
    const [nombre_total_prefecture, setNombre_total_prefecture] = useState([]);
    const [nombre_total_district, setNombre_total_district] = useState([]);
    const [nombre_total_centre, setNombre_total_centre] = useState([]);
    const [tanana, setTanana] = useState([]);
    const [pro_part, setPro_Part] = useState(0);
    const [pro_tot, setPro_Tot] = useState(0);
    const [pro_non, setPro_Non] = useState(0);
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const [listMisyApres, setListMisyApres] = useState([])
    const [listTsymisyApres, setListTsyMisyApres] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [img, setImg] = useState(null);
    const [nombres, setNombres] = useState([]);
    const [date_envs, setDate_envs] = useState('');
    const [date_recs, setDate_recs] = useState(new Date());
    var couleur = '';

    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].date_envoye != null) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListTsyMisy(newListTsyMisy);
        console.log(JSON.stringify(newListMisy) + "recu");
        console.log(JSON.stringify(newListTsyMisy) + "tsisy");
    }

    const getsListTsisyApres = (data) => {
        let newListMisyApres = [];
        let newListTsyMisyApres = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (date_envoye != null) {
                newListMisyApres.push(data[i]);
            } else {
                newListTsyMisyApres.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisyApres(newListMisyApres);
        setListTsyMisyApres(newListTsyMisyApres);
        console.log(JSON.stringify(newListMisyApres) + "envoyer");
    }

    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }

    const handleGetListProvince = async () => {
        await fetch(url.urlHttp + "materiel/data-province?materiel=Sobika", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA province ato : ", data.data);
                setBase_prov(data.data);
                getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetListBaseAvant = async () => {
        await fetch(url.urlHttp + "materiel/base-avant", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA base avant", data.data);
                setBases(data.data);
                //  getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetListNombreMatiere = async () => {
        await fetch(url.urlHttp + "materiel/nombre_serie_matiere", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA matiere apres", data.data);
                setNombres(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreMateriel = async (materiels) => {
        await fetch(url.urlHttp + "materiel/count?materiel=" + materiels + "&&type=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA materiel", data.data);
                if (materiels == "Electricites") {
                    setMaterielElect(data.data);
                } else if (materiels == "Sujets") {
                    setMaterielSujet(data.data)
                } else {
                    setMaterielFeuille(data.data)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGeProvinceParteielle = async (province) => {
        await fetch(url.urlHttp + "materiel/data-province-partiel?province=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA province partielle", data.data);
                setPro_Part(data.data);
                return data.data;
            })
            .catch(error => {
                console.log(error);
            });
    }


    const handleGeProvinceTotal = async (province) => {
        await fetch(url.urlHttp + "materiel/data-province-totales?province=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA province partielle", data.data);
                setPro_Tot(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }


    const handleGeProvinceNon = async (province) => {
        await fetch(url.urlHttp + "materiel/data-province-non?province=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA province non", data.data);
                setPro_Non(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotal = async (type) => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-province-count?type="+type, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                if(type == "recu_totalement"){
                    setProv_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setProv_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setProv_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalPref = async (type) => {
        await fetch(url.urlHttp + "materiel/data-pref-count?type="+type+"&&province=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
          
                if(type == "recu_totalement"){
                    setPref_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setPref_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setPref_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    const handleGetNombreTotalDistrict = async (type) => {
        await fetch(url.urlHttp + "materiel/data-dist-count?type="+type+"&&region=&&province=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
          
                if(type == "recu_totalement"){
                    setDist_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setDist_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setDist_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalCentres = async (type) => {
        await fetch(url.urlHttp + "materiel/data-centre-count?type="+type+"&&district=&&province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
          
                if(type == "recu_totalement"){
                    setCentre_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setCentre_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setCentre_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalEtab = async (type) => {
        await fetch(url.urlHttp + "materiel/data-etab-count?type="+type+"&&district=&&centre=&&province=&&izy=0", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
          
                if(type == "recu_totalement"){
                    setEtab_total(data.data)
                }
                if(type == "recu_partiellement"){
                    setEtab_partiel(data.data)
                }
                if(type == "non_envoye"){
                    setEtab_non(data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalDist = async () => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-district-total", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                setNombre_total_district(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetNombreTotalCentre = async () => {
        const data = {

        }
        await fetch(url.urlHttp + "materiel/data-centre-total", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA nombre total", data.data);
                setNombre_total_centre(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const detailProvince = (province, materiel, date_env, date_rec) => {
        setProvince_sec(province);
        setMateriel(materiel);
        setDate_envs(date_env);
        setDate_recs(date_rec);
        setVisibleRegion(true);
    }

    const handleFile = (e, id, type) => {
        const file = e.target.files[0];
        const table = "materiel_conclave_office";
        let urls = "";
        var data = null;
        e.preventDefault();
        if (!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result;
            if (type == "env") {
                data = {
                    id: id,
                    table: table,
                    column: "photo_envoi",
                    value: base64String
                }
                urls = url.urlHttp + "materiel/update";
                fetch(urls, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': auth_data.content_type,
                        'Authorization': auth_data.authorization
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        handleGetListProvince();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                data = {
                    id: id,
                    table: table,
                    column: "photo_recu",
                    value: base64String
                }
                urls = url.urlHttp + "materiel/update";
                fetch(urls, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': auth_data.content_type,
                        'Authorization': auth_data.authorization
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(data => {
                        handleGetListProvince();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
    }

    const handleSort = (order, key) => {
        const sortedWords = [...list]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setList(sortedWords);
    };

    const search = () => {
        const data_send = {
            materiel: materiel,
            responsable_envoye: responsable,
            receptionniste: receptionniste,
            date_envoye: date_envoye
        }
        console.log("ito", data_send);
    }

    var totalEnvoyeElectricite = 0;
    var totalRecuElectricite = 0;
    var totalResteElectricte = 0;
    var totalEnvoyeFeuille = 0;
    var totalRecuFeuille = 0;
    var totalResteFeuille = 0;
    var totalEnvoyeSujet = 0;
    var totalRecuSujet = 0;
    var totalResteSujet = 0;
    var nombre_salles = 0;
    var nombre_province = 0;
    var nombreMisyFeuilles = 0;
    var nombreMisyElect = 0;
    var nombreMisySujet = 0;
    var totalEtab = 0;
    var totalSalle = 0;
    var totalCandidat = 0;
    var totalFeuille = 0;
    var totalSujet = 0;
    let total = 0;


    let recu_totalement = 0;
    let recu_partiellement = 0;
    let non_envoye = 0;
    let recu_totalement_pref = 0;
    let recu_partiellement_pref = 0;
    let non_envoye_pref = 0;
    let recu_totalement_dist = 0;
    let recu_partiellement_dist = 0;
    let non_envoye_centre = 0;
    let recu_totalement_centre = 0;
    let recu_partiellement_centre = 0;
    let non_envoye_dist = 0;



    const accumulated = {};

    bases.forEach(item => {
        const office = item.office;
        if (!accumulated[office]) {
            console.log(item.nombre_salle, 'ee')
            accumulated[office] = true;
            totalEtab += item.nombre_centre;
            totalSalle += item.nombre_salle;
            totalCandidat += item.nombre_candidat
        }
    });


    for (let index = 0; index < bases.length; index++) {

        //  totalSalle += bases[index].nombre_salle;

    }

    for (let i = 0; i < bases.length; i++) {
        totalEnvoyeElectricite += bases[i].electricites;
        totalEnvoyeFeuille += bases[i].feuilles;
        totalEnvoyeSujet += bases[i].sujets;
        // totalRecuElectricite += data_provinces_elect[i].q_r;
        // totalResteElectricte += data_provinces_elect[i].reste;
    }

    for (let i = 0; i < nombres.length; i++) {
        totalFeuille += nombres[i].nombre_candidat * nombres[i].nombre_matiere;
        totalSujet += nombres[i].nombre_candidat * nombres[i].nombre_matiere;
    }

    for (let i = 0; i < data_provinces_sujet.length; i++) {
        totalEnvoyeSujet += data_provinces_sujet[i].q_e;
        totalRecuSujet += data_provinces_sujet[i].q_r;
        totalResteSujet += data_provinces_sujet[i].reste;
    }

    // for (let i = 0; i < nombre_salle.length; i++) {
    //     nombre_salles += nombre_salle[i].count;
    // }

    for (let i = 0; i < tanana.length; i++) {
        nombre_province += tanana[i].count;
    }

    for (let i = 0; i < materielFeuille.length; i++) {
        nombreMisyFeuilles += materielFeuille[i].count;
    }

    for (let i = 0; i < materielSujet.length; i++) {
        nombreMisySujet += materielSujet[i].count;
    }

    for (let i = 0; i < materielElect.length; i++) {
        nombreMisyElect += materielElect[i].count;
    }

    for (let index = 0; index < nombre_total.length; index++) {
        recu_partiellement += nombre_total[index].recu_partiellement;
        recu_totalement += nombre_total[index].recu_totalement;
        non_envoye += nombre_total[index].non_envoye;
    }

    for (let index = 0; index < nombre_total_prefecture.length; index++) {
        recu_partiellement_pref += nombre_total_prefecture[index].recu_partiellement;
        recu_totalement_pref += nombre_total_prefecture[index].recu_totalement;
        non_envoye_pref += nombre_total_prefecture[index].non_envoye;
    }


    for (let index = 0; index < nombre_total_district.length; index++) {
        recu_partiellement_dist += nombre_total_district[index].recu_partiellement;
        recu_totalement_dist += nombre_total_district[index].recu_totalement;
        non_envoye_dist += nombre_total_district[index].non_envoye;
    }

    for (let index = 0; index < nombre_total_centre.length; index++) {
        recu_partiellement_centre += nombre_total_centre[index].recu_partiellement;
        recu_totalement_centre += nombre_total_centre[index].recu_totalement;
        non_envoye_centre += nombre_total_centre[index].non_envoye;
    }

    const filtre = (value) => {
        const filteredData = base_prov.filter(item => item.office.toUpperCase().includes(value.toUpperCase()));
        getsListTsisy(filteredData);
    }

    useEffect(() => {
        handleGetNombreMateriel("Electricites");
        handleGetNombreMateriel("Feuilles");
        handleGetNombreMateriel("Sujets");
        handleGetListBaseAvant();
        handleGetListProvince();
        // handleGetListBaseAvant();
        handleGetNombreTotalPref('recu_totalement');
        handleGetNombreTotalPref('recu_partiellement');
        handleGetNombreTotalPref('non_envoye');

        handleGetNombreTotalDistrict('recu_partiellement');
        handleGetNombreTotalDistrict('recu_totalement');
        handleGetNombreTotalDistrict('non_envoye');

        handleGetNombreTotalCentres('recu_partiellement');
        handleGetNombreTotalCentres('recu_totalement');
        handleGetNombreTotalCentres('non_envoye');

        handleGetNombreTotalEtab('recu_partiellement');
        handleGetNombreTotalEtab('recu_totalement');
        handleGetNombreTotalEtab('non_envoye');

        handleGetListNombreMatiere();
        handleGetNombreTotal('recu_totalement');
        handleGetNombreTotal('recu_partiellement');
        handleGetNombreTotal('non_envoye');
        // handleGetNombreTotalDist();
        // handleGetNombreTotalCentre();
        //handleGeProvinceParteielle('Antananarivo');
    }, [])

    if (visibleRegion == true && province_sec != null && materiel != null && date_envs != null && date_recs != null) {
        return (
            <>
                <RegionLG setVisibleRegion={setVisibleRegion} province={province_sec} materiel={materiel} date_env={date_envs} date_rec={date_recs} />
            </>
        );
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> PROVINCE </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(prov_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(prov_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(prov_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> PREFECTURE </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(pref_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(pref_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(pref_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> DISTRICT </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(dist_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(dist_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(dist_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row><br /><br />
                <Row>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> CENTRE </span><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(centre_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(centre_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(centre_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ height: "100%" }}>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontWeight: 'bold', fontSize: "1.7rem" }}> ETABLISSEMENT </span><br /><br />
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu totalement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(etab_total)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Recu partiellement  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(etab_partiel)}</a></span>
                                    <span style={{ color: "gray", fontSize: "1rem" }}>Non envoyé  : <a style={{ color: "red", fontSize: "2.2rem", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(etab_non)}</a></span>
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="4"><br />
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Province :</a></label>
                            <input
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechecher province'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup><br />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle>
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "20px" }}> Au niveau National</span>
                                    </CardTitle>
                                </CardHeader>
                                <Button className="btn btn-outline-primary" onClick={voirPlus}>
                                    {isVoi ? "Voir Moins" : "Voir Plus"}
                                </Button>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <div>
                                        <Legende />
                                    </div>
                                    <Table style={{ marginTop: "40px" }} striped>
                                        <thead className="text-primary">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}>DOB/DEN &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Article&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Quantite envoyée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                {isVoi == true && (
                                                    <>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Camion transportée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Photo à l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Responsable de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Fonction du responsable de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Contact du responsable d'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Etat à l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    </>
                                                )}
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Quantite recue&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                {isVoi == true && (
                                                    <>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Photo à la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Responsable de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Fonction du responsable de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Contact de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Etat recu&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Observation&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listMisy.map((row, index) => {
                                                  //  alert(listMisy.length);
                                                    let couleur = ''; // declare couleur here
                                                    // if(handleGeProvinceParteielle(row.office) === 0){
                                                    //     couleur = '#adff2f';
                                                    // }
                                                    // handleGeProvinceTotal(row.office);
                                                    // if (pro_tot === 1) {
                                                    //     couleur = 'rgb(94, 199, 56)';
                                                    // }
                                                    // handleGeProvinceNon(row.office);
                                                    // if (pro_non === 1) {
                                                    //     couleur = 'red';
                                                    // }
                                                    if (row.q_e > row.q_r) {
                                                        couleur = '#adff2f';
                                                    }
                                                    if (row.q_r == row.q_e) {
                                                        couleur = 'rgb(94, 199, 56)';
                                                    }
                                                    if(row.q_e == 0){
                                                        couleur = 'red';
                                                    }

                                                    return (
                                                        <tr key={index} style={{ backgroundColor: couleur }}>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.office}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {ANRDataUtils.formatNombre(row.materiel)}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {ANRDataUtils.formatNombre(row.q_e)}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {new Date(row.date_envoye).toLocaleString()}
                                                            </td>
                                                            {isVoi && (
                                                                <>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.vehicule_transport}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.photo_envoi != null ?
                                                                            <img src={row.photo_envoi} alt="" /> :
                                                                            <input type="file" onChange={(e) => handleFile(e, row.id, 'env')} />
                                                                        }
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.responsable_envoye}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.fonction_envoi}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.contact_envoye}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.etat_envoye}
                                                                    </td>
                                                                </>
                                                            )}
                                                            <td style={{ fontSize: '12px' }}>
                                                                {ANRDataUtils.formatNombre(row.q_r)}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {new Date(row.date_reception).toLocaleString()}
                                                            </td>
                                                            {isVoi && (
                                                                <>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.photo_recu != null ?
                                                                            <img src={row.photo_recu} alt="" /> :
                                                                            <input type="file" onChange={(e) => handleFile(e, row.id, 'rec')} />
                                                                        }
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.responsable_reception}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.fonction_responsable}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.contact_reception}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.etat_recu}
                                                                    </td>
                                                                    <td style={{ fontSize: '12px' }}>
                                                                        {row.observation}
                                                                    </td>
                                                                </>
                                                            )}
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.q_e > 0 ?
                                                                    <button className={'btn btn-danger'} onClick={() => { detailProvince(row.office, row.materiel, row.date_envoye, row.date_reception) }}>Afficher Préfecture</button>
                                                                    :
                                                                    <button className={'btn btn-danger disabled'}>Afficher Préfecture</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            {
                                                isVoi == true && (
                                                    listTsymisy.map((row, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.office}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.materiel}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {ANRDataUtils.formatNombre(row.q_e)}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.date_envoye}
                                                                </td >
                                                                {isVoi == true && (
                                                                    <>
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.vehicule_transport}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {/* {row.photo_envoi != null ?
                                                                <img src={row.photo_envoi} alt="Photo Envoi" /> :
                                                                <input type="file" onChange={(e) => handleFile(e, row.responsable_envoye)} />
                                                            } */}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.responsable_envoye}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.fonction_envoi}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.contact_envoye}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.etat_envoye}
                                                                        </td >
                                                                    </>
                                                                )}
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {ANRDataUtils.formatNombre(row.q_r)}
                                                                </td>
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {row.date_reception}
                                                                </td >
                                                                {isVoi == true && (
                                                                    <>
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {/* {row.photo_recu != null ?
                                                                <img src={row.photo_recu} alt="Photo Envoi" /> :
                                                                <input type="file" />
                                                            }
                                                            <img src={img} alt="Photo Envoi" /> */}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.responsable_reception}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.fonction_responsable}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.contact_reception}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.etat_recu}
                                                                        </td >
                                                                        <td style={{ fontSize: '12px' }}>
                                                                            {row.observation}
                                                                        </td >
                                                                    </>
                                                                )}
                                                                <td style={{ fontSize: '12px' }}>
                                                                    {
                                                                        row.q_e > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={() => { detailProvince(row.office) }}>Afficher Préfecture</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Afficher Préfecture</button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ListLogistiques;