import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./css/login.css"
import { Card, Label, FormGroup, Form, Input, Button, Col, CardImg } from 'reactstrap';
import { useAuth } from './context/AuthContext';
import url from '../../urlConfig';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom";
import LoaderUtils from "../../components/Shared/Loading";
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';

import Fond from "../../assets/img/mprss.png"
import Logo from '../../assets/img/logo_mesupres.png';
const AlertSwal = withReactContent(Swal);

const LoginMen = () => {
    const [reveal, setReveal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate()
    const { isConnected, connect } = useAuth();

    const errorAlert = (state, args) => {
        Swal.fire(
            {
                icon: state,
                title: args,
            }
        )
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (username.trim() == '' || password.trim() == '') {
            ANRDataUtils.successAlert('Veuillez completer les champs')
        }
        await fetch(url.urlHttp + 'auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        }).then((response) => response.json())
            .then((data) => {
                // console.log("RESPONSE DATA : ", data)
                //console.log("RESPONSE DATA TOKEN 2 : ", data.status)
                if (data.status === 401) {
                    errorAlert('error', data.message)
                } else {
                    if (data.data.message) {
                        errorAlert('error', data.message)
                    }

                    if (data.data) {
                        if (data.data.accessToken) {
                            const dataLogin = {
                                type_token: data.data.type,
                                token: data.data.accessToken
                            }

                            const encryptData = ANRDataUtils.encryptLocalStorage(dataLogin)

                            localStorage.setItem("app_data", encryptData)
                            connect();
                            setTimeout(() => {
                                ANRDataUtils.successAlert(data.data.message)
                                navigate('/admin/dashboard');
                                //window.location.reload();
                                // errorAlert('success', 'Authentification réussie');
                            }, 600);
                        }
                    } else {
                        errorAlert('error', "VERIFIER VOS INFORMATION");
                    }
                }
            })
            .catch((error) => errorAlert('error', error))
    }


    return (

        <div className='wrapper wrapper-full-page login ' style={{ backgroundImage: `url(${Fond})`, backgroundSize: 'cover', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >


            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${Fond})`,
                backgroundSize: 'cover',
                filter: 'blur(2px)',
                zIndex: 1
            }}></div>
              <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0, 
                backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                zIndex: 2 
            }}></div>
            <Card style={{
                position: 'relative',
                zIndex: 3,
                height: 'auto',
                width: '550px',
                backgroundColor: '#f5f5f5',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '10px solid #109139'
            }}>
                <CardImg
                    src={Logo}
                    alt="Logo"
                    fluid
                    style={{ top: 0, left: 0, width: '70%', height: '70%' }}
                />
                <h2 className='text-muted' style={{ fontSize: '1.9rem', marginTop:'10px,', fontWeight: 'bold'  }}>PLATEFORME BACC_DATA</h2>
                <h2 className='text-muted' style={{ fontSize: '1.9rem', marginTop:'10px,', fontWeight: 'bold'  }}> 2024</h2>
                <Form id='loginForm' style={{ height: 'auto', width: '350px', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    onSubmit={handleSubmit}
                >
                    <Col className="pr-1" md="12">
                        <FormGroup>
                            <Label
                                className={'title'}
                                for="username"
                            >
                                Nom d'utilisateur
                            </Label>
                            <Input
                                id="username"
                                name="username"
                                placeholder="Nom d'utilisateur"
                                type="text"
                                value={username}
                                // onChange={(e) => handleOnChange(e)}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    {' '}
                    <Col className="pr-1" md="12">
                        <FormGroup>
                            <Label
                                className={'title'}
                                for="password"
                            >
                                Mot de passe
                            </Label>
                            <Input
                                id="password"
                                name="password"
                                placeholder="Mot de passe"
                                type={reveal ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    {' '}
                    <FormGroup >
                        <Input
                            type="checkbox"
                            checked={reveal}
                            onClick={() => {
                                setReveal(!reveal);
                            }}
                        />
                        <Label check>Afficher mot de passe</Label>
                    </FormGroup>
                    <Button type='submit' color="primary" style={{  paddingLeft: '43px', paddingRight: '43px' }}>
                        Se connecter
                    </Button>
                </Form>
            </Card>

             <footer style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'white', position: 'absolute', bottom: 0, width: '100%' }} className="text-center p-2">
                <h1 style={{ fontSize: '1.2rem', fontFamily: 'cursive', fontWeight: 'bold', margin: '0' }}>
                    Plateforme BACC_DATA 2024
                </h1>
            </footer>
        </div>
    );
}

export default LoginMen;