import React, { useState,useEffect,useRef } from 'react';
import url from "../../urlConfig";
import { Row, Table, Col, Card, CardBody, CardHeader, CardTitle, Form, FormGroup, Input } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import LoaderUtils from 'components/Shared/Loading';

const ChoixFokontany = ({regionList,setDistrict_f,setCommune_f,setfokotany_f,setregion_f}) =>{
    const auth_data = authHeader();
    const [suggestionDistrict, setSuggestionDistrict]=useState([]);
    const [suggestionCommune, setSuggestionCommune]=useState([]);
    const [suggestionFokontany, setSuggestionFokontany]=useState([]);
    const [code_r, setCode_r]=useState(null);
    const [code_d, setCode_d]=useState(null);
    const [code_c, setCode_c]=useState(null);
    const [region, setRegion]=useState('');
    const [district, setDistrict]=useState('');
    const [commune, setCommune]=useState('');
    const [fokotany, setFokotany]=useState('');
    const [isLoading, setIsLoading]=useState(false);

    
const choixDistrict=()=>{
    setIsLoading(true)
        fetch(url.urlHttp + "population/district-all-final?region="+region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false)
                setSuggestionDistrict(data); 
            })
            .catch(error => {
                console.log(error);
            });
            setSuggestionCommune([])
            setSuggestionFokontany([])
           
    }
  
    const choixCommune=()=>{
        setIsLoading(true);
        fetch(url.urlHttp + "population/commune-all-final?district="+district+"&region="+region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false)
                setSuggestionCommune(data); 
            })
            .catch(error => {
                console.log(error);
            });
            setSuggestionFokontany([])
    }

    const choixFokotany =()=>{
        setIsLoading(true)
        fetch(url.urlHttp + "membre/fokontany-final?district="+district+"&&commune="+commune+"&&region="+region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
              },
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false)
                setSuggestionFokontany(data); 
            })
            .catch(error => {
                console.log(error);
            });
            
        setregion_f(region);
        setDistrict_f(district);
        setCommune_f(commune);
        setfokotany_f(fokotany)
    }

    return(
         <>
                     <Row>
                        {isLoading? <LoaderUtils/>: <></>}
                         <Col className="pr-1" md="6">
                             <FormGroup>
                                 <label>REGION</label>
                                 <select  value={region}  className={'form-control'} onChange={e=>{setRegion(e.target.value);choixDistrict();}} onClick={()=>{choixDistrict(); setSuggestionDistrict([]); choixCommune(); setCommune(''); setFokotany(''); setDistrict('')}}>
                                     <option value={''}></option>
                                     {
                                         regionList.map((region) => (
                                             <option  value={region.nom_region}>{region.nom_region}</option>
                                         ))
                                     }
                                 </select>
                             </FormGroup>
                         </Col>
                         
                            <Col className="pl-1" md="6">
                                     <FormGroup>
                                 <label>DISTRICT * (Sélectionner le district)</label>
                                 <select className={'form-control'} value={district}  onChange={e=>{setDistrict(e.target.value);choixCommune()}} onClick={()=>{choixCommune(); setCommune(''); setFokotany('')} } >
                                     <option value={''}></option>
                                     
                                        { suggestionDistrict?.map(district => ( 

                                             <option  value={district.nom_district} >{district.nom_district}</option>
                                         ))}
                                 </select>
                        </FormGroup>
                         </Col>
                         <Col className="pr-1" md="6">
                            <FormGroup>
                            <label>COMMUNE</label>
                                 <select className={'form-control'} value={commune} onChange={(e)=>{setCommune(e.target.value);choixFokotany()}} onClick={()=>{choixFokotany(); setFokotany('')}} >
                                     <option value={''}></option>
                                     {
                                         suggestionCommune?.map(commune => (
                                             <option  value={commune.nom_commune}>{commune.nom_commune}</option>
                                         ))
                                     }
                                 </select>
                            </FormGroup>
                         
                         </Col>
                         <Col className="pl-1" md="6">
                         <FormGroup>
                            <label>FOKONTANY</label>
                                 <select className={'form-control'} value={fokotany} onChange={(e)=>{setFokotany(e.target.value)}}>
                                     <option value={''}></option>
                                     {  
                                         suggestionFokontany?.map(fkt => (
                                             <option  value={fkt.nom_fokontany}>{fkt.nom_fokontany}</option>
                                         ))
                                     }
                                 </select>
                            </FormGroup>
                         </Col>
                     </Row>
                 </>
    );
}
export default ChoixFokontany;