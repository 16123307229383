import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";



import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";

import CandidatC from "./CandidatC";

import iconElecteur2 from '../../assets/img/candidat.png';
import iconElecteur from '../../assets/img/maison.png';


const calculTotal = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const SalleC = ({ setVisibleCandidat, region, commune, district, province, etablissement }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [salle_d, setSalle_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra,setIsMpotra]=useState(false);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);
    const [validation, setValidation] = useState([]);
    const [etat, setEtat] = useState([])
    const [listeId, setListeId] = useState([]);
    const [visibiliteCandidat, setVisibiliteCandidat] = useState(false)
    const [listss, setListss] = useState([]);


    const toDateFormat = (date) => {
        const dateString = date

        // Parse the date string into a Date object
        const moment = require('moment');

        // Parse the date string and format it into "DD-MM-YYYY" format
        const formattedDate = moment(dateString, "ddd MMM DD HH:mm:ss Z YYYY").format("DD-MM-YYYY");

        return formattedDate;

    }
    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const getListEtablissement = () => {
        // console.log(url.urlHttp + "candidat-midina/candidat-ecole-liste-avec-recherche-etablissement?etablissement=" + etablissement + "&&centre=" + commune);
        fetch(url.urlHttp + "centre-milala/liste-salle?commune=" + commune + "&&district=" + district + "&&centre_xamen=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissement(data.data)
                setListss(data.data)
                setTotal_additionel(calculTotal(data.data.map(row => row.nombre_candidat_1)))
                setCandidat_ecole_1(calculTotal(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotal(data.data.map(row => row.candidat_libre_1)))
            })
            .catch(error => {
                console.log(error);
            });
    }

    const setValidations = (etat) => {
        console.log("miditra", url.urlHttp + "centre-milala/liste-salle?commune=" + commune + "&&district=" + district + "&&centre_xamen=" + etablissement);
        fetch(url.urlHttp + "centre-milala/liste-salle?commune=" + commune + "&&district=" + district + "&&centre_xamen=" + etablissement, {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(listeId),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Valider daholo");
                setListEtablissement(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const maka_listId = () => {
        // const timer = setTimeout(() => {
        const filteredIds = listEtablissement
            .filter(entry => entry.validation === 0)
            .map(entry => entry.id);
        setListeId(filteredIds);
        // }, 1000);

        // Clear the timer
        // return () => clearTimeout(timer);
    }

    useEffect(() => {
        getListEtablissement();
        maka_listId();
    }, [])
    console.log(listeId);

    const stat = {
        // sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        // sumCandidatEcole: listEtablissement.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: listEtablissement.length
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const valide = (value1, value2) => {
        setListeId(value1);

        console.log(listeId);
        setValidations(value2);

        // clearTimeout(timer);
        // set(value)
        // setVisibleCandidat(true)
    }

    const valides = (value) => {
        maka_listId();

        console.log(listeId);
        setValidations(value);

        setSalle_d(value)
        setVisibleCandidat(true)
        setVisibiliteCandidat(true)
    }
    var totalCentre = 0;
    var totalSalle = 0;
    var totalCapacite = 0;
    var totalEffectif = 0;
    var totalElectricite = 0;

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
            setListEtablissement(listss);
        }
        console.log(milahatra);
    };

    const sortByDifferenceAndSet = () => {
        if (milahatra == true) {
            const sortedData = [...listEtablissement].sort((a, b) => {
                const differenceA = a.nbr_place_max - a.nombre_candidat;
                const differenceB = b.nbr_place_max - b.nombre_candidat;
                return differenceA - differenceB; // Sorting in ascending order, adjust as needed
            });

            setListEtablissement(sortedData);
            setMilahatra(false);
        } else {
            setListEtablissement(listss)
            setMilahatra(true);
        }
    }

    for (let index = 0; index < listEtablissement.length; index++) {
        totalCentre += listEtablissement[index].nombre_centre;
        totalSalle++;
        totalCapacite += listEtablissement[index].nbr_place_max;
        totalEffectif += listEtablissement[index].nombre_candidat;
        totalElectricite += listEtablissement[index].electricite;
    }


    if (visibiliteCandidat == true && salle_d != null) {
        return (
            <CandidatC nombre_salle={salle_d.nbr_place_max} setVisibleCandidat={setVisibiliteCandidat} salle={salle_d.salle} etablissement={etablissement} commune={commune} district={district} province={province} region={region} />
        );
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        {isShow == false && (<Row md="12" style={{ gap: '50px', padding: '0px', margin: '0px' }} className="justify-content-center" >
                            <Card style={{ padding: '10px' }} lg="3" md="5" sm="5">
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        <div className="icon-big text-center icon-warning">
                                            <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                        </div>
                                        <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                        >Capacité du centre d'examen </p>
                                    </Row>
                                    <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite)}</b></CardTitle>
                                </Col>
                            </Card>
                            <Card style={{ padding: '10px' }} lg="3" md="5" sm="5">
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        <div className="icon-big text-center icon-warning">
                                            <img width='50px' height='auto' src={iconElecteur} alt='' className="img-responsive mb-3" />
                                        </div>
                                        <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                        >Nombre de  Salles  </p>
                                    </Row>
                                    <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalSalle)}</b></CardTitle>
                                </Col>
                            </Card>
                            {/* </Row>
                <Row md="12" style={{ gap: '12px', padding: '0px', margin: '0px' }}> */}

                            <Card style={{ padding: '10px' }} lg="3" md="5" sm="5">
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        <div className="icon-big text-center icon-warning">
                                            <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                        </div>
                                        <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                        >Candidats inscrits </p>
                                    </Row>
                                    <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalEffectif)}</b></CardTitle>
                                    {isMpotra==true&&(<CardTitle tag="p" style={{ fontSize: '1rem', color: 'red' }}><b>+{ANRDataUtils.formatNombre(total_additionel)}</b></CardTitle>)}
                                </Col>
                            </Card>
                            <Card style={{ padding: '10px' }} lg="3" md="5" sm="5">
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        <div className="icon-big text-center icon-warning">
                                            <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                        </div>
                                        <p className="font-weight-bold" style={{ color: 'black', fontSize: '1.5rem' }}
                                        > Places disponibles</p>
                                    </Row>
                                    <CardTitle tag="p" style={{ fontSize: '2rem', color: 'green' }}><b>{ANRDataUtils.formatNombre(totalCapacite - totalEffectif)}</b></CardTitle>
                                </Col>
                            </Card>
                            {/* <Card style={{ padding: '10px' }} lg="2.5" md="5" sm="5">
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        <div className="icon-big text-center icon-warning">
                                            <img width='50px' height='auto' src={iconElecteur2} alt='' className="img-responsive mb-3" />
                                        </div>
                                        <p className="font-weight-bold" style={{ color: 'black',fontSize:'1.5rem' }}
                                        > Salle sans Électricité</p>
                                    </Row>
                                    <CardTitle tag="p" style={{ fontSize: '2rem',color:'green' }}><b>{ANRDataUtils.formatNombre(totalElectricite)}/{ANRDataUtils.formatNombre(totalSalle)}</b></CardTitle>
                                </Col>
                            </Card> */}
                        </Row>)}

                    </Col>
                </Row>
                <Row>

                    <Col md="12">
                        <Card>
                            <CardBody>
                                {isShow == false && (<CardHeader>
                                    <CardTitle style={{ fontSize: '20px' }}>
                                        <span style={{ color: "black", fontWeight: 'bold' }}>Capacité d'etablissement  d'examen :
                                            <span style={{ color: "red", fontWeight: 'bold' }}> {etablissement}</span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> Centre :
                                                <span style={{ color: "red", fontWeight: 'bold' }}> {commune} </span></span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> District :
                                                <span style={{ color: "red", fontWeight: 'bold' }}> {district}</span></span><br />
                                            <span style={{ color: "black", fontWeight: 'bold' }}> Région :
                                                <span style={{ color: "red", fontWeight: 'bold' }}> {region}</span><br />
                                                <span style={{ color: "black", fontWeight: 'bold' }}> Province :
                                                    <span style={{ color: "red", fontWeight: 'bold' }}> {province} </span> </span>
                                            </span></span><br />
                                    </CardTitle>
                                </CardHeader>)}<br />
                                <Button
                                    className="btn btn-outline-primary "
                                    onClick={e => { setVisibleCandidat(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}> 
                                    {isMpotra ? "Fermer" : "Voir candidats Additionnels"}
                                </Button>
                                {isShow == false && (<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                    <Table striped>
                                        <thead className="text-primary styicky-header">
                                            <tr>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'salle')}>Salle &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'nbr_place_max')}>Capacité &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('desc', 'effectif')}>Candidats inscrits &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => sortByDifferenceAndSet()}>Reste &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                                {/* <th style={{ fontSize: '13px', position: "sticky", top: '0', zindex: '1' }} onClick={() => handleSort('asc', 'electricite')}>Salle sans electricité &nbsp;<i class="fa fa-arrow-up-a-z"></i></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listEtablissement.map((row) => (
                                                    <>
                                                        <tr>
                                                            <td >
                                                                {row.salle}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nbr_place_max)}
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}<br />
                                                                {isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                    +{ANRDataUtils.formatNombre(row.nombre_candidat_1)}
                                                                </span>)}
                                                            </td>

                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.nbr_place_max - row.nombre_candidat)}
                                                            </td>

                                                            <td >
                                                                {
                                                                    row.nombre_candidat > 0 ?
                                                                        <button className={'btn btn-danger'} onClick={(e) => { valides(row) }}>Afficher Candidats</button>
                                                                        :
                                                                        <button className={'btn btn-danger disabled'} >Afficher Candidats</button>
                                                                }
                                                            </td>

                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>)}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SalleC;
