var traite = {
    CreateChartGRoup: (chat,Chart, ctx, data, nom) => {
        console.log('DARATATTATATATATATATTATATT', data);
        let config = {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: ''
                    },
                },
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                    }
                }
            }
        };
        if(chat !=null){
            chat.clear()
            chat.destroy()
        }
        return new Chart(ctx.getContext('2d'), config);
    }
}
exports.traite = traite;