import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    FormGroup, Button
} from "reactstrap";
import DataTable from 'react-data-table-component';
import url from "urlConfig";
import ANRDataUtils from "utils/Utils";
import authHeader from "../../services/AuthHeader";
// import Etablissement from "./fokotany";
import EtablissementStatQG from "./EtablissementQG";
import CandidatQG from './CandidatQG';



const CommuneQG = ({ region, district, province, setVisibleCommune, libre, ecole }) => {
    const auth_data = authHeader()
    const [communelist, setCommunelist] = useState([])
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [commune_d, setcommune_d] = useState(null)
    const [visibiliteEtablissement, setVisibiliteEtablissement] = useState(false);
    const [visibleCandidat, setVisibleCandidat] = useState(false);
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);


    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].personnel > 0) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListss(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }

    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }

    const getListCommune = () => {
        console.log(url.urlHttp + "personnel/commune?region=" + region + "&&district=" + district);
        fetch(url.urlHttp + "personnel/commune?region=" + region + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setCommunelist(data.data);
                getsListTsisy(data.data);
                setLists(data.data);

            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        getListCommune()
    }, [])
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.commune.toUpperCase().includes(value.toUpperCase()));
        getsListTsisy(filteredData)
    }
    const stat = {
        sumpersonnel: communelist.map(entry => entry.personnel).reduce((acc, val) => acc + val, 0),
        sumsds: communelist.map(entry => entry.surveillants_de_salle).reduce((acc, val) => acc + val, 0),
        sumsdc: communelist.map(entry => entry.surveillants_de_cour).reduce((acc, val) => acc + val, 0),
        sumaa: communelist.map(entry => entry.autres_agents).reduce((acc, val) => acc + val, 0),
        sumcdc: communelist.map(entry => entry.chef_de_centre).reduce((acc, val) => acc + val, 0),
        sums: communelist.map(entry => entry.secretaires).reduce((acc, val) => acc + val, 0),
        sumsalle: communelist.map(entry => entry.nombre_salle).reduce((acc, val) => acc + val, 0),
        sumcentre: communelist.map(entry => entry.nombre_centre).reduce((acc, val) => acc + val, 0),
    };
    const details = (value) => {
        setcommune_d(value)
        setVisibiliteEtablissement(true)
    }

    const detailPers = (value) => {
        setcommune_d(value)
        setVisibleCandidat(true)
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listMisy]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListMisy(sortedWords);
        } else {
            setMilahatra(true);
        setListMisy(listss);
        }
        console.log(milahatra);
    };
    if (commune_d != null && visibiliteEtablissement == true) {
        return (
            <>
                <EtablissementStatQG setVisibiliteEtablissement={setVisibiliteEtablissement} commune={commune_d} district={district} province={province} region={region} ecole={ecole} libre={libre} />
            </>
        )
    } else if (visibleCandidat == true && commune_d != null) {
        return (
            <CandidatQG setVisibleCandidat={setVisibleCandidat} etablissement={""} commune={commune_d} district={district} province={province} region={region} />
        );
    } else {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            {/* Affichez un message d'erreur s'il y a une erreur */}
                            {error && <div className="alert alert-danger">{error}</div>}
                            <Row>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }}>
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Nombre d' Établissements</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumcentre)}
                                            </p>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }}>
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Total de salles</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumsalle)}
                                            </p>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }}>
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Chef de centre</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumcdc)}
                                            </p>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4" >
                                    <Card style={{ height: "90%" }} >
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Secrétaires</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sums)}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }} >
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Surveillants de cours</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumsdc)}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }} >
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Surveillants de salle</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumsds)}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }} >
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Autres agents</CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumaa)}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="4">
                                    <Card style={{ height: "90%" }} >
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Nombre de Personnels : </CardTitle>
                                            <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                                {ANRDataUtils.formatNombre(stat.sumpersonnel)}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="12" lg="12" sm="12">

                        </Col>
                    </Row>

                    <Row>
                    <Col className="pr-1" md="4"><br />
                            <FormGroup>
                                <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Centre :</a></label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    placeholder={'rechercher centre'}
                                    onChange={e => filtre(e.target.value)}
                                />
                            </FormGroup><br />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <CardHeader>
                                        <CardTitle>
                                        <span style={{ fontWeight: 'bold', fontSize: '20px', padding: '20px' }}>
                                            <a style={{ color: "black" }}>Province : </a> <a style={{ color: "red" }}>{province}</a>
                                            <a style={{ color: "black" }}>, Région : </a> <a style={{ color: "red" }}>{region}</a>
                                            <a style={{ color: "black" }}>, District : </a> <a style={{ color: "red" }}>{district}</a>
                                            </span>
                                        </CardTitle>
                                    </CardHeader>
                                    <Button className="btn btn-outline-primary " onClick={e => { setVisibleCommune(false) }}>retour</Button>
                                    <Button className="btn btn-outline-primary" onClick={voirPlus}>
                                        {isVoi ? "Voir Moins" : "Voir Plus"}
                                    </Button>
                                    <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                        <Table striped>
                                            <thead className="text-primary sticky-header">
                                                <tr>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'commune')}>Centre &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_centre')}>Nombre d' Établissements &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_salle')}>Total de salles &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'personnel')}>Total de personnels &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'chef_de_centre')}>Chef de centre &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'surveillants_de_salle')}>Surveillants de salle &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'surveillants_de_cour')}>Surveillants de cours &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'secretaires')}>Secrétaires &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'autres_agents')}>Autres agents &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listMisy.map((row) => (
                                                        <>
                                                            <tr>
                                                                <td >
                                                                    {row.commune}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nombre_centre)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nombre_salle)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.personnel)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.chef_de_centre)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.surveillants_de_salle)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.surveillants_de_cour)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.secretaires)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.autres_agents)} 
                                                                </td>
                                                                <td >
                                                                    {
                                                                        row.personnel > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher les Établissements</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Afficher les Établissements</button>
                                                                    }
                                                                </td>
                                                                <td >
                                                                    {
                                                                        row.personnel > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { detailPers(row.commune) }}>Voir Liste</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Voir Liste</button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    ))}
                                                {isVoi == true ?
                                                    listTsymisy.map((row) => (
                                                        <>
                                                            <tr>
                                                                <td >
                                                                    {row.commune}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nombre_centre)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.nombre_salle)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.personnel)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.chef_de_centre)}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.surveillants_de_salle)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.surveillants_de_cour)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.secretaires)} 
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {ANRDataUtils.formatNombre(row.autres_agents)} 
                                                                </td>
                                                                <td>
                                                                    {
                                                                        row.personnel > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { details(row.commune) }}>Afficher les Établissements</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Afficher les Établissements</button>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        row.personnel > 0 ?
                                                                            <button className={'btn btn-danger'} onClick={(e) => { detailPers(row.commune) }}>Voir Liste</button>
                                                                            :
                                                                            <button className={'btn btn-danger disabled'} >Voir Liste</button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    )) : ''
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

}
export default CommuneQG;