import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import ANRDataUtils from "utils/Utils";



import url from "../../urlConfig";

// import CommuneQG from './CommuneQG';
import authHeader from '../../services/AuthHeader';
import CandidatQG from './CandidatQG';

const calculTotals = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}
const EtablissementStatQG = ({ setVisibiliteEtablissement, region, commune, district, province, libre, ecole }) => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [visibleCommune, setVisibleCandidat] = useState(false);
    const [etablissement_d, setEtablissement_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [lists, setLists] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [isShow,setisShow]=useState(false);
    const show=()=>{
        setIsMpotra(!isMpotra);
    }
    const [isMpotra,setIsMpotra]=useState(false);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [membre, setMembre] = useState([]);
    console.log("ito commune :" + commune + "ito le district" + district);
    const [filteredList, setFilteredList] = useState([]);
    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [listss, setListss] = useState([]);
    console.log("libre ve : " + libre + " ecole ve : " + ecole)
    const getListEtablissement = () => {
        fetch(url.urlHttp + "candidat-midina/libre-liste-ecole?commune=" + commune + "&&district=" + district, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setTotal_additionel(calculTotals(data.data.map(row => row.total_1)))
                setCandidat_ecole_1(calculTotals(data.data.map(row => row.candidat_ecole_1)))
                setCandidat_libre_1(calculTotals(data.data.map(row => row.candidat_libre_1)))
                console.log("DATA RETOUR : ", data.data);

                // setLabels(data.data);
                if (ecole === false && libre === true) {
                    setListEtablissement(data.data.filter(eta => eta.etablissement.includes("Libre")));
                    setListss(data.data.filter(eta => eta.etablissement.includes("Libre")))
                    // setListEtablissement(filteredList);
                } else if (libre === false && ecole === true) {
                    setListEtablissement(data.data.filter(eta => !eta.etablissement.includes("Libre")));
                    setListss(data.data.filter(eta => !eta.etablissement.includes("Libre")))
                    // setListEtablissement(filteredList);
                } else {
                    setListEtablissement(data.data);
                    setListFi(data.data)
                    setListss(data.data)
                }
                
            })
            .then(() => {
                console.log("Filtered List: ", listEtablissement); // Log the updated filteredList here
            })
            .catch(error => {
                console.log(error);
            });
    }


    useEffect(() => {
        getListEtablissement();


    }, [])

    const[litFiltre,setListFi]=useState([])
    const stat = {
        sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).reduce((acc, val) => acc + val, 0),
        sumCandidatEcole: listEtablissement.map(entry => entry.candidat_ecole).reduce((acc, val) => acc + val, 0),
        sumTotal: listEtablissement.map(entry => entry.total).reduce((acc, val) => acc + val, 0)
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            t = t + donnee[i];
        }
        return t;
    }
    const detailEtablissement = (value) => {
        setEtablissement_d(value)
        setVisibleCandidat(true)
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
        setListEtablissement(listss);
        }
        console.log(milahatra);
    };
    const filtre = (value) => {
        const filteredData = litFiltre.filter(item => item.etablissement.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
        setListss(filteredData);
    }
    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "33.33%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "33.33%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "33.33%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "33.33%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 2,
            fontSize: 9
        }
    });

    const DistrictPDF = () => {
        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'black' }}>Types de candidats au niveau  <Text style={{ color: 'green' }}> Centre </Text> de <Text style={{ color: 'red' }}>{commune}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de  Candidats  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province  : <Text style={{ color: 'red' }}>{province}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Région  : <Text style={{ color: 'red' }}>{region}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>District  : <Text style={{ color: 'red' }}>{district}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Candidats d'École : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</Text> </Text></View>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>  Candidats Libres  : <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    {/* <View><Text style={{ fontWeight: 'bold' }}>Liste District </Text></View> */}
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Centre</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Etablissement</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}> Total de Candidats</Text>
                            </View>
                        </View>
                        {
                            listEtablissement.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.commune}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.etablissement)}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(dist.total)}</Text>
                                    </View>

                                    {/* <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                    </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const [visibiliteCandidat, setVisibiliteCandidat] = useState(false)
    if (visibleCommune == true && etablissement_d != null) {
        return (
            <CandidatQG setVisibleCandidat={setVisibleCandidat} etablissement={etablissement_d} types={'Etablissement'} commune={commune} district={district} province={province} region={region} />
        );
    } else {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Affichez un message d'erreur s'il y a une erreur */}
                        {error && <div className="alert alert-danger">{error}</div>}
                        {isShow==false&&(
                        <Row className="justify-content-center">
                            <Col md="4">
                                <Card style={{ height: "100%" }}>
                                    <CardHeader>
                                        <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{ color: "green", fontSize: '1rem', fontWeight: 'bold' }}> Au niveau Centre </span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red" }}>{province}</a></span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red" }}>{region}</a></span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red" }}>{district}</a></span>
                                            <span style={{ color: "black", fontSize: '1rem' }}>Centre : <a style={{ color: "red" }}>{commune}</a></span>
                                        </CardTitle>
                                    </CardHeader>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card style={{ height: "100%" }} >
                                    <CardHeader>
                                        <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        {ecole === true ? <span style={{ fontSize: '1rem', color: "black" }}>  Candidats d'École: <a style={{ fontSize: '1.5rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}  
                                        <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(candidat_ecole_1)}</span>
                                        </a></span> : <br />}
                                                {libre === true ? <span style={{ fontSize: '1rem', color: "black" }}>  Candidats Libres : <a style={{ fontSize: '1.5rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)} 
                                                  <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(candidat_libre_1)}</span>
                                                  </a></span> : <br />}
                                                <span style={{ fontSize: '1rem', color: "black" }}> Total de Candidats : <a style={{ fontSize: '1.5rem', color: "green", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}
                                                  <span style={{ fontSize: '0.8rem', color: "red", fontWeight: 'bold' }}>+{ANRDataUtils.formatNombre(total_additionel)}</span>
                                                  </a></span>
                                            </CardTitle>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>)}
                    </Col>
                    {isShow==false&&(<Col className="pr-1" md="4">
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Établissement :</a></label>
                            <input
                                type={'text'}
                                style={{ fontSize: '0.9rem' }}
                                className={'form-control'}
                                placeholder={'Rechercher etablissement'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup>
                    </Col>)}
                </Row>
                <Row>
                    <Col md="12">
                        <Card style={{ marginTop: '40px' }}>
                            <CardBody>
                                {isShow==false&&(<CardHeader>
                                    <CardTitle tag="h4" style={{ position: 'fi', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>
                                            Au niveau Centre </span>
                                            <span style={{ fontSize: '1rem', color: "green", fontWeight: 'bold' }}>
                                            <a style={{ fontSize: '1rem', color: "black", fontWeight: 'bold' }}>Province :    <a style={{ fontSize: '1rem', color: "red" }}>{province}</a></a>&nbsp;<br/>
                                            <a style={{ color: "black", fontSize: '1rem' , fontWeight: 'bold'}}>Région : <a style={{ color: "red" }}>{region}</a></a>&nbsp;<br/>
                                            <a style={{ color: "black", fontSize: '1rem' , fontWeight: 'bold'}}>District : <a style={{ color: "red" }}>{district}</a></a>&nbsp;<br/>
                                            <a style={{ color: "black", fontSize: '1rem', fontWeight: 'bold' }}>Centre : <a style={{ color: "red" }}>{commune}</a></a>&nbsp;<br/>
                                            {ecole === true ? <span style={{ fontSize: '1rem', fontWeight: 'bold', color: "black" }}>  Candidats d'École: <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatEcole)}</a><br/></span> : <br />}
                                            {libre === true ? <span style={{ fontSize: '1rem', fontWeight: 'bold', color: "black" }}>  Candidats Libres : <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumCandidatLibre)}</a><br/></span> : <br />}
                                            <span style={{ fontSize: '1rem', fontWeight: 'bold', color: "black" }}> Total de Candidats : <a style={{ fontSize: '1.5rem', color: "red", fontWeight: 'bold' }}>{ANRDataUtils.formatNombre(stat.sumTotal)}</a><br/></span> </span>

                                    </CardTitle>
                                </CardHeader>)}

                                <Button className="btn btn-outline-primary " onClick={e => { setVisibiliteEtablissement(false) }}>retour</Button>
                                <Button className="btn btn-outline-primary" onClick={show}>
                                    {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                                </Button>
                                <PDFDownloadLink className="btn btn-primary  fa fa-file" style={{ fontSize: '10px' }} document={<DistrictPDF />} fileName={"National"}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                    }
                                </PDFDownloadLink>
                                {isShow==false&&(<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <Table striped>
                                        <thead className="text-primary">
                                            <tr>
                                                <th style={{ fontSize: '1rem' }} onClick={() => handleSort('asc', 'etablissement')}>Établissement &nbsp;<i class="fa fa-arrow-down-a-z"></i></th>
                                                {/* <th>Nombre Candidat d'école</th>
                                            <th>Nombre Candidat libre</th> */}
                                                <th style={{ fontSize: '1rem' }} onClick={() => handleSort('desc', 'total')}>Nombre Total de Candidats &nbsp;<i class="fa fa-arrow-up-9-1"></i></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listEtablissement.map((row, index) => (
                                                    <>
                                                        <tr>
                                                            <td style={{  textAlign: 'left' }}>
                                                                {row.etablissement}
                                                            </td>
                                                            {/* <td>
                                                            {(row.candidat_ecole)}
                                                        </td>
                                                        <td>
                                                            {(row.candidat_libre)}
                                                        </td> */}
                                                            <td style={{ fontSize: '1.5rem' }}>
                                                                {ANRDataUtils.formatNombre(row.total)}<br />
                                                                {isMpotra==true&&(<span style={{ color: 'red' }}>
                                                                    +{ANRDataUtils.formatNombre(row.total_1)}
                                                                </span>)}
                                                                </td>
                                                            <td >
                                                                {
                                                                    row.total > 0 ?
                                                                        <button className={'btn btn-danger'} onClick={(e) => { detailEtablissement(row.etablissement) }}>Afficher candidat</button>
                                                                        :
                                                                        <button className={'btn btn-danger disabled'} >Afficher candidat</button>
                                                                }
                                                            </td>

                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default EtablissementStatQG;
