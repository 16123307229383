import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import url from 'urlConfig';
import authHeader from 'services/AuthHeader';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Table, Label, Input, Button, FormGroup } from 'reactstrap';

const District = () => {
    const auth_data = authHeader();
    const [data, setData] = useState([]);
    const prefectures =
        [
            {
                prefecture: "AMBATONDRAZAKA",
                district: "Amparafaravola",
                chef_district: "TSIRY Nohary Andrianina Harimanana Lalaina",
                contact: "034 07 522 57"
            },
            {
                prefecture: "AMBOSITRA",
                district: "Fandriana",
                chef_district: "RAZAZAROANABAVY  Felanirina  Hortensia",
                contact: "034 16 880 51 / 034 16 880 51"
            }
        ]
    const [data_contact, setData_contact] = useState([]);
    const [datas, setDatas] = useState();

    const handleGetListContact = async () => {
        await fetch(url.urlHttp + "materiel/contact_district", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv district ato : ", data.data);
                setData_contact(data.data);
                setDatas(data.data)
                //getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const filtre = (value, type) => {
        var filteredData = datas;
        if(type === "prefecture"){
            filteredData = datas.filter(item => {
                return item.prefecture.toUpperCase().includes(value.toUpperCase());
            });
        }
        if(type == "district"){
            filteredData = datas.filter(item => {
                return item.district.toUpperCase().includes(value.toUpperCase());
            });
        }
        if(type == "chef district"){
            filteredData = datas.filter(item => {
                return item.chef_district.toUpperCase().includes(value.toUpperCase());
            });
        }
        setData_contact(filteredData);
    }

    useEffect(() => {
        // setData(prefectures);
        handleGetListContact();
    }, [])
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle>
                                    </CardTitle>
                                </CardHeader>
                                <Row>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Prefecture :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher Prefecture'}
                                                onChange={e => filtre(e.target.value, "prefecture")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>District :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher District'}
                                                onChange={e => filtre(e.target.value, "district")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                    <Col className="pr-1" md="3"><br />
                                        <FormGroup>
                                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Chef de district :</a></label>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={'rechecher chef district'}
                                                onChange={e => filtre(e.target.value, "chef district")}
                                            />
                                        </FormGroup><br />
                                    </Col>
                                </Row>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>
                                    <Table style={{ marginTop: "40px" }} striped>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>PREFECTURES&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                                <th>DISTRICTS&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th>CHEF DE DISTRICT&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                                <th>CONTACTS&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data_contact.map((row, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.prefecture}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.district}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.chef_district}
                                                            </td>
                                                            <td style={{ fontSize: '12px' }}>
                                                                {row.contact}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default District;