import { Chart } from "chart.js";
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import '../../views/Chartfonction';
import { traite } from '../../views/Chartfonction';

const ComponentsChart = ({ id, liste, indice, labels, centre, candidat, candidat_libre }) => {

    const entete = authHeader();
    const [loader, setLoader] = useState(false);
    const [labes, setLabels] = useState([]);
    const [nombre, setNombre] = useState([])
    const [titre, setTitre] = useState();
    const [regions, setRegions] = useState([])
    const [districts, setDistricts] = useState([])
    const [communes, setCommunes] = useState([])
    const [fokontany, setFokontany] = useState([])
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([])
    const [resultat, setResultat] = useState([])
    const code_c = useRef();
    const code_r = useRef();
    const region_nom = useRef();
    const code_d = useRef();

    const [nomRegion, setNomRegion] = useState([]);
    const [nbrElecteur, setNbrElecteur] = useState([]);

    const [dataGlobal, setDataGlobal] = useState([]);
    const [nbrBureauVotes, setNbrBureauVotes] = useState([]);
    const [nbrElecteurVotes, setNbrElecteurVotes] = useState([]);
    const [nomRegionVotes, setNomRegionVotes] = useState([]);
    const [chat, setchat] = useState(null);

    // const[nombre_libre]


    const [visibleDistrict, setVisibleDistrict] = useState(false);
    const [region_sec, setregion_sec] = useState(null)
    const detailRegion = (value) => {
        setLoader(true);
        setregion_sec(value)
        setVisibleDistrict(true)
        setLoader(false);
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Centre',
                backgroundColor: 'green',
                // borderColor: 'rgba(75, 192, 192, 1)',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                // hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBackgroundColor: '#000',
                // hoverBorderColor: 'rgba(75, 192, 192, 1)',
                hoverBorderColor: '#9A9A9A',
                data: centre,
            }
            ,
            {
                label: 'Candidat Ecole ',
                backgroundColor: 'red',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                hoverBackgroundColor: '#000',
                hoverBorderColor: '#9A9A9A',
                data: candidat,
            },
            ,
            {
                label: 'Candidat Libre',
                backgroundColor: 'red',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                hoverBackgroundColor: '#000',
                hoverBorderColor: '#9A9A9A',
                data: candidat_libre,
            }
        ],
    };


    useEffect(() => {
        // let data= {
        //         labels: labels,
        //         datasets: [
        //             {
        //                 label: 'Centre',
        //                 data: centre,
        //                 backgroundColor:'Green',
        //             },
        //             {
        //                 label: 'Candidat',
        //                 data: candidat,
        //                 backgroundColor: 'Red',
        //             },
        //             {
        //                 label: 'Dataset 3',
        //                 data: candidat_libre,
        //                 backgroundColor:'Black',
        //             },
        //         ]
        //     };
        // console.log('iiiiiiiiiiiiiiiiiiiiiii',data);
        setchat(traite.CreateChartGRoup(chat, Chart, document.getElementById(id), {
            labels: labels,
            datasets: [
                {
                    label: 'Capacité de centres',
                    data: centre,
                    backgroundColor: '#B9B9B0',
                    stack: 'Stack 1',
                },
                {
                    label: "Candidat d'école",
                    data: candidat,
                    backgroundColor: 'Red',
                    stack: 'Stack 0',
                },
                {
                    label: "Candidat libre",
                    data: candidat_libre,
                    backgroundColor: 'Green',
                    stack: 'Stack 0',
                },
            ]
        }, 'ISIQIQIQIQI'))
    }, [indice])

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [regionSelect, setRegionselect] = useState('');
    const handleSelect = (key) => {

        detailRegion(key)
    }
    return (
        <div className="content">

            <Row>
                <Col>
                    <Card>
                        <canvas height='100' width='400' id={id}></canvas>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ComponentsChart;