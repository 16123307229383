import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardImg, Col, Row } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';


import Serie from 'views/Serie/Serie';
import GN from '../../assets/img/1.png';
import PT from '../../assets/img/2.png';
import TC from '../../assets/img/3.png';
import '../Serie/style.css';


const Specialite = () => {
    const auth_data = authHeader();
    const [loader, setLoader] = useState(false);
    const [visibleSerie, setVisibleSerie] = useState(false);
    const [specialite_sec, setSpecialite_sec] = useState(null);
    const [data_provinces, setData_provinces] = useState([]);
    const [nombre_total, setNombre] = useState(0);

    const [total_additionel, setTotal_additionel] = useState(0);
    const image = [
        GN, PT, TC
    ]
    const data_spec = [
        {
            nom: "General",
            total: 123,
        },
        {
            nom: "Protechnique",
            total: 103,
        }, {
            nom: "Technique",
            total: 133,
        }
    ]
    let index = 0;
    const getProvinces = () => {
        setLoader(true);
        fetch(url.urlHttp + 'serie-bobon/liste-specialite', {
            method: "GET",
            // headers: { "Content-Type": "application/json" }
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then((response) => response.json())
            .then(data => {
                setData_provinces(data.data);
                console.log("DATA ", data_provinces);
                setLoader(false);
            })
            .catch(error => { console.log(error) })
    }


    const detailSpec = (spec) => {
        setSpecialite_sec(spec);
        setVisibleSerie(true);
    }
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);

    useEffect(() => {
        getProvinces();
    }, [])
    let total = 0;
    var total_1 = 0;
    for (let i = 0; i < data_provinces.length; i++) {
        total += data_provinces[i].nombre_candidat;
        total_1 += data_provinces[i].nombre_candidat_1;
    }

    if (visibleSerie == true && specialite_sec != null) {
        return (
            <>
                <Serie setVisibleSerie={setVisibleSerie} Specialite={specialite_sec} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row className="justify-content-center">
                    <Col md="auto">
                        <Card style={{ width: '400px', textAlign: 'center' }}>
                            <CardBody>
                                <h5 style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black', marginBottom: '20px' }}>Nombre total de candidats</h5>
                                <h2 style={{ fontSize: '2rem', fontWeight: 'bold', color: 'green', marginBottom: '0' }}>
                                    {ANRDataUtils.formatNombre(total)}
                                </h2>
                                {isMpotra==true&&(<h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red', marginBottom: '0' }}>
                                    +{ANRDataUtils.formatNombre(total_1)}
                                </h2>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Button className="btn btn-outline-primary" onClick={show}>
                        {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                    </Button>
                </Row>


                <Row>
                    {
                        data_provinces.map((row, index) => (
                            <Col md="4" key={index}>
                                <Card
                                    className="animated-card"
                                    style={{ padding: '10px', textAlign: 'center', maxHeight: '100%', transition: 'transform 0.3s, box-shadow 0.3s' }}
                                    onClick={(e) => { detailSpec(row) }}
                                >
                                    <CardBody className="d-flex flex-column justify-content-center align-items-center text-center">
                                        <CardImg
                                            src={image[index]}
                                            alt="GN"
                                            fluid
                                            style={{ width: '20%', height: '20%', marginBottom: '10px' }}
                                        />
                                        <div style={{ marginBottom: '10px' }}>
                                            <div className="icon-big text-center icon-warning">
                                                <img width='30px' height='auto' alt='' className="img-responsive mb-2" />
                                            </div>
                                            <p className="font-weight-bold" style={{ color: 'green', fontSize: '1.2rem', marginBottom: '3px' }}>SPÉCIALITÉ : </p>
                                            <p style={{ fontSize: '1rem', color: "black", marginBottom: '10px' }}>{row.specialite}</p>
                                        </div>
                                        <b style={{ fontSize: '1.5rem', color: "green" }}>{ANRDataUtils.formatNombre(row.nombre_candidat)}</b>
                                        {isMpotra==true&&(<b style={{ fontSize: '1.2rem', color: "red" }}>+{ANRDataUtils.formatNombre(row.nombre_candidat_1)}</b>)}
                                    </CardBody>
                                </Card>
                            </Col>
                        ))
                    }



                </Row>
            </div>
        );
    }
}

export default Specialite;