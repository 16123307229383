import { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Row, Table } from 'reactstrap';
import authHeader from 'services/AuthHeader';
import url from 'urlConfig';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';
import EtablissementV from './EtablissementV';
import RegionV from './RegionV';
const calculTotal = (donnee) => {
    let t = 0;
    let i = 0;
    for (i = 0; i < donnee.length; i++) {
        t = t + donnee[i];
    }
    return t;
}

const ProvinceV = () => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [labes, setLabels] = useState([]);
    const [provinces, setProvinces] = useState([])
    const [districts, setRegions] = useState([])
    // const [communes, setCommunes] = useState([])
    // const [fokontany, setFokontany] = useState([])
    const [electeurs, setElecteurs] = useState([])

    const [libre, setLibre] = useState(true);
    const [ecole, setEcole] = useState(true);
    const vueEcole = () => {
        setLibre(false);
        setEcole(true);
        console.log("Candidat d'école")
    }
    const vueLibre = () => {
        setLibre(true);
        setEcole(false);
        console.log("Candidat libre")

    }
    const vueTotal = () => {
        setLibre(true);
        setEcole(true);
        console.log("Candidat d'école et libre")

    }
    let datas = {
        region: ''
    }
    const [anomalie, setAomalie] = useState(0)

    const [list, setList] = useState([]);
    const getProvinces = () => {
        setLoader(true);
        fetch(url.urlHttp + "validation/province", {
            method: "get",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        }).then(response => response.json())
            .then((res) => {
                // console.log("**************************** TANJONA : "+ res.data);
                // console.log("**************************** 123456789 " + JSON.stringify(res.data));
                setAomalie(calculTotal(res.data.map(row => row.refuser)))
                setList(res.data);

            })
            .catch(error => { console.log(error) })
    }

    const [nbr_province, setNbr_province] = useState(0);
    const [nbr_Region, setNbr_Region] = useState(0);
    const [nbr_Commune, setNbr_Commune] = useState(0);
    const [nbr_Fokotany, setNbr_Fokotany] = useState(0);
    const getCardDASHBOARD = () => {
        setLoader(true);
        fetch(url.urlHttp + "quartier-nouveau/dashboard", {
            method: "GET",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setNbr_province(data.data.nbr_province);
                setNbr_Region(data.data.nbr_dsitrict);
                setNbr_Commune(data.data.nbr_commune);
                setNbr_Fokotany(data.data.nbr_fokotany);
            })
            .catch(error => { console.log(error) })
    }
    const [visibleRegion, setVisibleRegion] = useState(false);
    const [province_sec, setprovince_sec] = useState(null)
    const detailProvince = (value) => {
        setprovince_sec(value)
        setVisibleRegion(true)
    }
    const detailsList = (value) => {
        setprovince_sec(value)
        setVisibleCandidat(true)
    }


    const data = {
        labels: labes,
        datasets: [
            {
                label: 'QG',
                backgroundColor: '#e67e22',
                borderColor: '#9A9A9A',
                borderWidth: 1,
                // hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)',
                data: electeurs,
            }
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [provinceSelect, setProvinceselect] = useState('');
    useEffect(() => {
        getProvinces();

        getCardDASHBOARD();
        //getProvincesALL()
        //getNbrElecteurVotes(),

    }, [])

    console.log("azertyuioppapiezpouazopeua" + (list));
    const handleSelect = (key) => {

        detailProvince(key)
    }

    const handleSort = (order, key) => {
        const sortedWords = [...list]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setList(sortedWords);
    };

    const [visibiliteCandidat, setVisibleCandidat] = useState(false)
    if (visibleRegion == true && province_sec != null) {
        return (
            <>
                <RegionV setVisibleRegion={setVisibleRegion} province={province_sec} libre={libre} ecole={ecole} />
            </>
        )
    }
    else if (visibiliteCandidat == true && province_sec != null) {
        return (
            <>
                <EtablissementV types='Province' setVisibiliteEtablissement={setVisibleCandidat} commune={''} district={''} province={province_sec} region={''} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row className="justify-content-center">

                    {/* <Col lg="4" md="5" sm="5"> */}
                    <Card onClick={vueEcole} style={{ height: "100%", paddingRight: "40px" }}>
                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CardTitle tag="p" className="text-center" style={{ fontSize: '1.3rem', fontWeight: 'bold', color: 'black' }}>Nombre total de Candidats Libres avec Anomalie</CardTitle>
                            <h1 className="text-center" style={{ fontWeight: 'bold', color: 'red', fontSize: '1.5rem', }}>
                                {/* {(dashboard_qg.fokotany_qg)}  */}
                                {ANRDataUtils.formatNombre(anomalie)}
                            </h1>

                        </CardBody>
                    </Card>
                    {/* </Col> */}


                </Row>

                <Row>
                    <Table striped style={{ marginTop: "40px" }}>
                        <thead className="text-primary">
                            <tr>
                                <th style={{ fontSize: '13px' }} onClick={() => handleSort('asc', 'province')}>Province &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                {ecole === true ? <th  style={{ fontSize: '13px' }} onClick={() => handleSort('desc', 'refuser')}>Nombre Candidats avec anomalie &nbsp;<i class="fa fa-arrow-down-9-1"></i></th> : ''}
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((row) => (
                                    <>
                                        <tr>
                                            <td style={{ fontSize: '13px' }}>
                                                {row.province}
                                            </td>
                                            {ecole === true ?
                                                <td style={{ fontSize: '13px' }}>
                                                    {ANRDataUtils.formatNombre(row.refuser)}
                                                </td>
                                                :
                                                ''}
                                            <td style={{ fontSize: '13px' }}>
                                                {
                                                    row.refuser > 0 ?
                                                        <button className={'btn btn-danger'} onClick={(e) => { detailProvince(row.province) }}>Afficher Région</button>
                                                        :
                                                        <button className={'btn btn-danger disabled'} >Afficher Région</button>
                                                }
                                            </td>
                                            {/* <td style={{ fontSize: '13px' }}>
                                                {
                                                    row.refuser > 0 ?
                                                        <button className={'btn btn-danger'} onClick={(e) => { detailsList(row.province) }}>Afficher Listes</button>
                                                        :
                                                        <button className={'btn btn-danger disabled'} >Afficher Listes</button>
                                                }
                                            </td> */}

                                        </tr>
                                    </>
                                ))}
                        </tbody>
                    </Table>
                </Row>
            </div>
        );
    }
}

export default ProvinceV;