import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input, Row, Col,NavItem, Button
} from "reactstrap";

import routes from "routes.jsx";

import { DatePicker } from 'reactstrap-date-picker'
import url from "urlConfig";
import authHeader from "services/AuthHeader";
import ANRDataUtils from "utils/Utils";

function Header(props) {

  const auth_data = authHeader()
  const navigate=useNavigate()

  const handleLogout = async () => {
    await fetch(url.urlHttp + 'auth/logout', {
      method: 'POST',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
    .then(response => response.json())
    .then(data => {
      //console.log("DATA BE  MIVOKA : ", data)
      navigate("/login")
      localStorage.removeItem("app_data");
      ANRDataUtils.successAlert(data.message)
      
    }).catch(err => {
      console.log("ERROR : ", err)
    })
  }

  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    // Update the current date every second
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  const getBrand = () => {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        if (prop.name==='Simulation Bureau de vote') {
          brandName =prop.name+ '\n (nombre des voix à viser)'
        }
        brandName = prop.name;
      }
      return null;
    });
    return brandName.toUpperCase();
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };

  const [startDate, setStartDate] = useState(new Date());

  const handleChangeDateDuJour = (value) => {
    value = new Date();
    setStartDate(value);
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    };

  }, [location]);
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
          (color === "transparent" ? "navbar-transparent " : "")
      }

    >
      <Container fluid style={{position:'relative'}}>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="/">{getBrand()}</NavbarBrand>

        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          
          <Nav navbar>
            {/* <NavItem>
              <Link to="#pablo" className="nav-link btn-magnify">
                <i className="nc-icon nc-layout-11" />
                <p>
                  <span className="d-lg-none d-md-block">Stats</span>
                </p>
              </Link>
            </NavItem> */}
            {/* <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
            >
              <DropdownToggle caret nav>
                <i className="nc-icon nc-alert-circle-i" />
                <p>
                  <span className="d-lg-none d-md-block">Parametre du compte</span>
                </p>
              </DropdownToggle> */}
            {/* <DropdownMenu right>
                <DropdownItem tag="a">Parametre du compte</DropdownItem>
                <DropdownItem tag="a">Deconnexion</DropdownItem> */}
            {/* <DropdownItem tag="a">Something else here</DropdownItem> */}
            {/* </DropdownMenu>
            </Dropdown> */}
            {/* <NavItem>
              <Link to="#pablo" className="nav-link btn-rotate">
                <i className="nc-icon nc-settings-gear-65" />
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </Link>
            </NavItem> */}
            {/* <Col className="pl-4" md="9">
              <span style={{ fontWeight: 'bold', fontSize: '1.1vw', color: '#973116' }}>Situation en date du : </span>
            </Col> */}

            <Row >
              <Col >
                <FormGroup style={{  padding:'5px', textAlign:'center'}}>
                <Col style={{padding:'5px', backgroundColor:'white', borderRadius:'6px'}}>
                <span style={{fontWeight:'bold', fontSize:'1.7em', color:'black'}}>{currentDate.toLocaleString()}</span>
                </Col>
                </FormGroup>
              </Col>
            </Row>
            
          </Nav>&nbsp;&nbsp;&nbsp;
          <Button style={{backgroundColor:'#ca3310'}} onClick={handleLogout}>
                <i className="nc-icon nc-settings-gear-65" /> Deconnexion
          </Button>
          
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
