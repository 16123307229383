import React, { useState, useRef } from "react";
import { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
    Table,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input
} from "reactstrap";
import url from "urlConfig";
import authHeader from 'services/AuthHeader';
import ANRDataUtils from 'utils/Utils';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';

const Nouveau_logistique = () => {

    const auth_data = authHeader();
    const [articles, setArticles] = useState([]);
    const [article, setArticle] = useState('');
    const [conclave, setConclave] = useState('Antananarivo');
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    const [province_arrive, setProvince_arrive] = useState('');
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState('');
    const [region_arrive, setRegion_arrive] = useState('');
    const [prefecture, setPrefecture] = useState('');
    const [prefecture_arrive, setPrefecture_arrive] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [district_arrive, setDistrict_arrive] = useState('');
    const [communes, setCommunes] = useState([]);
    const [etablissement, setEtablissement] = useState('');
    const [etablissement_arrice, setEtablissement_arrive] = useState('');
    const [salle, setSalle] = useState('');
    const [salles, setSalles] = useState([]);
    const [typeCandidat, setTypeCandidat] = useState('');
    const [typeCandidats, setTypeCandidats] = useState([]);
    const [commune, setCommune] = useState('');
    const [commune_arrive, setCommune_arrive] = useState('');
    const [disiableEcole, setDisableEcole] = useState(false);
    const [ecole, setEcole] = useState('');
    const [specialites, setSpecialites] = useState([]);
    const [specialite, setSpecialite] = useState('');
    const [secteurs, setSecteurs] = useState([]);
    const [secteur, setSecteur] = useState('');
    const [series, setSeries] = useState([]);
    const [serie, setSerie] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [genre, setGenre] = useState('');
    const [date_naissance, setDate_naissance] = useState('');
    const [addresse, setAddresse] = useState('');
    const [numero, setNumero] = useState('');
    const [titre, setTitre] = useState('');
    const [lieu_naissance, setLieu_naissance] = useState('');
    const [code_commune, setCode_commune] = useState('');
    const [listEtablissement, setListEtablissement] = useState([]);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [depart, setDepart] = useState('');
    const [arrive, setArrive] = useState('');
    const [seo, setSeo] = useState(false);
    const [materiel, setMateriel] = useState('');
    const [date_envoi, setDate_envoi] = useState('');
    const [q_e, setq_e] = useState(0);
    const [responsable_envoi, setResponsable_envoi] = useState('');
    const [fonction_responsable_envoi, setFonction_responsable_envoi] = useState('');
    const [contact_envoi, setContact_envoi] = useState('');
    const [etat_envoi, setEtat_envoi] = useState('');
    const [photo_envoi, setPhoto_envoi] = useState('');
    const [date_recu, setDate_recu] = useState('');
    const [q_r, setq_r] = useState(0);
    const [responsable_recu, setResponsable_recu] = useState('');
    const [fonction_responsable_recu, setFonction_responsable_recu] = useState('');
    const [contact_recu, setContact_recu] = useState('');
    const [etat_recu, setEtat_recu] = useState('');
    const [photo_recu, setPhoto_recu] = useState('');
    const [observation, setObservation] = useState('');
    const [camion_trans, setCamion_trans] = useState('');
    const [marque, setMarque] = useState('');
    const videoRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [photoData, setPhotoData] = useState(null);
    const [modalEnvoi, setModalEnvoi] = useState(false);
    const [code_etablissement, setCode_etablissement] = useState(0);
    const toggleEnvoi = () => setModalEnvoi(!modalEnvoi);
    const [modalRecu, setModalRecu] = useState(false);
    const toggleRecu = () => setModalRecu(!modalRecu);
    const [modalArticle, setModalArticle] = useState(false);
    const toogleArticle = () => setModalArticle(!modalArticle);
    const [type, setType] = useState('');
    const [base_rht, setBaseRht] = useState([]);
    const [types, setTypes] = useState('');
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const [visibleElement, setVisibleElement] = useState(false);
    const [suivant, setSuivant] = useState(false);
    const [avant, setAvant] = useState(false);
    const [first, setFirst] = useState(true);
    const [don, setDon] = useState([]);
    const [modalPhoto, setModalPhoto] = useState(false);
    const [prefs, setPrefs] = useState([]);
    const [pref_arrive, setPref_Arrive] = useState('');
    const [pref_depart, setPref_Depart] = useState('');
    const tooglePhoto = () => {
        setModalPhoto(!modalPhoto);
    }
    const [modalPhotoRecu, setModalPhotoRecu] = useState(false);
    const tooglePhotoRecu = () => {
        setModalPhotoRecu(!modalPhotoRecu);
    }

    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].date_envoye != null) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListTsyMisy(newListTsyMisy);
        console.log(JSON.stringify(newListMisy) + "recu");
        console.log(JSON.stringify(newListTsyMisy) + "tsisy");
    }

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            setIsCameraOn(true);
        } catch (err) {
            console.error('Error accessing the camera:', err);
        }
    };

    const takePhoto = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext('2d').drawImage(videoRef.current, 0, 0);

        const imageUrl = canvas.toDataURL('image/jpeg');
        setPhotoData(imageUrl); // Save the base64 string to state
    };

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegionArr = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province_arrive, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        fetch(url.urlHttp + "materiel/dist?province=" + prefecture, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST PREF", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrictArr = () => {
        fetch(url.urlHttp + "materiel/dist?province=" + prefecture_arrive, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommune = () => {
        fetch(url.urlHttp + "tanana/commune?region=&&district=" + district.toUpperCase() + "&&commune=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCommuneArr = () => {
        // alert(province_arrive+"/"+district_arrive)
        fetch(url.urlHttp + "tanana/commune?region=&&district=" + district_arrive.toUpperCase() + "&&commune=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA Com", data.data);
                setCommunes(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCentre_examen = () => {
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune + "&&district=" + district.toUpperCase(), {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setListEtablissement(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCentre_examenArr = () => {
        fetch(url.urlHttp + "centre-milala/liste-centre?commune=" + commune_arrive + "&&district=" + district_arrive.toUpperCase(), {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setListEtablissement(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const role_centre = () => {
        fetch(url.urlHttp + "role_centre/role", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                setRoles(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getSpecialite = () => {
        fetch(url.urlHttp + "candidat/specialite", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA spec", data.data);
                setSpecialites(data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const showElement = () => {
        if (depart != "") {
            setSeo(true);
        } else {
            setSeo(false);
        }
        setAvant(true);
        handleArticles();
        // const inputElement = document.getElementById("CONCLAVE").value;
        // alert(inputElement) // Hide the element
        // document.getElementById(arrive).display = 'block';
    }

    const retour = () => {
        setAvant(true);
        setSuivant(false);
        setFirst(true);
    }

    const handleTitre = () => {
        if (genre == "M") {
            setTitre('Monsieur')
        } else {
            setTitre("Mademoiselle");
        }
    }

    const handleCentre = (commune) => {
        for (let i = 0; i < communes.length; i++) {
            if (communes[i].commune == commune) {
                setCode_commune(communes[i].code_commune);
            }
        }
    }

    const handleFileInputChange = (e, type) => {
        const file = e.target.files[0];
        e.preventDefault();
        if (!file) return;
        // Convert the selected file to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result;
            if (type == "env") {
                setPhoto_envoi(base64String);
            } else {
                setPhoto_recu(base64String);
            }
        };
    };

    const [list, setList] = useState([]);
    const handleSort = (order, key) => {
        const sortedWords = [...list]; // Faites une copie pour éviter de muter l'état directement
        sortedWords.sort((a, b) => {
            if (typeof a[key] === 'string') {
                if (order === 'asc') {
                    return a[key].localeCompare(b[key]); // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                }
            } else if (typeof a[key] === 'number') {
                if (order === 'asc') {
                    return a[key] - b[key]; // Trie par ordre croissant
                } else if (order === 'desc') {
                    return b[key] - a[key]; // Trie par ordre décroissant
                }
            }
            return 0;
        });
        setList(sortedWords);
    };

    const handleGetList = async (type) => {
        let urls = "";
        if (type == "CONCLAVE_OFFICE") {
            urls = url.urlHttp + "materiel/data-province?materiel=";
        }
        if (type == "OFFICE_REGION") {
            urls = url.urlHttp + "materiel/data-region?province=&&materiel=&&date_envoi=&&date_recu=";
        }
        if (type == "REGION_DISTRICT") {
            urls = url.urlHttp + "materiel/data-district?materiel=&&region=";
        }
        if (type == "DISTRICT_CENTRE") {
            urls = url.urlHttp + "materiel/data-centre?materiel=&&district=";
        }
        if (type == "CENTRE_ETABLISSEMENT") {
            urls = url.urlHttp + "materiel/data-etab?materiel=&&centre=";
        }
        if (type == "ETABLISSEMENT_SALLE") {
            urls = url.urlHttp + "materiel/data-salle?materiel=&&etab=";
        }
        await fetch(urls, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA rhtr ato : ", data.data);
                setBaseRht(data.data);
                getsListTsisy(data.data);
                setTypes(type);
            })
            .catch(error => {
                console.log(error);
            });
    }


    const save = async () => {
        const data_send = {
            type: depart + "_" + arrive,
            conclave: conclave,
            office: province,
            region: pref_depart,
            district: district,
            centre: commune,
            commune: commune,
            etablissement_examen: etablissement,
            centre_examen: etablissement,
            salle: salle,
            office_arr: province_arrive,
            region_arr: pref_arrive,
            district_arr: district_arrive,
            centre_arr: commune_arrive,
            commune_arr: commune_arrive,
            etablissement_examen_arr: etablissement_arrice,
            centre_examen_arr: etablissement_arrice,
            materiel: article,
            date_envoye: date_envoi,
            vehicule_transport: camion_trans + " " + marque,
            quantite_envoyer: q_e,
            q_e: q_e,
            responsable_envoye: responsable_envoi,
            fonction_envoi: fonction_responsable_envoi,
            contact_envoye: contact_envoi,
            etat_envoye: etat_envoi,
            photo_envoi: photo_envoi,
            date_reception: date_recu,
            quantite_recu: q_r,
            q_r: q_r,
            responsable_reception: responsable_recu,
            fonction_responsable: fonction_responsable_recu,
            contact_reception: contact_recu,
            etat_recu: etat_recu,
            photo_recu: photo_recu,
            observation: observation
        }
        // alert(data_send.type);
        handleGetList(data_send.type);
        await fetch(url.urlHttp + 'materiel/ajout', {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(data_send)
        }).then((response) => response.json())
            .then((data) => {
                console.log(data.data);
                ANRDataUtils.successAlert(data.message);
                handleGetList(data_send.type);
            })
            .catch((error) => console.log('error', error))
    }

    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }

    const handlePrefsArr = (e) => {
        setPref_Arrive(e.target.options[e.target.selectedIndex].text);
    }

    const handlePrefsDep = (e) => {
        setPref_Depart(e.target.options[e.target.selectedIndex].text);
    }

    function handleTakePhotoEnvoi(dataUri) {
        const convertImageToBase64 = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const dataURL = canvas.toDataURL('image/jpeg');
                    resolve(dataURL);
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = url;
            });
        };

        convertImageToBase64(dataUri)
            .then((base64Image) => {
                console.log(base64Image);
                setPhoto_envoi(base64Image)
                toggleEnvoi();
                tooglePhoto();
            })
            .catch((error) => {
                console.error('Erreur lors de la conversion de l\'image en Base64 :', error);
            });
    }

    function handleTakePhotoRecu(dataUri) {
        const convertImageToBase64 = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const dataURL = canvas.toDataURL('image/jpeg');
                    resolve(dataURL);
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = url;
            });
        };

        convertImageToBase64(dataUri)
            .then((base64Image) => {
                console.log(base64Image);
                setPhoto_recu(base64Image)
                toggleRecu();
                tooglePhotoRecu();
            })
            .catch((error) => {
                console.error('Erreur lors de la conversion de l\'image en Base64 :', error);
            });
    }

    const next = () => {
        setAvant(false);
        setSuivant(true);
        setFirst(false);
    }

    const handleSalle = () => {
        fetch(url.urlHttp + "materiel/data-salle?materiel=&&etab=" + etablissement, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv salle ato : ", data.data);
                // alert(data.data)
                setSalles(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSalleArr = () => {
        fetch(url.urlHttp + "materiel/data-salle?materiel=&&etab=" + etablissement_arrice, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv salle ato : ", data.data);
                // alert(data.data)
                setSalles(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }


    const handleArticles = () => {
        fetch(url.urlHttp + "materiel/liste", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA article ato : ", data.data);
                setArticles(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const ajoutArticle = async () => {
        const donne = {
            nom_materiel: article
        }
        await fetch(url.urlHttp + "materiel/add", {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(donne)
        })
            .then(response => response.json())
            .then(data => {
                ANRDataUtils.successAlert(data.message);
                handleArticles();
                setModalArticle(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetListContact = async () => {
        await fetch(url.urlHttp + "materiel/prefs", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA csv district ato : ", data.data);
                // setData_contact(data.data);
                setPrefs(data.data);
                // setDatas(data.data)
                //getsListTsisy(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const updatePhoto = (type, photo) => {
        if (type == 'env') {
            setPhoto_envoi(photo);
            tooglePhoto();
        } else {
            setPhoto_recu(photo);
            tooglePhotoRecu();
        }
    }

    useEffect(() => {
        handleGetList("CONCLAVE_OFFICE");
        handleGetListContact();
        getProvinces();
        getSpecialite();
        role_centre();
        handleArticles();
    }, [])

    return (
        <>
            <Modal isOpen={modalEnvoi} toggle={toggleEnvoi} size='xl'>
                <ModalHeader toggle={toggleEnvoi}>Prendre photo</ModalHeader>
                <ModalBody>
                    <div>
                        <Camera
                            idealFacingMode={FACING_MODES.USER}
                            idealResolution={{ width: 640, height: 480 }}
                            imageType={IMAGE_TYPES.JPG}
                            imageCompression={0.97}
                            onTakePhoto={(dataUri) => handleTakePhotoEnvoi(dataUri)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleEnvoi}>
                        Quitter
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalRecu} toggle={toggleRecu} size='xl'>
                <ModalHeader toggle={toggleRecu}>Prendre photo</ModalHeader>
                <ModalBody>
                    <div>
                        <Camera
                            idealFacingMode={FACING_MODES.USER}
                            idealResolution={{ width: 640, height: 480 }}
                            imageType={IMAGE_TYPES.JPG}
                            imageCompression={0.97}
                            onTakePhoto={(dataUri) => handleTakePhotoRecu(dataUri)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleRecu}>
                        Quitter
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalArticle} toggle={toogleArticle}>
                <ModalHeader toggle={toogleArticle}>Ajout Article</ModalHeader>
                <ModalBody>
                    <Row>
                        <FormGroup className="md-12" style={{ left: "70px" }}>
                            <Input type="text" value={article} onChange={(e) => setArticle(e.target.value)} placeholder="nom" className="form-control" style={{ width: "320px" }}></Input>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="md-12" style={{ left: "70px" }}>
                            <Button className="btn btn-outline-primary" style={{ width: "320px" }} onClick={ajoutArticle}>
                                Enregister
                            </Button>
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toogleArticle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalPhoto} toggle={tooglePhoto}>
                <ModalHeader toggle={tooglePhoto}>Photo</ModalHeader>
                <ModalBody>
                    <Row>
                        <FormGroup className="md-12" style={{ width: "100%", height: "100%" }}>
                            <img src={photo_envoi} alt="" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="md-12" style={{ left: "70px" }}>
                            <Col>
                                <Button className="btn btn-outline-primary" style={{ width: "320px" }} onClick={() => updatePhoto('env', photo_envoi)}>
                                    Enregister
                                </Button>
                            </Col>
                            <Col>
                                <Button className="btn btn-outline-primary" style={{ width: "320px" }} onClick={() => {
                                    tooglePhoto();
                                    toggleEnvoi();
                                }}>
                                    Retour
                                </Button>
                            </Col>
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={tooglePhoto}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalPhotoRecu} toggle={tooglePhotoRecu}>
                <ModalHeader toggle={tooglePhotoRecu}>Photo</ModalHeader>
                <ModalBody>
                    <Row>
                        <FormGroup className="md-12" style={{ width: "100%", height: "100%" }}>
                            <img src={photo_recu} alt="" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="md-12" style={{ left: "70px" }}>
                            <Col>
                                <Button className="btn btn-outline-primary" style={{ width: "320px" }} onClick={() => updatePhoto('rec', photo_recu)}>
                                    Enregister
                                </Button>
                            </Col>
                            <Col>
                                <Button className="btn btn-outline-primary" style={{ width: "320px" }} onClick={() => {
                                    tooglePhotoRecu();
                                    toggleRecu();
                                }}>
                                    Retour
                                </Button>
                            </Col>
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={tooglePhotoRecu}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
            <Card style={{ marginTop: "100px", marginLeft: "20px", marginRight: "20px" }} >
                <CardHeader></CardHeader>
                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                    AJOUT DE LOGISTIQUE
                </CardTitle>
                <CardBody>
                    <Form >
                        <Row>
                            {
                                first == true && (
                                    <><Col className="pr-3" md="4">
                                        <FormGroup className="mr-3">
                                            <label style={{ border: 'type-round' }}><a tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green", fontSize: "20px" }}> DEPART :</a> </label><br />
                                            <select className={'form-control'} value={depart} onChange={e => { { setDepart(e.target.value); } }}>
                                                <option value={'0'}></option>
                                                <option value="CONCLAVE">CONCLAVE</option>
                                                <option value="OFFICE">DOB/DEN</option>
                                                <option value="REGION">PREFECTURE</option>
                                                <option value="DISTRICT">DISTRICT</option>
                                                <option value="CENTRE">CENTRE</option>
                                                <option value="ETABLISSEMENT">ETABLISSEMENT</option>
                                            </select>
                                        </FormGroup>
                                    </Col><Col className="pr-3" md="4">
                                            <br /><br />
                                            <Button className="btn btn-primary" onClick={showElement}>
                                                Valider
                                            </Button>
                                        </Col></>
                                )
                            }
                            {
                                first == false && (
                                    <><Col className="pr-3" md="4">
                                        <label style={{ border: 'type-round' }}><a tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green", fontSize: "20px" }}> ARRIVEE :</a> </label><br />
                                        <select className={'form-control'} value={arrive} onChange={e => { { setArrive(e.target.value); } }}>
                                            <option value={'0'}></option>
                                            {depart == "CONCLAVE" && (
                                                <>
                                                    <option value="OFFICE">DOB/DEN</option>
                                                    <option value="REGION">PREFECTURE</option>
                                                    <option value="DISTRICT">DISTRICT</option>
                                                    <option value="CENTRE">CENTRE</option>
                                                    <option value="ETABLISSEMENT">ETABLISSEMENT</option>
                                                    <option value="SALLE">SALLE</option>
                                                </>
                                            )}
                                            {depart == "OFFICE" && (
                                                <>
                                                    <option value="REGION">PREFECTURE</option>
                                                    <option value="DISTRICT">DISTRICT</option>
                                                    <option value="CENTRE">CENTRE</option>
                                                    <option value="ETABLISSEMENT">ETABLISSEMENT</option>
                                                    <option value="SALLE">SALLE</option>
                                                </>
                                            )}
                                            {depart == "REGION" && (
                                                <>
                                                    <option value="DISTRICT">DISTRICT</option>
                                                    <option value="CENTRE">CENTRE</option>
                                                    <option value="ETABLISSEMENT">ETABLISSEMENT</option>
                                                    <option value="SALLE">SALLE</option>
                                                </>
                                            )}
                                            {depart == "DISTRICT" && (
                                                <>
                                                    <option value="CENTRE">CENTRE</option>
                                                    <option value="ETABLISSEMENT">ETABLISSEMENT</option>
                                                    <option value="SALLE">SALLE</option>
                                                </>
                                            )}
                                            {depart == "CENTRE" && (
                                                <>
                                                    <option value="ETABLISSEMENT">ETABLISSEMENT</option>
                                                    <option value="SALLE">SALLE</option>
                                                </>
                                            )}
                                            {depart == "ETABLISSEMENT" && (
                                                <option value="SALLE">SALLE</option>
                                            )}
                                        </select>
                                    </Col><br /></>
                                )
                            }
                            {
                                seo == true && (
                                    <>
                                        {
                                            avant == true && (
                                                <>
                                                    {
                                                        depart == "CONCLAVE" && (
                                                            <><Col className="pr-4" md="4">

                                                            </Col><Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>CONCLAVE :</a></label><br />
                                                                        <select className={'form-control'} value={conclave} onChange={e => setConclave(e.target.value)} id="CONCLAVE" disabled="true">
                                                                            <option value={'0'}></option>
                                                                            <option value='Antananarivo'>Antananarivo</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        depart == "OFFICE" && (
                                                            <><Col className="pr-4" md="4">

                                                            </Col>
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                        <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                                            <option value={'0'}></option>
                                                                            {provinces?.map(province => (
                                                                                <option value={province.province} >{province.province}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        depart == "REGION" && (
                                                            <><Col className="pr-4" md="4">

                                                            </Col>
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                        <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                                            <option value={'0'}></option>
                                                                            {provinces?.map(province => (
                                                                                <option value={province.province} >{province.province}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                        <select
                                                                            className={'form-control'}
                                                                            value={prefecture}
                                                                            onChange={(e) => {
                                                                                setPrefecture(e.target.value);
                                                                                handlePrefsDep(e);  // Appel de la première fonction
                                                                            }}
                                                                            onClick={handleDistrict}  // Si vous devez aussi appeler cette fonction au clic
                                                                        >
                                                                            <option value={'0'}></option>
                                                                            {prefs?.map((pref) => (
                                                                                <option key={pref.region} value={pref.region}>
                                                                                    {pref.prefecture}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        depart == "DISTRICT" && (
                                                            <><Col className="pr-4" md="4">

                                                            </Col>
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                        <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                                            <option value={'0'}></option>
                                                                            {provinces?.map(province => (
                                                                                <option value={province.province} >{province.province}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                        <select
                                                                            className={'form-control'}
                                                                            value={prefecture}
                                                                            onChange={(e) => {
                                                                                setPrefecture(e.target.value);
                                                                                handlePrefsDep(e);  // Appel de la première fonction
                                                                            }}
                                                                            onClick={handleDistrict}  // Si vous devez aussi appeler cette fonction au clic
                                                                        >
                                                                            <option value={'0'}></option>
                                                                            {prefs?.map((pref) => (
                                                                                <option key={pref.region} value={pref.region}>
                                                                                    {pref.prefecture}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                        <select className={'form-control'} value={district} id="DISTRICT" onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                                                            <option value={'0'}></option>
                                                                            {districts?.map(district => (
                                                                                <option value={district.district} >{district.district}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        depart == "CENTRE" && (
                                                            <><Col className="pr-4" md="4">

                                                            </Col>
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                        <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                                            <option value={'0'}></option>
                                                                            {provinces?.map(province => (
                                                                                <option value={province.province} >{province.province}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                        <select
                                                                            className={'form-control'}
                                                                            value={prefecture}
                                                                            onChange={(e) => {
                                                                                setPrefecture(e.target.value);
                                                                                handlePrefsDep(e);  // Appel de la première fonction
                                                                            }}
                                                                            onClick={handleDistrict}  // Si vous devez aussi appeler cette fonction au clic
                                                                        >
                                                                            <option value={'0'}></option>
                                                                            {prefs?.map((pref) => (
                                                                                <option key={pref.region} value={pref.region}>
                                                                                    {pref.prefecture}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                        <select className={'form-control'} value={district} id="DISTRICT" onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                                                            <option value={'0'}></option>
                                                                            {districts?.map(district => (
                                                                                <option value={district.district} >{district.district}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                        <select className={'form-control'} value={commune} id="CENTRE" onChange={e => { { setCommune(e.target.value) } }} onClick={handleCentre_examen}>
                                                                            <option value={'0'}></option>
                                                                            {communes?.map(commune => (
                                                                                <option value={commune.commune} >{commune.commune}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        depart == "ETABLISSEMENT" && (
                                                            <><Col className="pr-4" md="4">

                                                            </Col>
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                        <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                                                            <option value={'0'}></option>
                                                                            {provinces?.map(province => (
                                                                                <option value={province.province} >{province.province}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                        <select
                                                                            className={'form-control'}
                                                                            value={prefecture}
                                                                            onChange={(e) => {
                                                                                setPrefecture(e.target.value);
                                                                                handlePrefsDep(e);  // Appel de la première fonction
                                                                            }}
                                                                            onClick={handleDistrict}  // Si vous devez aussi appeler cette fonction au clic
                                                                        >
                                                                            <option value={'0'}></option>
                                                                            {prefs?.map((pref) => (
                                                                                <option key={pref.region} value={pref.region}>
                                                                                    {pref.prefecture}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                        <select className={'form-control'} value={district} id="DISTRICT" onChange={e => { setDistrict(e.target.value) }} onClick={handleCommune}>
                                                                            <option value={'0'}></option>
                                                                            {districts?.map(district => (
                                                                                <option value={district.district} >{district.district}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-4" md="4" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                        <select className={'form-control'} value={commune} id="CENTRE" onChange={e => { { setCommune(e.target.value) } }} onClick={handleCentre_examen}>
                                                                            <option value={'0'}></option>
                                                                            {communes?.map(commune => (
                                                                                <option value={commune.commune} >{commune.commune}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pr-3" md="3" >
                                                                    <FormGroup className="mr-3">
                                                                        <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                        <select className={'form-control'} value={etablissement} id="ETABLISSEMENT" onChange={e => { { setEtablissement(e.target.value) } }} onClick={handleSalle}>
                                                                            <option value={'0'}></option>
                                                                            {listEtablissement?.map(etab => (
                                                                                <option value={etab.centre_examen} >{etab.centre_examen}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <label style={{ border: 'type-round' }} > <a style={{ color: "black", fontWeight: 'bold' }}>ARTICLE</a> </label><br />
                                                            <select className={'form-control'} value={article} onChange={e => { setArticle(e.target.value) }} >
                                                                <option value={'0'}></option>
                                                                {articles?.map(article => (
                                                                    <option value={article.nom_materiel} >{article.nom_materiel}</option>
                                                                ))}
                                                            </select>
                                                            <Button className="btn btn-info" onClick={toogleArticle}>+</Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>IMMATRICULATION VEHICULE </a> :</Label>
                                                            <Input
                                                                placeholder="Matricule"
                                                                type="text"
                                                                value={camion_trans} onChange={e => { setCamion_trans(e.target.value) }}
                                                            >
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>MARQUE VEHICULE </a> :</Label>
                                                            <Input
                                                                placeholder="Marque"
                                                                type="text"
                                                                value={marque} onChange={e => { setMarque(e.target.value) }}
                                                            >
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>DATE DE L'ENVOI</a> :</Label>
                                                            <Input
                                                                placeholder="Adresse"
                                                                type="datetime-local"
                                                                value={date_envoi} onChange={e => { setDate_envoi(e.target.value) }}
                                                            >
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>QUANTITE ENVOYEE</a> :</Label>
                                                            <Input
                                                                type="number"
                                                                value={q_e} onChange={e => { setq_e(e.target.value) }}
                                                            >
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>NOM DU RESPONSABLE DE L'ENVOI (Expediteur)</a></label><br />
                                                            <input type="text" className={'form-control'} value={responsable_envoi} onChange={e => { setResponsable_envoi(e.target.value) }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>FONCTION DU RESPONSABLE DE L'ENVOI (Expéditeur)</a></label><br />
                                                            <input type="text" className={'form-control'} value={fonction_responsable_envoi} onChange={e => { setFonction_responsable_envoi(e.target.value) }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>CONTACT DU RESPONSABLE DE L'ENVOI (Expéditeur)</a></label><br />
                                                            <input type="text" className={'form-control'} value={contact_envoi} onChange={e => { setContact_envoi(e.target.value) }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>ETAT A L'ENVOI</a></label><br />
                                                            <input type="text" className={'form-control'} value={etat_envoi} onChange={e => { setEtat_envoi(e.target.value) }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup className="mr-3">
                                                            <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PHOTO A L'ENVOI</a></label><br />
                                                            <input type="file" className={'form-control'} onChange={(e) => handleFileInputChange(e, 'env')} /> ou &nbsp;&nbsp;&nbsp; <button onClick={toggleEnvoi}>Prendre une photo</button>&nbsp;&nbsp;
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="pr-3" md="4">
                                                        <FormGroup tag="fieldset">
                                                            <br />
                                                            <Button className="btn btn-primary" onClick={next}>
                                                                Suivant
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            )
                                        }
                                        {
                                            suivant == true && (
                                                <>
                                                    {
                                                        arrive == "OFFICE" && (
                                                            <>
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                        <select className={'form-control'} value={province_arrive} onChange={e => { setProvince_arrive(e.target.value); }} onClick={handleRegionArr}>
                                                                            <option value={'0'}></option>
                                                                            {provinces?.map(province => (
                                                                                <option value={province.province}>{province.province}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        arrive == "REGION" && (
                                                            <>
                                                                {
                                                                    depart == "CONCLAVE" && (
                                                                        <Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                                <select className={'form-control'} value={province_arrive} onChange={e => { setProvince_arrive(e.target.value); }} onClick={handleRegionArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {provinces?.map(province => (
                                                                                        <option value={province.province}>{province.province}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )
                                                                }
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                        <select
                                                                            className={'form-control'}
                                                                            value={prefecture_arrive}
                                                                            onChange={(e) => {
                                                                                setPrefecture_arrive(e.target.value);
                                                                                handlePrefsArr(e);  // Appel de la première fonction
                                                                            }}
                                                                            onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                        >
                                                                            <option value={'0'}></option>
                                                                            {prefs?.map((pref) => (
                                                                                <option key={pref.region} value={pref.region}>
                                                                                    {pref.prefecture}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        arrive == "DISTRICT" && (
                                                            <>
                                                                {
                                                                    depart == "CONCLAVE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                                <select className={'form-control'} value={province_arrive} onChange={e => { setProvince_arrive(e.target.value); }} onClick={handleRegionArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {provinces?.map(province => (
                                                                                        <option value={province.province}>{province.province}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                    <select
                                                                                        className={'form-control'}
                                                                                        value={prefecture_arrive}
                                                                                        onChange={(e) => {
                                                                                            setPrefecture_arrive(e.target.value);
                                                                                            handlePrefsArr(e);  // Appel de la première fonction
                                                                                        }}
                                                                                        onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                    >
                                                                                        <option value={'0'}></option>
                                                                                        {prefs?.map((pref) => (
                                                                                            <option key={pref.region} value={pref.region}>
                                                                                                {pref.prefecture}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "OFFICE" && (
                                                                        <Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                <select
                                                                                    className={'form-control'}
                                                                                    value={prefecture_arrive}
                                                                                    onChange={(e) => {
                                                                                        setPrefecture_arrive(e.target.value);
                                                                                        handlePrefsArr(e);  // Appel de la première fonction
                                                                                    }}
                                                                                    onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                >
                                                                                    <option value={'0'}></option>
                                                                                    {prefs?.map((pref) => (
                                                                                        <option key={pref.region} value={pref.region}>
                                                                                            {pref.prefecture}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )
                                                                }
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                        <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                            <option value={'0'}></option>
                                                                            {districts?.map(district => (
                                                                                <option value={district.district} >{district.district}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        arrive == "CENTRE" && (
                                                            <>
                                                                {
                                                                    depart == "CONCLAVE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                                <select className={'form-control'} value={province_arrive} onChange={e => { setProvince_arrive(e.target.value); }} onClick={handleRegionArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {provinces?.map(province => (
                                                                                        <option value={province.province}>{province.province}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                    <select
                                                                                        className={'form-control'}
                                                                                        value={prefecture_arrive}
                                                                                        onChange={(e) => {
                                                                                            setPrefecture_arrive(e.target.value);
                                                                                            handlePrefsArr(e);  // Appel de la première fonction
                                                                                        }}
                                                                                        onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                    >
                                                                                        <option value={'0'}></option>
                                                                                        {prefs?.map((pref) => (
                                                                                            <option key={pref.region} value={pref.region}>
                                                                                                {pref.prefecture}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                    <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {districts?.map(district => (
                                                                                            <option value={district.district} >{district.district}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "OFFICE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                <select
                                                                                    className={'form-control'}
                                                                                    value={prefecture_arrive}
                                                                                    onChange={(e) => {
                                                                                        setPrefecture_arrive(e.target.value);
                                                                                        handlePrefsArr(e);  // Appel de la première fonction
                                                                                    }}
                                                                                    onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                >
                                                                                    <option value={'0'}></option>
                                                                                    {prefs?.map((pref) => (
                                                                                        <option key={pref.region} value={pref.region}>
                                                                                            {pref.prefecture}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                    <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {districts?.map(district => (
                                                                                            <option value={district.district} >{district.district}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "REGION" && (
                                                                        <Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {districts?.map(district => (
                                                                                        <option value={district.district} >{district.district}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )
                                                                }
                                                                <Col className="pr-4" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                        <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                            <option value={'0'}></option>
                                                                            {communes?.map(commune => (
                                                                                <option value={commune.commune}>{commune.commune}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        arrive == "ETABLISSEMENT" && (
                                                            <>
                                                                {
                                                                    depart == "CONCLAVE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                                <select className={'form-control'} value={province_arrive} onChange={e => { setProvince_arrive(e.target.value); }} onClick={handleRegionArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {provinces?.map(province => (
                                                                                        <option value={province.province}>{province.province}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                    <select
                                                                                        className={'form-control'}
                                                                                        value={prefecture_arrive}
                                                                                        onChange={(e) => {
                                                                                            setPrefecture_arrive(e.target.value);
                                                                                            handlePrefsArr(e);  // Appel de la première fonction
                                                                                        }}
                                                                                        onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                    >
                                                                                        <option value={'0'}></option>
                                                                                        {prefs?.map((pref) => (
                                                                                            <option key={pref.region} value={pref.region}>
                                                                                                {pref.prefecture}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                    <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {districts?.map(district => (
                                                                                            <option value={district.district} >{district.district}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                    <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {communes?.map(commune => (
                                                                                            <option value={commune.commune}>{commune.commune}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "OFFICE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                <select
                                                                                    className={'form-control'}
                                                                                    value={prefecture_arrive}
                                                                                    onChange={(e) => {
                                                                                        setPrefecture_arrive(e.target.value);
                                                                                        handlePrefsArr(e);  // Appel de la première fonction
                                                                                    }}
                                                                                    onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                >
                                                                                    <option value={'0'}></option>
                                                                                    {prefs?.map((pref) => (
                                                                                        <option key={pref.region} value={pref.region}>
                                                                                            {pref.prefecture}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                    <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {districts?.map(district => (
                                                                                            <option value={district.district} >{district.district}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                    <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {communes?.map(commune => (
                                                                                            <option value={commune.commune}>{commune.commune}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "REGION" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {districts?.map(district => (
                                                                                        <option value={district.district} >{district.district}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                    <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {communes?.map(commune => (
                                                                                            <option value={commune.commune}>{commune.commune}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "DISTRICT" && (
                                                                        <Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {communes?.map(commune => (
                                                                                        <option value={commune.commune}>{commune.commune}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )
                                                                }
                                                                <Col className="pr-3" md="3">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                        <select className={'form-control'} value={etablissement_arrice} onChange={e => { { setEtablissement_arrive(e.target.value); } }} >
                                                                            <option value={'0'}></option>
                                                                            {listEtablissement?.map(etab => (
                                                                                <option value={etab.centre_examen}>{etab.centre_examen}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        arrive == "SALLE" && (
                                                            <>
                                                                {
                                                                    depart == "CONCLAVE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DOB/DEN :</a></label><br />
                                                                                <select className={'form-control'} value={province_arrive} onChange={e => { setProvince_arrive(e.target.value); }} onClick={handleRegionArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {provinces?.map(province => (
                                                                                        <option value={province.province}>{province.province}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                    <select
                                                                                        className={'form-control'}
                                                                                        value={prefecture_arrive}
                                                                                        onChange={(e) => {
                                                                                            setPrefecture_arrive(e.target.value);
                                                                                            handlePrefsArr(e);  // Appel de la première fonction
                                                                                        }}
                                                                                        onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                    >
                                                                                        <option value={'0'}></option>
                                                                                        {prefs?.map((pref) => (
                                                                                            <option key={pref.region} value={pref.region}>
                                                                                                {pref.prefecture}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                    <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {districts?.map(district => (
                                                                                            <option value={district.district} >{district.district}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                    <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {communes?.map(commune => (
                                                                                            <option value={commune.commune}>{commune.commune}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-3" md="3">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                                    <select className={'form-control'} value={etablissement_arrice} onChange={e => { { setEtablissement_arrive(e.target.value); } }} onClick={handleSalleArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {listEtablissement?.map(etab => (
                                                                                            <option value={etab.centre_examen}>{etab.centre_examen}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "OFFICE" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PREFECTURE : </a> </label><br />
                                                                                <select
                                                                                    className={'form-control'}
                                                                                    value={prefecture_arrive}
                                                                                    onChange={(e) => {
                                                                                        setPrefecture_arrive(e.target.value);
                                                                                        handlePrefsArr(e);  // Appel de la première fonction
                                                                                    }}
                                                                                    onClick={handleDistrictArr}  // Si vous devez aussi appeler cette fonction au clic
                                                                                >
                                                                                    <option value={'0'}></option>
                                                                                    {prefs?.map((pref) => (
                                                                                        <option key={pref.region} value={pref.region}>
                                                                                            {pref.prefecture}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                    <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {districts?.map(district => (
                                                                                            <option value={district.district} >{district.district}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                    <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {communes?.map(commune => (
                                                                                            <option value={commune.commune}>{commune.commune}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-3" md="3">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                                    <select className={'form-control'} value={etablissement_arrice} onChange={e => { { setEtablissement_arrive(e.target.value); } }} onClick={handleSalleArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {listEtablissement?.map(etab => (
                                                                                            <option value={etab.centre_examen}>{etab.centre_examen}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "REGION" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                                                                <select className={'form-control'} value={district_arrive} onChange={e => { setDistrict_arrive(e.target.value); }} onClick={handleCommuneArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {districts?.map(district => (
                                                                                        <option value={district.district} >{district.district}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-4" md="4">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                    <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {communes?.map(commune => (
                                                                                            <option value={commune.commune}>{commune.commune}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="pr-3" md="3">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                                    <select className={'form-control'} value={etablissement_arrice} onChange={e => { { setEtablissement_arrive(e.target.value); } }} onClick={handleSalleArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {listEtablissement?.map(etab => (
                                                                                            <option value={etab.centre_examen}>{etab.centre_examen}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "DISTRICT" && (
                                                                        <><Col className="pr-4" md="4">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE :</a> </label><br />
                                                                                <select className={'form-control'} value={commune_arrive} onChange={e => { { setCommune_arrive(e.target.value); } }} onClick={handleCentre_examenArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {communes?.map(commune => (
                                                                                        <option value={commune.commune}>{commune.commune}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col><Col className="pr-3" md="3">
                                                                                <FormGroup className="mr-3">
                                                                                    <br />
                                                                                    <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                                    <select className={'form-control'} value={etablissement_arrice} onChange={e => { { setEtablissement_arrive(e.target.value); } }} onClick={handleSalleArr}>
                                                                                        <option value={'0'}></option>
                                                                                        {listEtablissement?.map(etab => (
                                                                                            <option value={etab.centre_examen}>{etab.centre_examen}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </FormGroup>
                                                                            </Col></>
                                                                    )
                                                                }
                                                                {
                                                                    depart == "CENTRE" && (
                                                                        <Col className="pr-3" md="3">
                                                                            <FormGroup className="mr-3">
                                                                                <br />
                                                                                <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> CENTRE D'EXAMEN :</a> </label><br />
                                                                                <select className={'form-control'} value={etablissement_arrice} onChange={e => { { setEtablissement_arrive(e.target.value); } }} onClick={handleSalleArr}>
                                                                                    <option value={'0'}></option>
                                                                                    {listEtablissement?.map(etab => (
                                                                                        <option value={etab.centre_examen}>{etab.centre_examen}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )
                                                                }
                                                                <Col className="pr-3" md="3">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}> SALLE:</a> </label><br />
                                                                        <select className={'form-control'} value={salle} onChange={e => { { setSalle(e.target.value); } }}>
                                                                            <option value={'0'}></option>
                                                                            {salles?.map(salle => (
                                                                                <option value={salle.salles}>{salle.salles}</option>
                                                                            ))}
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                    {
                                                        arrive != '' && (
                                                            <><Col className="pr-3" md="4">
                                                                <FormGroup className="mr-3">
                                                                    <br />
                                                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>DATE DE LA RECEPTION</a> :</Label>
                                                                    <Input
                                                                        placeholder="Adresse"
                                                                        type="datetime-local"
                                                                        value={date_recu}
                                                                        onChange={e => setDate_recu(e.target.value)} />
                                                                </FormGroup>
                                                            </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>QUANTITE RECUE</a> :</Label>
                                                                        <Input
                                                                            type="number"
                                                                            value={q_r}
                                                                            onChange={e => setq_r(e.target.value)} />
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>NOM DU RESPONSABLE DE LA RECEPTION</a></label><br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>(Destinataire)</a></label>
                                                                        <input type="text" className={'form-control'} value={responsable_recu} onChange={e => setResponsable_recu(e.target.value)} />
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>FONCTION DU RESPONSABLE DE LA RECEPTION (Destinataire)</a></label><br />
                                                                        <input type="text" className={'form-control'} value={fonction_responsable_recu} onChange={e => setFonction_responsable_recu(e.target.value)} />
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>CONTACT DU RESPONSABLE DE LA RECEPTION (Destinataire)</a></label><br />
                                                                        <input type="text" className={'form-control'} value={contact_recu} onChange={e => setContact_recu(e.target.value)} />
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>ETAT A LA RECEPTION</a></label><br />
                                                                        <input type="text" className={'form-control'} value={etat_recu} onChange={e => setEtat_recu(e.target.value)} />
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>PHOTO A LA RECEPTION</a></label><br />
                                                                        <input type="file" className={'form-control'} onChange={(e) => handleFileInputChange(e, 'rec')} />ou &nbsp;&nbsp;&nbsp; <button onClick={toggleRecu}>Prendre une photo</button>&nbsp;&nbsp;
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup className="mr-3">
                                                                        <br />
                                                                        <label style={{ border: 'type-round' }}><a style={{ color: "black", fontWeight: 'bold' }}>OBSERVATION</a></label><br />
                                                                        <input type="text" className={'form-control'} value={observation} onChange={e => setObservation(e.target.value)} />
                                                                    </FormGroup>
                                                                </Col><Col className="pr-3" md="4">
                                                                    <FormGroup tag="fieldset">
                                                                        <br /><br />
                                                                        <Button className="btn btn-primary" onClick={save}>
                                                                            Enregistrer
                                                                        </Button>
                                                                        <Button className="btn btn-primary" onClick={retour}>
                                                                            Retour
                                                                        </Button>
                                                                    </FormGroup>
                                                                </Col></>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Row>
                    </Form>
                    <Button className="btn btn-outline-primary" onClick={voirPlus}>
                        {isVoi ? "Voir Moins" : "Voir Plus"}
                    </Button>
                    <Table style={{ marginTop: "40px" }} striped>
                        <thead className="text-primary">
                            <tr>
                                {
                                    types === "CONCLAVE_OFFICE" && (
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}>DOB/DEN&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    )
                                }
                                {
                                    types === "OFFICE_REGION" && (
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}> PREFECTURE&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    )
                                }
                                {
                                    types === "REGION_DISTRICT" && (
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}> DISTRICT&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    )
                                }
                                {
                                    types === "DISTRICT_CENTRE" && (
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}> CENTRE&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    )
                                }
                                {
                                    types === "CENTRE_ETABLISSEMENT" && (
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}> ETABLISSEMENT&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    )
                                }
                                {
                                    types === "ETABLISSEMENT_SALLE" && (
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}> SALLE&nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                    )
                                }
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Article&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Quantite envoyée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                {isVoi == true && (
                                    <>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_ecole')}>Camion transportée&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Photo à l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Nom du Responsable de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Fonction du responsable de l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Contact du responsable d'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Etat à l'envoi&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                    </>
                                )}
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Quantite recue&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Date de reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                {isVoi == true && (
                                    <>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Photo à la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Nom du Responsable de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Fonction du responsable de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Contact de la reception&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Etat recu&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                        <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'candidat_libre')}>Observation&nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listMisy.map((row, index) => (
                                    <>
                                        <tr key={index}>
                                            {
                                                types === "CONCLAVE_OFFICE" && (
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.office}
                                                    </td>
                                                )
                                            }
                                            {
                                                types === "OFFICE_REGION" && (
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.district}
                                                    </td>
                                                )
                                            }
                                            {
                                                types === "REGION_DISTRICT" && (
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.district}
                                                    </td>
                                                )
                                            }
                                            {
                                                types === "DISTRICT_CENTRE" && (
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.centre}
                                                    </td>
                                                )
                                            }
                                            {
                                                types === "CENTRE_ETABLISSEMENT" && (
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.centre_examen}
                                                    </td>
                                                )
                                            }
                                            {
                                                types === "ETABLISSEMENT_SALLE" && (
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.salles}
                                                    </td>
                                                )
                                            }
                                            <td style={{ fontSize: '12px' }}>
                                                {ANRDataUtils.formatNombre(row.materiel)}
                                            </td>
                                            <td style={{ fontSize: '12px' }}>
                                                {ANRDataUtils.formatNombre(row.q_e)}
                                            </td>
                                            <td style={{ fontSize: '12px' }}>
                                                {new Date(row.date_envoye).toLocaleString()}
                                            </td >
                                            {isVoi == true && (
                                                <>
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.vehicule_transport}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.photo_envoi != null &&
                                                            <img src={row.photo_envoi} alt="" />
                                                        }
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.responsable_envoye}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.fonction_envoi}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.contact_envoye}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.etat_envoye}
                                                    </td >
                                                </>
                                            )}
                                            <td style={{ fontSize: '12px' }}>
                                                {ANRDataUtils.formatNombre(row.q_r)}
                                            </td>
                                            <td style={{ fontSize: '12px' }}>
                                                {new Date(row.date_reception).toLocaleString()}
                                            </td >
                                            {isVoi == true && (
                                                <>
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.photo_recu != null &&
                                                            <img src={row.photo_recu} alt="" />
                                                        }
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.responsable_reception}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.fonction_responsable}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.contact_reception}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.etat_recu}
                                                    </td >
                                                    <td style={{ fontSize: '12px' }}>
                                                        {row.observation}
                                                    </td >
                                                </>
                                            )}
                                        </tr>
                                    </>
                                ))}
                            {
                                isVoi == true && (
                                    listTsymisy.map((row, index) => (
                                        <>
                                            <tr key={index}>
                                                {
                                                    types === "CONCLAVE_OFFICE" && (
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.office}
                                                        </td>
                                                    )
                                                }
                                                {
                                                    types === "OFFICE_REGION" && (
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.region}
                                                        </td>
                                                    )
                                                }
                                                {
                                                    types === "REGION_DISTRICT" && (
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.district}
                                                        </td>
                                                    )
                                                }
                                                {
                                                    types === "DISTRICT_CENTRE" && (
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.centre}
                                                        </td>
                                                    )
                                                }
                                                {
                                                    types === "CENTRE_ETABLISSEMENT" && (
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.centre_examen}
                                                        </td>
                                                    )
                                                }
                                                {
                                                    types === "ETABLISSEMENT_SALLE" && (
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.salles}
                                                        </td>
                                                    )
                                                }
                                                <td style={{ fontSize: '12px' }}>
                                                    {row.materiel}
                                                </td>
                                                <td style={{ fontSize: '12px' }}>
                                                    {ANRDataUtils.formatNombre(row.q_e)}
                                                </td>
                                                <td style={{ fontSize: '12px' }}>
                                                    {row.date_envoye}
                                                </td >
                                                {isVoi == true && (
                                                    <>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.vehicule_transport}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {/* {row.photo_envoi != null ?
                                                                <img src={row.photo_envoi} alt="Photo Envoi" /> :
                                                                <input type="file" onChange={(e) => handleFile(e, row.responsable_envoye)} />
                                                            } */}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.responsable_envoye}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.fonction_envoi}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.contact_envoye}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.etat_envoye}
                                                        </td >
                                                    </>
                                                )}
                                                <td style={{ fontSize: '12px' }}>
                                                    {ANRDataUtils.formatNombre(row.q_r)}
                                                </td>
                                                <td style={{ fontSize: '12px' }}>
                                                    {row.date_reception}
                                                </td >
                                                {isVoi == true && (
                                                    <>
                                                        <td style={{ fontSize: '12px' }}>
                                                            {/* {row.photo_recu != null ?
                                                                <img src={row.photo_recu} alt="Photo Envoi" /> :
                                                                <input type="file" />
                                                            }
                                                            <img src={img} alt="Photo Envoi" /> */}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.responsable_reception}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.fonction_responsable}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.contact_reception}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.etat_recu}
                                                        </td >
                                                        <td style={{ fontSize: '12px' }}>
                                                            {row.observation}
                                                        </td >
                                                    </>
                                                )}

                                            </tr>
                                        </>
                                    ))
                                )
                            }
                        </tbody>
                    </Table>
                    {/* </div> */}
                </CardBody>
            </Card>
        </>
    );
}

export default Nouveau_logistique;