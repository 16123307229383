import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import authHeader from '../../services/AuthHeader';
import url from "../../urlConfig";
import ANRDataUtils from "../../utils/Utils";
const CandidatQG = ({ types, setVisibleCandidat, region, commune, district, province, etablissement }) => {
    const auth_data = authHeader()
    const [lists, setLists] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null); // État pour stocker les erreurs
    const [listEtablissement, setListEtablissement] = useState([]);
    const [etablissement_d, setEtablissement_d] = useState('');
    const [nbr_electeur, setNbrElecteur] = useState(0);
    const [total_additionel, setTotal_additionel] = useState(0);
    const [candidat_ecole_1, setCandidat_ecole_1] = useState(0);
    const [candidat_libre_1, setCandidat_libre_1] = useState(0);
    const [nombre_membre, setNombre_membre] = useState(0);
    const [labels, setLabels] = useState([]);
    const [electeurs, setElecteurs] = useState([])
    const [membre, setMembre] = useState([]);
    const [validation, setValidation] = useState([]);
    const [etat, setEtat] = useState([])
    const [listeId, setListeId] = useState([]);

    const toDateFormat = (date) => {
        const dateString = date

        // Parse the date string into a Date object
        const moment = require('moment');

        // Parse the date string and format it into "DD-MM-YYYY" format
        const formattedDate = moment(dateString, "ddd MMM DD HH:mm:ss Z YYYY").format("DD-MM-YYYY");

        return formattedDate;

    }
    // const [loader, setLoader] = useState(false);
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.7,
    };
    const [isShow, setisShow] = useState(false);
    const show = () => {
        setIsMpotra(!isMpotra);
    }
    const [isMpotra, setIsMpotra] = useState(false);
    const [listss, setListss] = useState([]);
    const getListEtablissement = () => {
        // console.log(url.urlHttp + "candidat-midina/candidat-ecole-liste-avec-recherche-etablissement?etablissement=" + etablissement + "&&centre=" + commune);
        fetch(url.urlHttp + "candidat-midina/candidat-ecole-liste-avec-recherche-etablissement?etablissement=" + etablissement + "&&commune=" + commune
            + "&&district=" + district + "&&province=" + province + "&&region=" + region, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA RETOUR : ", data.data);
                setListEtablissement(data.data)
                setLists(data.data);
                setListss(data.data);
                // setLabels(data.data.map(row => row.distrcit))
                // setElecteurs(data.data.map(row => row.nbr_qg))
                // setLoader(false)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const pdfStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "11.11%",
            borderStyle: "solid",
            backgroundColor: "#f5f5f5",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNonCheker: {
            width: "11.11%",
            borderStyle: "solid",
            backgroundColor: "#7e7676",
            borderWidth: 1,
            color: "#ffffff",
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColCheker: {
            width: "11.11%",
            borderStyle: "solid",
            backgroundColor: "#e35f5f",
            borderWidth: 1,
            borderLeftWidth: 0,
            color: "#ffffff",
            borderTopWidth: 0
        },
        tableCol: {
            width: "11.11%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 6
        }
    });

    const [name, setName] = useState('')
    const DistrictPDF = () => {
        if (types == 'Région') {
            setName(region)
        } else if (types == 'District') {
            setName(district)
        } else if (types == 'Commune') {
            setName(commune)
        } else {
            setName(etablissement)
        }

        return (
            <Document>
                <Page size={'A4'} style={{ padding: '19px' }}>
                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}> Répartition de types de candidats au niveau   <Text style={{ color: 'red' }}>{types} </Text> </Text></View>
                    {province !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Province : <Text style={{ color: 'red' }}>{province}</Text> </Text></View>
                        </>
                    )}
                    {region !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Région : <Text style={{ color: 'red' }}>{region}</Text> </Text></View>
                        </>
                    )}
                    {district !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>District : <Text style={{ color: 'red' }}>{district}</Text> </Text></View>
                        </>
                    )}
                    {commune !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Centre : <Text style={{ color: 'red' }}>{commune}</Text> </Text></View>
                        </>
                    )}
                    {etablissement !== '' && (
                        <>
                            <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Établissement : <Text style={{ color: 'red' }}>{etablissement}</Text> </Text></View>
                        </>
                    )}

                    <View><Text style={{ fontWeight: 'bold', color: 'green' }}>Total de  Candidats   <Text style={{ color: '#000000' }}></Text> <Text style={{ color: 'red' }}>{ANRDataUtils.formatNombre(listEtablissement.length)}</Text> </Text></View>
                    \<View><Text>-----------------------------------------------------------------------------------------------</Text></View>
                    <View><Text style={{ fontWeight: 'bold' }}>Listes au niveau {types} <Text style={{ color: 'red' }}>{name}</Text> </Text></View>
                    <View style={pdfStyles.table}>
                        <View style={pdfStyles.tableRow}>
                            {/* {district === '' && ( */}
                            <>
                                <View style={pdfStyles.tableColHeader}>
                                    <Text style={pdfStyles.tableCell}>District  </Text>
                                </View>
                            </>
                            {/* )} */}
                            {/* {commune === '' && ( */}
                            <>
                                <View style={pdfStyles.tableColHeader}>
                                    <Text style={pdfStyles.tableCell}>Commune  </Text>
                                </View>
                            </>
                            {/* ) } */}

                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Numero Inscription</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Nom</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Prénom</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Sexe</Text>
                            </View>

                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Série</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Type de candidat</Text>
                            </View>
                            <View style={pdfStyles.tableColHeader}>
                                <Text style={pdfStyles.tableCell}>Date Naissance</Text>
                            </View>
                        </View>
                        {
                            listEtablissement.map(dist => (
                                <View style={pdfStyles.tableRow}>
                                    {/* {district === '' && ( */}
                                    <>
                                        <View style={pdfStyles.tableColHeader}>
                                            <Text style={pdfStyles.tableCell}>{dist.district}  </Text>
                                        </View>
                                    </>
                                    {/* )} */}
                                    {/* {commune === '' && ( */}
                                    <>
                                        <View style={pdfStyles.tableColHeader}>
                                            <Text style={pdfStyles.tableCell}>{dist.commune}  </Text>
                                        </View>
                                    </>
                                    {/* )} */}
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.numero_incription}</Text>
                                    </View>

                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.nom}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.prenom}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.sexe}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.serie_option}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{dist.type_candidat}</Text>
                                    </View>
                                    <View style={pdfStyles.tableCol}>
                                        <Text style={pdfStyles.tableCell}>{toDateFormat(dist.date_naissance)}</Text>
                                    </View>
                                    {/* <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{ANRDataUtils.formatNombre(parseInt(calculPourcentage(dist.nbr_electeur, pourcentage)))}</Text>
                                </View> */}
                                </View>
                            ))
                        }
                    </View>
                </Page>
            </Document>
        )
    }
    const setValidations = (etat) => {
        console.log("miditra", url.urlHttp + "candidat-midina/validation?etat=" + etat + "&&etablissement=" + etablissement + "&&centre=" + commune);
        fetch(url.urlHttp + "candidat-midina/validation?etat=" + etat + "&&etablissement=" + etablissement + "&&centre=" + commune, {
            method: 'POST',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(listeId),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Valider daholo");
                setListEtablissement(data.data)
                setTotal_additionel(calculTotal(data.data))
            })
            .catch(error => {
                console.log(error);
            });
    }

    const maka_listId = () => {
        // const timer = setTimeout(() => {
        const filteblackIds = listEtablissement
            .filter(entry => entry.validation === 0)
            .map(entry => entry.id);
        setListeId(filteblackIds);
        // }, 1000);

        // Clear the timer
        // return () => clearTimeout(timer);
    }

    useEffect(() => {
        getListEtablissement();
        maka_listId();
    }, [])
    console.log(listeId);

    const stat = {
        // sumCandidatLibre: listEtablissement.map(entry => entry.candidat_libre).blackuce((acc, val) => acc + val, 0),
        // sumCandidatEcole: listEtablissement.map(entry => entry.candidat_ecole).blackuce((acc, val) => acc + val, 0),
        sumTotal: listEtablissement.length
    };
    const calculTotal = (donnee) => {
        let t = 0;
        let i = 0;
        for (i = 0; i < donnee.length; i++) {
            if (donnee[i].additionnel == 1) {
                t = t + 1;
            }

        }
        return t;
    }
    const valide = (value1, value2) => {
        setListeId(value1);

        console.log(listeId);
        setValidations(value2);
    }

    const valides = (value) => {
        maka_listId();

        console.log(listeId);
        setValidations(value);
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listEtablissement]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListEtablissement(sortedWords);
        } else {
            setMilahatra(true);
            setListEtablissement(listss);
        }
        console.log(milahatra);
    };

    const filtreNom = (value) => {
        const filteredData = lists.filter(item => item.nom.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
        setListss(filteredData);
    };
    const filtrePrenom = (value) => {
        const filteredData = lists.filter(item => item.prenom.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
        setListss(filteredData);
    };
    const filtreNumero = (value) => {
        const filteredData = lists.filter(item => item.numero_incription.toUpperCase().includes(value.toUpperCase()));
        setListEtablissement(filteredData);
        setListss(filteredData);
    }

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    {/* Affichez un message d'erreur s'il y a une erreur */}
                    {error && <div className="alert alert-danger">{error}</div>}
                    {isShow == false && (<Row className="justify-content-center">
                        <Col md="4">
                            <Card style={{ height: "100%" }}>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{ color: "green", fontSize: '1rem', fontWeight: 'bold' }}> Au niveau {types} </span>
                                        {province !== '' && (
                                            <>
                                                <span style={{ color: "black", fontWeight: 'bold', fontSize: '1rem' }}>Province : <a style={{ color: "red" }}>{province}</a> </span>
                                            </>
                                        )}
                                        {region !== '' && (
                                            <>
                                                <span style={{ color: "black", fontWeight: 'bold', fontSize: '1rem' }}>Région : <a style={{ color: "red" }}>{region}</a></span>
                                            </>
                                        )}
                                        {district !== '' && (
                                            <>
                                                <span style={{ color: "black", fontWeight: 'bold', fontSize: '1rem' }}>District : <a style={{ color: "red" }}>{district}</a></span>
                                            </>
                                        )}
                                        {commune !== '' && (
                                            <>
                                                <span style={{ color: "black", fontWeight: 'bold', fontSize: '1rem' }}>Centre : <a style={{ color: "red" }}>{commune}</a></span>
                                            </>
                                        )}

                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card style={{ height: "100%", flexDirection: "column" }}>
                                <CardHeader>
                                    <CardTitle tag="h4" style={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        {etablissement !== '' && (
                                            <>
                                                <span style={{ color: "black", fontWeight: 'bold', fontSize: '1rem', justifyContent: "center", alignItems: "center", textAlign: 'center' }}>Établissement : <br /> <a style={{ color: "red", justifyContent: "center", alignItems: "center" }}>{etablissement}</a></span><br />
                                            </>
                                        )}
                                        {isMpotra == true && (
                                            <>
                                                <span style={{ color: "black", fontWeight: 'bold', fontSize: '1rem' }}> Total de Candidats : <a style={{ fontWeight: 'bold', color: "green", fontSize: '1.8rem' }}>{stat.sumTotal}</a> </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>
                                                    (+{ANRDataUtils.formatNombre(total_additionel)})
                                                </span>
                                            </>
                                        )}
                                        <br />
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>)}<br />
                </Col>


            </Row>
            {isShow == false && (<Row>
                <Col className="pr-1" md="4">
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Nom :</a></label>
                        <input type={'text'} style={{ fontSize: '0.9rem' }} className={'form-control'} placeholder={'Rechercher Nom'} s onChange={e => filtreNom(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pr-1" md="4">
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Prénoms :</a></label>
                        <input type={'text'} style={{ fontSize: '0.9rem' }} className={'form-control'} placeholder={'Rechercher Prénom'} s onChange={e => filtrePrenom(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pr-1" md="4">
                    <FormGroup>
                        <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.2rem' }}>Numero d'inscription :</a></label>
                        <input type={'text'} style={{ fontSize: '0.9rem' }} className={'form-control'} placeholder={"Rechercher Numero d'incription"} s onChange={e => filtreNumero(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>)}
            <Row>
                <Col md="12">
                    <Card style={{ marginTop: '40px' }}>
                        <CardBody>
                            {isShow == false && (<CardHeader>
                                <CardTitle tag="h4" style={{ position: 'fi', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ color: "green", fontSize: '1.5rem', fontWeight: 'bold' }}>
                                        Au niveau {types}
                                    </span>
                                    <span style={{ color: "green", fontSize: '1rem', fontWeight: 'bold' }}>
                                        {province !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Province : <a style={{ color: "red" }}>{province}</a> </span><br />
                                            </>
                                        )}
                                        {region !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Région : <a style={{ color: "red" }}>{region}</a></span><br />
                                            </>
                                        )}&nbsp;
                                        {district !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1rem' }}>District : <a style={{ color: "red" }}>{district}</a></span><br />
                                            </>
                                        )}&nbsp;
                                        {commune !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1rem' }}>Centre : <a style={{ color: "red" }}>{commune}</a></span><br />
                                            </>
                                        )}&nbsp;
                                        {etablissement !== '' && (
                                            <>
                                                <span style={{ color: "black", fontSize: '1rem', justifyContent: "center", alignItems: "center", textAlign: 'center' }}>Établissement : <a style={{ color: "red", justifyContent: "center", alignItems: "center" }}>{etablissement}</a></span><br />
                                            </>
                                        )}&nbsp;
                                        <span style={{ color: "black", fontSize: '1rem' }}> Total de Candidats : <a style={{ fontWeight: 'bold', fontSize: '1.5rem', color: "red" }}>{stat.sumTotal}</a> </span><br />
                                    </span>
                                </CardTitle>
                            </CardHeader>)}

                            <Button className="btn btn-outline-primary " onClick={e => { setVisibleCandidat(false) }}>retour</Button>
                            <Button className="btn btn-outline-primary" onClick={show}>
                                {isMpotra ? "Fermer" : "Voir candidats additionnels"}
                            </Button>
                            <PDFDownloadLink className="btn btn-primary fa fa-file" document={<DistrictPDF />} fileName={"National"}>
                                {({ blob, url, loading, error }) =>
                                    loading ? ' Chargement du PDF...' : ' Exporter PDF'
                                }
                            </PDFDownloadLink>
                            {isShow == false && (<div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                <Table striped>
                                    <thead className="text-primary sticky-header">
                                        <tr>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'region')}>Région &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'district')}>District &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'commune')}>Commune &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>

                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'numero_incription')}>Numéro &nbsp;<i class="fa fa-arrow-up-1-9"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'nom')}>Nom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'prenom')}>Prénom &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'sexe')}>Sexe &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'type_candidat')}>Type de Candidats &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                            <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'date_naissance')}>Date de naissance &nbsp;<i class="fa fa-arrow-down"></i></th>
                                            {/* <th>
                                            <button className={'btn btn-success'} >Valider</button>
                                                            <button className={'btn btn-danger'} >Refuser</button>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listEtablissement.map((row, index) => (
                                                <>
                                                    <tr>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {row.region}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.district)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.commune)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {row.numero_incription.split(".")[0]}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.nom)}
                                                        </td>
                                                        <td style={{ fontSize: '12px', textAlign: "left" }} >
                                                            {(row.prenom)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.sexe)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {(row.type_candidat)}
                                                        </td>
                                                        <td style={{ fontSize: '12px' }} >
                                                            {toDateFormat(row.date_naissance)}
                                                        </td>
                                                        {/* <td>
                                                            <button className={'btn btn-success'} >Valider</button>
                                                            <button className={'btn btn-danger'} >Refuser</button>
                                                        </td> */}

                                                    </tr>
                                                </>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
// }

export default CandidatQG;
