// Calendrier.js
import React, { useEffect, useState } from 'react';
import Calendar from './Calendar';
import url from "../../urlConfig";
import authHeader from '../../services/AuthHeader';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";


function Calendrier() {
  const auth_data = authHeader()
  const [serie, setSerie] = useState('A1');
  const [specialite, setSpecialite] = useState('General');
  const [secteur, setSecteur] = useState('Litteraire');
  const [data, setData] = useState([]);
  const [session, setSession] = useState('1');
  const [specialites, setSpecialites] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [series, setSeries] = useState([]);

  // Sample schedule data
  const getListEdt = () => {
    fetch(url.urlHttp + "edt/liste?id_session=" + session + "&&serie=" + serie, {
      method: 'POST',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA RETOUR : ", data.data);
        setData(data.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getSpecialite = () => {
    fetch(url.urlHttp + "candidat/specialite", {
      method: 'GET',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA spec", data.data);
        setSpecialites(data.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleSecteur = () => {
    fetch(url.urlHttp + "candidat/secteur?specialite=" + specialite, {
      method: 'GET',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA secteur", data.data);
        setSecteurs(data.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleSerie = () => {
    fetch(url.urlHttp + "candidat/serie?secteur=" + secteur, {
      method: 'GET',
      headers: {
        'Content-Type': auth_data.content_type,
        'Authorization': auth_data.authorization
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("DATA serie", data.data);
        setSeries(data.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleSearchs = () => {
    getListEdt();
  }

  useEffect(() => {
    getListEdt();
    getSpecialite();
    handleSecteur();
    handleSerie();
  }, [])

  console.log(data);
  return (
    <div className="content">
      <CardBody>
        <h3 ><a style={{color:'black'}}>Emplois du temps du Specialité</a> <a style={{color:'red'}}>{specialite}</a> <a style={{color:'black'}}>Secteur</a> <a style={{color:'red'}}>{secteur}</a> <a style={{color:'black'}}>Série</a> <a style={{color:'red'}}>{serie}</a> </h3>
        <Row>
          <Col md="3">
            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > SPÉCIALITÉ : </label><br />
            <select className={'form-control'} value={specialite} onChange={e => { setSpecialite(e.target.value) }} onClick={handleSecteur}>
              <option value="" ></option>
              {specialites?.map(specialite => (
                <option value={specialite.specialite} >{specialite.specialite}</option>
              ))}
            </select>
          </Col>
          <Col md="3">
            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > SECTEUR : </label><br />
            <select className={'form-control'} value={secteur} onChange={e => { setSecteur(e.target.value) }} onClick={handleSerie}>
              <option value=""></option>
              {secteurs?.map(secteur => (
                <option value={secteur.secteur} >{secteur.secteur}</option>
              ))}
            </select>
          </Col>
          <Col md="3">
            <label style={{ border: 'type-round', fontWeight: 'bold', color: "black" }} > SÉRIE : </label><br />
            <select className={'form-control'} value={serie} onChange={e => { setSerie(e.target.value) }} >
              <option value="" ></option>
              {series?.map(serie => (
                <option value={serie.serie_option} >{serie.serie_option}</option>
              ))}
            </select>
          </Col>
          <Col md="3">
            <Button className="btn btn-outline-info" onClick={(e) => { handleSearchs() }} style={{ marginTop: "20px" }}>
              Voir Emplois du temps
            </Button>
          </Col>
        </Row>
        <Calendar scheduleData={data} />
      </CardBody>
    </div>
  );
}

export default Calendrier;
