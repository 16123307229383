import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardTitle, CardBody, Table, Button, CardHeader, FormGroup } from 'reactstrap';
import url from 'urlConfig';
import RegionStatQG from 'views/personnels/RegionStatQG';
import ANRDataUtils from 'utils/Utils';
import './../scrollbar.css';
import authHeader from 'services/AuthHeader';


const ProvincePersonnelQG = () => {
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [labes, setLabels] = useState([]);
    const [libre, setLibre] = useState(true);
    const [ecole, setEcole] = useState(true);
    const [voir, setVoir] = useState(false);
    const [listMisy, setListMisy] = useState([])
    const [listTsymisy, setListTsyMisy] = useState([]);
    const [lists, setLists] = useState([]);
    const [listss, setListss] = useState([]);

    const getsListTsisy = (data) => {
        let newListMisy = [];
        let newListTsyMisy = [];
        let i = 0;
        for (i = 0; i < data.length; i++) {
            console.log('data', i);
            if (data[i].personnel > 0) {
                newListMisy.push(data[i]);
            } else {
                newListTsyMisy.push(data[i]);
            }
        }
        console.log('datas', i);
        setListMisy(newListMisy);
        setListss(newListMisy);
        setListTsyMisy(newListTsyMisy);
    }

    const [isVoi, setIsVoi] = useState(false)

    const voirPlus = () => {
        if (isVoi == true) {
            setIsVoi(false);
        } else {
            setIsVoi(true);
        }

    }
    let datas = {
        region: ''
    }

    const [list, setList] = useState([]);
    const getProvinces = () => {
        setLoader(true);
        fetch(url.urlHttp + "personnel/province", {
            method: "get",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        }).then(response => response.json())
            .then((res) => {
                setList(res.data);
                getsListTsisy(res.data);
                setLists(res.data);

            })
            .catch(error => { console.log(error) })
    }
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.province.toUpperCase().includes(value.toUpperCase()));
        getsListTsisy(filteredData)
    }

    

    const stat = {
        sumpersonnel: list.map(entry => entry.personnel).reduce((acc, val) => acc + val, 0),
        sumsds: list.map(entry => entry.surveillants_de_salle).reduce((acc, val) => acc + val, 0),
        sumsdc: list.map(entry => entry.surveillants_de_cour).reduce((acc, val) => acc + val, 0),
        sumaa: list.map(entry => entry.autres_agents).reduce((acc, val) => acc + val, 0),
        sumcdc: list.map(entry => entry.chef_de_centre).reduce((acc, val) => acc + val, 0),
        sums: list.map(entry => entry.secretaires).reduce((acc, val) => acc + val, 0),
        sumsalle: list.map(entry => entry.nombre_salle).reduce((acc, val) => acc + val, 0),
        sumcentre: list.map(entry => entry.nombre_centre).reduce((acc, val) => acc + val, 0),
    };


    const [visibleRegion, setVisibleRegion] = useState(false);
    const [province_sec, setprovince_sec] = useState(null)
    const detailProvince = (value) => {
        setprovince_sec(value)
        setVisibleRegion(true)
    }

    useEffect(() => {
        getProvinces();
        // getCardDASHBOARD();

    }, [])

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...listMisy]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setListMisy(sortedWords);
        } else {
            setMilahatra(true);
        setListMisy(listss);
        }
        console.log(milahatra);
    };

    console.log("azertyuioppapiezpouazopeua" + (list));
    const handleSelect = (key) => {

        detailProvince(key)
    }

    if (visibleRegion == true && province_sec != null) {
        return (
            <>
                <RegionStatQG setVisibleRegion={setVisibleRegion} province={province_sec} libre={libre} ecole={ecole} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row>
                    <Row>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }}>
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Nombre d' Établissements</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumcentre)}
                                    </p>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }}>
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Total de salles</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumsalle)}
                                    </p>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }}>
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle tag="p" className="text-center" style={{ fontSize: '1rem', color: 'black' }}>Chef de centre</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumcdc)}
                                    </p>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4" >
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Secrétaires</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sums)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Surveillants de cours</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumsdc)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Surveillants de salle</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumsds)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Autres agents</CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumaa)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="3" sm="4">
                            <Card style={{ height: "90%" }} >
                                <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CardTitle className="text-center" tag="p" style={{ fontSize: '1rem', color: 'black' }}>Nombre de Personnels : </CardTitle>
                                    <p className="text-center" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
                                        {ANRDataUtils.formatNombre(stat.sumpersonnel)}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Row>
                <Row>
                <Col className="pr-1" md="4"><br />
                            <FormGroup>
                                <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Province :</a></label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    placeholder={'rechercher province'}
                                    onChange={e => filtre(e.target.value)}
                                />
                            </FormGroup><br />
                        </Col>
                </Row>
                <Row>
                    <Col md="12">
                    <Card>
                        <CardBody>
                           <CardHeader>
                            <CardTitle>
                            <span style={{ color: "green" , fontWeight: "bold", fontSize:"20px"}}> Au niveau National</span>
                            </CardTitle>
                           </CardHeader>
                    <Button className="btn btn-outline-primary" onClick={voirPlus}>
                        {isVoi ? "Voir Moins" : "Voir Plus"}
                    </Button>
                    <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                    <Table style={{ marginTop: "-20px" }} striped>
                        <thead className="text-primary sticky-header">
                            <tr>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('asc', 'province')}>Province &nbsp;<i class="fa fa-arrow-up-a-z"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_centre')}>Nombre d' Établissements &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'nombre_salle')}>Total de salles &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '13px' }} onClick={() => handleSort('desc', 'personnel')}>Total de personnels &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'chef_de_centre')}>Chef de centre &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'surveillants_de_salle')}>Surveillants de salle &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'surveillants_de_cour')}>Surveillants de cours &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'secretaires')}>Secrétaires &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th style={{ fontSize: '12px' }} onClick={() => handleSort('desc', 'autres_agents')}>Autres agents &nbsp;<i class="fa fa-arrow-down-9-1"></i></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listMisy.map((row) => (
                                    <>
                                        <tr>
                                            <td >
                                                {row.province}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.nombre_centre)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.nombre_salle)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.personnel)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.chef_de_centre)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.surveillants_de_salle)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.surveillants_de_cour)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.secretaires)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.autres_agents)} 
                                            </td>
                                            <td >
                                                {
                                                    row.personnel > 0 ?
                                                        <button className={'btn btn-danger'} onClick={(e) => { detailProvince(row.province) }}>Afficher Region</button>
                                                        :
                                                        <button className={'btn btn-danger disabled'} >Afficher Region</button>
                                                }
                                            </td>

                                        </tr>
                                    </>
                                ))}
                                {isVoi == true ? 
                                listTsymisy.map((row) => (
                                    <>
                                        <tr>
                                            <td style={{ fontSize: '13px' }}>
                                                {row.province}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.nombre_centre)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.nombre_salle)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.personnel)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.chef_de_centre)}
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.surveillants_de_salle)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.surveillants_de_cour)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.secretaires)} 
                                            </td>
                                            <td style={{ fontSize: '1.5rem' }}>
                                                {ANRDataUtils.formatNombre(row.autres_agents)} 
                                            </td>
                                            <td style={{ fontSize: '13px' }}>
                                                {
                                                    row.personnel > 0 ?
                                                        <button className={'btn btn-danger'} onClick={(e) => { detailProvince(row.province) }}>Afficher Region</button>
                                                        :
                                                        <button className={'btn btn-danger disabled'} >Afficher Region</button>
                                                }
                                            </td>

                                        </tr>
                                    </>
                                )) : ''} 
                        </tbody>
                    </Table>
                    </div>
                     
                    </CardBody>
                    </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProvincePersonnelQG;